import { ReactComponent as PrinterSvg } from "../../../../assets/imprimer.svg";
import { ReactComponent as ImprementSvg } from "../../../../assets/imprimente.svg";
import { Checkbox } from "@material-tailwind/react";

export interface IImpressionProps {}

export default function Impression(props: IImpressionProps) {
  const checkBoxes = [
    "Ordonnance médicale",
    "Analyses biologiques",
    "Examens radiologiques",
  ];
  return (
    <div className=" flex gap-4 mt-6">
      <div className=" flex flex-col justify-center gap-6">
        {checkBoxes.map((item, index) => {
          return (
            <div key={index} className="flex items-center gap-2 text-[#858585]				">
              <Checkbox key={index} name={item} />
              <div>{item}</div>
            </div>
          );
        })}
      </div>
      <div className="w-[1px] h-[100px]  bg-[##5E5E5E]"></div>
      <div className="impression flex flex-col gap-4 justify-between">
        <ImprementSvg className=" w-[15rem] aspect-square" />
        <button className="flex  gap-2 bg-[#1AA5C0] py-3 justify-center items-center text-white rounded-md">
          <PrinterSvg width={20} />
          Imprimer
        </button>
      </div>
    </div>
  );
}
