// DynamicDialogWrapper.tsx
import React from 'react';
import useDynamicDialog from '../../hooks/useDynamicDialog';
import DynamicDialog from './DynamicDialog';

const DynamicDialogWrapper: React.FC = () => {
  const {
    open,
    confirm,
    message,
    size,
    component,
    title,
    handleConfirm,
    handleCancel,
    closeDynamicDialog,
  } = useDynamicDialog();

  return (
    <>
   
        <DynamicDialog
          open={open ? open : false}
          size={size}
          handleClose={closeDynamicDialog}
          message={message}
          component={component}
          title={title}
          handleConfirm={handleConfirm}
          handleCancel={handleCancel}
        />
    
    </>
  );
};

export default DynamicDialogWrapper;
