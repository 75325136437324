import { Card, Typography } from "@material-tailwind/react";
import { useNavigate } from "react-router-dom";

// const header = ["Name", "Job", "Employed", ""];

type rowsType = {
  ayant: string;
  surname: string;
  name: string;
  gender: string;
  id: number;
};

type headerType = string[];
export default function Table({
  header,
  rows,
}: {
  header: headerType;
  rows: rowsType[];
}) {
  const nav = useNavigate();
  return (
    <Card className="overflow-y-scroll max-h-[80%] h-full w-full rounded-lg border-2 border-gray-600/50 p-4">
      <table className="w-full min-w-max table-auto text-left">
        <thead>
          <tr>
            {header.map((head) => (
              <th
                key={head}
                className="border-b border-blue-gray-100 bg-white p-4"
              >
                <Typography
                  variant="small"
                  className="font-xl text-xl  text-[#1f1e1e] leading-none opacity-70"
                >
                  {head}
                </Typography>
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {rows.map(({ ayant, surname, name, gender, id }, index) => (
            <tr
              key={index}
              className=" [&>*]:my-1 border-white border-8 bg-gray-200"
            >
              <td className="p-4">
                <Typography
                  variant="small"
                  color="blue-gray"
                  className="font-normal text-[#6f6969] rounded-lg font-xl text-lg"
                >
                  {ayant}
                </Typography>
              </td>
              <td className="p-4">
                <Typography
                  variant="small"
                  color="blue-gray"
                  className="font-normal text-[#6f6969] rounded-lg font-xl text-lg"
                >
                  {surname}
                </Typography>
              </td>
              <td className="p-4">
                <Typography
                  variant="small"
                  color="blue-gray"
                  className="font-normal text-[#6f6969] rounded-lg font-xl text-lg"
                >
                  {name}
                </Typography>
              </td>
              <td className="p-4">
                <Typography
                  variant="small"
                  color="blue-gray"
                  className="font-normal text-[#6f6969] font-xl text-lg"
                >
                  {gender}
                </Typography>
              </td>
              <td className="p-4 w-[20rem]">
                <button
                  className="bg-[#1AA5C0] px-3 gap-2 rounded-full py-2 flex justify-center items-center  "
                  onClick={() => {
                  }}
                >
                  <img src="/addpatient/file.svg" alt="" />
                  <span className="text-white ">
                    Accéder au dossier patient
                  </span>
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </Card>
  );
}
