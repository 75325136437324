import { NOTIFICATION_TYPE } from "../../enums/notification";
import { Popover, Transition } from "@headlessui/react";
import { CalendarDaysIcon, ChevronDownIcon } from "@heroicons/react/24/solid";
import * as React from "react";
import { useQuery } from "react-query";
import { useNavigate } from "react-router";
import patientRecordIcon from '../../assets/patientRecordIcon.svg'
import waitingListIcon from '../../assets/waiting_list.svg'
import appointmentIcon from '../../assets/appointmentIcon.svg'
import supportIcon from '../../assets/support.svg'
import visiostationIcon from '../../assets/visiostationIcon.svg'
import exit from "../../assets/disconnect.png";
import anonymous from '../../assets/anonymous.svg'
import { getDoctorNamebyId } from "../../axiosAPI/Network";
import styled from "styled-components";
import APP_CONSTANTS from "../../constants";
import { RootState } from "../../store";
import { useDispatch, useSelector } from "react-redux";
import { app, role } from "../../enums/app";
import { setCurrentApp } from "../../features/auth";
import { Link } from "react-router-dom";
interface Notification {
  message: string;
  type: string;
  icon?: React.ReactNode;
  title: string;
}
export interface INotificationsProps {
  PopoverButton: React.ReactNode;
}
const ProfilePart = styled.div`
  position: relative;
  cursor: pointer;
  margin-right: 30px;
  display: flex;
  gap: 10px;

  border-radius: 7.21264px;
  padding: 10px;
  .title {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: space-around;
    .name {
      font-family: "Ubuntu";
      font-style: normal;
      font-weight: 500;
      width: 200px;
      display: flex;
      align-items: center;
      justify-content: center;

      font-size: 18.3979px;
      line-height: 13px;
      color: balck;
    }
    .work {
      font-family: "Ubuntu";
      font-style: normal;
      font-weight: 400;
      font-size: 16.1621px;
      line-height: 13px;
      color: #20a8d3;
    }
  }
`;
const UserCard = styled.div`
width: 332px;
height: 70px;
flex-shrink: 0;
border-radius: 10.535px;
background: rgba(26, 165, 192, 0.08);

.user-name {
  color: #50799E;
font-feature-settings: 'clig' off, 'liga' off;
font-family: Ubuntu;
font-size: 18.052px;
font-style: normal;
font-weight: 500;
line-height: 12.703px; /* 70.368% */
}
`

const QuickNavigation = styled.span`

color: rgba(80, 121, 158, 0.61);
font-feature-settings: 'clig' off, 'liga' off;
font-family: Ubuntu;
font-size: 16.68px;
font-style: normal;
font-weight: 400;
line-height: 12.703px; /* 76.155% */
`

const AppItem = styled.div`

.selected-item {
  width: 100%;
  border-radius: 10.535px;
background: rgba(26, 165, 192, 0.08);
}
.item-container {
  width: 100%;
  justify-content: "flex-start";
height: 40.4pt;
flex-shrink: 0;
}
.item-text {
  color: #50799E;
font-feature-settings: 'clig' off, 'liga' off;
font-family: Ubuntu;
font-size: 20.037px;
font-style: normal;
font-weight: 400;
line-height: 16.147px; /* 80.582% */
letter-spacing: -0.839px;
}
`
export default function Menu({ PopoverButton }: INotificationsProps) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { user: storedUser, access, currentApp } = useSelector((state: RootState) => state?.auth);

  const hasAccess = (resourceName: string) => {
    if (!storedUser?.roles?.length) {
      return false;
    }

    // Find the user's group based on the resourceName
    const userGroup = storedUser.roles.find((group: any) => {
      return group.accessibilities.some(
        (accessibility: any) => accessibility.resource.name === resourceName
      );
    });


    console.log(userGroup, resourceName)
    // Check if the user has the required permission for the resource
    if (userGroup) return userGroup.accessibilities.some((accessibility: any) => accessibility.resource.name === resourceName);

    return false;
  };
  const disconnect = () => {
    // Get all keys from localStorage
    const keys = Object.keys(localStorage);

    // Iterate through the keys and remove the items with the desired prefix
    keys.forEach((key) => {
      if (key.startsWith(APP_CONSTANTS.LOCALSTORAGE_PREFIX)) {
        localStorage.removeItem(key);
      }
    });

    // NAVIGATE TO LOGIN PAGE
    navigate(APP_CONSTANTS.ROUTES.LOGIN);
  };

  return (
    <div>
      <Popover className="relative">
        {({ open }) => (
          <>
            {PopoverButton}
            <Transition
              as={React.Fragment}
              enter="transition ease-out duration-200"
              enterFrom="opacity-0 translate-y-1"
              enterTo="opacity-100 translate-y-0"
              leave="transition ease-in duration-150"
              leaveFrom="opacity-100 translate-y-0"
              leaveTo="opacity-0 translate-y-1"
            >
              <Popover.Panel className="absolute  z-50 top-[4.5rem] w-screen max-w-sm  sm:px-0 ">
                <div className="overflow-hidden rounded-lg shadow-lg ring-1 ring-black ring-opacity-5">
                  <div className="bg-white p-4">
                    {storedUser ? (
                      <>
                        <div className="cursor-pointer flow-root rounded-md px-2 py-2 ">
                          <div className="flex">


                            <div className="ml-3">

                              <UserCard className="flex items-center">
                                <div className="w-[20%]">
                                  <img src={anonymous} alt="" />
                                </div>
                                <Link to="/doctor/addDoctor" className="w-[80%]">
                                  <span className="flex items-center">
                                    <span className="text-lg font-medium text-gray-700 user-name">
                                      { }
                                      {`${storedUser?.firstName || ""}    ${storedUser?.lastName || ""
                                        }`}

                                    </span>
                                  </span>
                                  <span className="flex items-center">
                                    <span className="text-sm font-medium text-[#1AA5C0]">
                                      {`${storedUser.roles[0].name === role.NURSE ? 'Infirmière' : ''}`}
                                      {`${storedUser.roles[0].name === role.GENERALIST ? 'Medecin géneraliste' : ""}`}
                                    </span>
                                  </span>
                                </Link>
                              </UserCard >

                              <div className="mt-4">
                                <QuickNavigation >Navigation rapide</QuickNavigation>
                                <AppItem className="mt-4">
                                  {/* item  visiostation*/}
                                  {hasAccess(app.VISIO_STATION) && <button
                                    onClick={() => { dispatch(setCurrentApp(app.VISIO_STATION)); navigate(APP_CONSTANTS.ROUTES.DEVICES) }}
                                    className={currentApp === app.VISIO_STATION ? "selected-item flex items-center   mb-2  item-container" : "flex items-center   mb-2  item-container"}
                                  >
                                    <div className="w-[30%]">
                                      <img src={visiostationIcon} alt="" />
                                    </div>
                                    <div>
                                      <span className="w-[70%] item-text">Prise de mesures</span>
                                    </div>
                                  </button>}
                                  {/* item */}
                                  <hr />

                                  {/* item patient record */}
                                  {hasAccess(app.PATIENT_RECORD) && <button
                                    onClick={() => { dispatch(setCurrentApp(app.PATIENT_RECORD)); navigate(APP_CONSTANTS.ROUTES.PATIENTS_LIST) }}
                                    className={currentApp === app.PATIENT_RECORD ? "selected-item flex items-center   mb-2  item-container" : "flex items-center   mb-2  item-container"}
                                  >
                                    <div className="w-[30%]">
                                      <img src={patientRecordIcon} alt="" />

                                    </div>
                                    <div>
                                      <span className="w-[70%] item-text ">Dossier patient</span>
                                    </div>
                                  </button>}
                                  {/* item */}
                                  <hr />
                                  {/* item  appointment*/}
                                  {hasAccess(app.APPOINTMENTS) && <button
                                    onClick={() => { 
                                      dispatch(setCurrentApp(app.APPOINTMENTS)); 
                                      window.location.href = APP_CONSTANTS.ROUTES.CALENDAR;
                                      // navigate(APP_CONSTANTS.ROUTES.CALENDAR)
                                     }}
                                    className={currentApp === app.APPOINTMENTS ? "selected-item flex items-center   mb-2  item-container" : "flex items-center   mb-2  item-container"}
                                  >
                                    <div className="w-[30%]">
                                      <img src={appointmentIcon} alt="" />

                                    </div>
                                    <div>
                                      <span className="w-[70%] item-text">Rendez-vous</span>
                                    </div>
                                  </button>}
                                  {/* item */}

                                  {/* item  waiting list*/}
                                  {storedUser.roles[0].name == role.HOST && <button
                                    onClick={() => { dispatch(setCurrentApp(app.WAITING_LIST)); navigate(APP_CONSTANTS.ROUTES.WAITING_LIST) }}
                                    className={currentApp === app.WAITING_LIST ? "selected-item flex items-center   mb-2  item-container" : "flex items-center   mb-2  item-container"}
                                  >
                                    <div className="w-[30%]">
                                      <img src={waitingListIcon} alt="" />

                                    </div>
                                    <div>
                                      <span className="w-[70%] item-text">File d'attente</span>
                                    </div>
                                  </button>}
                                  {/* item */}

                                </AppItem>
                              </div>
                              {/* <div style={{marginTop:"50%"}}>
                                <QuickNavigation >Support</QuickNavigation>
                                <AppItem className="mt-4">
                                <button
                                   
                                    className={ "flex items-center   mb-2  item-container"}
                                  >
                                    <div className="w-[25%]">
                                      <img src={supportIcon} width={42} alt="" />

                                    </div>
                                    <div className="flex flex-col mb-1">
                                      <p className="w-[70%] item-tex mb-1" style={{fontSize:"14px",width:"100%",color:"gray"}}>Contactez-nous</p>
                                      <span className="w-[70%] item-text">0668957412</span>
                                    </div>
                                  </button>
                                </AppItem>
                              </div> */}



                            </div>
                          </div>
                        </div>
                        {/* <div className="cursor-pointer flow-root rounded-md px-2 py-2 transition duration-150 ease-in-ou focus:outline-none focus-visible:ring focus-visible:ring-orange-500 focus-visible:ring-opacity-50">
                          <div className="flex justify-center">
                            <div className="flex flex-row mt-3" onClick={disconnect}>
                              <span className="flex items-center">
                                <div onClick={disconnect} className="mx-3">
                                  <img
                                    src={exit}
                                    width={30}
                                    alt="icons"
                                    className="cursor-pointer"
                                  />
                                </div>
                              </span>
                              <span className="flex items-center ">
                                <span className="text-lg font-medium text-red-700">
                                  Déconnexion
                                </span>
                              </span>
                            </div>
                          </div>
                        </div> */}
                      </>
                    ) : (
                      <a className="mt-4 animate-pulse flex items-center justify-between">
                        <div>
                          <div className="h-2.5 bg-gray-300 rounded-full dark:bg-gray-600 w-24 mb-2.5"></div>
                          <div className="w-32 h-2 bg-gray-200 rounded-full dark:bg-gray-700"></div>
                        </div>
                        <div className="h-2.5 bg-gray-300 rounded-full dark:bg-gray-700 w-12"></div>
                      </a>
                    )}
                  </div>
                </div>
              </Popover.Panel>
            </Transition>
          </>
        )}
      </Popover>
    </div>
  );
}
