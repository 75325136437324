import { useState } from "react";
// import { CheckIcon, SelectorIcon } from '@heroicons/react/solid'
import { TbSelector } from "react-icons/tb";
import { Combobox } from "@headlessui/react";
import { Spinner } from "@material-tailwind/react";

function classNames(...classes: (string | boolean)[]) {
  return classes.filter(Boolean).join(" ");
}

interface IProps {
  patient: { id: number; details: string }[];
  isLoading: boolean;
  onChange: (arg: any) => void;
  selectedPatient: (arg: any) => void;
}

export default function SearchInput({
  patient,
  onChange,
  selectedPatient,
  isLoading,
}: IProps) {
  const [selectedPerson, setSelectedPerson] = useState();

  return (
    <Combobox
      as="div"
      value={selectedPerson}
      onChange={(patient: any) => {
        setSelectedPerson(patient);
        selectedPatient(patient);
      }}
    >
      <div className="relative">
        <Combobox.Input
          className="w-full  h-[2.6rem] rounded-md border border-black-300 bg-white py-2 pl-3 pr-10 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-1 focus:ring-indigo-500 sm:text-sm"
          onChange={onChange}
          displayValue={(person: any) => person?.details}
        />
        <Combobox.Button className="absolute inset-y-0 right-0 flex items-center rounded-r-md px-2 focus:outline-none">
          <TbSelector className="h-5 w-5 text-gray-400" aria-hidden="true" />
        </Combobox.Button>

        {!isLoading && patient.length > 0 && (
          <Combobox.Options className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
            {patient.map((person) => (
              <Combobox.Option
                onSelect={(e) => console.log("selected patient", e)}
                key={person.id}
                value={person}
                className={({ active }) =>
                  classNames(
                    "relative cursor-default select-none py-2 pl-3 pr-9",
                    active ? "bg-indigo-600 text-white" : "text-gray-900"
                  )
                }
              >
                {({ active, selected }) => (
                  <>
                    <span
                      className={classNames(
                        "block truncate",
                        selected && "font-semibold"
                      )}
                    >
                      {person.details}
                    </span>

                    {selected && (
                      <span
                        className={classNames(
                          "absolute inset-y-0 right-0 flex items-center pr-4",
                          active ? "text-white" : "text-indigo-600"
                        )}
                      >
                        <div className="h-5 w-5" aria-hidden="true" />
                      </span>
                    )}
                  </>
                )}
              </Combobox.Option>
            ))}
          </Combobox.Options>
        )}
        {isLoading && (
          <Combobox.Options className="absolute z-10 mt-1 max-h-60 w-full rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
            <Spinner width={70} className="mx-auto" />
          </Combobox.Options>
        )}
      </div>
    </Combobox>
  );
}
