import { clearDiagnosticDisplayData } from "./features/diagnosticAssocie/diagnosticAssocieSlice";
import {
  clearConsultationData,
  clearDiagnostics,
} from "./features/consultationData/consultationDataSlice";
import { clearMotifs } from "./features/motifs/motifsSlice";
import { setIsMedicals } from "./features/medical/medicalSlice";
import { setIsSurgicals } from "./features/surgical/surgicalSlice";
import { setIsAllergic } from "./features/allergy/allergySlice";
import { setisTabacConsuming } from "./features/isTabacConsuming/isTabacConsumingSlice";
import { setIsAlcoholic } from "./features/alcoholic/alcoholSlice";
import { sethasOtherSubs } from "./features/hasOtherSubs/hasOtherSubsSlice";

import { clearCompteRendu } from "./features/compteRendu/compteRenduSlice";
import { clearMedicationConsultationData } from "./features/medicationConsults/medicationConsultsSlice";
import { clearAnalysesRadio } from "./features/analysesRadio/analysesRadioSlice";
import { clearBioAnalyses } from "./features/bioAnalyses/bioAnalysesSlice";

export function clearConsultationStore(dispatch: (arg: any) => any) {
  dispatch(clearMotifs());
  dispatch(clearConsultationData());
  dispatch(clearDiagnosticDisplayData());
  dispatch(clearDiagnostics());

  dispatch(setIsMedicals(false));
  dispatch(setIsSurgicals(false));
  dispatch(setIsAllergic(false));
  dispatch(setisTabacConsuming(false));
  dispatch(setIsAlcoholic(false));
  dispatch(sethasOtherSubs(false));
  dispatch(clearCompteRendu());
  dispatch(clearMedicationConsultationData());
  dispatch(clearAnalysesRadio());
  dispatch(clearBioAnalyses());
}
