import { PayloadAction, createSlice } from "@reduxjs/toolkit";

interface Call {
    id?: string | null;
    consultationId: string | null;
    patientId: string | null;
    isInterlocutorOnline: boolean;
    connectedUsers: Array<any>;
    status: 'joined' | 'missed' | 'ended' | 'incoming' | 'rejected' | null;
    participants: string[] | null; // Array of participant IDs
    startTime: string | null; // Start time as a string (you can use Date objects if needed)
    endTime: string | null; // End time as a string (you can use Date objects if needed)
    duration: number | null; // Duration in minutes
    Messages: string | null;
    cause: string | null;

}

const initialState: Call = {
    consultationId: null,
    connectedUsers: [],
    isInterlocutorOnline: false,
    patientId: null,
    status: null,
    participants: null, // Array of participant IDs
    startTime: null, // Start time as a string (you can use Date objects if needed)
    endTime: null, // End time as a string (you can use Date objects if needed)
    duration: null, // Duration in minutes
    Messages: null,
    cause: null,
};

const callSlice = createSlice({
    name: 'call',
    initialState,
    reducers: {

        setIsInterlocutorOnline:(
            state,
            action: PayloadAction<Call['isInterlocutorOnline']>,
        ) => {
            if (state) {
                state.isInterlocutorOnline = action.payload; // Update the status of the active call
            }
        },

        updateCall: (
            state,
            action: PayloadAction<Partial<Call>>,
        ) => {
            if (state) {
                Object.assign(state, action.payload);
            }
        },
        updateCallStatus: (state, action: PayloadAction<Call['status'] | null>) => {
            if (state) {
                state.status = action.payload; // Update the status of the active call
            }
        },
        updateCallParticipants: (state, action: PayloadAction<string[]>) => {
            if (state) {
                state.participants = action.payload; // Update participants of the active call
            }
        },
        updateCallTime: (state, action: PayloadAction<{ startTime: string; endTime: string; duration: number }>) => {
            if (state) {
                const { startTime, endTime, duration } = action.payload;
                state.startTime = startTime;
                state.endTime = endTime;
                state.duration = duration;
            }
        },
        updateUxMessages: (state, action: PayloadAction<{cause:string,Messages:string }>) => {
            if(state)
            {
                const {cause,Messages} = action.payload;
                state.Messages = Messages;
                state.cause = cause;
            }
            
        },
    },
});

export const {
    setIsInterlocutorOnline
   
  } = callSlice.actions;
  
  export default callSlice.reducer;
  
