import { Link } from "react-router-dom";
import APP_CONSTANTS from "../../constants";
import styled from "styled-components";
import { useSelector } from "react-redux";
import { RootState } from "../../store";

const GlobalStyle = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 2rem;
  height: 100%;
`;

const Card = styled.div<{ color: any }>`
  width: 412px;
  height: 290px;
  background: #ffffff;
  border-radius: 13.1235px;
  border-top: 12px solid ${(props) => props.color};
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: "Ubuntu";
  font-style: normal;
  font-weight: 500;
  font-size: 38px;
  line-height: 44px;
  color: ${(props) => props.color};
`;

const Periphiriques = () => {


  const isConnected = useSelector((state: RootState) => state.auth.isConnected);
  return (
    <>
      
        <GlobalStyle>
          <Link to={isConnected ? APP_CONSTANTS.ROUTES.BASIC_DEVICES : APP_CONSTANTS.ROUTES.BASIC_DEVICES_WITHOUT_AUTHENTICATION}>
            <Card color="#20A8D3" className="text-center">
              Périphériques <br /> de base
            </Card>
          </Link>
          <Link to={isConnected ? APP_CONSTANTS.ROUTES.ADVANCED_DEVICES : APP_CONSTANTS.ROUTES.ADVANCED_DEVICES_WITHOUT_AUTHENTICATION}>
            <Card color="#1AA5C0" className="text-center">
              Périphériques <br /> avancés
            </Card>
          </Link>
        </GlobalStyle>
    </>
  );
};
export default Periphiriques;
