// medicalDevicesSlice.ts
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { MEDICAL_DEVICES } from '../../constants';
import { AgentStatus } from '../../enums/agent';
import { SLICE } from '../../enums/store';


interface MedicalDevice {
  id: string;
  name: keyof typeof MEDICAL_DEVICES;
  status: string;
}

interface MedicalDevicesState {
  devices: MedicalDevice[];
  agentStatus: string;

}

const initialState: MedicalDevicesState = {
  devices: Object.keys(MEDICAL_DEVICES).map((key) => ({
    id: key,
    name: key as keyof typeof MEDICAL_DEVICES,
    status: 'idle',
  })),
  agentStatus: AgentStatus.Idle,

};

const medicalDevicesSlice = createSlice({
  name: SLICE.MEDICAL_DEVICES,
  initialState,
  reducers: {
    // Reducer to update the status of a specific device
    updateDeviceStatus: (
      state,
      action: PayloadAction<{ deviceId: keyof typeof MEDICAL_DEVICES; status: string }>
    ) => {
      const { deviceId, status } = action.payload;
      const device = state.devices.find((d) => d.name === deviceId);
      if (device) {
        device.status = status;
      }
    },
    // Reducer to update the agent's status
    updateAgentStatus: (state, action: PayloadAction<string>) => {
      state.agentStatus = action.payload;
    },

  },
});

export const { updateDeviceStatus, updateAgentStatus } = medicalDevicesSlice.actions;
export default medicalDevicesSlice.reducer;
