import * as React from "react";
import { useConsultationContext } from "../../../../../../src/services/ConsultationContext";

export interface IDiagnosticPrincipaleIntelligentProps {}

export default function DiagnosticPrincipaleIntelligent(
  props: IDiagnosticPrincipaleIntelligentProps
) {
  const {
    patient,
    vitalSignsc,
    setShowIframe,
    showIframe,
    receivedDiagnostics,
  } = useConsultationContext();
  return (
    receivedDiagnostics && (
      <div
        className="bg-blue-100 border-t border-b border-blue-500 text-blue-700 px-4 py-3"
        role="alert"
      >
        <p className="font-bold">Diagnostic principal Intelligent</p>
        <p className="text-sm"> {receivedDiagnostics.diagnostic_1}</p>
      </div>
    )
  );
}
