import * as React from 'react';
import { BUTTON_COLORS } from "../../../enums/stepper";
import { PaperClipIcon } from '@heroicons/react/24/solid';
import { IFileData } from '@interfaces/Stepper';
import moment from 'moment';

export interface IFileInputProps {
  onFileUpload: (fileName: IFileData) => void;

}

export default function FileInput (props: IFileInputProps) {
  const fileInputRef = React.useRef<HTMLInputElement>(null);

  const handleFileUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      const newFileData: IFileData = {
        date: moment().format('DD/MM/YYYY'),
        fileName: file.name,
        comment: '',
      };
      console.log(newFileData)
      props.onFileUpload(newFileData);
    }
  };

  return (
    <div>
      <input
        ref={fileInputRef}
        type="file"
        accept="application/pdf,application/vnd.ms-excel"
        style={{ display: "none" }}
        onChange={handleFileUpload}
      />
      {/* <button
        type="button"
        className="bg-[#1AA5C0] flex p-2 w-[15rem] justify-center text-white text-center rounded-md"
        onClick={() => fileInputRef.current?.click()}
      >
        Sélectionner un fichier
        <PaperClipIcon className='w-6 ml-3'/>
      </button> */}
    </div>
  );
}

