// analysesRadioSlice.ts

import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { SLICE } from "../../enums/store";
import { IAnalysesRadioState } from "@interfaces/prescriptionStep";
import { dataNumberByIndex } from "@interfaces/Stepper";
const initialState: IAnalysesRadioState = {
  analysesRadio: [],
  isLoading: false,
  isUpdated: false,
  isRefetchRequired: true,
  error: null,
};

const analysesRadioSlice = createSlice({
  name: SLICE.ANALYSES_RADIO,
  initialState,
  reducers: {
    setAnalysesRadioData:(state, action: PayloadAction<any>) => {
      state.analysesRadio=action.payload
    },
    setAnalysesRadioNotes: (
      state,
      action: PayloadAction<any>
    ) => {
      const newAnalysesRadio = [
        ...JSON.parse(JSON.stringify(state.analysesRadio)),
      ];

      newAnalysesRadio[action.payload.index] = {
        imagingId: newAnalysesRadio[action.payload.index]?.imagingId ?? null,
        note: action.payload.value,
      };
      state.analysesRadio=newAnalysesRadio;
      state.isRefetchRequired = false;
      state.isUpdated = true;
    },
    setAnalysesRadio: (state, action: PayloadAction<any>) => {
      const newAnalysesRadio = [
        ...JSON.parse(JSON.stringify(state.analysesRadio)),
      ];
      
      newAnalysesRadio[action.payload.index] = {
        imagingId: action.payload.value,
        note: newAnalysesRadio[action.payload.index]?.note ?? "",
      };
      
      state.analysesRadio=newAnalysesRadio;
      state.isRefetchRequired = false;
      state.isUpdated = true;
    },
    addAnalyseRadio: (state) => {
      return {
        ...state,
        analysesRadio: [
          ...state.analysesRadio,
          {
            imagingId: "",
            note: "",
          },
        ],
      };
    },
    spliceAnalysesRadio: (state, action: PayloadAction<number>) => {
      let splicedanalysesRadio = JSON.parse(JSON.stringify(state.analysesRadio));
      splicedanalysesRadio.splice(action.payload, 1);
      state.analysesRadio = splicedanalysesRadio;
      state.isUpdated = true;
    },
    clearAnalysesRadio: (state) => {
      state.analysesRadio = [];
      state.isUpdated = true;
    },
  },
});

export const {
  setAnalysesRadio,
  setAnalysesRadioNotes,
  spliceAnalysesRadio,
  setAnalysesRadioData,
  addAnalyseRadio,
  clearAnalysesRadio,
} = analysesRadioSlice.actions;
export default analysesRadioSlice.reducer;
