import React, {
  createContext,
  useContext,
  useState,
  ReactNode,
  useEffect,
} from "react";
import { getSpecialities } from "../../axiosAPI/Network";
import { getDoctorSpecialite } from "../../axiosAPI/params";

interface AppContextType {
  appointmentDate: Date;
  setAppointmentDate: React.Dispatch<React.SetStateAction<Date>>;
  appTime: {
    start: number; // Change the type here
    end: number; // Change the type here
  };
  setAppTime: React.Dispatch<
    React.SetStateAction<{ start: number; end: number }>
  >; // Change the type here
  reason: string;
  setReason: React.Dispatch<React.SetStateAction<string>>;
  appSelected: boolean;
  setAppSelected: React.Dispatch<React.SetStateAction<boolean>>;
  events: Event[];
  setEvents: React.Dispatch<React.SetStateAction<Event[]>>;
  range: number;
  setRange: React.Dispatch<React.SetStateAction<number>>;
  notify: boolean;
  setNotify: React.Dispatch<React.SetStateAction<boolean>>;
}

const AppContext = createContext<AppContextType | undefined>(undefined);

interface AppointementProviderProps {
  children: ReactNode;
}

export const AppointementProvider: React.FC<AppointementProviderProps> = ({
  children,
}) => {
  const currentDate = new Date();
  currentDate.setHours(0);
  currentDate.setMinutes(0);
  currentDate.setSeconds(0);
  const [appointmentDate, setAppointmentDate] = useState<Date>(currentDate);
  const [appTime, setAppTime] = useState({} as { start: number; end: number });
  const [reason, setReason] = useState<string>("");
  const [appSelected, setAppSelected] = useState<boolean>(false);
  const [events, setEvents] = useState<Event[]>([]);
  const [range, setRange] = useState<number>(30);
  const [notify, setNotify] = useState(false);



  const value = {
    appointmentDate,
    setAppointmentDate,
    appTime,
    setAppTime,
    reason,
    setReason,
    appSelected,
    setAppSelected,
    events,
    setEvents,
    range,
    setRange,
    notify,
    setNotify,
  };

  return <AppContext.Provider value={value}>{children}</AppContext.Provider>;
};
/**
 *
 * @returns
 */
export const useAppointmentContext = (): AppContextType => {
  const context = useContext(AppContext);

  if (!context) {
    throw new Error(
      "useAppointmentContext must be used within an AppointementProvider"
    );
  }

  return context;
};
