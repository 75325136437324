import {
  SELECT_DATA_STATE,
  TAG_DATA_STATE,
} from "../../../../../enums/stepper";
import * as React from "react";
import SearchableSelect from "../../../../FormBuilder/FormElements/SearchableSelect";
import Tag from "../../../../../Components/Tag";
import {
  addAnalyseRadio,
  setAnalysesRadio,
  setAnalysesRadioNotes,
  spliceAnalysesRadio,
} from "../../../../../features/analysesRadio/analysesRadioSlice";
import TextInput from "../../../../../Components/FormBuilder/FormElements/TextInput";
import { PlusIcon, TrashIcon } from "@heroicons/react/24/solid";
import { useDispatch, useSelector } from "react-redux";
import { setAnalyseRadio } from "../../../../../features/consultationData/consultationDataSlice";
import { setRadioNames } from "../../../../../features/persistedPrescriptionData/persistedPrescriptionDataSlice";
import { getImagingByIndex } from "../../../../../axiosAPI/Network";

export interface IRadioExamsProps {}

export default function RadioExams(props: IRadioExamsProps) {
  const dispatch = useDispatch();
  const storedAnalysesRadio=useSelector((state: any) => state.analysesRadio.analysesRadio)
  const storedRadioNames=useSelector((state: any) => 
  state.persistedPrescriptionData.persistedData.radioNames
  )

  React.useEffect(() => {
    dispatch(setAnalyseRadio(storedAnalysesRadio    
     ))
     storedAnalysesRadio.map((imaging: any, index: number) => {
      
      imaging?.imagingId&&getImagingByIndex(imaging.imagingId).then((res) => {
        if (res?.data?.name) {
          dispatch(
            setRadioNames({
              index: index,
              value: res.data.name,
            })
          );
        }
      });
    });
 }, [storedAnalysesRadio])
 console.log(storedRadioNames);
 
  return (
    <div className="flex flex-col h-full  py-4 border-1">
      <label htmlFor="" className="h-full text-lg text-[#5E5E5E]">
        Examens radiologiques
      </label>
      {useSelector((state: any) => state.analysesRadio.analysesRadio).map(
        (analysesRadio: any, index: number) => {
          return (
            <div className="flex w-full">
              <SearchableSelect
                name={`analysesRadio`}
                label={``}
                defaultValue={{
                  index:index,
                  name:storedRadioNames?storedRadioNames[index]:"",
                }}
                index={index}
                // placeholder={`Chercher`}
                dataState={SELECT_DATA_STATE.IMAGING}
                actions={[setAnalysesRadio]}
                style="mt-4 h-full text-sm text-[#5E5E5E] min-w-[25rem]"
              />
              <div className="mt-4 ml-3 w-full">
                <TextInput
                  name=""
                  label="Commentaire"
                  style=""

                  defaultValue={analysesRadio.note}
                  onChange={(value: string) => {

                    dispatch(
                      setAnalysesRadioNotes({
                        index: index,
                        value: value,
                      })
                    );
                  }}
                />
              </div>
              <div className="flex justify-center rounded-full bg-[#FF375E] w-14 h-10 m-4">
                <TrashIcon
                  color="white"
                  onClick={() => {
                    dispatch(spliceAnalysesRadio(index));
                  }}
                  width={20}
                  className=" cursor-pointer "
                />
              </div>
            </div>
          );
        }
      )}
      <button
        type="button"
        className="bg-[#1AA5C0] mt-6 flex p-2 w-[15rem] justify-center text-white text-center rounded-md"
        onClick={() => {
          dispatch(addAnalyseRadio());
        }}
      >
        Nouvel examen <PlusIcon width={23} className="ml-4" />
      </button>
    </div>
  );
}
