import React, { useRef } from "react";
import styled from "styled-components";
import Table from "../SearchPatient/Table";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import { Navigation } from "swiper";
import "swiper/css";
import MyChart from "./MyChart";
import VitalTable from "./VitalTable";

const data = [
  ["John", "Doe", 30],
  ["Jane", "Doe", 28],
  ["Bob", "Smith", 35],
  ["John", "Doe", 30],
  ["Jane", "Doe", 28],
  ["Bob", "Smith", 35],
  ["John", "Doe", 30],
  ["Jane", "Doe", 28],
  ["Bob", "Smith", 35],
  ["John", "Doe", 30],
  ["Jane", "Doe", 28],
  ["Bob", "Smith", 35],
  ["John", "Doe", 30],
  ["Jane", "Doe", 28],
  ["Bob", "Smith", 35],
  ["John", "Doe", 30],
  ["Jane", "Doe", 28],
  ["Bob", "Smith", 35],
  ["John", "Doe", 30],
  ["Jane", "Doe", 28],
  ["Bob", "Smith", 35],
];

const datachart = [
  {
    name: "Sales",
    data: [30, 40, 35, 50, 49, 60, 70, 91, 125],
  },
];

const categories = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
];

const MySwiper = () => {
  const swiperRef = useRef<any>(null);

  return (
    <div style={{ width: "100%", minHeight: "100%" }}>
      <Swiper
        ref={swiperRef}
        navigation={true}
        modules={[Navigation]}
        className="mySwiper"
        spaceBetween={50}
        slidesPerView={3}
        onSlideChange={() => {}}
        onSwiper={(swiper) => {}}
        style={{ width: "100%", minHeight: "100%", paddingLeft: '1rem' }}
      >
        <SwiperSlide>
          <MyChart
            data={datachart}
            categories={categories}
            typeChart={"line"}
          />
        </SwiperSlide>
        <SwiperSlide>
          <MyChart
            data={datachart}
            categories={categories}
            typeChart={"area"}
          />
        </SwiperSlide>
        <SwiperSlide>
          <MyChart data={datachart} categories={categories} typeChart={"bar"} />
        </SwiperSlide>
        <SwiperSlide>
          <MyChart
            data={datachart}
            categories={categories}
            typeChart={"line"}
          />
        </SwiperSlide>
        <SwiperSlide>
          <MyChart data={datachart} categories={categories} typeChart={"bar"} />
        </SwiperSlide>
        <SwiperSlide>
          <MyChart
            data={datachart}
            categories={categories}
            typeChart={"area"}
          />
        </SwiperSlide>
        <SwiperSlide>
          <MyChart
            data={datachart}
            categories={categories}
            typeChart={"line"}
          />
        </SwiperSlide>
      </Swiper>
    </div>
  );
};
const header = ["First Name", "Last Name", "Age"];
interface IVitalSignsProps {
  patientId?: number
}
const VitalSigns = (props: IVitalSignsProps) => {
  return (
    <GlobalContent>
      {/* <ChartParts>
        <MySwiper />
      </ChartParts> */}
      <ChartParts>
        {/* <Table header={header} data={data} /> */}
        <VitalTable />
      </ChartParts>
    </GlobalContent>
  );
};

export default VitalSigns;

const GlobalContent = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding-bottom: 1rem;
  .swiper {
    width: 100%;
    height: 100%;
  }

  .swiper-slide {
    text-align: center;
    font-size: 18px;
    background: #fff;

    /* Center slide text vertically */
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .swiper-slide img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  .swiper-button-next,  .swiper-rtl .swiper-button-prev {
    right: var(--swiper-navigation-sides-offset,10px);
    left: auto;
    color: #AEAEAE;
  }
`;
const ChartParts = styled.div`
  width: 100%;
  border: 1.45967px solid #dadada;
  border-radius: 10.2177px;
  min-height: 350px;
  max-height: 650px;
  overflow-y: auto;
  /* background: red; */
`;
