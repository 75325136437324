import React, { useEffect } from "react";
import { useState, useContext } from "react";
import { getPatient, searchPatientData } from "../axiosAPI/Network";
import { PATIENT_FILTER } from "../enums/table";

const PAGINATION_TAKE = 10;
const MyContext = React.createContext({});

export function useMyContext() {
  return useContext(MyContext);
}

export function SearchPatientProv({ children }: any) {
  const [patientdata, setPatientdata] = useState<any>();
  const [patientFilter, setPatientFilter] = useState<any>(PATIENT_FILTER.ALL_PATIENTS);
  const [dateRange, setDateRange] = useState<any>([null, null]);
  const [isLoading, setIsLoading] = useState(false);
  const [searchTerm, setSearchTerm] = useState<any>(null);
  const [currentPage, setCurrentPage] = useState<any>(1);
  const [totalPages, setTotalPages] = useState<any>(1);
  const [pages, setPages] = useState<any>([]);
  const [isError, setIsError] = useState(false);

  /**
   *
   */
  const searchPatient = async (
    options: any,
    searchTerm?: string | undefined
  ) => {
    console.log("new search request", options)
    const take = PAGINATION_TAKE;
    try {
      console.log('executing search patient ....', options)
      // setIsLoading(true);
      if (searchTerm && searchTerm?.length > 0) {
        const res: any = await searchPatientData(options, searchTerm);

        setPatientdata(res);
        setTotalPages(Math.ceil(res.data.totalResults / take));
      } else {
        const res: any = await searchPatientData(options);

        setPatientdata(res);
        setTotalPages(Math.ceil(res.data.totalResults / take));

      }
      // setIsLoading(false);
    } catch (error) {
      setIsError(true);
    }
  };

  useEffect(() => {
    console.log("date range changed ", dateRange)
  }, [dateRange])

  useEffect(() => {
    const take = PAGINATION_TAKE;
    const skip = (currentPage - 1) * take;

    searchPatient({ take, skip, patientFilter, dateRange }, searchTerm);
  }, [searchTerm, patientFilter, dateRange, currentPage]);

  useEffect(() => {
    const take = PAGINATION_TAKE;
    searchPatient({ take, skip: 0, patientFilter, dateRange });
  }, []);

  // useEffect(() => {
  //   const totalResults = patientdata?.totalResults;
  //   const take = PAGINATION_TAKE;

  //   const skip = (currentPage - 1) * take;
  //   searchPatient({ take, skip,patientFilter });
  // }, [currentPage]);

  useEffect(() => {
    const pages: number[] = Array.from(
      { length: totalPages },
      (_, index) => index + 1
    );
    setPages(pages);
  }, [totalPages]);

  return (
    <MyContext.Provider
      value={{
        patientdata,
        setPatientdata,
        isLoading,
        setIsLoading,
        currentPage,
        pages,
        setPages,
        setCurrentPage,
        totalPages,
        setTotalPages,
        isError,
        setIsError,
        searchTerm,
        setSearchTerm,
        patientFilter,
        setPatientFilter,
        dateRange,
        setDateRange
      }}
    >
      {children}
    </MyContext.Provider>
  );
}
