import React, { useState, useEffect } from 'react'
import { getAppointmentsByDoctorId, getCallsHistoryByDoctorId } from '../../../src/axiosAPI/Network';
import dayjs from 'dayjs'
import SearchBar from '../../Components/SearchPatient/Searchbar';
import { setLeftIsOpen } from '../../features/sidebar/SidebarSlice';
import './tablecss.css'
import { useSelector } from 'react-redux';
import { RootState } from '../../store';
import { Pagination } from 'swiper';


export function MyTable() {
    const [currentPage, setCurrentPage] = useState(1);
    const [dataTable, setDataTable] = useState<any>([])
    const [searchTerm, setSearchTerm] = useState<string>()
    const [itemsPerPage, setItemsPage] = useState(10);

    const doctorId = useSelector((state: RootState) => state.auth.user)
    const docId = doctorId.doctor?.id
    // const { data} = useGetConsultationByDoctorIDQuery(docId)
    // console.log(data)
    // useEffect(() => {
    //     getAppointmentsByDoctorId(docId).then((result:any) => {
    //         setDataTable(result?.data.results)
    //         console.log(result.data)
    //     })
    // }, [docId])

    useEffect(() => {
        getData()
    }, [currentPage, searchTerm]);

    const getData = async () => {
        const skip = (currentPage - 1) * itemsPerPage;
        const take = itemsPerPage;
        const str = searchTerm
        try {
            const newData = await getAppointmentsByDoctorId(docId, { skip, take }, str);
            console.log('newData:', newData);
            setDataTable(newData.data.results);
        } catch (error) {
            alert('Error fetching data');
        }
    };

    // for Pagination
    const totalPages = Math.ceil(dataTable.length / itemsPerPage);
    // const startIndex = (currentPage - 1) * itemsPerPage;
    // const endIndex = Math.min(startIndex + itemsPerPage, dataTable.length);
    // const currentData = dataTable.slice(startIndex, endIndex);

    const handlePageChange = (page: number) => {
        setCurrentPage(page);
    };
    const handleInput = (e: React.ChangeEvent<HTMLInputElement>) => {
        setSearchTerm(e.target.value)
    }

    // for making search table
    // const filteredData = currentData.filter((item:any) => item.patient?.user?.firstName.toLowerCase().includes(searcheTerm?.toLowerCase()));


    // const filteredData = searchTerm
    //     ? currentData.filter((item: any) => item.station?.user?.center?.name.toLowerCase().includes(searcheTerm?.toLowerCase()))
    //     : currentData;
    const leftIsOpen = useSelector((state: RootState) => state.sidebar.leftIsOpen)
    console.log(docId)
    console.log(searchTerm)
    return (
        <div className='flex flex-col gap-3 justify-center mt-10 w-[100%] -pl-14'>
            <SearchBar onChange={handleInput} value={searchTerm} />
            <div className={leftIsOpen ? "relative overflow-x-auto shadow-md sm:rounded-lg w-full" :
                "relative overflow-x-auto shadow-md m-0 sm:rounded-lg sm:w-[40rem] md:w-full 2xl:w-full"}>
                <table className="w-full text-sm text-left text-[#2E3552] dark:text-gray-400">
                    <thead className="text-xs text-white uppercase bg-[#1AA5C0] dark:bg-gray-700 dark:text-gray-400 h-16">
                        <tr className='text-center'>
                            <th scope="col" className="p-4 ">
                                <div className="flex items-center">

                                </div>
                            </th>
                            <th scope="col" className="px-6 py-3">
                                Date du rendez vous
                            </th>
                            {/* <th scope="col" className="px-6 py-3">
                            Unité mobile
                            </th> */}
                            <th scope="col" className="px-6 py-3">
                                Station
                            </th>
                            <th scope="col" className="px-6 py-3">
                            Status
                            </th>

                        </tr>
                    </thead>
                    <tbody>
                        {dataTable?.map((item: any) => (<>
                            <tr key={item.id} className="bg-white cursor-pointer border-2 border-b border-b-[#F6F8FD] mt-5 rounded-xlg m-5 text-center dark:bg-gray-800  hover:bg-gray-50 dark:hover:bg-gray-600">
                                <td className="w-4 p-4">

                                </td>
                                <th className="m-3 font-medium flex justify-center whitespace-nowrap dark:text-white
                                  p-2">
                                    <span className='p-0'> {dayjs(item.startTime).format("DD-MM-YYYY hh:mm")}</span>
                                </th>
                                <td className="px-6 py-4 font-medium text-[#2E3552]">
                                    {item.station?.center?.name!}
                                </td>
                                {/* <td className="px-6 py-4 font-medium text-[#2E3552]">
                                    {item.station?.user?.firstName}
                                </td> */}
                                <td className="px-6 py-4 font-medium text-[#2E3552]">
                                    {item.status === "reserved" ?
                                    <span className="bg-green-100 text-green-800 text-xs font-medium mr-2 px-2.5 py-0.5 rounded-full dark:bg-green-900 dark:text-green-300">Reservé</span>: item.status === 'cancelled' ? <span className="bg-red-100 text-red-800 text-xs font-medium mr-2 px-2.5 py-0.5 rounded-full dark:bg-red-900 dark:text-red-300">Annulé</span>:''}
                                </td>

                            </tr>
                        </>))}
                    </tbody>
                </table>

                <nav className="flex items-center justify-end p-4 bg-white" aria-label="Table navigation" style={{ border: '2px #efe4e4 solid' }}>
                    <div className="flex items-center justify-center text-center mt-2">

                        <ul className="flex items-center -space-x-px h-10 text-base">
                            <li>
                                <button onClick={() => handlePageChange(currentPage - 1)}
                                    disabled={currentPage === 1} className="flex items-center justify-center px-4 h-10 ml-0 leading-tight text-gray-500 bg-white hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800  dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white">
                                    <span className="sr-only">Previous</span>
                                    <svg className="w-3 h-3" aria-hidden="true" fill="none" viewBox="0 0 6 10">
                                        <path stroke="currentColor" stroke-linecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 1 1 5l4 4" />
                                    </svg>
                                </button>
                            </li>
                            {Array.from({ length: totalPages }, (_, i) => i + 1).map((page) => (
                                <li>

                                    <button key={page}
                                        onClick={() => handlePageChange(page)}
                                        disabled={currentPage === page}
                                        className={currentPage === page ? 'active' : 'flex cursor-pointer items-center justify-center px-4 h-10 leading-tight text-gray-500 bg-white'}
                                    >
                                        {page}
                                    </button>
                                </li>
                            ))}

                            <li>
                                <button onClick={() => handlePageChange(currentPage + 1)}
                                    disabled={currentPage === totalPages} className="flex  items-center justify-center px-4 h-10 leading-tight
                                     text-gray-500 bg-white    hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 
                                      dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white">
                                    <span className="sr-only">Next</span>
                                    <svg className="w-3 h-3" aria-hidden="true" fill="none" viewBox="0 0 6 10">
                                        <path stroke="currentColor" stroke-linecap="round" strokeLinejoin="round" strokeWidth="2" d="m1 9 4-4-4-4" />
                                    </svg>
                                </button>
                            </li>
                        </ul>

                    </div>
                </nav>
            </div>

        </div>
    )
}

export default MyTable
