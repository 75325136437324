import { ForwardedRef, forwardRef } from "react";
import styled from "styled-components";

export const DeviceVideo = forwardRef(
  (props: any, deviceRef: ForwardedRef<HTMLVideoElement>) => {
    return (
      <VideoContainer>
        <Video
          ref={deviceRef}
          autoPlay
          muted
          playsInline
          className="w-full h-full border-radius-10 max-h-[60%]"
          // maxHeight={props.maxHeight}
        />
      </VideoContainer>
    );
  }
);

const VideoContainer = styled.div`
  /* background-color: red; */
  /* width: 100%;
  height: 100%; */
  /* max-height: 100% !important; */
  /* width: var(--width);
  height: var(--height); */
  /* width: 100%;
  height: 100%; */
  /* background-color: #3a3a3e; */
  box-sizing: border-box;
  max-height: 100%;
  width: 100% !important;

  height: 100%;
  padding: 10px;
  /* position: relative; */
`;

const Video = styled.video`
  height: 100% !important;
  border-radius: 10px;
  width: 100%;
  max-height: 100%;
`;

/* max-height: ${({ maxHeight }) => maxHeight + "px"};
  height: ${({ maxHeight }) => maxHeight + "px"}; */

// ----------------------
/* height: ${({ maxHeight }) => maxHeight && maxHeight + "px"}; */
/* max-height: ${({ maxHeight }) => maxHeight && maxHeight + "px"}; */
