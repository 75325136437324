import React from "react";
import styled from "styled-components";
import { Checkbox } from "@material-tailwind/react";

const GlobalContent = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  .title {
    font-family: "Ubuntu";
    font-style: normal;
    font-size: 18px;
    font-weight: 500;
    color: #a4a4a4;
    line-height: 15px;
  }
`;

const MyCheckbox = styled.div`
  background: rgba(159, 146, 44, 0.11);
  border: 1px solid #20A8D3;
  border-radius: 8px;
  display: flex;
  align-items: center;
  gap: 1rem;
  padding-inline-end: 10px;
  color: rgb(32, 168, 211);
`;

interface MyPropsType {
  ischild: boolean | undefined;
  setIsChild: any;
  iswife: boolean | undefined;
  setIswife: any;
}

const AyantDroitSelect = ({
  ischild,
  setIsChild,
  iswife,
  setIswife,
}: MyPropsType) => {
  return (
    <GlobalContent>
      {/* <div className="title">Ayant droits</div> */}
      <MyCheckbox>
        <Checkbox
          
          label={
            <label
              className="font-ubuntu text-28.6877 leading-15 flex items-end "
              style={{ color: "#20A8D3", fontWeight: 600 }}
            >
              Enfant
            </label>
          }
          checked={ischild ? true : false}
          onChange={() => {
            setIsChild(!ischild);
            setIswife(false);
          }}
        />
        <Checkbox
          label={
            <label
              className="font-ubuntu text-28.6877 leading-15 flex items-end "
              style={{ color: "#20A8D3", fontWeight: 600 }}
            >
              Conjoint
            </label>
          }
          
          
          checked={iswife ? true : false}
          onChange={() => {
            setIswife(!iswife);
            setIsChild(false);
          }}
        />
      </MyCheckbox>
    </GlobalContent>
  );
};

export default AyantDroitSelect;
// import React, { useState } from "react";
// import styled from "styled-components";
// import { Checkbox } from "@material-tailwind/react";
// import MyModalInput from "./Modal";

// const GlobalContent = styled.div`
//   display: flex;
//   align-items: center;
//   gap: 10px;
//   .title {
//     font-family: "Ubuntu";
//     font-style: normal;
//     font-size: 18px;
//     font-weight: 500;
//     color: #a4a4a4;
//     line-height: 15px;
//   }
// `;

// const MyCheckbox = styled.div`
//   background: #20A8D3;
//   box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
//   border-radius: 28px;
//   display: flex;
//   align-items: center;
//   gap: 1rem;
//   padding-inline-end: 10px;
//   color: #ffff;
//   padding: 0.5rem 1.5rem 0.5rem 1.5rem;
//   cursor: pointer;
// `;

// interface MyPropsType {
//   ischild: boolean | undefined;
//   setIsChild: any;
//   iswife: boolean | undefined;
//   setIswife: any;
// }

// const AyantDroitSelect = ({
//   ischild,
//   setIsChild,
//   iswife,
//   setIswife,
// }: MyPropsType) => {
//   const [visible, setVisible] = useState(false);
//   return (
//     <GlobalContent>
//       {/* <div className="title">Ayant droits</div> */}
//       <MyCheckbox onClick={() => setVisible(true)}>
//         Ajouter ayant droit
//       </MyCheckbox>
//       <MyModalInput visible={visible} setVisible={setVisible} />
//     </GlobalContent>
//   );
// };

// export default AyantDroitSelect;
