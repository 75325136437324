import { CONSULTATION_STATUSES } from '../../enums/consultation';
import { SLICE } from '../../enums/store';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';





interface ConsultationsState {
    status: CONSULTATION_STATUSES.ENDED | CONSULTATION_STATUSES.CANCELED | CONSULTATION_STATUSES.ON_GOING | CONSULTATION_STATUSES.SCHEDULED ;
    locationStatus: string;
    timer: string;
}

const consultationsStatusSlice = createSlice({
    name: SLICE.CONSULTATION_STATUS,
    initialState: {
        status: CONSULTATION_STATUSES.ENDED,
        locationStatus: '',
        timer: ''
    },
    reducers: {
        updateStatusConsultation(state, action: PayloadAction<CONSULTATION_STATUSES>) {
            state.status = action.payload
        },
        getLocationStatus(state, action: PayloadAction<string>) {
            state.locationStatus = action.payload
        },
        getTimerStatus(state, action: PayloadAction<string>) {
            state.timer = action.payload
        },
    },

});

export const { updateStatusConsultation, getLocationStatus, getTimerStatus } = consultationsStatusSlice.actions;

export default consultationsStatusSlice.reducer;
