import * as React from "react";
import RadioInput from "../../../../../Components/FormBuilder/FormElements/Radio";
import { useDispatch, useSelector } from "react-redux";
import { setIsAlcoholic } from "../../../../../features/alcoholic/alcoholSlice";
import { setToxicsAlcohol } from "../../../../../features/consultationData/consultationDataSlice";

export interface IAlcoholConsumptionProps {}

export default function AlcoholConsumption(props: IAlcoholConsumptionProps) {
  const dispatch = useDispatch();
  const isAlcoholic = useSelector((state: any) => state.alcohol.isAlcoholic);

  return (
    <div>
      <div className="flex gap-4 items-center">
        <RadioInput
          id="Oui4"
          name="type"
          label="Oui"
          checked={isAlcoholic}
          onChange={() => {
            dispatch(setIsAlcoholic(true));
            dispatch(setToxicsAlcohol(true));
          }}
        />
        <div className="h-[2rem] w-[1px] bg-blue-gray-200"></div>
        <RadioInput
          id="Non4"
          name="type"
          label="Non"
          onChange={() => {
            dispatch(setIsAlcoholic(false));
            dispatch(setToxicsAlcohol(false));
          }}
          checked={!isAlcoholic}
        />
      </div>
    </div>
  );
}
