// medicationConsultsSlice.ts

import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { SLICE } from "../../enums/store";
import { IMedicationConsultsState } from "../../interfaces/medicationConsults";
import { dataNumberByIndex, dataStringByIndex } from "@interfaces/Stepper";
import { Console } from "console";
const initialState: IMedicationConsultsState = {
  medicationConsults: [],
  isLoading: false,
  isUpdated: false,
  isRefetchRequired: true,
  error: null,
};

const medicationConsultsSlice = createSlice({
  name: SLICE.MEDICATION_CONSULTATION,
  initialState,
  reducers: {
    setMedicationConsultationData: (state, action: PayloadAction<any>) => {
      state.medicationConsults = action.payload;
    },
    clearMedicationConsultationData: (state) => {
      state.medicationConsults = [];
    },
    decrementMorningValue: (state, action: PayloadAction<any>) => {
      const newMedicationConsults = [
        ...JSON.parse(JSON.stringify(state.medicationConsults)),
      ];
      newMedicationConsults[action.payload.index].morning =
        newMedicationConsults[action.payload.index]?.morning > 0 &&
        newMedicationConsults[action.payload.index].morning - 1;
      state.medicationConsults = newMedicationConsults;
      state.isRefetchRequired = false;
      state.isUpdated = true;
    },
    incrementMorningValue: (state, action: PayloadAction<any>) => {
      const newMedicationConsults = [
        ...JSON.parse(JSON.stringify(state.medicationConsults)),
      ];
      newMedicationConsults[action.payload.index].morning =
        newMedicationConsults[action.payload.index].morning + 1;
      state.medicationConsults = newMedicationConsults;
      state.isRefetchRequired = false;
      state.isUpdated = true;
    },
    incrementEveningValue: (state, action: PayloadAction<any>) => {
      const newMedicationConsults = [
        ...JSON.parse(JSON.stringify(state.medicationConsults)),
      ];
      newMedicationConsults[action.payload.index].evening =
        newMedicationConsults[action.payload.index].evening + 1;
      state.medicationConsults = newMedicationConsults;
      state.isRefetchRequired = false;
      state.isUpdated = true;
    },
    decrementEveningValue: (state, action: PayloadAction<any>) => {
      const newMedicationConsults = [
        ...JSON.parse(JSON.stringify(state.medicationConsults)),
      ];
      newMedicationConsults[action.payload.index].evening =
        newMedicationConsults[action.payload.index].evening > 0 &&
        newMedicationConsults[action.payload.index].evening - 1;
      state.medicationConsults = newMedicationConsults;
      state.isRefetchRequired = false;
      state.isUpdated = true;
    },
    incrementNoonValue: (state, action: PayloadAction<any>) => {
      const newMedicationConsults = [
        ...JSON.parse(JSON.stringify(state.medicationConsults)),
      ];
      newMedicationConsults[action.payload.index].noon =
        newMedicationConsults[action.payload.index].noon + 1;
      state.medicationConsults = newMedicationConsults;
      state.isRefetchRequired = false;
      state.isUpdated = true;
    },
    decrementNoonValue: (state, action: PayloadAction<any>) => {
      const newMedicationConsults = [
        ...JSON.parse(JSON.stringify(state.medicationConsults)),
      ];
      newMedicationConsults[action.payload.index].noon =
        newMedicationConsults[action.payload.index].noon > 0 &&
        newMedicationConsults[action.payload.index].noon - 1;
      state.medicationConsults = newMedicationConsults;
      state.isRefetchRequired = false;
      state.isUpdated = true;
    },
    incrementPeriodValue: (state, action: PayloadAction<any>) => {
      const newMedicationConsults = [
        ...JSON.parse(JSON.stringify(state.medicationConsults)),
      ];
      newMedicationConsults[action.payload.index].period =
        newMedicationConsults[action.payload.index].period + 1;
      state.medicationConsults = newMedicationConsults;
      state.isRefetchRequired = false;
      state.isUpdated = true;
    },
    decrementPeriodValue: (state, action: PayloadAction<any>) => {
      const newMedicationConsults = [
        ...JSON.parse(JSON.stringify(state.medicationConsults)),
      ];
      newMedicationConsults[action.payload.index].period =
        newMedicationConsults[action.payload.index].period > 0 &&
        newMedicationConsults[action.payload.index].period - 1;
      state.medicationConsults = newMedicationConsults;
      state.isRefetchRequired = false;
      state.isUpdated = true;
    },

    setNoteValue: (state, action: PayloadAction<dataStringByIndex>) => {
      const newMedicationConsults = [
        ...JSON.parse(JSON.stringify(state.medicationConsults)),
      ];
      newMedicationConsults[action.payload.index].note = action.payload.value;
      state.medicationConsults = newMedicationConsults;
      state.isRefetchRequired = false;
      state.isUpdated = true;
    },
    setMedicationValue: (state, action: PayloadAction<dataStringByIndex>) => {
      const newMedicationConsults = [...state.medicationConsults];
      newMedicationConsults[action.payload.index].medicationId =
        action.payload.value;
      state.medicationConsults = newMedicationConsults;
      state.isRefetchRequired = false;
      state.isUpdated = true;
    },
    addMedication: (state) => {
      const newMedicationConsults = [...state.medicationConsults];
      newMedicationConsults.push({
        noon: 0,
        evening: 0,
        period: 0,
        note: "",
        morning: 0,
        route: "",
        medicationId: "",
      });
      state.medicationConsults = newMedicationConsults;
      state.isRefetchRequired = false;
      state.isUpdated = true;
    },
    spliceMedication: (state, action: PayloadAction<number>) => {
      let splicedMedications = state.medicationConsults;
      splicedMedications.splice(action.payload, 1);
      state.medicationConsults = splicedMedications;
      state.isUpdated = true;
    },
  },
});

export const {
  setMedicationValue,
  incrementNoonValue,
  incrementPeriodValue,
  decrementEveningValue,
  decrementNoonValue,
  decrementPeriodValue,
  setNoteValue,
  incrementMorningValue,
  decrementMorningValue,
  incrementEveningValue,
  addMedication,
  setMedicationConsultationData,
  clearMedicationConsultationData,
  spliceMedication,
} = medicationConsultsSlice.actions;
export default medicationConsultsSlice.reducer;
