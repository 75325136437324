// slices/counterSlice.ts
import { SLICE } from "../../enums/store";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import  { RootState } from "../../store";

interface CallInfosState {
  generalistId: number | null;
  boxId: number | null;
  centerId: number | null;
}

const initialState: CallInfosState = {
    generalistId: null,
    boxId: null,
    centerId: null
};

const CallInfosSlice = createSlice({
  name: SLICE.CALLINITINFO,
  initialState,
  reducers: {
    setGeneralistId(state,action:PayloadAction<number>) {
        state.generalistId = action.payload;
    },
    setBoxId(state,action:PayloadAction<number>) {
        state.boxId = action.payload;
    },
    setCenterId(state,action:PayloadAction<number>) {
        state.centerId = action.payload;
    }
  },
});

export const { setGeneralistId, setBoxId ,setCenterId} = CallInfosSlice.actions;
export default CallInfosSlice.reducer;
//export the selectors
export const getGeneralistId = (state: RootState): boolean => 
  state.CallInfos.generalistId;
export const getBoxId = (state: RootState): boolean =>
  state.CallInfos.boxId;
  export const getCenterId = (state: RootState): boolean =>
  state.CallInfos.centerId;