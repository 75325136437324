import { useEffect, useState } from "react";
import { useConsultationContext } from "../../services/ConsultationContext";



interface IframeProps {
    url: string;
    style: any
    
}
const CustomIframe = (props: IframeProps) => {

    const { receivedDiagnostics, setReceivedDiagnostics } = useConsultationContext();

    const { url,style } = props;

    useEffect(() => {
        const messageListener = (e: any) => {
            const { diagnostic_1, diagnostic_2, interpretation } = e.data;

            if (diagnostic_1 && diagnostic_2 && interpretation) {
                console.log("diagnostics are",e.data)
                // Set the received message to state
                setReceivedDiagnostics(e.data);
            }
        };

        window.addEventListener('message', messageListener);

        return () => {
            window.removeEventListener('message', messageListener);
        };
    }, []);

    return (<iframe style={style} src={url}></iframe>)
}


export default CustomIframe;