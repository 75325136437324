// slices/counterSlice.ts
import { SLICE } from "../../enums/store";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "src/store";

interface CallActionsState {
  mute: boolean;
  toggleScreen: boolean;
  hangUp: boolean;
  showOthers: boolean;
}

const initialState: CallActionsState = {
  mute: false,
  toggleScreen: false,
  hangUp: false,
  showOthers: false,
};

const callActionsSlice = createSlice({
  name: SLICE.CALL_ACTIONS,
  initialState,
  reducers: {
    setMute(state) {
      state.mute = !state.mute;
    },
    setToggleScreen(state) {
      state.toggleScreen = !state.toggleScreen;
    },
    setHangUp(state) {
      state.hangUp = !state.hangUp;
    },
    setShowOthers(state) {
      state.showOthers = !state.showOthers;
    }
  },
});

export const { setMute, setHangUp, setToggleScreen,setShowOthers } = callActionsSlice.actions;
export default callActionsSlice.reducer;
//export the selectors
export const selectMute = (state: RootState): boolean => state.callActions.mute;
export const selectToggleScreen = (state: RootState): boolean =>
  state.callActions.toggleScreen;
export const selectHangUp = (state: RootState): boolean =>
  state.callActions.hangUp;

export const selectShowOthers = (state: RootState): boolean =>
  state.callActions.showOthers;
