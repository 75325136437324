// isVoirAntecedentShownSlice.ts

import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { SLICE } from "../../enums/store";
import { IisVoirAntecedentShownState } from "../../interfaces/Stepper";
const initialState: IisVoirAntecedentShownState = {
  isVoirAntecedentShown: false,
};

const isVoirAntecedentShownSlice = createSlice({
  name: SLICE.IS_VOIR_ANTECEDENT_SHOWN,
  initialState,
  reducers: {
    
    setIsVoirAntecedentShown: (state, action: PayloadAction<boolean>) => {
      state.isVoirAntecedentShown=action.payload
    }
    
  },
});

export const { setIsVoirAntecedentShown } = isVoirAntecedentShownSlice.actions;
export default isVoirAntecedentShownSlice.reducer;
