// selectedPrescriptionSlice.ts

import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { SLICE } from "../../enums/store";
import { ISelectedPrescriptionState } from "@interfaces/Stepper";
const initialState: ISelectedPrescriptionState = {
    selectedPrescription:0,
};

const selectedPrescriptionSlice = createSlice({
  name: SLICE.SELECTED_PRESCRIPTION,
  initialState,
  reducers: {
    
    setSelectedPrescription: (state, action: PayloadAction<number>) => {
          state.selectedPrescription = action.payload;
      
    },
    
  },
});

export const {setSelectedPrescription } = selectedPrescriptionSlice.actions;
export default selectedPrescriptionSlice.reducer;
