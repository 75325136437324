import { setSelectedPrescription } from "../../../../features/selectedPrescription/selectedPrescriptionSlice";
import { Tab, Tabs, TabsHeader } from "@material-tailwind/react";
import { RootState } from "../../../../store";
import * as React from "react";
import { useDispatch, useSelector } from "react-redux";

export interface IPrescriptionTabsProps {}

export default function PrescriptionTabs(props: IPrescriptionTabsProps) {
  const activeTab = useSelector(
    (state: RootState) => state.selectedPrescription.selectedPrescription
  );

  console.log("active tab", activeTab);
  let [categories, setCategories] = React.useState([
    "Médicaments",
    "Analyses biologiques",
    "Examens radiologiques",
  ]);
  const dispatch = useDispatch();
  function classNames(...classes: any) {
    return classes.filter(Boolean).join(" ");
  }

  return (
    <div className="mt-4">
      <Tabs value={categories[activeTab]} className="rounded-md">
        <TabsHeader
          placeholder=""
          className="bg-white"
          indicatorProps={{
            className: "bg-[#1AA5C0]",
          }}
        >
          {categories.map((category: any, index: number) => (
            <Tab
              key={index}
              value={category}
              className="py-2 bg-[#D9D9D9] m-2 rounded-md"
              onClick={() => {
                dispatch(setSelectedPrescription(index));
              }}
            >
              <div className="flex items-center gap-2 text-white">
                {category}
              </div>
            </Tab>
          ))}
        </TabsHeader>
      </Tabs>
    </div>
  );
}
