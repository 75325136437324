import { useEffect } from "react";
import Biology from "../biology";
import MedicalHistory from "../medicalHistory";
import PatientFile from "../patientFile";
import Report from "../report";
import VitalSigns from "../vitalSigns";
import Tabs from "../tabsComp";
import { setActiveTab } from "../../features/patientRecord/patientRecordTabSlice";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../store";
import Routes from "../../Routes";
import { setCurrentPatientId } from "../../features/patient/currentPatientSlice";
import { PATIENT_RECORD_TABS } from "../../constants";

// Interfaces
interface IOption {
  enable: number[];
}

interface ITabsProps {
  options?: IOption;
}
interface IPatientRecordProps {
  patientId: number;
  options?: IOption;
}
interface IPatientRecordContentProps {
  patientId: number;
  options?: IOption;
}

const PatientRecordContent = ({
  patientId,
  options,
}: IPatientRecordContentProps) => {
  const activeTab = useSelector(
    (state: RootState) => state.patientRecordTab.activeTab
  );

  //  render condionnaly
  if (
    activeTab == PATIENT_RECORD_TABS.PATIENT_FILE &&
    options?.enable.includes(PATIENT_RECORD_TABS.PATIENT_FILE)
  )
    return <PatientFile patientId={patientId} />;
  else if (
    activeTab == PATIENT_RECORD_TABS.MEDICAL_HISTORY &&
    options?.enable.includes(PATIENT_RECORD_TABS.MEDICAL_HISTORY)
  )
    return <MedicalHistory patientId={patientId} />;
  else if (
    activeTab == PATIENT_RECORD_TABS.VITAL_SIGNS &&
    options?.enable.includes(PATIENT_RECORD_TABS.VITAL_SIGNS)
  )
    return <VitalSigns patientId={patientId} />;
  else if (
    activeTab == PATIENT_RECORD_TABS.BIOLOGY &&
    options?.enable.includes(PATIENT_RECORD_TABS.BIOLOGY)
  )
    return <Biology patientId={patientId} />;
  else if (
    activeTab == PATIENT_RECORD_TABS.REPORTS &&
    options?.enable.includes(PATIENT_RECORD_TABS.REPORTS)
  )
    return <Report patientId={patientId} />;
  else return <></>;
};

// tabs declared here
const PatientRecordTabs = ({ options }: ITabsProps) => {
  const dispatch = useDispatch();
  const activeTab = useSelector(
    (state: RootState) => state.patientRecordTab.activeTab
  );

  // handler
  const handleTabChange = (index: number) => {
    dispatch(setActiveTab(index));
  };

  return (
    <Tabs
      index={activeTab}
      indicator={false}
      labels={Routes.map((route, _index: number) => {
        if (options?.enable.includes(_index)) return route.name;
      })}
      activeClassName="!bg-primary  !text-white"
      labelClassName="capitalize flex justify-center items-center h-[4rem]  bg-blue-300 mx-[2px] bg-primary/10 font-bold text-primary rounded-sm transition-colors hover:bg-primary/20 flex-1"
      onChange={(index) => {
        handleTabChange(index);
      }}
    />
  );
};

// The main component
export const PatientRecord = ({ patientId, options }: IPatientRecordProps) => {
  const dispatch = useDispatch();
  const currentPatientId = useSelector(
    (state: RootState) => state.currentPatient.currentPatientId
  );
  if (!currentPatientId) dispatch(setCurrentPatientId(patientId));
  return (
    currentPatientId && (
      <>
        <PatientRecordTabs options={options} />
        <PatientRecordContent patientId={patientId} options={options} />
      </>
    )
  );
};
