import React, { useEffect } from "react";
import { Outlet, Route, Routes } from "react-router-dom";
import LoginPage from "./Components/Login/Login";
import GlobalLayout from "./layout/Layout";
import AddPatientParams from "./Components/addPatientParams/AddPatientParams";
import AddPatient from "./Components/addPatient/addPatiend";
import Test from "./Components/tabs";
import tabsRoutes from "./Routes";
import WebSocketService from "./services/Notification/socketService";

import { app, userStatus } from "./enums/app";
import Disponibilite from "./Components/dispo";
import PeripheriquesDeBase from "./Components/periphiqueDeBase";
import PeriphiriquesAvancee from "./Components/periphiriqueAvancee";
import Periphiriques from "./Components/periphiriques";
import { QueryClient, QueryClientProvider } from "react-query";
import Appointement from "./Components/calendar/Appointement";
import Call from "./Components/call/Call";
import Dev from "./Components/Dev";
import SearchPatient from "./Components/SearchPatient";
import NotificationContextProvider from "./services/NotificationContext";
import { DevComponent } from "./helper/DevComponent";
import useConnection from "./hooks/useConnection";
import { useDispatch, useSelector } from "react-redux";
import store, { RootState } from "./store";
import { Sidebar } from "./layout/specialiste/Sidebar";
import { SpecialisteLayout } from "./layout/specialiste";
import Stepss from "./Components/Stepss/Steps";
import "./App.css";
import { ResumeConsultationButton } from "./Components/Consultation/ResumeConsultationButton";
import { Toaster, toast } from "react-hot-toast";
import APP_CONSTANTS from "./constants";
import MyTable from "./layout/specialiste/MyTable";
import MainContent from "./layout/specialiste/MainContent";
import MyLayout from "./layout/specialiste/MyLayout";
import { useNavigate } from "react-router-dom";
import { generateUniqueId } from "./services/utils.service";
import { ForgotPassword } from "./Components/Login/ForgotPassword";
import AppointementTable from "./layout/specialiste/AppointementTable";
import { setCurrentApp } from "./features/auth";
import NotFound from "./layout/NotFound";

import UpdateUser from "./Components/updateUser";
import Statistics from "./Components/statistics/Statistics";

import { WaitingList } from "./Components/waitingList";

const queryClient: any = new QueryClient();




const MainRouteRedirect = () => {
  const navigate = useNavigate();
  useEffect(() => {
    navigate("/login");
  });
  return <>Redirection vers la page de connexion...</>;
};



const App: React.FC = () => {
  useConnection();
  const dispatch = useDispatch();
  const currentApp = useSelector((state: RootState) => state.auth.currentApp);
  const isConnected = useSelector(
    (state: RootState) => state?.connection.isConnected
  );
  const { user: storedUser, access } = useSelector(
    (state: RootState) => state?.auth
  );

  // Use a flag to track if the toast has been shown
  const [toastShown, setToastShown] = React.useState(false);

  // Perform actions based on the internet connection status
  if (!isConnected && !toastShown) {
    // Do something when the internet connection is down
    toast.error(`L'appareil est déconnecté`, {
      id: "700",
    });

    // Set the flag to indicate that the toast has been shown
    setToastShown(true);
  } else if (isConnected && !toastShown) {
    // Do something when the internet connection is up
    toast.success(`Cet appareil est connecté à Internet`, {
      id: "800",
    });

    // Set the flag to indicate that the toast has been shown
    setToastShown(true);
  }


  /**
   * we will update the doctor status as online on his access
   */
  useEffect(() => {
    WebSocketService.updateDoctorStatus(userStatus.ONLINE, storedUser?.userId);
  }, []);



  

  /**
   *
   * @param resourceName
   * @returns
   */
  const hasAccess = (resourceName: string) => {
    if (!storedUser?.roles?.length) {
      return false;
    }

    // Find the user's group based on the resourceName
    const userGroup = storedUser.roles.find((group: any) => {
      return group.accessibilities.some(
        (accessibility: any) => accessibility.resource.name === resourceName
      );
    });

    // Check if the user has the required permission for the resource
    if (userGroup)
      return userGroup.accessibilities.some(
        (accessibility: any) => accessibility.resource.name === resourceName
      );

    return false;
  };

  useEffect(() => {
    const currentApp = localStorage.getItem(
      `${APP_CONSTANTS.LOCALSTORAGE_PREFIX}currentApp`
    );
 
     if (currentApp) dispatch(setCurrentApp(currentApp));
  }, []);


  // track current app change
  useEffect(() => {
    if (currentApp)
      localStorage.setItem(
        `${APP_CONSTANTS.LOCALSTORAGE_PREFIX}currentApp`,
        currentApp
      );
  }, [currentApp]);

  


  return (
    <QueryClientProvider client={queryClient}>
      <NotificationContextProvider>
        <Routes>
          <Route
            path={APP_CONSTANTS.ROUTES.FORGOT_PASSWORD}
            element={<ForgotPassword />}
          />


          {/*Main route redirects to login*/}
          <Route path="/" element={<MainRouteRedirect />} />


          {/* Shared Routes */}
          <Route path={APP_CONSTANTS.ROUTES.LOGIN} element={<LoginPage />} />

          {/* SPECIALIST ROUTES */}

          {hasAccess(app.SPECIALIST) && (
            <>
              {/* <Route path="specialiste" element={<MyLayout />}>
                <Route path="doctor" element={<MyTable />} /> */}
              <Route
                path={APP_CONSTANTS.ROUTES.CALL_HISTORY}
                element={<MyLayout />}
              >
                <Route
                  index
                  path={APP_CONSTANTS.ROUTES.SPECIALISR_CALENDAR}
                  element={<Appointement />}
                />
                <Route
                  path={APP_CONSTANTS.ROUTES.SPECIALIST_RDV_TABLE}
                  element={<AppointementTable />}
                />
                <Route
                  path={APP_CONSTANTS.ROUTES.SPECIALIST_DOCTOR_HISTORIQUE}
                  element={<MyTable />}
                />
                <Route
                  path={APP_CONSTANTS.ROUTES.SPECIALIST_AVAILABILITY}
                  element={<Disponibilite />}
                />
                <Route
                  path={APP_CONSTANTS.ROUTES.CALLSPECIALIST}
                  element={<Call />}
                />
              </Route>
              {/* </Route> */}
            </>
          )}

          {/* BASIC ROUTES */}
          {!hasAccess(app.SPECIALIST) && (
            <Route path="/doctor" element={<GlobalLayout />}>
              {/* Appointments routes */}

              {hasAccess(app.APPOINTMENTS) && (
                <Route
                  path={APP_CONSTANTS.ROUTES.CALENDAR}
                  element={<Appointement />}
                />
              )}

              {/* Patient record routes */}

              {hasAccess(app.PATIENT_RECORD) && (
                <>
                  {/* APPOINTMENT */}
                  <Route
                    path={APP_CONSTANTS.ROUTES.CALENDAR}
                    element={<Appointement />}
                  />

                  <Route
                    path={APP_CONSTANTS.ROUTES.PATIENTS_LIST}
                    element={<SearchPatient />}
                  />
                  <Route
                    path={APP_CONSTANTS.ROUTES.ADD_PATIENT}
                    element={<AddPatient />}
                  />
                  <Route
                    path={APP_CONSTANTS.ROUTES.ADD_DOCTOR}
                    element={<UpdateUser />}
                  />
                  <Route
                    path={APP_CONSTANTS.ROUTES.STATISTICS}
                    element={<Statistics />}
                  />
                  <Route path="/doctor/patient/:id" element={<Test />}>

                  </Route>
                  <Route
                    path={APP_CONSTANTS.ROUTES.EXAMINATION}
                    element={<Stepss />}
                  />
                </>
              )}
              {/* Call routes */}

              {hasAccess(app.VISIO_STATION) && (
                <>
                  <Route
                    path={APP_CONSTANTS.ROUTES.DEVICES}
                    element={<Periphiriques />}
                  />
                  <Route
                    path={APP_CONSTANTS.ROUTES.BASIC_DEVICES}
                    element={<PeripheriquesDeBase isConsultation={false} />}
                  />
                  <Route
                    path={APP_CONSTANTS.ROUTES.ADVANCED_DEVICES}
                    element={<PeriphiriquesAvancee />}
                  />
                  <Route path={APP_CONSTANTS.ROUTES.CALL} element={<Call />} />
                </>
              )}
              {/* Waiting list routes */}
              {hasAccess(app.PATIENT_RECORD) && (
                <>
                  <Route
                    path={APP_CONSTANTS.ROUTES.WAITING_LIST}
                    element={<WaitingList />}
                  />

                </>
              )}


            </Route>


          )}



          {/* PUBLIC ROUTES */}
          <Route path="/no-auth" element={<GlobalLayout />}>
            <Route
              path={APP_CONSTANTS.ROUTES.DEVICES_WITHOUT_AUTHENTICATION}
              element={<Periphiriques />}
            />
            <Route
              path={APP_CONSTANTS.ROUTES.BASIC_DEVICES_WITHOUT_AUTHENTICATION}
              element={<PeripheriquesDeBase isConsultation={false} />}
            />
            <Route
              path={APP_CONSTANTS.ROUTES.ADVANCED_DEVICES_WITHOUT_AUTHENTICATION}
              element={<PeriphiriquesAvancee />}
            />
          </Route>





          <Route path="*" element={
            <NotFound />
          } />
        </Routes>
      </NotificationContextProvider>
    </QueryClientProvider>
  );
};

export default App;
