import { Player } from "@lottiefiles/react-lottie-player";
import React from "react";

const Loader = () => {
  return (
    <div className="h-screen w-screen z-50 bg-black/50 absolute top-0 left-0 backdrop-blur-sm flex justify-center items-center">
      <Player
        autoplay
        loop
        src="https://assets5.lottiefiles.com/packages/lf20_f1dhzsnx.json"
        style={{ height: "300px", width: "300px" }}
      ></Player>
    </div>
  );
};

export default Loader;
