// patientSlice.ts
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IPatient } from '../../interfaces'; // Define your IPatient type

interface PatientState {
  patients: IPatient[];
  selectedPatient: any ; 
  isLoading: boolean;
  error: string | null;
}

const initialState: PatientState = {
  patients: [],
  selectedPatient: null,
  isLoading: false,
  error: null,
};

const patientSlice = createSlice({
  name: 'patient',
  initialState,
  reducers: {
    fetchPatientsRequest: (state) => {
      state.isLoading = true;
      state.error = null;
    },
    fetchPatientsSuccess: (state, action: PayloadAction<any[]>) => {
      state.isLoading = false;
      state.patients = action.payload;
      state.error = null;
      console.log("setting selected patient",action.payload)
      state.selectedPatient = action.payload.length > 0 ? action.payload[0] : null;

    },
    fetchPatientsFailure: (state, action: PayloadAction<string>) => {
      state.isLoading = false;
      state.error = action.payload;
    },
    selectPatient: (state, action: PayloadAction<any>) => {
      state.selectedPatient = action.payload;
      console.log("setting patient ",state.selectedPatient)

    },
  },
});

export const {
  fetchPatientsRequest,
  fetchPatientsSuccess,
  fetchPatientsFailure,
  selectPatient, 
} = patientSlice.actions;

export default patientSlice.reducer;
