import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import APP_CONSTANTS from "../../constants";
import { api } from "../api/api";
import {
  removeUnnecessaryFieldsAnalyses,
  removeUnnecessaryFieldsChiefComplaints,
  removeUnnecessaryFieldsImagings,
  removeUnnecessaryFieldsMedications,
} from "../../../src/utils/medicationDetails";

const consultationDataApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getConsultationData: builder.query<[], number>({
      query: (id) =>
        `consultation/${id}?include=chiefComplaintConsultations.chiefComplaint,medicalHistory,medicationConsultations.medication,analyseConsultations.analyse,imagingConsultations.imaging`,
    }),
    getOnGoingPatientConsultation: builder.query<[], number>({
      query: (id) =>
        `consultation?where={"endTime":null,"patientId":${id}}&orderBy={"createdAt":"desc"}`,
    }),
    getOnGoingPatientAndDoctorConsultation: builder.query<
      [],
      { patientId: number; doctorId: number }
    >({
      query: ({ patientId, doctorId }) =>
        `consultation?where={"endTime":null,"patientId":${patientId}, "doctorId":${doctorId}}&orderBy={"createdAt":"desc"}`,
    }),
    updateConsultationData: builder.mutation({
      query: ({ id, body }) => {
        // this handle the issue of 400 (cannot send id, createdAt ...), but we need to fix it in a higher level
        delete body.id;
        delete body.createdAt;
        delete body.updatedAt;
        delete body.deletedAt;
        delete body.currentStep;
        delete body.doctorId;
        delete body.patientId;
        delete body.stationId;
        delete body.medicalHistoryId;
        delete body.medicalHistory?.id;
        delete body.medicalHistory?.createdAt;
        delete body.medicalHistory?.updatedAt;

        if (body.medicationConsultations) {
          body.medicationConsultations = removeUnnecessaryFieldsMedications(
            body.medicationConsultations
          );
        }
        if (body.analyseConsultations) {
          body.analyseConsultations = removeUnnecessaryFieldsAnalyses(
            body.analyseConsultations
          );
        }
        if (body.imagingConsultations) {
          body.imagingConsultations = removeUnnecessaryFieldsImagings(
            body.imagingConsultations
          );
        }
        if (body.chiefComplaintConsultations) {
          body.chiefComplaintConsultations =
            removeUnnecessaryFieldsChiefComplaints(
              body.chiefComplaintConsultations
            );
        }
        // imagingConsultations: imagingId
        //analyseConsultations analyseId
        return {
          url: `consultation/${id}`,
          method: "PATCH",
          body,
        };
      },
    }),
  }),
});

export const {
  useGetOnGoingPatientConsultationQuery,
  useGetOnGoingPatientAndDoctorConsultationQuery,
  useGetConsultationDataQuery,
  useLazyGetConsultationDataQuery,
  useUpdateConsultationDataMutation,
} = consultationDataApi;

export default consultationDataApi;
