import React, { useState, useEffect } from 'react'
import { getCallsHistoryByDoctorId } from '../../../src/axiosAPI/Network';
import dayjs from 'dayjs'
import SearchBar from '../../Components/SearchPatient/Searchbar';
import { setLeftIsOpen } from '../../features/sidebar/SidebarSlice';
import './tablecss.css'
import { useSelector } from 'react-redux';
import { RootState } from '../../store';
import { Pagination } from 'swiper';
import { FaFilter } from 'react-icons/fa'


export function MyTable() {
    const initialSortDirections: Record<string, 'asc' | 'desc'> = {
        column1: 'asc',
        column2: 'asc',
        column3: 'asc',
        column4: 'asc',
    };
    const [currentPage, setCurrentPage] = useState(1);
    const [dataTable, setDataTable] = useState<any>([])
    const [searchTerm, setSearchTerm] = useState<string>()
    const [sortDirection, setSortDirection] = useState<'asc' | 'desc'>('asc');
    const itemsPerPage = 10;
    const doctorId = useSelector((state: RootState) => state.auth.user)
    // console.log("doctor id ----->",doctorId)
    const userId = doctorId.userId



    const totalPages = Math.ceil(dataTable.length / itemsPerPage);

    useEffect(() => {
        getData()
    }, [currentPage, searchTerm]);

    const getData = async () => {

        const skip = (currentPage - 1) * itemsPerPage;
        const take = itemsPerPage;

        try {
            const newData = await getCallsHistoryByDoctorId(
                userId,
                { skip, take },
                searchTerm
            );
            console.log(newData)
            setDataTable(newData.data?.results);
        } catch (error) {
            console.log('Error fetching data');
        }
    };



    // for Pagination

    const handlePageChange = (page: number) => {
        setCurrentPage(page);
    };
    const handleInput = (e: React.ChangeEvent<HTMLInputElement>) => {
        setSearchTerm(e.target.value)
    }
    // for sorting data 
    const sortedData = [...dataTable].sort((a, b) => {
        return Object.keys(a).reduce((result, column) => {
            if (result !== 0) {
                return result;
            }
            if (sortDirection === 'asc') {
                return a[column] > b[column] ? 1 : -1;
            } else {
                return a[column] < b[column] ? 1 : -1;
            }
        }, 0);
    });
    const handleSort = () => {
        setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
    };

    const leftIsOpen = useSelector((state: RootState) => state.sidebar.leftIsOpen)

    return (
        <div className='flex flex-col gap-3 justify-center mt-10 w-[100%] -pl-14'>

            <form className='w-[30em]'>
                <label htmlFor="default-search" className="mb-2 text-sm font-medium text-gray-900 sr-only dark:text-white">Search</label>
                <div className="relative">

                    <input type="search" onChange={handleInput} value={searchTerm} id="default-search" className="block w-full p-4 pl-10 text-sm
                     text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-blue-500 focus:border-blue-500
                      dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500
                       dark:focus:border-blue-500" placeholder="Recherche ..." required />
                    <button disabled type="submit" className="text-white absolute right-2.5 bottom-2.5 mt-6 bg-Bleu hover:bg-Bleu focus:ring-4 focus:outline-none
                     focus:ring-blue-300 font-medium rounded-lg text-sm px-4  dark:bg-Bleu dark:hover:bg-blue-700 dark:focus:ring-blue-800
                     w-16 h-10">
                        <div className="justify-center inset-y-0 left-0 flex items-center text-white pointer-events-none">
                            <svg className="w-4 h-4 text-white-500 dark:text-white-400" aria-hidden="true"
                                fill="none" viewBox="0 0 20 20">
                                <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                                    d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z" />
                            </svg>
                        </div>
                    </button>
                </div>
            </form>

            <div className={leftIsOpen ? "relative overflow-x-auto shadow-md sm:rounded-lg w-full" :
                "relative overflow-x-auto shadow-md m-0 sm:rounded-lg sm:w-[40rem] md:w-full 2xl:w-full"}>
                <div>


                    <table className="w-full text-sm text-left text-[#2E3552] dark:text-gray-400">
                        <thead className="text-xs text-white uppercase bg-[#1AA5C0] dark:bg-gray-700 dark:text-gray-400 h-16">
                            <tr className='text-center'>
                                <th scope="col" className="p-4 ">
                                    <div className="flex items-center">

                                    </div>
                                </th>
                                <th scope="col" className="px-6 py-3" onClick={handleSort}>
                                    <div className='flex gap-3 justify-center'>Date de démarrage de l'appel <FaFilter className='cursor-pointer' /></div>
                                </th>
                                <th scope="col" className="px-6 py-3 " onClick={handleSort}>
                                    <div className='flex gap-3 justify-center'>Date de fin de l'appel <FaFilter className='cursor-pointer' /></div>

                                </th>
                                {/* <th scope="col" className="px-6 py-3" onClick={handleSort}>
                                    <div className='flex gap-3 justify-center'>Station <FaFilter className='cursor-pointer' /></div>
                                </th> */}
                                <th scope="col" className="px-6 py-3" onClick={handleSort}>
                                    <div className='flex gap-3 justify-center'>Center <FaFilter className='cursor-pointer' /></div>
                                </th>

                            </tr>
                        </thead>
                        <tbody>
                            {sortedData?.map((item: any) => (<>
                                <tr key={item.id} className="bg-white cursor-pointer border-2 border-b border-b-[#F6F8FD] mt-5 rounded-xlg m-5 text-center dark:bg-gray-800  hover:bg-gray-50 dark:hover:bg-gray-600">
                                    <td className="w-4 p-4">

                                    </td>
                                    <th scope="row" className="m-3 font-medium flex justify-center whitespace-nowrap dark:text-white
                                  p-2">
                                        <span className='p-0'> {dayjs(item.startTime).format("DD-MM-YYYY à hh:mm:ss")}</span>
                                    </th>
                                    <td className="px-6 py-4 font-medium text-[#2E3552]">
                                        {dayjs(item.endTime).format("DD-MM-YYYY à hh:mm:ss")}
                                    </td>
                                    {/* <td className="px-6 py-4 font-medium text-[#2E3552]">
                                        {item.station?.user?.firstName}
                                    </td> */}
                                    <td className="px-6 py-4 font-medium text-[#2E3552]">
                                        {item.station?.center?.name!}
                                    </td>

                                </tr>
                            </>))}
                        </tbody>
                    </table>

                    <nav className="flex items-center justify-end p-4 bg-white" aria-label="Table navigation" style={{ border: '2px #efe4e4 solid' }}>
                        <div className="flex items-center justify-center text-center mt-2">

                            <ul className="flex items-center -space-x-px h-10 text-base">
                                <li>
                                    <button
                                        onClick={() => setCurrentPage(currentPage - 1)}
                                        disabled={currentPage === 1} className="flex items-center justify-center px-4 h-10 ml-0 leading-tight text-gray-500 bg-white hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800  dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white">
                                        <span className="sr-only">Previous</span>
                                        <svg className="w-3 h-3" aria-hidden="true" fill="none" viewBox="0 0 6 10">
                                            <path stroke="currentColor" stroke-linecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 1 1 5l4 4" />
                                        </svg>
                                    </button>
                                </li>
                                {Array.from({ length: totalPages }, (_, i) => i + 1).map((page) => (
                                    <li>

                                        <button key={page}
                                            onClick={() => handlePageChange(page)}
                                            disabled={currentPage === page}
                                            className={currentPage === page ? 'active' : 'flex cursor-pointer items-center justify-center px-4 h-10 leading-tight text-gray-500 bg-white'}
                                        >
                                            {page}
                                        </button>
                                    </li>
                                ))}

                                <li>
                                    <button
                                        onClick={() => setCurrentPage(currentPage + 1)}
                                        disabled={dataTable.length < itemsPerPage} className="flex  items-center justify-center px-4 h-10 leading-tight
                                     text-gray-500 bg-white    hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 
                                      dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white">
                                        <span className="sr-only">Next</span>
                                        <svg className="w-3 h-3" aria-hidden="true" fill="none" viewBox="0 0 6 10">
                                            <path stroke="currentColor" stroke-linecap="round" strokeLinejoin="round" strokeWidth="2" d="m1 9 4-4-4-4" />
                                        </svg>
                                    </button>
                                </li>
                            </ul>

                        </div>
                    </nav>

                </div>
            </div>

        </div>
    )
}

export default MyTable
