import React, { useEffect, useState } from "react";
import pic from "../assets/user.svg";
import logo from "../assets/logo2.svg";
import { BsChevronCompactDown } from "react-icons/bs";
import { RiMenuUnfoldLine } from "react-icons/ri";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "src/store";
import { setLeftIsOpen, setRightIsOpen } from "../features/sidebar/SidebarSlice";
import APP_CONSTANTS from "../constants";
import { useNavigate, useParams } from "react-router-dom";
import { LuLogOut } from "react-icons/lu";
import { SignOut, getDoctorNamebyId } from "../axiosAPI/Network";
import Notifications from "../Components/Notifications";
import { setNotifications } from "../features/notifications/notificationsSlice";
import { Popover } from "@headlessui/react";
import { IoMdNotificationsOutline } from "react-icons/io";
import { useGetNotificationsQuery } from "../features/notifications/notificationsApi";



function Nav() {


  const { currentCallId } = useSelector((state: RootState) => state.auth);
  const { id } = useParams<{ id: string }>(); // Fetching the 'id' parameter from the URL

  const [doctorId, setDoctorId] = useState("");
  const [refrech, setRefrech] = useState("");
  const [showReturnBackTocallButton, setShowReturnBackTocallButton] = useState<boolean>(false);
  const [docFirstName, setDocFirstName] = useState("")
  const [docLastName, setDocLastName] = useState("")
  const [speciality, setSpeciality] = useState("")
  const dispatch = useDispatch()
  const { data: notifsData } = Number(localStorage.getItem(`${APP_CONSTANTS.LOCALSTORAGE_PREFIX}userId`)) ? useGetNotificationsQuery(Number(localStorage.getItem(`${APP_CONSTANTS.LOCALSTORAGE_PREFIX}userId`))) : { data: {} };

  React.useMemo(() => dispatch(setNotifications(notifsData)), [notifsData])
  const leftIsOpen = useSelector((state: RootState) => state.sidebar.leftIsOpen)
  const nav = useNavigate()

  useEffect(() => {

    setDoctorId(
      localStorage.getItem(`${APP_CONSTANTS.LOCALSTORAGE_PREFIX}doctorId`) || ""
    );
    setRefrech(localStorage.getItem(`${APP_CONSTANTS.LOCALSTORAGE_PREFIX}doctorId`) || "")
    const id = parseInt(doctorId)

    getDoctorNamebyId(id).then(res => {
      setDocFirstName(res?.user?.firstName)
      setDocLastName(res?.user?.lastName)
      setSpeciality(res?.speciality?.name)
    })
  }, [doctorId])

  useEffect(() => {
    const currentURL = window.location.href;
    const containsCallId = currentURL.includes(`/call/${id}`);
    
    if (containsCallId || !currentCallId) {
      setShowReturnBackTocallButton(false)
    } else {
      setShowReturnBackTocallButton(true)
    }
  }, [id]);


  /**
   * 
   */
  const handleReturnBackToCall = () => {
    nav(`/specialiste/doctor/call/${currentCallId}`)
  }

  const logout = () => {
    const keys = Object.keys(localStorage);


    keys.forEach((key) => {
      if (key.startsWith(APP_CONSTANTS.LOCALSTORAGE_PREFIX)) {
        localStorage.removeItem(key);
      }
    });

    window.location.pathname = APP_CONSTANTS.ROUTES.LOGIN;
  }

  return (
    <>
      <nav className="fixed w-full border-gray-200 bg-white z-20">
        <div className="max-w-screen-xlg mx-6 flex flex-wrap items-center justify-between p-4">
          <div className="flex">
            <img src={logo} className="h-8 mr-5" alt="user Logo" />
            <RiMenuUnfoldLine className={`${!leftIsOpen ? "mt-2 cursor-pointer" : "mt-2 cursor-pointer rotate-180"}`} size={20} onClick={() => dispatch(setLeftIsOpen(!leftIsOpen))} />
          </div>

          {/* next near appointment */}
          {/* <div className="flex items-center p-4 mb-4 text-sm text-blue-800 rounded-lg bg-blue-50 dark:bg-gray-800 dark:text-blue-400" role="alert">
            <svg className="flex-shrink-0 inline w-4 h-4 mr-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
              <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z" />
            </svg>
            <span className="sr-only">Info</span>
            <div>
              Votre rendez-vous le plus proche est avec l' unité mobile <strong>"Nouaceur 1"</strong> le <strong>30 août</strong> à <strong>18:00</strong>.
            </div>
          </div> */}
          {/* notfocation call */}
          <Notifications
            PopoverButton={
              <Popover.Button className="outline-none mt-1" >
                <div className="relative" onClick={() => {
                  dispatch(setNotifications(notifsData?.results))
                }}>
                  <IoMdNotificationsOutline size={40} />
                  {notifsData?.results?.length > 0
                    && <span className="absolute -top-2 right-1 inline-flex items-center justify-center w-5 h-5 ml-2 text-xs font-semibold text-white bg-Bleu rounded-full">
                      {notifsData?.results?.length}
                    </span>}
                </div>
              </Popover.Button>
            }
            Notifs={[
              {
                message: "Votre consultation va démarrer dans moin d' 1min",
                type: Notification.APPOINTMENT,
                title: "Nouvelle Consultation",
              },
              {
                message: "Votre consultation est dans 5min",
                type: Notification.APPOINTMENT,
                title: "Nouvelle Consultation",
              },
            ]}
          />
          {/* call flag */}
          {/* {showReturnBackTocallButton && <button
            className=" text-white  bg-gradient-to-r from-cyan-500 to-blue-500 hover:bg-gradient-to-bl focus:ring-4 focus:outline-none focus:ring-cyan-300 dark:focus:ring-cyan-800 font-medium rounded-lg text-sm px-5 py-2.5 text-center mb-2"

            onClick={handleReturnBackToCall}>retourner à l'appel</button>} */}
          <div className="flex items-center md:order-2">
            <img className="w-8 h-8 rounded-full" src={pic} alt="user photo" />

            <span className="m-2 bg-light">Dr. {docFirstName!} {" "} {docLastName!}</span>
            <span className="bg-green-100 text-green-800 text-xs font-medium mr-2 px-2.5 py-0.5 rounded-full dark:bg-green-900 dark:text-green-300">
              Specialité : {speciality}
            </span>
            <LuLogOut className="cursor-pointer" onClick={logout} />
            {/* 
            <div
              className="z-50 hidden my-4 text-base list-none bg-white divide-y divide-gray-100 rounded-lg shadow 
              dark:bg-gray-700 dark:divide-gray-600"
              id="user-dropdown"
            >
              <div className="px-4 py-3">
                <button  className="block text-sm text-gray-900 dark:text-white">
                  Logout
                </button>
              </div>
            </div> */}
          </div>
        </div>
      </nav>
    </>
  );
}

export default Nav;
