//socket io context provider with typescript
import React, {
  createContext,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { io, Socket } from "socket.io-client";
// import { useAuth } from './authContext';
import { useQuery } from "react-query";
import { useNavigate } from "react-router-dom";
import { Alert, Typography } from "@material-tailwind/react";
import { CheckCircleIcon } from "@heroicons/react/24/solid";
import { Toaster, toast } from "react-hot-toast";
import { getDoctorNamebyId } from "../axiosAPI/Network";
import { EventModalData } from "../Components/types";
import styled from "styled-components";

const NotificationContext = createContext<any>({});
const StyledToastContainer = styled(Toaster)`
position:fixed;
  /* z-index: 99999999999999999999999999999999999999999999; Adjust the value as needed */
  /* Additional styling properties can be added here */
`;
export const useNotificationContext = () => useContext(NotificationContext);

const NotificationContextProvider: React.FC<any> = ({ children }) => {
  const socketcall = useRef<Socket | null>(null);
  const nav = useNavigate();
  const [roomCreatedData, setRoomCreatedData] = useState<any>({});
  const doctorUserId = localStorage.getItem("far_userId");
  const doc_gen = "generalist";
  const [consultationData, setConsultationData] =
    useState<EventModalData | null>(null);

  const [isGeneralist, setIsGeneralist] = useState<boolean>((): boolean => {
    const data = JSON.parse(localStorage.getItem("far_roles")!);
    if(data)return data[0].name === "generalist";
    else return false;
  });

  // const demarrerConsultation = () => {
  //   socketcall.current!.emit("call-user", { offer: "" });
  // };

  useEffect(() => {
    if (localStorage.getItem("far_doctorSpecialite")) {
      // console.log(
      //   "storage changed",
      //   localStorage.getItem("far_doctorSpecialite") === doc_gen
      //     ? "gen"
      //     : "not gen"
      // );
      setIsGeneralist(localStorage.getItem("far_doctorSpecialite") === doc_gen);
    }

    // window.addEventListener("storage", handleStorageChange);

    return () => {
      // console.log("storage changed out");
      // window.removeEventListener("storage", handleStorageChange);
    };
  }, [localStorage.getItem("far_doctorSpecialite")]);

  const createRoom = (patientId: number) => {
    socketcall.current!.emit(
      "createRoom",
      JSON.stringify({
        owner: 148,
        patientId: patientId,
        staffId: 130,
        consultationId: 1,
      })
    );
    // console.log("[call] createRoom");
  };

  const dismissPropose = () => {
    // console.log("dismissed");
  };

  useEffect(() => {
    // Create a new WebSocket instance
    // socketcall.current = io(`${process.env.REACT_APP_SOCKET_URL}`, {
    //   auth: { token: localStorage.getItem("far_token") },
    // });

    // socketcall.current.on("connect", () => {
    //   // console.log("SockerServer sConnected");
    // });

    // socketcall.current.on("room-created", (data: any) => {
    //   const { room, token } = JSON.parse(data);
    //   console.log("ROOM-CREATED TOKEN", token);
    //   // if (tmp.owner == localStorage.getItem("userId")) {
    //   nav(`/doctor/call/${room.id}`, {
    //     state: {
    //       id: 1,
    //       consultationId: 1,
    //       staffId: 130,
    //       isAnounym: true,
    //       token: token,
    //     },
    //   });
    //   localStorage.setItem("call_token", token);
    //   // }
    // });

    // socketcall.current.on("disconnect", (reason) => {
    //   console.log("SockerServer useEffect disconnect", reason);
    // });

    // socketcall.current.on("connect_error", (error) => {
    //   console.log("SockerServer useEffect connect_error > reason: ", error);
    // });
    // socketcall.current.on("reconnect_attempt", () => {
    //   console.log("SockerServer useEffect reconnect_attempt");
    // });
    // socketcall.current.on("reconnect", () => {
    //   console.log("SockerServer useEffect reconnect");
    // });

    // socketcall.current.on("disconnecting", (reason) => {
    //   console.log("SockerServer useEffect disconnecting", reason);
    // });

    // if (!isGeneralist) {
    //   console.log("[call] setDoctorStatus", doctorUserId);
    //   socketcall.current.emit(
    //     "setDoctorStatus",
    //     JSON.stringify({
    //       userId: doctorUserId,
    //       status: "online",
    //     })
    //   );
    // } else {
    // }
    // socketcall.current.on("updateConsultation", (data: any) => {
    //   const { patientId } = data;
    //   notify("Demarrer Maintenant", "pas maintennt", patientId);
    // });
    return () => {
      if (socketcall.current) socketcall.current.close();
    };
  }, []);

  useEffect(() => {
    if (consultationData) {
      console.log("consultationData", consultationData);
    }
  }, [consultationData]);

  return (
    <NotificationContext.Provider
      value={{
        // notify,
        socketcall,
        isGeneralist,
        setConsultationData,
      }}
    >
      {children}
      <StyledToastContainer position="top-center" reverseOrder={false} />
    </NotificationContext.Provider>
  );
};

export default NotificationContextProvider;
