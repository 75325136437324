// dialogSlice.ts
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface DialogState {
  confirm?: boolean;
  open?: boolean;
  size?: 'xl' | 'md' | 'lg' | 'sm' | 'xs' | 'xxl';
  message: string;
  isLoading: boolean,
  component?: React.ReactNode,
  title: string;
  handleConfirm?: any;
  handleCancel?: any;
}

const initialState: DialogState = {
  confirm: false,
  open: false,
  isLoading: false,
  message: '',
  size: "md",
  component: null,
  title: '',
  handleConfirm: null,
  handleCancel: null,
};

const dialogSlice = createSlice({
  name: 'dialog',
  initialState,
  reducers: {
    openDialog: (state, action: PayloadAction<DialogState>) => {
       state.handleConfirm = action.payload.handleConfirm;
      state.open = true;
      Object.assign(state, action.payload);
    },
    setIsLoading:(state, action: PayloadAction<boolean>) => {
     state.isLoading = action.payload;
     
   },
    closeDialog: (state) => {
      state.open = false;
      state.confirm = false;
      state.message = '';
      state.title = '';
      state.isLoading = false;
      state.component = null;
      state.handleConfirm = () => {};
      state.handleCancel = () => {};
    },
  },
});

export const { openDialog, closeDialog,setIsLoading } = dialogSlice.actions;

export default dialogSlice.reducer;
