import React, { useEffect, useState } from "react";
import styled from "styled-components";
import SearchBar from "./Searchbar";
import Table from "./Table";
import { useQuery } from "react-query";
import { getPatient } from "../../axiosAPI/Network";
import Loader from "../common/Loader";
import { SearchPatientProv } from "../../Providers/SearchPatientProv";
import SearchPatientIndex from "./SearchPatient";

export const GlobalStyle = styled.div`
  background: white;
  border-top-left-radius: 7px;
  border-top-right-radius: 7px;
  height: 100%;
  overflow-y: auto;
`;

export const GlobalContent = styled.div`
  width: 100%;
  padding: 2rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  box-sizing: border-box;
  height: 100%;
  .title {
    font-family: "Ubuntu";
    font-style: normal;
    font-weight: 500;
    font-size: 35.0127px;
    line-height: 17px;
    display: flex;
    align-items: center;
    color: #34615a;
  }
`;


const SearchPatient = () => {
  return (
    <SearchPatientProv>
      <SearchPatientIndex />
    </SearchPatientProv>
  );
};
export default SearchPatient;
