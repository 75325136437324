import { useGetChiefComplaintsQuery } from "../../../../features/chiefComplaints/chiefComplaintsApi";
import {
  DISPLAY_CONDITION_STATE,
  SELECT_DATA_STATE,
} from "../../../../enums/stepper";
import { Combobox, Listbox, Transition } from "@headlessui/react";
import { ChevronDownIcon, XMarkIcon } from "@heroicons/react/24/solid";
import React, {
  Fragment,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getAnalysis,
  getChiefComplaints,
  getHospitals,
  getImaging,
  getMedications,
  getPatient,
  getPatientByStr,
  getSpecialities,
  searchDoctorData,
} from "../../../../axiosAPI/Network";
import { Option, Select } from "@material-tailwind/react";
import { getYears } from "../../../../utils";
import { ActionCreator, PayloadAction } from "@reduxjs/toolkit";
import { IselectedFamiliauxState } from "@interfaces/selectedFamiliaux";
import { SLICE } from "../../../../enums/store";
import { IConsultationDataState } from "../../../../interfaces/Consultation";
import { SELECT_DATA_OF } from "../../../../enums/telemedecine";
import { setDiagnosticAssocie } from "../../../../features/diagnosticAssocie/diagnosticAssocieSlice";
import { useParams } from "react-router";
export interface ISearchableSelectProps {
  name: string;
  label: string;
  placeholder?: string;
  index?: number;
  dataState: string;
  defaultValue?: any;
  actions?: any;
  useCase?: any;

  style: string;
  displayConditionState?: string;
  consultationData?: IConsultationDataState;
}
function debounce<T extends (...args: any[]) => void>(func: T, delay: number) {
  let timer: ReturnType<typeof setTimeout>;
  return function (this: any, ...args: Parameters<T>) {
    clearTimeout(timer);
    timer = setTimeout(() => {
      func.apply(this, args);
    }, delay);
  };
}

export default function SearchableInput({
  name,
  label,
  placeholder,
  defaultValue,
  useCase,
  dataState,
  displayConditionState,
  actions,
  index,
  style,
  consultationData,
}: ISearchableSelectProps) {
  const [options, setOptions] = useState<any>([]);
  const [queryValue, setQueryValue] = useState<string>();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isHidden, setIsHidden] = useState(false);
  const [dates, setDates] = useState<any>();
  const { id, consultationId } = useParams();
  const [internalDefaultValue, setInternalDefaultValue] = useState<any>();
  const chiefComplaintsConstult = useSelector(
    (state: any) => state.diagnosticAssocie.princip
  );

  const docSpecialtyId = useSelector(
    (state: any) => state.appointment.specialtyId
  );
  const [selectedOption, setSelectedOption] = useState<any>("");

  const consultationDataState = useSelector(
    (state: any) => state.consultationData
  );
  const fetchData = useCallback(() => {
    switch (dataState) {
      case SELECT_DATA_STATE.CHIEF_COMPLAINTS:
        getChiefComplaints(queryValue ? queryValue : "").then((res: any) => {
          setOptions(res.data.results);
          setIsLoading(false);
        });
        break;
      case SELECT_DATA_STATE.DIAGNOSTIC_ASSOCIE:
        getChiefComplaints(queryValue ? queryValue : "").then((res: any) => {
          setOptions(res.data.results);
          setIsLoading(false);
        });
        break;
      case SELECT_DATA_OF.HOSPITAL:
        getHospitals({ take: 5, skip: 0 }, queryValue ? queryValue : "").then(
          (res: any) => {
            setOptions(res.data.results);
            setIsLoading(false);
          }
        );
        break;
      case SELECT_DATA_OF.PATIENT:
        getPatientByStr(queryValue ? queryValue : "").then((res: any) => {
          let data = res.data.results.map((d: any, index: number) => {
            return {
              index: index,
              id: d.id,
              name: `${d.user.firstName} ${d.user.lastName}, (${
                d.cine ? d.cine : "CIN non disponible"
              }),`,
            };
          });

          setOptions(data);
          setIsLoading(false);
        });
        break;

      case SELECT_DATA_OF.SPECIALITY:
        getSpecialities(
          { take: 5, skip: 0 },
          queryValue ? queryValue : ""
        ).then((res: any) => {
          setOptions(res.data.results);
          setIsLoading(false);
        });
        break;
      case SELECT_DATA_OF.DOCTOR:
        searchDoctorData(
          { take: 5, skip: 0 },
          docSpecialtyId,
          queryValue ? queryValue : ""
        ).then((res: any) => {
          let temp = getSubQueryData(res.data.results);
          setOptions(temp);
          setIsLoading(false);
        });
        break;
      case SELECT_DATA_OF.MEDICATIONS:
        {
          getMedications(queryValue ? queryValue : "").then((res: any) => {
            setOptions(res.data.results);
            setIsLoading(false);
          });
        }
        break;
      case SELECT_DATA_OF.ANALYSE:
        getAnalysis(queryValue ? queryValue : "").then((res: any) => {
          setOptions(res.data.results);
          setIsLoading(false);
        });
        break;
      case SELECT_DATA_OF.IMAGING:
        getImaging(queryValue ? queryValue : "").then((res: any) => {
          setOptions(res.data.results);
          setIsLoading(false);
        });
        break;
      default:
        break;
    }
  }, [dataState, queryValue]);

  const getSubQueryData = (data: any) => {
    return data.map((doctor: any) => {
      return {
        ...doctor,
        name: doctor.user.firstName + " " + doctor.user.lastName,
      };
    });
  };

  const debouncedFetchData = React.useMemo(
    () => debounce(fetchData, 500),
    [fetchData]
  );
  const dispatch = useDispatch();
  useEffect(() => {
    if (queryValue && queryValue.length > 0) {
      setIsLoading(true);
      const timerId = setTimeout(() => {
        debouncedFetchData();
      }, 500);

      return () => {
        clearTimeout(timerId);
      };
    }
  }, [queryValue, debouncedFetchData]);
  // console.log("sd", chiefComplaintsConstult);

  // console.log("sd d", internalDefaultValue);

  useEffect(() => {
    switch (useCase) {
      case SLICE.PRINCIPALE_DIAGNOSTIC:
        chiefComplaintsConstult &&
          setInternalDefaultValue(chiefComplaintsConstult);
        break;
    }
  }, [useCase, chiefComplaintsConstult]);

  useMemo(() => {
    switch (displayConditionState) {
      case DISPLAY_CONDITION_STATE.APPT_PATIENT:
        id && setIsHidden(true);
        break;
      default:
        break;
    }
  }, [displayConditionState]);
  return (
    <>
      {!isHidden && (
        <div className={style}>
          {label && <h4 className="mb-3 text-[#5E5E5E]">{label}</h4>}

          <Combobox
            onChange={(e: any) => {
              console.log("sd ", e);

              setSelectedOption(e);
              switch (name) {
                case SLICE.BIO_ANALYSES:
                  actions?.map((action: any) => {
                    dispatch(
                      action({
                        index: index,
                        value: e.id,
                      })
                    );
                  });

                  break;
                case SLICE.MOTIFS:
                  actions?.map((action: any) => {
                    dispatch(
                      action({
                        index: index,
                        value: e.name,
                      })
                    );
                  });

                  break;
                case SLICE.HOSPITALIZATION:
                  actions?.map((action: any) => {
                    dispatch(
                      action({
                        index: index,
                        value: e.name,
                      })
                    );
                  });

                  break;
                case SLICE.MEDICATION_CONSULTATION:
                  actions?.map((action: any) => {
                    dispatch(
                      action({
                        index: index,
                        value: e.id,
                      })
                    );
                  });
                  break;
                case SLICE.MEDICAUX:
                  actions?.map((action: any) => {
                    dispatch(
                      action({
                        index: index,
                        value: e.name,
                      })
                    );
                  });
                  break;
                case SLICE.PATIENT:
                  actions?.map((action: any) => {
                    dispatch(action(e.id));
                  });
                  break;
                case SLICE.TRAITEMENT:
                  actions?.map((action: any, id: number) => {
                    dispatch(
                      action({
                        medicationId: e.id,
                        medicationName: e.name,
                      })
                    );
                  });
                  break;
                case SLICE.ANALYSES_RADIO:
                  actions?.map((action: any) => {
                    dispatch(
                      action({
                        index: index,
                        value: e.id,
                      })
                    );
                  });
                  break;
                case SLICE.SELECTED_FAMILIAUX:
                  actions?.map((action: any) => {
                    dispatch(action(e.name));
                  });
                  break;

                case SLICE.ANTECEDENTS:
                  actions?.map((action: any) => {
                    dispatch(action(e.name));
                  });
                  break;
                case SLICE.DOCTOR:
                  actions?.map((action: any) => {
                    dispatch(action(e));
                  });
                  break;

                case SLICE.HOSPITAL:
                  actions?.map((action: any) => {
                    dispatch(action(e.id));
                  });
                  break;
                case SLICE.SPECIALITY:
                  actions?.map((action: any) => {
                    dispatch(action(e.id));
                  });
                  break;
                case SLICE.PRINCIPALE_DIAGNOSTIC:
                  actions?.map((action: any) => {
                    dispatch(action(e.id));
                  });
                  break;
                case SLICE.DIAGNOSTIC_SECONDAIRE:
                  dispatch(setDiagnosticAssocie(e.name));
                  actions?.map((action: any) => {
                    dispatch(action(e.id));
                  });
                  break;
                default:
                  actions &&
                    actions?.map((action: any, index: number) => {
                      dispatch(action(e.name));
                    });
                  break;
              }
            }}
          >
            <div className="relative mt-1">
              <div className="relative w-full cursor-default overflow-hidden rounded-lg bg-white text-left shadow-md focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 focus-visible:ring-offset-teal-300 sm:text-sm">
                <Combobox.Input
                  placeholder={
                    placeholder ?? defaultValue?.name ?? internalDefaultValue
                  }
                  className="outline-none w-full border-none h-9 py-2 pl-3 pr-10 text-sm leading-5 text-gray-900 focus:ring-0  "
                  displayValue={(option: any) =>
                    selectedOption == option
                      ? option?.name
                      : !defaultValue
                      ? ""
                      : option.name
                  }
                  onChange={(e) => {
                    setQueryValue(e.target.value);
                  }}
                />
                {selectedOption && (
                  <button
                    onClick={() => {
                      setSelectedOption(null);
                      setQueryValue("");
                    }}
                    className="mr-5 absolute inset-y-0 right-0 flex items-center pr-2 cursor-pointer"
                  >
                    <XMarkIcon
                      className="h-5 w-5 text-gray-400"
                      aria-hidden="true"
                    />
                  </button>
                )}
                {options && options?.length > 0 && (
                  <>
                    <Combobox.Button className="absolute inset-y-0 right-0 flex items-center pr-2">
                      <ChevronDownIcon
                        className="h-5 w-5 text-gray-400"
                        aria-hidden="true"
                      />
                    </Combobox.Button>
                  </>
                )}
              </div>
              <Transition
                as={Fragment}
                leave="transition ease-in duration-100"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
                afterLeave={() => {}}
              >
                <Combobox.Options
                  className={
                    "absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm"
                  }
                >
                  {isLoading ? (
                    <div className="relative cursor-default select-none py-2 px-4 text-gray-700">
                      Recherche en cours...
                    </div>
                  ) : (
                    <>
                      {options?.length === 0 ? (
                        <div className="relative cursor-default select-none py-2 px-4 text-gray-700">
                          Aucun résulat.
                        </div>
                      ) : (
                        options?.map((option: any, index: number) => (
                          <Combobox.Option
                            key={index}
                            className={({ active }) =>
                              `cursor-pointer	 relative  select-none py-2 pl-5 pr-4 ${
                                active
                                  ? "cursor-pointer bg-[#20a8d3] text-white"
                                  : "text-gray-900"
                              }`
                            }
                            value={option}
                          >
                            {({ selected, active }) => (
                              <>
                                <span
                                  className={`block truncate ${
                                    selected ? "font-medium" : "font-normal"
                                  }`}
                                >
                                  {option.name}
                                </span>
                              </>
                            )}
                          </Combobox.Option>
                        ))
                      )}
                    </>
                  )}
                </Combobox.Options>
              </Transition>
            </div>
          </Combobox>
        </div>
      )}
    </>
  );
}
