import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import APP_CONSTANTS from "../../constants";
import { api } from "../api/api";
const motifsApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getMotifs: builder.query<[], number>({
      query: (id) => `consultation/${id}`,
      transformResponse: (response: any) => response.motifComplaint,
      providesTags: [],
    }),
  }),
});

export const { useGetMotifsQuery } = motifsApi;

export default motifsApi;
