import React, { useCallback, useState } from "react";
import { GlobalContent, GlobalStyle } from "../SearchPatient/SearchPatient";
import styled from "styled-components";
import AyantDroitSelect from "./AyantDroitRadio";
import AddPatientfrom from "./addPatientfrom";
import { SearchPart } from "../SearchPatient/Searchbar";
import { debounce } from "lodash";
import { getPatientByStr } from "../../axiosAPI/Network";

const Title = styled.div`
  font-family: "Ubuntu";
  font-style: normal;
  font-weight: 600;
  font-size: 20.0127px;
  line-height: 17px;
  display: flex;
  align-items: center;
  gap: 1rem;
  color: #50799e;
`;

const TitleBar = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  .is-patient {
    display: flex;
    align-items: center;
    justify-content: start;
    gap: 10px;
    font-family: "Ubuntu";
    font-style: normal;
    line-height: 15px;
    .one {
      font-size: 19.2777px;
      color: #20a8d3;
      font-weight: 600;
    }
    .two {
      font-weight: 500;
      font-size: 17.2777px;
      color: rgba(133, 133, 133, 1);
    }
  }
`;

const AddPatient = () => {
  const [ischild, setIsChild] = useState<boolean | undefined>(undefined);
  const [iswife, setIswife] = useState<boolean | undefined>(undefined);
  const [isSearchAndAdd, setSearchAndAdd] = useState<boolean | undefined>(
    undefined
  );
  const [userSearched, setUserSearched] = useState<any>([]);
  const [userSelected, setUserSelected] = useState<any>();

  // const [searchTerm, setSearchTerm] = useState("");§

  const handleSearch = useCallback(
    debounce(async (value) => {
      if (value) {
        const res = await getPatientByStr(value);
        // console.log(res.data);

        setUserSearched(res.data);
      }
    }, 500),
    []
  );

  const handleChange = (event: any) => {
    // setSearchTerm(event.target.value);
    handleSearch(event.target.value);
  };
  return (
    <GlobalStyle>
      <GlobalContent>
        <Title>
          <img src="/addpatient/add.svg" alt="" />
          Nouveau dossier patient
        </Title>
        <TitleBar>
          {/* <div className="is-patient">
            <div className="one">{isSearchAndAdd ? "Adhérent" : "Patient"}</div>
            <div className="two">
              {!isSearchAndAdd &&
                (ischild ? "(Enfant)" : iswife ? "(Conjoint)" : "(Adhérent)")}
            </div>
          </div> */}
          {/* {!isSearchAndAdd && (
            <AyantDroitSelect
              ischild={ischild}
              setIsChild={setIsChild}
              iswife={iswife}
              setIswife={setIswife}
            />
          )} */}
        </TitleBar>
        {isSearchAndAdd && (
          <div
            className=""
            style={{
              display: "flex",
              flexWrap: "wrap",
              alignItems: "center",
              gap: "1rem",
            }}
          >
            <SearchPart>
              <input
                placeholder="Recherche par (Nom, Prénom CINE..)"
                type="text"
                onChange={handleChange}
              />
              <img
                src="/searchPage/searchgreen.svg"
                alt=""
                style={{ paddingRight: 10 }}
              />
            </SearchPart>
            {userSearched?.results?.map((item: any, key: any) => {
              return (
                <UserFoundStyle
                  key={key}
                  onClick={() => {
                    setUserSelected(item);
                  }}
                >
                  {item.user.firstName + " " + item.user.lastName}
                </UserFoundStyle>
              );
            })}
          </div>
        )}
        <AddPatientfrom
          userSelected={userSelected}
          isModif={!userSelected ? true : false}
          setIsModif={() => {}}
          ischild={ischild}
          iswife={iswife}
          setIsChild={setIsChild}
          setIswife={setIswife}
          isSearchAndAdd={isSearchAndAdd}
          setSearchAndAdd={setSearchAndAdd}
        />
      </GlobalContent>
    </GlobalStyle>
  );
};

export default AddPatient;

const UserFoundStyle = styled.div`
  background: rgba(31, 131, 131, 0.28);
  border-radius: 5px;
  padding-inline: 1rem;
  padding-block: 10px;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 15px;
  color: #1aa5c0;
  height: 40px;
  cursor: pointer;
`;
