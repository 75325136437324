import moment from "moment";
import React, { useState, useEffect } from "react";

function LiveTime() {
  const [currentTime, setCurrentTime] = useState(new Date());

  useEffect(() => {
    // console.log("updating timer 1")
    // Update the time every minute
    const intervalId = setInterval(() => {
      setCurrentTime(new Date());
    }, 1000);

    // Clear the interval when the component unmounts
    return () => clearInterval(intervalId);
  }, []);

  return <div>{moment(currentTime).format("HH:mm")}</div>;
}

export default LiveTime;
