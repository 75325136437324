// slices/counterSlice.ts
import { SLICE } from "../../enums/store";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface SidebarState {
  availableServices: [];
  availableSensors: string[];
}

const initialState: SidebarState = {
  availableServices: [],
  availableSensors: [],
};

const mediotAgentsSlice = createSlice({
  name: SLICE.COMPAGNIONAGENT,
  initialState,
  reducers: {
    setAvailableServices(state, action: PayloadAction<[]>) {
      console.log(state.availableServices, action.payload);
      state.availableServices = action.payload;
    },
    setAvailableSensors(state, action: PayloadAction<string[]>) {
      console.log(state.availableServices, action.payload);
      state.availableSensors = action.payload;
    },
  },
});

export const { setAvailableServices, setAvailableSensors } =
  mediotAgentsSlice.actions;
export default mediotAgentsSlice.reducer;
// selectors.js
export const selectAvailableSensors = (state: any) =>
  state.mediotAgents.availableSensors;
