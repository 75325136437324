import { ErrorMessage, Formik } from "formik";
import React, { useEffect, useMemo, useState } from "react";
import styled from "styled-components";

import {
  addPatient,
  getLocation,
  getCountries,
  getCities,
  updatePatient,
  getRegions,
  getProvinces,
  getPatient,
} from "../../axiosAPI/Network";
import { useMutation } from "react-query";

import toast, { Toaster } from "react-hot-toast";
import { useNavigate } from "react-router-dom";

import moment from "moment";

import MySelect from "../addPatient/MySelect";
import MyInput from "./MInput";
import * as Yup from "yup";
import { generateUniqueId } from "../../../src/services/utils.service";
// import { useQuery } from 'react-query'
// import axios from "axios";
// import { addPatient } from "../../axiosAPI/Network";

const StyledForm = styled.form`
  width: 100%;
  display: flex;
  gap: 1rem;
  flex-wrap: wrap;
  overflow-y: auto;
  overflow-x: auto;
  max-height: 80vh;
  .myform {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 1rem;
    flex-wrap: wrap;
    .line {
      width: 100%;
      display: flex;
      gap: 1rem;
      flex-wrap: wrap;
      .duble-size {
        width: 100%;
      }
      .half-size {
        width: 50%;
      }
    }
  }
`;

const WrraperBotton = styled.div`
  margin-top: 1rem;
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: space-between;
  color: #ffff;
  .but {
    background: #858585;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
    border-radius: 3px;
    padding-inline: 4rem;
    padding-block: 0.5rem;
    margin-bottom: 1rem;
  }
`;

interface MyPropsDataType {
  ischild: boolean | undefined;
  iswife: boolean | undefined;
  isSearchAndAdd: boolean | undefined;
  setSearchAndAdd: any;
  setIsChild: any;
  isModif: boolean;
  setIswife: any;
  setIsModif: any;
  userSelected?: any;
  patientData?: any;
  setModal?: any;
  setSuccess?: any;
}

const UpdatePatient = ({
  ischild,
  setIsModif,
  iswife,
  setSearchAndAdd,
  isSearchAndAdd,
  isModif,
  setIsChild,
  setIswife,
  userSelected,
  patientData,
  setModal,
  setSuccess,
}: MyPropsDataType) => {
  const [dataToAdd, setDataToAdd] = useState<any>({});
  const [countryArr, setCountryArr] = useState<any>([]);
  const [provinceArr, setProvinceArr] = useState<any>([]);
  const [selectedProvinceId, setSelectedProvinceId] = useState<any>(null);

  const [regionArr, setRegionArr] = useState<any>([]);
  const [filteredCitiesArr, setFilteredCitiesArr] = useState<any>([
    {
      id: patientData?.cityId,
      name: patientData?.city?.name
    }
  ]);

  const [cityArr, setCityArr] = useState<any>([]);
  const [citiesArr, setCitiesArr] = useState<any>([]);
  const [provincesArr, setProvincesArr] = useState<any>([]);
  const [stateObject, setStateObject] = useState<any>();
  const [age, setAge] = useState<any>(0);
  const requiredMsg: string = "Ce champs est obligatoire";
  const [selectCityValue, setSelectCityValue] = useState(
    patientData?.city?.id
  );
  const nav = useNavigate();
  useEffect(() => {
    console.log("userSelected", flattenObject(userSelected));

    (async () => {
      const [countriesData, citiesData, regionsData, provincesData] =
        await Promise.all([
          getCountries(),
          getCities(),
          getRegions(),
          getProvinces(),
        ]);
      console.log("cities", citiesData.data);

      setCitiesArr(citiesData.data.results);
      setProvincesArr(provincesData.data.results);
      setProvinceArr(
        provincesData.data.results.map((province: any) => province?.name)
      );
      setRegionArr(regionsData.data.results.map((region: any) => region.name));

      setCityArr(citiesData.data.results.map((city: any) => city.name));
      setCountryArr(
        countriesData.data.results.map((country: any) => country.name)
      );

      if (userSelected) setStateObject(flattenObject(userSelected));
    })();
  }, [userSelected]);

  useEffect(() => {
    const citySelect = document.getElementsByName("cityId")[0];

    setSelectedProvinceId(patientData.citry?.province?.id);
    console.log("changed province", selectedProvinceId);

    if (selectedProvinceId) {
      const provinceCities = citiesArr.filter(
        (city: any) => city.provinceId == selectedProvinceId
      );
      console.log("--->", provinceCities);
      provinceCities.unshift({
        id: "",
        name: ""
      })

      setFilteredCitiesArr(provinceCities);





    }
  }, [selectedProvinceId]);



  const { mutate, isLoading } = useMutation(
    (data: any) => updatePatient(data, isSearchAndAdd, patientData),
    {
      onSuccess: (res: any) => {
        if (res != false) {
          toast.success("Les modifications ont été bien enregistrées.", {
            id: generateUniqueId(),
          });
          setTimeout(() => {
            window.location.href = `/doctor/patient/${patientData?.id}`;
          }, 3000);

          console.log(res);
          setModal(false);
        } else {
          setModal(false);
          setTimeout(() => {
            setModal(true);
          }, 3000);
        }
      },
      onError: (err) => {
        console.log("errr", err);
        toast.error(
          "Une erreur s'est produite lors de la soumission du formulaire"
        );
      },
    }
  );

  /**
   *
   * @param obj
   * @returns
   */
  function flattenObject(obj: any) {
    let result: any = {};

    function recurse(current: any, path = "") {
      for (const key in current) {
        const value = current[key];
        const newPath = path ? `${key}` : key;

        if (typeof value === "object" && value !== null) {
          recurse(value, newPath);
        } else {
          result[newPath] = value;
        }
      }
    }

    recurse(obj);
    return result;
  }

  const handleCancelCick = () => {
    nav("/doctor/searchPatient");
  };
  if (isLoading) return <div>Loading ...</div>;

  console.log("patient data ", patientData);
  const selectedItem = provincesArr.find(
    (item: any) => item.id === patientData?.city?.province?.id
  );
  console.log("selected item ", selectedProvinceId);
  const result = provincesArr.filter(
    (item: any) => item.id === patientData?.city?.provinceId
  );

  const validationSchema = Yup.object().shape({
    cine: Yup.string(),
      // .required(requiredMsg)
      //.matches(/^[a-zA-Z]{1,2}\d{1,10}$/, "Format CINE incorrect"),
    lastName: Yup.string()
      .required(requiredMsg)
      .matches(
        /^(?:[a-zA-ZÀ-ÿ'-]+(?:\s[a-zA-ZÀ-ÿ'-]+)*){2,}$/,
        "Format Nom incorrect"
      ),
    firstName: Yup.string()
      .required(requiredMsg)
      .matches(
        /^(?:[a-zA-ZÀ-ÿ'-]+(?:\s[a-zA-ZÀ-ÿ'-]+)*){2,}$/,
        "Format Prénom incorrect"
      ),
    gender: Yup.string().required("champ obligatoire"),
    birthDate: Yup.date()
      .required(requiredMsg)
      .max(new Date().toISOString().split("T")[0], "Date invalide"),
    phoneNumber: Yup.string()
      // .required(requiredMsg)
      .matches(
        /^(?:(?:\+|00)212|0)([5-7]\d{8})$/,
        "Format Numero de telephone incorrect"
      ),
    cityId: Yup.string().required(requiredMsg),

    // Add validation rules for other fields
  });
  console.log("🚀 ~ file: UpdatePatient.tsx:219 ~ result:", result);
  return (
    <Formik
      initialValues={
        patientData.cine ? {
        cine: patientData.cine,
        firstName: patientData?.user?.firstName,
        lastName: patientData?.user?.lastName,
        gender: patientData?.user?.gender,
        birthDate: patientData?.user?.birthDate,
        phoneNumber: patientData?.user?.phoneNumber,
        cityId: selectCityValue,
        provinceId: patientData?.city?.provinceId,
      }: {
        firstName: patientData?.user?.firstName,
        lastName: patientData?.user?.lastName,
        gender: patientData?.user?.gender,
        birthDate: patientData?.user?.birthDate,
        phoneNumber: patientData?.user?.phoneNumber,
        cityId: selectCityValue,
        provinceId: patientData?.city?.provinceId,
      }}
      validationSchema={validationSchema}
      onSubmit={async (values) => {
        console.log("submitting vaaa", values);
        if (!ischild && !iswife && !isSearchAndAdd) {
          mutate({ data: values });
        }
        if (isModif) {
          setIsModif(false);
        }
        if (isSearchAndAdd) {
          mutate({ data: { dataToAdd, values } });
          // setDataToAdd({dataToAdd, values});
        }
        if (ischild || iswife) {
          setDataToAdd(values);
          setSearchAndAdd(true);
          setIsChild(false);
          setIswife(false);
        }
        console.log("data to send", values);
      }}
    >
      {({ values, handleChange, handleSubmit }) => (
        <StyledForm onSubmit={handleSubmit}>
          <div className="myform">
            <div className="line">
              <div className="flex flex-col relative">
                {patientData?.cine && <MyInput
                  ismodif={isModif}
                  label={"Nº CINE"}
                  placeholder={"CINE"}
                  value={patientData?.cine}
                  isImportant={false}
                  name={"cine"}
                  handleChange={handleChange}
                  hasLeft={false}
                  hasRight={false}
                  type={"text"}
                />}

                {/* ------------------------- */}
                {patientData.cine == null && <MyInput
                  ismodif={isModif}
                  label={"Nº CINE"}
                  placeholder={"CINE"}
                  isImportant={false}
                  name={"cine"}
                  handleChange={handleChange}
                  hasLeft={false}
                  hasRight={false}
                  type={"text"}
                />}
                <ErrorMessage
                  className="absolute -bottom-[35px] error-message"
                  name="cine"
                  component="div"
                />
              </div>
              <div className="flex flex-col relative">
                <MyInput
                  value={values.lastName}
                  ismodif={isModif}
                  label={"Nom"}
                  placeholder={"Nom"}
                  isImportant={true}
                  name={"lastName"}
                  handleChange={handleChange}
                  hasLeft={false}
                  hasRight={false}
                  type={"text"}
                />
                <ErrorMessage
                  className="absolute -bottom-[35px] error-message"
                  name="lastName"
                  component="div"
                />
              </div>
              <div className="flex flex-col relative">
                <MyInput
                  value={patientData?.user?.firstName}
                  ismodif={isModif}
                  label={"Prénom"}
                  placeholder={"Prénom"}
                  isImportant={true}
                  name={"firstName"}
                  handleChange={handleChange}
                  hasLeft={false}
                  hasRight={false}
                  type={"text"}
                />
                <ErrorMessage
                  className="absolute -bottom-[35px] error-message"
                  name="firstName"
                  component="div"
                />
              </div>
              <div className="flex flex-col relative">
                <MySelect
                  slected={patientData?.user?.gender}
                  value={values.gender}
                  ismodif={isModif}
                  label={"Sexe"}
                  placeholder={"Sexe"}
                  isImportant={true}
                  options={[
                    {
                      id: "",
                      name: "",
                    },
                    { id: "male", name: "Masculin" },
                    { id: "female", name: "Féminin" },
                  ]}
                  name={"gender"}
                  handleChange={(e: any) => {
                    console.log(e.target.value);
                    handleChange(e);
                  }}
                />
                <ErrorMessage
                  className="absolute -bottom-[35px] error-message"
                  name="gender"
                  component="div"
                />
              </div>
              <div className="flex flex-col relative">
                <MyInput
                  value={
                    patientData?.user?.birthDate
                      ? new Date(patientData.user.birthDate)
                        .toISOString()
                        .substring(0, 10)
                      : ""
                  }
                  ismodif={isModif}
                  label={"Date de naissance"}
                  placeholder={"Date de naissance"}
                  isImportant={true}
                  name={"birthDate"}
                  handleChange={handleChange}
                  hasLeft={false}
                  hasRight={true}
                  type={"date"}
                  // max={new Date().toISOString().split("T")[0]}

                  isAge={true}
                />
                <ErrorMessage
                  className="absolute -bottom-[35px] error-message"
                  name="birthDate"
                  component="div"
                />
              </div>
              {!ischild && !iswife && (
                <MySelect
                  value={patientData?.medicalCover}
                  ismodif={isModif}
                  label={"Couverture"}
                  placeholder={"Couverture"}
                  isImportant={false}
                  options={[
                    {
                      id: "",
                      name: "",
                    },
                    {
                      id: "CNSS PIPC",
                      name: "CNSS PIPC",
                    },
                    {
                      id: "EXONÉRÉ",
                      name: "EXONÉRÉ",
                    },
                    {
                      id: "CNSS",
                      name: "CNSS",
                    },
                    {
                      id: "CNOPS",
                      name: "CNOPS",
                    },
                    {
                      id: "FAR",
                      name: "FAR",
                    },
                  ]}
                  name={"medicalCover"}
                  handleChange={handleChange}
                  slected={undefined}
                />
              )}
              {!ischild && !iswife && (
                <MyInput
                  value={patientData?.affiliationNumber}
                  ismodif={isModif}
                  label={"Nº adhérent"}
                  placeholder={"Nº adhérent"}
                  isImportant={false}
                  name={"affiliationNumber"}
                  handleChange={handleChange}
                  hasLeft={false}
                  hasRight={false}
                  type={"text"}
                />
              )}

              <MySelect
                slected={undefined}
                options={["Maroc"]}
                ismodif={isModif}
                label={"Pays"}
                placeholder={"Pays"}
                isImportant={false}
                name={"country"}
                handleChange={handleChange}
              />
              {/* <MySelect
                ismodif={isModif}
                label={"Region"}
                placeholder={"Region"}
                isImportant={true}
                name={"Region"}
                handleChange={handleChange}
                options={regionArr}
              /> */}
              {!ischild && (
                <div className="relative">
                  <MySelect
                    slected={patientData?.city?.province?.name}
                    value={selectedItem && selectedItem.name}
                    ismodif={isModif}
                    label={"Province"}
                    placeholder={patientData?.city?.province?.name}
                    canBeEmpty={true}
                    isImportant={false}
                    name={"province"}
                    handleChange={(e: any) => {
                      setSelectedProvinceId(e.target.value);
                      handleChange(e);
                      handleChange({
                        target: { name: "cityId", value: "" },
                      });
                    }}
                    options={provincesArr}
                  />
                  <ErrorMessage
                    className="absolute -bottom-[35px] error-message"
                    name="provinceId"
                    component="div"
                  />
                </div>
              )}
              <div className={`${ischild ? "half-size pl-7" : ""}`}>
                <div className="relative">
                  <MySelect
                    slected={selectCityValue}
                    ismodif={isModif}
                    label={"Ville"}
                    value={selectCityValue}
                    placeholder={"Ville"}
                    isImportant={true}

                    name={"cityId"}
                    canBeEmpty={false}
                    handleChange={handleChange}
                    options={filteredCitiesArr}
                  />
                  <ErrorMessage
                    className="absolute -bottom-[35px] error-message"
                    name="cityId"
                    component="div"
                  />
                </div>
              </div>

              <div className={`${ischild ? "duble-size" : ""}`}>
                <MyInput
                  ismodif={isModif}
                  value={patientData?.user?.address}
                  label={"Adresse"}
                  placeholder={"Adresse"}
                  isImportant={false}
                  name={"address"}
                  handleChange={handleChange}
                  hasLeft={false}
                  hasRight={false}
                  type={"text"}
                />
              </div>
              {!ischild && (
                <MyInput
                  ismodif={isModif}
                  value={patientData?.user?.address2}
                  label={"Complement d’adresse"}
                  placeholder={"Complement d’adresse"}
                  isImportant={false}
                  name={"address2"}
                  handleChange={handleChange}
                  hasLeft={false}
                  hasRight={false}
                  type={"text"}
                />
              )}
              {/* {!ischild && (
                <MyInput
                  ismodif={isModif}
                  label={"Code postal"}
                  value={patientData?.user?.postalCode}
                  placeholder={"Code postal"}
                  isImportant={true}
                  name={"postalCode"}
                  handleChange={handleChange}
                  hasLeft={false}
                  hasRight={false}
                  type={"number"}
                />
              )} */}
              {!ischild && (
                <div className="flex flex-col relative">
                  <MyInput
                    ismodif={isModif}
                    label={"Téléphone mobile"}
                    placeholder={"Téléphone mobile"}
                    value={patientData?.user?.phoneNumber}
                    isImportant={false}
                    name={"phoneNumber"}
                    handleChange={handleChange}
                    hasLeft={true}
                    hasRight={false}
                    type={"text"}
                  />
                  <ErrorMessage
                    className="absolute -bottom-[35px] error-message"
                    name="phoneNumber"
                    component="div"
                  />
                </div>
              )}
              {/* {!ischild && (
                <MyInput
                  ismodif={isModif}
                  label={"Téléphone fixe"}
                  value={patientData?.user?.phoneNumber2}
                  placeholder={"Téléphone fixe"}
                  isImportant={false}
                  name={"phoneNumber2"}
                  handleChange={handleChange}
                  hasLeft={true}
                  hasRight={false}
                  type={"text"}
                />
              )}
              {!ischild && (
                <MyInput
                  ismodif={isModif}
                  label={"Adresse électronique"}
                  placeholder={"Adresse électronique"}
                  value={patientData?.user?.email}
                  isImportant={false}
                  name={"email"}
                  handleChange={handleChange}
                  hasLeft={false}
                  hasRight={false}
                  type={"text"}
                />
              )} */}
            </div>
          </div>
          {isModif && (
            <WrraperBotton>
              <button
                type="button"
                className="but"
                onClick={() => setModal(false)}
              >
                Annuler
              </button>
              <button
                className="but"
                type="submit"
                // disabled
                style={{ background: "#2699DA" }}
              >
                Modifier
              </button>
            </WrraperBotton>
          )}
        </StyledForm>
      )}
    </Formik>
  );
};

export default UpdatePatient;
