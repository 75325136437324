import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import APP_CONSTANTS from "../../constants";
import { setChiefComplaints } from "./chiefComplaintsSlice";
const token = localStorage.getItem(`${APP_CONSTANTS.LOCALSTORAGE_PREFIX}token`);

const chiefComplaintsApi = createApi({
  reducerPath: "chiefComplaintsApi",
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_SERVER_URL,
    prepareHeaders: (headers) => {
      headers.set("Authorization", `Bearer ${token}`);
      return headers;
    },
  }),

  endpoints: (builder) => ({
    getChiefComplaints: builder.query<[], string>({
      query: (str) => `chiefComplaint?skip=5000&query={${str}}`,
      transformResponse: (response: any) => response.results,
      providesTags: [],
    }),
    getComplexChiefComplaints: builder.query<[], { parentId: number | null, search: string }>({
      query: ({ parentId, search }) => {

        let where: { parentId: number | null; type?: string } = { parentId: parentId };

        let query = `chiefComplaint?`;
        if (search) query += `search=${search}&`;
        if (parentId === null) where = { ...where, type: 'chapter' };
        query += `where=${JSON.stringify(where)}`;
        return query;
      },
      transformResponse: (response: any) => response.results,
      providesTags: [],
    }),
  }),
});

export const { useGetChiefComplaintsQuery, useGetComplexChiefComplaintsQuery } = chiefComplaintsApi;

export default chiefComplaintsApi;
