  // hasOtherSubsSlice.ts

  import { createSlice, PayloadAction } from "@reduxjs/toolkit";
  import { SLICE } from "../../enums/store";
  const initialState: any = {
    hasOtherSubs:false,
  };

  const hasOtherSubsSlice = createSlice({
    name: SLICE.HAS_OTHER_SUBS,
    initialState,
    reducers: {
      
      sethasOtherSubs: (state, action: PayloadAction<boolean>) => {
            state.hasOtherSubs= action.payload;
        
      },
    
    },
  });

  export const { sethasOtherSubs } = hasOtherSubsSlice.actions;
  export default hasOtherSubsSlice.reducer;
