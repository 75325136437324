/*EXPECT CONTENT PASSED TO FORM BUILDER AS PROP */
export enum CONTENT_TYPE {
  FORM = "form",
  CUSTOM_COMPONENT = "custom",
}
export enum TEXT_INPUT_ICON_TYPE {
  PLUS = "plus",
}
export enum FORM_ELEMENT_TYPE {
  TEXTAREA = "textarea",
  TEXTINPUT = "textinput",
  SELECT = "select",
  LISTBOX = "listbox",
  CUSTOM = "custom",
}

export enum ADDITIONAL_COMPONENT_TYPE {
  BUTTON = "button",
  TAG = "tag",
}
export enum TAG_DATA_STATE {
  NONE = 'none',
  MOTIFS = 'motifs',
  CHIEF_COMPLAINTS = 'chiefComplaints',
  OTHER_FAMILIAUX = 'otherFamiliaux',
  SELECTED_FAMILIAUX = 'selectedFamiliaux',
  ALLERGY = 'allergy',
  ANALYSES_RADIO = 'analysesRadio',
  SURGICAL_MOTIFS='surgicalMotifs',
  MEDICAL_MOTIFS='medicalMotifs',
  PREVIOUS_HOSPITALIZATION='previousHospitalization',
  DIAGNOSTIC_ASSOCIE="diagnosticAssocie"
}

export enum BUTTON_COLORS {
  BLACK = "black",
  BLUE = "blue",
  PRIMARY = "primary",
  
}
export enum SELECT_DATA_STATE {
  CHIEF_COMPLAINTS = "chiefComplaints",
  YEAR = "year",
  MEDICATION = "medications",
  ANALYSE="analyse",
  IMAGING="imaging",
  DIAGNOSTIC_ASSOCIE="diagnosticAssocie"

}
export enum DISPLAY_CONDITION_STATE {
  ALLERGY = "allergy",
  OTHER_FAMILIAUX = "otherFamiliaux",
  APPT_PATIENT="apptPatient"
}
