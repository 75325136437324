import * as React from "react";
import { useEffect } from "react";

export interface INotFoundProps {}

export default function NotFound(props: INotFoundProps) {
  window.location.pathname = "/login";

  return (
    <div className="w-full h-full flex flex-col items-center justify-center mt-[12rem]">
      <div className="text-center">
        <h1 className="text-4xl font-bold">404</h1>
        <p className="text-lg">
          La page que vous recherchez n'a pas pu être trouvée.
        </p>
      </div>

      <div className="mt-8">
        <a href="/" className="btn btn-primary">
          Retour à l'accueil
        </a>
      </div>
    </div>
  );
}
