import React from 'react';
import { CgMenuGridR } from 'react-icons/cg';
import { LuCalendarDays } from 'react-icons/lu';
import { BsClockHistory } from 'react-icons/bs';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { RootState } from 'src/store';
import { AiOutlinePieChart } from 'react-icons/ai';

const Left = () => {
    const leftIsOpen = useSelector((state: RootState) => state.sidebar.leftIsOpen)
    return (<>
        <nav className={!leftIsOpen ? "hidden" : ""}>
            <aside
                id="default-sidebar"
                className="fixed top-20 left-0 z-40  h-screen transition-transform -translate-x-full sm:translate-x-5 "
                aria-label="Sidebar"
            >
                <div className="h-full px-3 py-4 overflow-y-auto  dark:bg-gray-800 bg-white">
                    <ul className="space-y-2 font-medium">
                        <li>
                            <Link
                                to="/specialiste/doctor/calendar"
                                className="flex items-center  p-2 text-gray-400 rounded-lg hover:text-Bleu focus:text-Bleu
                                dark:text-white hover:bg-gray-100 dark:hover:bg-gray-400 group"
                            >
                                <AiOutlinePieChart />

                                <span className="flex-1 ml-3 whitespace-nowrap">
                                    Planning
                                </span>
                            </Link>
                        </li>

                        {/* <li>
                            <Link
                                to="/specialiste/doctor/historique"
                                className="flex items-center  p-2 text-gray-400 rounded-lg hover:text-Bleu focus:text-Bleu
                dark:text-white hover:bg-gray-100 dark:hover:bg-gray-400 group"
                            >
                                <BsClockHistory />
                                <span className="flex-1 ml-3 whitespace-nowrap">
                                    Historique
                                </span>
                            </Link>
                        </li> */}
                        <li>
                            <Link
                                to="/specialiste/doctor/availability"
                                className="flex items-center  p-2 text-gray-400 rounded-lg hover:text-Bleu focus:text-Bleu
                dark:text-white hover:bg-gray-100 dark:hover:bg-gray-400 group"
                            >
                                <LuCalendarDays />
                                <span className="flex-1 ml-3 whitespace-nowrap">
                                    Disponibilités
                                </span>
                            </Link>
                        </li>
                        {/* <li>
                            <Link
                                to="/specialiste/doctor/rdv-table"
                                className="flex items-center  p-2 text-gray-400 rounded-lg hover:text-Bleu focus:text-Bleu
                dark:text-white hover:bg-gray-100 dark:hover:bg-gray-400 group"
                            >
                                <LuCalendarDays />
                                <span className="flex-1 ml-3 whitespace-nowrap">
                                    rdv table
                                </span>
                            </Link>
                        </li> */}
                    </ul>


                  
                </div>


            </aside>

        </nav>
    </>);
};

export default Left;
