import styled from "styled-components";

export const GlobalStyle = styled.div`
  /* background: white; */
  margin-top: 1rem;
  border-top-left-radius: 7px;
  border-top-right-radius: 7px;
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  overflow: hidden !important;
`;

export const MicSpan = styled.div`
  width: 38px;

  height: 38px;
  border-radius: 100%;

  padding: 10px;
  display: flex;
  justify-content: center;
  align-items: center;

  cursor: pointer;

  position: absolute;
  right: 12px;
  top: 12px;
  user-select: none;
  svg {
    width: 90%;
    height: 90%;
  }

  .activeSvg path{
    fill:#ffffff;
    }

    .inactiveSvg path{
      fill:#222222;
      }
`;

export const CallDivParent = styled.div`
  width: 100%;
  height: 80vh;
  /* position: relative; */
  background: rgba(16, 41, 38, 0.5);
  border: 1px solid #628596;
  backdrop-filter: blur(21.5px);
  overflow: hidden;
  flex: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  height: calc(-100px + 100vh);
  border-radius: 31px 31px 0px 0px;
`;

export const CallDiv = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
  background: rgba(16, 41, 38, 0.5);
  border: 1px solid #628596;
  backdrop-filter: blur(21.5px);
  border-radius: 31px;
  overflow: hidden;
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  .name {
    font-family: "Ubuntu";
    font-style: normal;
    font-weight: 500;
    font-size: 25.5552px;
    line-height: 12px;
    color: #ffffff;
  }
  .spec {
    font-family: "Ubuntu";
    font-style: normal;
    font-weight: 300;
    font-size: 15.207px;
    line-height: 12px;
    color: #d7d7d7;
  }
`;
export const CallDivModal = styled.div`
  width: 100rem;
  height: 66rem;
  position: relative;
  background: #fff;
  border: 1px solid #628596;
  backdrop-filter: blur(21.5px);
  border-radius: 31px;
  overflow: hidden;
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  .name {
    font-family: "Ubuntu";
    font-style: normal;
    font-weight: 500;
    font-size: 25.5552px;
    line-height: 12px;
    color: #ffffff;
  }
  .spec {
    font-family: "Ubuntu";
    font-style: normal;
    font-weight: 300;
    font-size: 15.207px;
    line-height: 12px;
    color: #d7d7d7;
  }
`;
export const CallTools = styled.div`
  width: 100% !important;
  background: #083640;
  border: 1px solid #628596;
  backdrop-filter: blur(21.5px);
  border-radius: 31px;
  display: flex;
  flex-wrap: wrap;
  flex-flow: row;
  align-items: center;
  justify-content: space-around;
  padding-block: 1.5rem;
  margin-left: 10px;
`;

export const BottonsWrrapper = styled.div<{ isSpecialist: boolean }>`
  display: flex;
  position: absolute;
  /* top: 50%; */
  right: 50%;
  bottom: 0%;
  width: inherit;
  margin-bottom: 60px;
  gap: 4;
  transform: ${(props) =>
    props.isSpecialist ? "translate(50%, 61%)" : "translate(50%, 38%)"};
`;
export const MyCameraWrapper = styled.div`
  position: absolute;
  /* bottom: 10rem; */
  left: 1rem;
  display: flex;
  gap: 1rem;
  align-items: center;
  background: rgba(37, 62, 74, 0.5);
  border: 3px solid #ffffff;
  backdrop-filter: blur(21.5px);
  width: 400px;
  height: 202px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  border-radius: 24px;
  font-family: "Ubuntu";
  font-style: normal;
  font-weight: 400;
  font-size: 16.4116px;
  line-height: 24px;
  color: #ffffff;
  overflow: hidden;
`;

export const ChatStyle = styled.div`
  position: relative;
  background: #102926;
  box-shadow: 0px 4px 18px rgba(0, 0, 0, 0.53);
  border-radius: 17px;
  height: 100%;
  width: 350px;
`;

export const Topchar = styled.div`
  width: 100%;
  height: 50px;
  position: absolute;
  top: 0;
  border-top-right-radius: 17px;
  border-top-left-radius: 17px;
  background: rgba(41, 80, 75, 1);
  display: flex;
  align-items: center;
  padding-left: 1rem;
`;

export const TextStyle = styled.div`
  font-family: "Ubuntu";
  font-style: normal;
  font-weight: 500;
  font-size: 17px;
  color: #ffffff;
`;

export const Wrapper = styled.div`
  display: flex;
  gap: 1.5rem;
  width: 100%;
  /* flex: 1; */
  position: relative;
`;
export const WrapperInput = styled.div`
  width: 100%;
  padding-inline: 1rem;
  position: absolute;
  bottom: 1rem;
`;

export const InputChat = styled.input`
  outline: none;
  height: 50px;
  width: 100%;
  background: rgba(255, 255, 255, 0.15);
  border: 1.1816px solid #d7d7d7;
  border-radius: 8.27119px;
`;
export const DrawerContainer = styled.div`
  /* Add the desired styles for the Drawer container */
  position: relative;
  /* Other styles such as background, padding, etc. */
`;
/**
 * toggle fullscrean
 * 
  function handleFullscreen(fullscreen) {
    toggleFullscreen(fullscreen, mainRef.current);
  }

  function handleShareScreenFullscreen(fullscreen) {
    toggleFullscreen(fullscreen, devicesRef.current);
  }

export function toggleFullscreen(fullscreen, element) {
  if (fullscreen) {
    if (element.requestFullscreen) {
      element.requestFullscreen();
    } else if (element.msRequestFullscreen) {
      element.msRequestFullscreen();
    } else if (element.mozRequestFullScreen) {
      element.mozRequestFullScreen();
    } else if (element.webkitRequestFullscreen) {
      element.webkitRequestFullscreen();
    }
  } else {
    if (document.exitFullscreen) {
      document.exitFullscreen();
    } else if (document.mozCancelFullScreen) {
      document.mozCancelFullScreen();
    } else if (document.webkitExitFullscreen) {
      document.webkitExitFullscreen();
    } else if (document.msExitFullscreen) {
      document.msExitFullscreen();
    }
  }
}
 */
