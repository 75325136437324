// slices/counterSlice.ts
import { SLICE } from "../../enums/store";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../../store";

interface MainCameraState {
  deviceId: string | null;
  category: string | null;
  kind: string | null;
}

const initialState: MainCameraState = {
  deviceId: null,
  category: null,
  kind: null,
};

const mainCameraIdSlice = createSlice({
  name: SLICE.MAINCAMERA_DEVICE_ID,
  initialState,
  reducers: {
    setMainCameraDeviceId(state, action: PayloadAction<{ deviceId: string, category: string, kind: string }>) {
      state.category = action.payload.category;
      state.kind = action.payload.kind;
      state.deviceId = action.payload.deviceId;
    },

    setMainMicroDeviceId(state, action: PayloadAction<string>) {
      state.deviceId = action.payload;
    },
  },
});

export const { setMainCameraDeviceId, setMainMicroDeviceId } = mainCameraIdSlice.actions;
export default mainCameraIdSlice.reducer;

export const getMainCameraId = (state: RootState): { deviceId: string, category: string, kind: string } =>
  ({
    deviceId: state.mainCameraId.deviceId,
    category: state.mainCameraId.category,
    kind: state.mainCameraId.kind,
  });
