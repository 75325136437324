import {
    Dialog,
    DialogHeader,
    DialogBody,
    DialogFooter,
    Button,
} from "@material-tailwind/react";
function index({ handleOpen, open, specialityId, HandleSelectChange, specialitys, submmitBtn, myFunction }) {
    return (
        <div>
            <Dialog open={open} handler={handleOpen}>
                <DialogHeader>Merci de choisir une spécialité</DialogHeader>
                <DialogBody>
                    <div className="w-72">
                        <select value={specialityId} onChange={HandleSelectChange} className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500">
                            <option value="">Généraliste</option>
                            {specialitys?.map((item: any) =>
                                <option value={item.id} >{item.name}</option>
                            )}
                        </select>
                    </div>
                </DialogBody>
                <DialogFooter>
                    <Button
                        variant="text"
                        onClick={handleOpen}
                        className="mr-1 text-white bg-red-500"
                    >
                        <span>Annuler</span>
                    </Button>
                    <Button className="bg-[#1AA5C0]" disabled={submmitBtn} onClick={myFunction}>
                        <span>Confirmer</span>
                    </Button>
                </DialogFooter>
            </Dialog>
        </div>
    )
}

export default index