//should add interface very soon
export function removeUnnecessaryFieldsMedications(medications: any[]) {
  return medications
    .filter(
      (e) =>
        e.medicationId &&
        e.medicationId !== "" &&
        typeof e.medicationId === "number"
    )
    .map((e) => {
      return {
        medicationId: e.medicationId,
        morning: e.morning,
        noon: e.noon,
        evening: e.evening,
        note: e.note,
        period: e.period,
        periodFrequency: e.periodFrequency,
        route: e.route,
        endTime: e.endTime,
        startTime: e.startTime,
      };
    });
}

export function removeUnnecessaryFieldsAnalyses(medications: any[]) {
  return medications
    .filter(
      (e) =>
        e.analyseId && e.analyseId !== "" && typeof e.analyseId === "number"
    )
    .map((e) => {
      return {
        analyseId: e.analyseId,
        morning: e.morning,
        noon: e.noon,
        evening: e.evening,
        note: e.note,
        period: e.period,
        periodFrequency: e.periodFrequency,
        route: e.route,
        endTime: e.endTime,
        startTime: e.startTime,
      };
    });
}
export function removeUnnecessaryFieldsImagings(medications: any[]) {
  return medications
    .filter(
      (e) =>
        e.imagingId && e.imagingId !== "" && typeof e.imagingId === "number"
    )
    .map((e) => {
      return {
        imagingId: e.imagingId,
        morning: e.morning,
        noon: e.noon,
        evening: e.evening,
        note: e.note,
        period: e.period,
        periodFrequency: e.periodFrequency,
        route: e.route,
        endTime: e.endTime,
        startTime: e.startTime,
      };
    });
}

export function removeUnnecessaryFieldsChiefComplaints(chiefComplaint: any[]) {
  return chiefComplaint.map((e) => {
    return {
      chiefComplaintId: e.chiefComplaintId,
      type: e.type,
    };
  });
}
