// otherFamiliauxSlice.ts

import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { SLICE } from "../../enums/store";
import { IotherFamiliauxState } from "../../interfaces/otherFamiliaux";
import { useGetOtherFamiliauxQuery } from "./otherFamiliauxApi";
const initialState: IotherFamiliauxState = {
  otherFamiliaux: [],
  hasOtherFamiliaux:true,
  isLoading: false,
  isUpdated: false,
  isRefetchRequired:true,
  error: null,
};

const otherFamiliauxSlice = createSlice({
  name: SLICE.OTHER_FAMILIAUX,
  initialState,
  reducers: {
    setOtherFamiliaux: (state, action: PayloadAction<IotherFamiliauxState>) => {
      switch (typeof action.payload.otherFamiliaux) {
        case "string":
          state.otherFamiliaux = [...state.otherFamiliaux, action.payload.otherFamiliaux];
          state.isRefetchRequired=action.payload.isRefetchRequired
          state.isUpdated = true;
          break;
        case "object":
          state.otherFamiliaux = action.payload.otherFamiliaux;
          state.isRefetchRequired=action.payload.isRefetchRequired
          state.isUpdated = true;
          break;
        default:
          break;
      }
    },
    appendOtherFamiliaux:(state, action: PayloadAction<string>) => {
      state.otherFamiliaux = [...state.otherFamiliaux, action.payload];
      state.isUpdated = true;
      state.isRefetchRequired=false;
    },
    spliceOtherFamiliaux: (state, action: PayloadAction<number>) => {
      let splicedOtherFamiliaux = state.otherFamiliaux;
      splicedOtherFamiliaux.splice(action.payload, 1);
      state.otherFamiliaux = splicedOtherFamiliaux;
      state.isUpdated = true;
      
    },
    clearOtherFamiliaux: (state) => {
      state.otherFamiliaux = [];
      state.isUpdated = true;
    },
  },
});

export const { setOtherFamiliaux, clearOtherFamiliaux,appendOtherFamiliaux, spliceOtherFamiliaux } = otherFamiliauxSlice.actions;
export default otherFamiliauxSlice.reducer;
