// connectionSlice.ts

import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ConnectionState } from "../../interfaces/app";
import { SLICE } from "../../enums/store";

export interface AvailableMainCamerasState {
  // [category: string]: string;
  // array of objects containing category, kind, deviceId
  mainDevices: { category: string; kind: string; deviceId: string }[];
}

const initialState: AvailableMainCamerasState = {
  mainDevices: [],
};

const defaultMainCameras = createSlice({
  name: SLICE.MAIN_CAMERAS,
  initialState,
  reducers: {
    updateAvailableMainCameras: (state, action: PayloadAction<{ category: string; deviceId: string, kind: string }>) => {
      const { category, deviceId, kind } = action.payload;
      return {
        ...state,
        mainDevices: [
          ...state.mainDevices,
          { category, kind, deviceId }
        ]
      };
    },

  },
});

export const { updateAvailableMainCameras } = defaultMainCameras.actions;
export default defaultMainCameras.reducer;
