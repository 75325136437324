import React, { Suspense, useContext, useEffect } from "react";
import { GlobalStyle } from "../SearchPatient/SearchPatient";
import { useState } from "react";
import MyCalendar from "./MyCalendar";
import Calendar from "react-calendar";
import styled from "styled-components";
import { AppointementProvider } from "./AppointementContext";
import { Avatar, Button, Spinner } from "@material-tailwind/react";
import {


  getSpecialities,
} from "../../axiosAPI/Network";
import { useLocation, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "src/store";




const Appoinetement = () => {
  const dispatch = useDispatch();

  const location = useLocation();

  const params = useParams();

  const { user: storedUser, access } = useSelector((state: RootState) => state?.auth);


  const [itemSelected, setItemSelected] = useState(-1);

  const [specialty, setSpecialty] = useState([]);

  const [patientId, setPatientId] = useState<number | null>(null);


  const [patientNameData, setPatientNameData] = useState();






  useEffect(() => {
    setPatientId(parseInt(params.id || "", 10));
  }, [location]);



  React.useMemo(() => {
    specialty?.length < 1 &&
      getSpecialities().then((res: any) => {
        setSpecialty(res.data.results);
      });
  }, [specialty]);







  return (
    <AppointementProvider>
      <GlobalStyle>
        <main className="flex w-full h-[90%] ">
          {/* {!(storedUser?.roles[0]?.name === role.SPECIALIST) ?
            <div className="left w-[20%] ">
              <div className="h-[60%] p-4">
                <h4 className="text-[#5E5E5E] text-lg font-semibold mb-2">
                  Filtrer par Spécialités
                </h4>
                <div className="flex flex-col gap-2 overflow-y-scroll p-1">
                  {specialty?.length > 0 ? <> {specialty.map((spec: any, index) => {
                    return (
                      <div
                        key={index}
                        className={`p-2 rounded-md bg-[#F5F5F5] cursor-pointer text-[#858585] font-bold ${itemSelected == spec.id ? "bg-primary text-white" : ""
                          }`}
                        onClick={() => {
                          dispatch(setSelectedSpecialty(spec));
                          setItemSelected(spec.id);
                        }}
                      >
                        {spec?.name}
                      </div>
                    );
                  })}</> : <Spinner className="mx-auto" />}
                </div>
              </div>
            </div> :
            <></>} */}
          <MyCalendar patientId={patientId!} />

          {/* <Toaster position="top-left" reverseOrder={false} /> */}
        </main>
      </GlobalStyle>
    </AppointementProvider>
  );
};

export default Appoinetement;
