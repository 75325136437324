// useDynamicDialog.ts
import { useDispatch, useSelector } from 'react-redux';
import { openDialog, closeDialog } from '../features/dialog/dialogSlice';
import { RootState } from 'src/store';
import { size as sizeEnum } from '@material-tailwind/react/types/components/avatar';


interface DynamicDialogOptions {
  open?: boolean;
  size?: sizeEnum;
  message: string;
  title: string;
  component?: React.ReactNode;
  confirm?: boolean;
  handleConfirm?: any;
  handleCancel?: any;
}

const useDynamicDialog = () => {
  const dispatch = useDispatch();
  const { confirm,open, message,component, title, handleConfirm, handleCancel,size } = useSelector(
    (state: RootState) => state.dialog
  );

  const openDynamicDialog = (options: DynamicDialogOptions) => {
    dispatch(openDialog(options));
  };

  const closeDynamicDialog = () => {
    dispatch(closeDialog());
  };

  return {
    open,
    confirm,
    size,
    component,
    message,
    title,
    handleConfirm,
    handleCancel,
    openDynamicDialog,
    closeDynamicDialog,
  };
};

export default useDynamicDialog;
