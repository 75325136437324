// notifsSlice.ts

import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { SLICE } from "../../enums/store";
import { setConsultationData } from "../../features/consultationData/consultationDataSlice";
import { useDispatch } from "react-redux";
import { InotifsState } from "../../interfaces/notifications";
const initialState: InotifsState = {
  notifications: [],
  refreshCalendar: false
  
};

const notifsSlice = createSlice({
  name: SLICE.MOTIFS,
  initialState,
  reducers: {

    setNotifications: (state, action: PayloadAction<any>) => {
      return{
        ...state,
        notifs:action.payload
      }
    },
    setRefreshCalendar: (state, action: PayloadAction<boolean>) => {
      state.refreshCalendar   = action.payload 
    },
   
  },
});

export const { setNotifications, setRefreshCalendar} = notifsSlice.actions;
export default notifsSlice.reducer;
