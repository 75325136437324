import { useEffect, useState } from "react";
import AgoraRTC from "agora-rtc-sdk-ng";
import { getDevicesWithLabel } from "../../helper/mediaDeviceHelper";
import VideoPlayer from "./VideoPlayer";
import devices from "../../helper/devicesInfos.json";
import styled from "styled-components";
const APP_ID =
  process.env.REACT_APP_APP_ID ;
const client = AgoraRTC.createClient({
  mode: "rtc",
  codec: "vp8",
  //TODO: turn server
});

export const AgoraDevice = ({
  token,
  channel,
  label,
  removeDevice,
  setRemoveDevice,
  deviceUid,
  deviceId,
  isVideoStream,
  toolName,
  setCurrentDeviceStream,
}: any) => {
  // const [isMuted, setIsMuted] = useState(false)
  const [users, setUsers] = useState<any>([]);

  const handleUserLeft = (user: any) => {
    setUsers((previousUsers: any) =>
      previousUsers.filter((u: any) => u.uid !== user.uid)
    );
  };
  useEffect(() => {
    console.log("*DEVICE* deviceuid", deviceUid);
  }, [deviceUid]);

  /**
   *
   * @param user
   * @param mediaType
   */
  const handleUserJoined = async (user: any, mediaType: any) => {
    await client.subscribe(user, mediaType);
    const newUser = {
      kind: "deviceCamera",
      uid: user.uid,
      videoTrack: user.videoTrack,
      // specialAttribute: generateUniqueAttribute(), // Generate a unique attribute for each user
    };

    if (mediaType === "video") {
      setUsers((previousUsers: any) => [...previousUsers, newUser]);
    }

    // if (mediaType === "audio") {
    //   user.audioTrack.play();
    // }
  };
  useEffect(() => {
    const handellingMainCamera = async () => {
      client.on("user-published", handleUserJoined);
      client.on("user-left", handleUserLeft);
      console.log("*DEVICE* isVideoStream", isVideoStream)
      client
        .join(APP_ID, channel, token, null)
        .then((uid) =>
          Promise.all([
            !isVideoStream
              ? AgoraRTC.createMicrophoneAudioTrack({
                AEC: true,
                ANS: true,
                // encoderConfig: { sampleRate: 48000, stereo: true },
                microphoneId: deviceId,
              })
              : AgoraRTC.createCameraVideoTrack({
                cameraId: deviceId,
                encoderConfig: "480p",
                optimizationMode: "detail",
                // scalabiltyMode: "1SL1TL",
              }),
            // AgoraRTC.createMicrophoneAndCameraTracks(
            //   { AGC: true },
            //   { cameraId: deviceId }
            // ),
            uid,
          ])
        )
        .then(([tracks, uid]) => {
          // console.log("tracks__", tracks);
          // const [audioTrack, videoTrack] = tracks;
          if (isVideoStream) {
            setUsers((previousUsers: any) => [
              ...previousUsers,
              {
                kind: toolName,
                uid,
                videoTracks: tracks,
              },
            ]);
            setCurrentDeviceStream({ videoTrack: tracks, client: client });
          } else {
            setUsers((previousUsers: any) => [
              ...previousUsers,
              {
                kind: toolName,
                uid,
                audioTracks: tracks,
              },
            ]);
            setCurrentDeviceStream({ audioTrack: tracks, client: client });
            console.log("*DEVICE* stethoscope update clients", users);
          }
          client.publish(tracks);
        });
    };
    console.log("[*DEVICE* token]", token);

    if (token) handellingMainCamera();

    return () => {
      client.off("user-published", handleUserJoined);
      client.off("user-left", handleUserLeft);
      client.unpublish().then(() => client.leave());
    };
  }, [token]);

  // return <>{users[0] && <VideoPlayer key={users[0].uid} user={users[0]} />}</>;
  return (
    <>
      {users.map((user: any) => {
        if (user.uid === deviceUid) {
          console.log("*DEVICE* deviceuid check", deviceUid);
        }
        return (
          // user.uid === deviceUid ?? <VideoPlayer key={user.uid} user={user} />
          <></>
        );
      })}
    </>
  );
};
