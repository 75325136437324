import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { SLICE } from "../../enums/store";

interface ISelectedAdherant {
  selectedAdherant: any
}
const initialState: ISelectedAdherant = {
  selectedAdherant: null, 
};

const selectedAdherantSlice = createSlice({
  name: SLICE.SELECTED_ADHERANT, 
  initialState,
  reducers: {
    setSelectedAdherant: (state, action: PayloadAction<any>) => {
      console.log("setting adherant...", action.payload);
      state.selectedAdherant = action.payload;
    },
  },
});

export const { setSelectedAdherant } = selectedAdherantSlice.actions;
export default selectedAdherantSlice.reducer;
