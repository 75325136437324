import * as React from "react";
import { Spinner } from "@material-tailwind/react";
import RadioInput from "../../../../../Components/FormBuilder/FormElements/Radio";
import { Tab, Transition } from "@headlessui/react";
import { useDispatch, useSelector } from "react-redux";
import {
  clearToxicsTabac,
  setToxicsTabacBoxPerYear,
  setToxicsTabacCigarttesPerDay,
  setToxicsTabacDuration,
  setToxicsTabacType,
} from "../../../../../features/consultationData/consultationDataSlice";
import Actif from "./Actif";
import Passif from "./Passif";
import Sevre from "./Sevre";
import { setisTabacConsuming } from "../../../../../features/isTabacConsuming/isTabacConsumingSlice";

export interface ITabacConsumptionProps {}

export default function TabacConsumption(props: ITabacConsumptionProps) {
  const dispatch = useDispatch();
  const [selectedTabac, setSelectedTabac] = React.useState<number>();
  const [isTabacConsuming, setIsTabacConsuming] =
    React.useState<boolean>(false);
  const [cigarettePerDay, setcigarettePerDay] = React.useState("");
  const [totalPaquets, setTotalPaquets] = React.useState<number>();
  const storedTabacData = useSelector(
    (state: any) =>
      state.consultationData?.consultationData?.medicalHistory?.toxic?.tabac
  );
  const storedIsTabacConsuming = useSelector(
    (state: any) => state.isTabacConsuming.isTabacConsuming
  );
  React.useMemo(() => {
    cigarettePerDay &&
      dispatch(
        setToxicsTabacBoxPerYear(
          Math.ceil((Number(cigarettePerDay) * 365) / 20)
        )
      );
    setTotalPaquets(Math.ceil((Number(cigarettePerDay) * 365) / 20));
  }, [cigarettePerDay]);

  React.useEffect(() => {
    dispatch(setisTabacConsuming(isTabacConsuming));
  }, [isTabacConsuming]);
  React.useEffect(() => {
    !isTabacConsuming &&
      storedIsTabacConsuming &&
      setIsTabacConsuming(storedIsTabacConsuming);
  }, [storedIsTabacConsuming]);
  React.useMemo(() => {
    if (storedTabacData) {
      switch (storedTabacData.type) {
        case "Actif":
          setSelectedTabac(0);
          break;
        case "Passif":
          setSelectedTabac(1);
          break;
        case "Sevré":
          setSelectedTabac(2);
          break;
      }
    }
  }, [storedTabacData]);
  console.log(storedTabacData?.type);
  console.log("SelectedTabac", selectedTabac);

  React.useEffect(() => {
    console.log(selectedTabac);

    switch (selectedTabac) {
      case 0:
        dispatch(setToxicsTabacType("actif"));
        break;
      case 1:
        dispatch(setToxicsTabacType("passif"));
        break;
      case 2:
        dispatch(setToxicsTabacType("sevre"));
        break;
    }
  }, [selectedTabac]);
  React.useEffect(() => {
    if (!isTabacConsuming) {
      dispatch(clearToxicsTabac());
    }
  }, [isTabacConsuming]);
  return (
    <div className="flex flex-wrap w-full">
      <div className="w-full flex gap-4 items-center mb-6">
        <RadioInput
          // onChange={handleIsConsumingTabacChange}
          id="Oui3"
          name="IsConsumingTabac"
          onChange={() => {
            setIsTabacConsuming(true);
          }}
          checked={isTabacConsuming}
          label="Oui"
        />
        <div className="h-[2rem] w-[1px] bg-blue-gray-200"></div>
        <RadioInput
          id="Non3"
          // onChange={handleIsConsumingTabacChange}
          name="IsConsumingTabac"
          label="Non"
          onChange={() => {
            setIsTabacConsuming(false);
          }}
          checked={!isTabacConsuming}
        />
      </div>

      <Transition
        className={"mb-4 w-full"}
        enter="transition-opacity ease-linear duration-300"
        enterFrom="opacity-0"
        enterTo="opacity-100"
        leave="transition-opacity ease-linear duration-300"
        leaveFrom="opacity-100"
        leaveTo="opacity-0"
        show={isTabacConsuming}
      >
        <div>
          {/* <Tabs className="rounded-md" value={
           selectedTabac? selectedTabac==0?"Actif":selectedTabac==1?"Passif":selectedTabac==2&&"Sevré":storedTabacData?.type
          } >
            <TabsHeader
              defaultChecked={true}
              
              className="bg-white"
              indicatorProps={{
                className: "bg-[#1AA5C0]",
              }}
            >
              {["Actif", "Passif", "Sevré"].map(
                (category: any, index: number) => (
                  <Tab
                  
                    key={index}
                    value={category}
                    className="py-2 bg-[#D9D9D9] m-2 rounded-md text-white"
                    onClick={() => {
                      setSelectedTabac(index);
                      // dispatch(setSelectedPrescription(index));
                    }}
                  >
                    {category}
                  </Tab>
                )
              )}
            </TabsHeader>
          </Tabs> */}
          <Tab.Group selectedIndex={selectedTabac ?? 0}>
            <Tab.List className="flex space-x-1 rounded-xl bg-blue-900/20 p-1">
              {["Actif", "Passif", "Sevré"].map((category, index) => (
                <Tab
                  key={index}
                  onClick={() => {
                    setSelectedTabac(index);
                    // dispatch(setSelectedPrescription(index));
                  }}
                  className={({ selected }) =>
                    selected
                      ? "bg-white shadow w-full rounded-lg py-2.5 text-sm font-medium leading-5 text-[#1AA5C0] ring-white ring-opacity-60 ring-offset-2 ring-offset-blue-400 focus:outline-none focus:ring-2"
                      : "tex-gray-100 hover:bg-white/[0.12] hover:text-white w-full rounded-lg py-2.5 text-sm font-medium leading-5 text-[#1AA5C0] ring-white ring-opacity-60 ring-offset-2 ring-offset-blue-400 focus:outline-none focus:ring-2"
                  }
                >
                  {category}
                </Tab>
              ))}
            </Tab.List>
          </Tab.Group>
          {selectedTabac == 0 || !selectedTabac ? (
            <Actif />
          ) : selectedTabac == 1 ? (
            <Passif />
          ) : (
            selectedTabac == 2 && <Sevre />
          )}
        </div>
      </Transition>
    </div>
  );
}
