import { useParams } from "react-router-dom";
import { TAG_DATA_STATE } from "../enums/stepper";
import { useGetMotifsQuery } from "../features/motifs/motifsApi";
import React from "react";
import { setMotifs } from "../features/motifs/motifsSlice";
import { useDispatch, useSelector } from "react-redux";
import { setChiefComplaints } from "../features/chiefComplaints/chiefComplaintsSlice";
import { setOtherFamiliaux } from "../features/otherFamiliaux/otherFamiliauxSlice";
import { useGetOtherFamiliauxQuery } from "../features/otherFamiliaux/otherFamiliauxApi";
import { useGetSelectedFamiliauxQuery } from "../features/selectedFamiliaux/selectedFamiliauxApi";
import { setSelectedFamiliaux } from "../features/selectedFamiliaux/selectedFamiliauxSlice";

export default function useDynamicTagDataQuery(key: string, tagsData: any) {
  const { consultationId } = useParams();
  const dispatch = useDispatch();

  switch (key) {
    case TAG_DATA_STATE.MOTIFS:
      const { data: motifsData, refetch: refetchMotifs } = useGetMotifsQuery(
        Number(consultationId),
        {
          refetchOnMountOrArgChange: true,
        }
      );
      tagsData.isRefetchRequired &&
        tagsData.motifs != motifsData &&
        dispatch(
          setMotifs({
            ...tagsData,
            isRefetchRequired: false,
            motifs: motifsData,
          })
        );

      break;

    // case TAG_DATA_STATE.OTHER_FAMILIAUX:
    //   const { data: otherFamiliauxData } = useGetOtherFamiliauxQuery(
    //     Number(consultationId),
    //     {
    //       refetchOnMountOrArgChange: false,
    //     }
    //   );
    //   tagsData.isRefetchRequired &&
    //     dispatch(
    //       setOtherFamiliaux({
    //         ...tagsData,
    //         isRefetchRequired: false,
    //         otherFamiliaux: otherFamiliauxData,
    //       })
    //     );

    //   break;
    // case TAG_DATA_STATE.SELECTED_FAMILIAUX:
    //   const { data: selectedFamiliauxData } = useGetSelectedFamiliauxQuery(
    //     Number(consultationId),
    //     {
    //       refetchOnMountOrArgChange: false,
    //     }
    //   );
    //   tagsData.isRefetchRequired &&
    //     dispatch(
    //       setSelectedFamiliaux({
    //         ...tagsData,
    //         isRefetchRequired: false,
    //         selectedFamiliaux: selectedFamiliauxData,
    //       })
    //     );

    //   break;

    default:
      break;
  }
}
