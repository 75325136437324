// allergySlice.ts

import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { SLICE } from "../../enums/store";
import { IAlcoholicState } from "@interfaces/alcoholic";
const initialState: IAlcoholicState = {
  isAlcoholic: false,
};

const allergySlice = createSlice({
  name: SLICE.ALCOHOL,
  initialState,
  reducers: {
    setIsAlcoholic: (state, action: PayloadAction<boolean>) => {
      state.isAlcoholic = action.payload;
    },
  },
});

export const { setIsAlcoholic } = allergySlice.actions;
export default allergySlice.reducer;
