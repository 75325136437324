import { useEffect, useState } from "react";



interface SpeedTestResult {
    downloadSpeedMbps: number;
    uploadSpeedMbps: number;
  }

export const useConnectionSpeed = (): SpeedTestResult | null => {
    const [speed, setSpeed] = useState<SpeedTestResult | null>(null);
  
    const performSpeedTest = async () => {
      try {
        const fileSizeMB = 10; // Size of the file to download/upload in megabytes
        const fileURL = 'http://speedtest.ftp.otenet.gr/files/test10Mb.db'; // URL of a large file
  
        const startTime = performance.now();
  
        // Download test
        const downloadResponse = await fetch(fileURL);
        await downloadResponse.blob();
        const downloadEndTime = performance.now();
  
        // Calculate download speed in Mbps
        const downloadSpeedMbps = (fileSizeMB * 8) / ((downloadEndTime - startTime) / 1000);
  
        // Upload test
        const uploadData = new FormData();
        uploadData.append('test', new Blob([new ArrayBuffer(fileSizeMB * 1024 * 1024)]));
  
        const uploadStartTime = performance.now();
        await fetch('https://example.com/upload-endpoint', {
          method: 'POST',
          body: uploadData,
        });
        const uploadEndTime = performance.now();
  
        // Calculate upload speed in Mbps
        const uploadSpeedMbps = (fileSizeMB * 8) / ((uploadEndTime - uploadStartTime) / 1000);
  
        setSpeed({
          downloadSpeedMbps,
          uploadSpeedMbps,
        });
      } catch (error) {
        console.error('Speed test failed:', error);
      }
    };
  
    useEffect(() => {
      // performSpeedTest();
    }, []);
  
    return speed;
  };