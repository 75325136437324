import * as React from 'react';
import RadioInput from '../../../../Components/FormBuilder/FormElements/Radio';
import { useDispatch, useSelector } from 'react-redux';
import { setIsAllergic } from '../../../../features/allergy/allergySlice';

export interface IAllergiesProps {
}

export default function Allergies (props: IAllergiesProps) {
  const dispatch= useDispatch()
  return (
    <div className="content flex gap-8 mt-6 ">
          <div className="flex gap-4 items-center">
            <RadioInput
              id="Oui6"
              name="isAllergic"
              onChange={()=>{
                dispatch(setIsAllergic(true))
              }}
              label="Oui"
              checked={useSelector((state:any)=>state.allergy.isAllergic)}
            />
            <div className="h-[2rem] w-[1px] bg-blue-gray-200"></div>
            <RadioInput
              id="Non6"
              name="isAllergic"
              onChange={()=>{
                dispatch(setIsAllergic(false))
              }}
              label="Non"
              checked={!useSelector((state:any)=>state.allergy.isAllergic)}
            />
          </div>
          
          
        </div>
  );
}
