// chiefComplaintsSlice.ts

import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { SLICE } from "../../enums/store";
import { IchiefComplaintsState } from "../../interfaces/chiefComplaints";
const initialState: IchiefComplaintsState = {
  chiefComplaints: [],
  isLoading: false,
  error: null,
  isRefetchRequired:false
};

const chiefComplaintsSlice = createSlice({
  name: SLICE.CHIEF_COMPLAINTS,
  initialState,
  reducers: {
    setChiefComplaints: (state, action: PayloadAction<IchiefComplaintsState>) => {
        state.chiefComplaints = action.payload.chiefComplaints;    
    },
    
  },
});

export const { setChiefComplaints } = chiefComplaintsSlice.actions;
export default chiefComplaintsSlice.reducer;
