import { Switch } from "@material-tailwind/react";

import { useEffect, useState } from "react";

import { ReactComponent as MasseSvg } from "../../assets/masseSvg.svg";
import { ReactComponent as GlecimieSvg } from "../../assets/glecimieSvg.svg";
import { ReactComponent as RespiratoirSvg } from "../../assets/resperatoirSvg.svg";
import { ReactComponent as HeartSvg } from "../../assets/hearSvg.svg";
import CardWithSingleValue from "./CardWithSingleValue";
import CardWithTowValues from "./CardWithTowValues";
import { set } from "date-fns";
import { useAgentSocket } from "../../services/socketIOContext";
import { ToastContainer, toast } from "react-toastify";
import { vitalSignsType } from "@interfaces/baseParams";
import { ToastOptions } from "react-hot-toast";
import { toastConstraint } from "../../helper/mediaDeviceHelper";
import { baseParamsMaxValues, baseParamsMinValues } from "../../constants";
import { useDispatch } from "react-redux";
import { updateBaseParams } from "../../features/Examen/baseParamsSlice";

const PeriphiriqueRight = () => {
  const [glycemiValue, setGlycemiValue] = useState(0);
  const [cardiaqueValue, setCardiaqueValue] = useState(0);
  const [respiratoireValue, setRespiratoireValue] = useState(0);
  const [graissValue, setGraissValue] = useState(0);
  const [smmValue, setSmmValue] = useState(0);
  const [pourcentageValue, setPourcentageValue] = useState(0);
  const [selectedDevice, setSelectedDevice] = useState<string | null>(null);
  const [cancelCheck, setCancelCheck] = useState(false);
  const [gettingData, setGettingData] = useState(false);
  const {
    agentSocket /*ECGCallBackStatus*/,
    availableSensors,
    oxymeterCallBackStatus,
  } = useAgentSocket();
  const dispatch = useDispatch();


  /**
   *
   */
  useEffect(() => {
    // Dispatch action when oxymetreValue changes
    dispatch(updateBaseParams({ messure: "glycemi", value: glycemiValue }));
  }, [glycemiValue, dispatch]);


  useEffect(() => {
    // Dispatch action when oxymetreValue changes
    dispatch(updateBaseParams({ messure: "rr", value: respiratoireValue }));
  }, [respiratoireValue, dispatch]);


  useEffect(() => {
    // Dispatch action when oxymetreValue changes
    dispatch(updateBaseParams({ messure: "rc", value: cardiaqueValue }));
  }, [cardiaqueValue, dispatch]);


  useEffect(() => {
    // Dispatch action when oxymetreValue changes
    dispatch(updateBaseParams({ messure: "graisse", value: graissValue }));
  }, [graissValue, dispatch]);


  useEffect(() => {
    // Dispatch action when oxymetreValue changes
    dispatch(updateBaseParams({ messure: "SMM", value: smmValue }));
  }, [smmValue, dispatch]);


  useEffect(() => {
    // Dispatch action when oxymetreValue changes
    dispatch(updateBaseParams({ messure: "gresse_porcentage", value: pourcentageValue }));
  }, [pourcentageValue, dispatch]);



  /**
   *
   */
  const toastConstraint: ToastOptions | any = {
    position: toast.POSITION.BOTTOM_RIGHT,
    autoClose: 4000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "light",
  };

  /**
   *
   * @param isOxyError
   */
  const getCardiacValuefromECG = (isOxyError: boolean) => {
    if (isOxyError) {
      agentSocket?.off("spo2_cmd");
      agentSocket?.emit("spo2_cmd_off");
      agentSocket?.off("spo2_cmd_off");
    }
    toast.warn(
      "🔄: capture de la fréquence cardiaque à l'aide de l'ECG",
      toastConstraint
    );
    console.log("rythmeCardiaque_ecg");
    agentSocket?.emit("ecg_cmd", (data: any) => {
      console.log("rythmeCardiaque_ecg", data);
      const { ecg_status, heart_rate } = JSON.parse(data);
      console.log("ecg status", ecg_status);
      // if (ecg_status === "ECG_OFF") {
      console.log("ECG_OFF");
      // setSelectedDevice("rr");
      setGettingData(true);
      setCardiaqueValue(heart_rate);
      // }
    });
  };

  /**
   *
   * @param messageText message text to notify
   */
  const disableRC = (messageText?: string) => {
    agentSocket?.off("spo2_cmd");
    agentSocket?.emit("spo2_cmd_off");
    agentSocket?.off("spo2_cmd_off");
  };

  useEffect(() => {
    if (agentSocket) {
      // if (selectedDevice === "glycemi") {
      // check connection
      // setGlycemiValue(0);
      // }
      if (selectedDevice === "rc") {
        if (availableSensors.includes("oxy")) {
          agentSocket?.emit("spo2_cmd");
          agentSocket?.on("spo2", (data: any) => {
            const { pulse_rate, spo2_status } = JSON.parse(data);
            console.log("pulse rate", data);
            if (spo2_status === oxymeterCallBackStatus?.No_finger_inserted) {
              toast.warn("👈🏼", toastConstraint(toast.POSITION.BOTTOM_CENTER));
              console.log("sorry no finger inserted [OFF]");

              setGettingData(false);
              setSelectedDevice(null);

              // getCardiacValuefromECG(true);
            } else {
              setGettingData(true);
              console.log(pulse_rate);
              setCardiaqueValue(pulse_rate);
            }
          });
          // } else if (availableSensors.includes("ecg")) {
          // getCardiacValuefromECG(false);
          // }
        }
        // if (selectedDevice === "rs") {
        // agentSocket?.emit("rythmeRespiratoire");
        // agentSocket?.on("rythmeRespiratoire", getrespiratoireValue);
        // }
        // if (selectedDevice === "4") {
        // agentSocket?.emit("masseGrasse");
        // agentSocket?.on("masseGrasse", getgraissValue);
        // }
      }
      return () => {
        console.log("selectedevice [clearnUp fonction]", selectedDevice);
        if (selectedDevice === "rc") {
          disableRC();
        }
      };
    }
  }, [selectedDevice]);

  /**
   * useEffect for cancelling the get of the data
   */
  useEffect(() => {
    if (cancelCheck) {
      console.log("cancel check");
      if (selectedDevice === "rc") {
        disableRC();
      }
      setSelectedDevice(null);
      setGettingData(false);
      setCancelCheck(false);
    }
  }, [cancelCheck]);

  return (
    <div className=" w-[90%] m-auto flex gap-4 flex-wrap justify-center gap-x-20  grid-cols-12 ">
      <div className="col-span-5 min-w-[22rem]">
        <CardWithSingleValue
          title="Glycémie"
          icon={<GlecimieSvg />}
          sign="g/l"
          id="glycemi"
          value={glycemiValue}
          setValue={setGlycemiValue}
          setCmpntId={setSelectedDevice}
          cmpntId={selectedDevice}
          sensorExists={availableSensors.includes("glycemi")}
          maxValue={baseParamsMaxValues.GLYCEMIE}
          minValue={baseParamsMinValues.GLYCEMIE}
          setCancelCheck={setCancelCheck}
        />
      </div>
      <div className="col-span-7 min-w-[22rem]">
        <CardWithSingleValue
          title="Rythme cardiaque"
          icon={<HeartSvg />}
          sign="Bpm"
          id="rc"
          value={cardiaqueValue}
          setValue={setCardiaqueValue}
          setCmpntId={setSelectedDevice}
          cmpntId={selectedDevice}
          gettingData={gettingData}
          sensorExists={availableSensors.includes("oxy")}
          // maxValue={220-props.patientAge}
          maxValue={baseParamsMaxValues.RYTHMCARDIAQUE }
          minValue={baseParamsMinValues.RYTHMCARDIAQUE }
          setCancelCheck={setCancelCheck}
        />
      </div>
      <div className="col-span-5 min-w-[22rem]">
        <CardWithSingleValue
          title="Fréquence respiratoire"
          icon={<RespiratoirSvg />}
          sign="Cycles/min"
          id="rr"
          value={respiratoireValue}
          setValue={setRespiratoireValue}
          setCmpntId={setSelectedDevice}
          cmpntId={selectedDevice}
          gettingData={gettingData}
          sensorExists={availableSensors.includes("ecg")}
          maxValue={baseParamsMaxValues.FREQUENCE_RESPIRATOIRE}
          minValue={baseParamsMinValues.FREQUENCE_RESPIRATOIRE}
          setCancelCheck={setCancelCheck}
        />
      </div>
      {/* <div className="col-span-7">
        <CardWithTowValues
          title="Masse grasse et musculaire"
          icon={<MasseSvg />}
          sign={[
            {
              sign: "Kg",
              name: "SMM",
              value: smmValue,
              gettingData: gettingData,
              cmpntId: selectedDevice,
              sensorExists: availableSensors.includes("balance_smm"),
              setValue: setSmmValue,
              setCmpntId: setSelectedDevice,
              setGettingData: setGettingData,
              maxValue: 190,
            },
            {
              sign: "%",
              name: "Graisse",
              value: graissValue,
              setValue: setGraissValue,
              gettingData: gettingData,
              setGettingData: setGettingData,
              sensorExists: availableSensors.includes("balance_smm"),
              maxValue: 99,
            },
            {
              name: "Pourcentage de graisse",
              sign: "%",
              value: pourcentageValue,
              setValue: setPourcentageValue,
              gettingData: gettingData,
              setGettingData: setGettingData,
              maxValue: 99,
              sensorExists: false,
            },
          ]}
          id="balance_smm"
          escapeLastValue={true}
        />
      </div> */}
      {/* <ToastContainer /> */}
    </div>
  );
};

export default PeriphiriqueRight;
