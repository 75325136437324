export enum AgentStatus {
    Idle = 'Idle', // The agent is not actively performing any tasks and is ready for new tasks.
    Connecting = 'Connecting', // The agent is in the process of establishing connections with devices.
    Connected = 'Connected', // The agent has successfully connected to all devices and is ready to perform tasks.
    Disconnected = 'Disconnected', // The agent has lost connection to one or more devices.
    Processing = 'Processing', // The agent is actively processing data or performing tasks with the connected devices.
    Error = 'Error', // An error or issue has occurred in the agent's operation.
    UpdatingFirmware = 'Updating Firmware', // The agent is in the process of updating the firmware of connected devices.
    Calibrating = 'Calibrating', // The agent is calibrating the devices.
    Maintenance = 'Maintenance', // The agent is undergoing maintenance or routine checks.
    Offline = 'Offline', // The agent is intentionally taken offline for maintenance or other reasons.
    Unknown = 'Unknown', // The status is unknown or not yet determined.
  }