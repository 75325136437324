import { get } from "lodash";
import React, { useEffect, useState } from "react";
import { getVitalSigns } from "../../axiosAPI/params";
import { useParams } from "react-router-dom";

const header = [
  {
    name: "Date",
    sign: "",
  },
  {
    name: "Heure",
    sign: "",
  },
  {
    name: "Poids",
    sign: "(kg)",
  },
  {
    name: "Taille",
    sign: "(cm)",
  },
  {
    name: "Pression systolique",
    sign: "(mmHg)",
  },
  {
    name: "Pression diastolique",
    sign: "(mmHg)",
  },
  {
    name: "Fréquence respiratoire",
    sign: "(Cycles/min)",
  },
  {
    name: "Température",
    sign: "(°C)",
  },
  {
    name: "Saturation O2",
    sign: "(%)",
  },
  {
    name: "Fréquence cardiaque",
    sign: "(Batt/min)",
  },
  {
    name: "Glycémie",
    sign: "(g/l)",
  },
];

const VitalTable = () => {
  const { id: patientId } = useParams<{ id: string }>();
  type dataType = {
    bloodSugar: string;
    createdAt: string;
    dia: string;
    fatMass: string;
    weight: string;
    height: string;
    pulse: string;
    respiratoryRythm: string;
    spo2: string;
    sys: string;
    temperature: string;
    leanMase: string;
  };

  const [data, setData] = useState<dataType[]>([]);
  useEffect(() => {
    getVitalSigns(parseInt(patientId!)).then((res) => {
      setData(res?res?.results:[]);
    });
  }, []);
  const timeOptions = {
    hour: "2-digit" as const,
    minute: "2-digit" as const,
  };
  return (
    <div className="p-3 w-full">
      <div className="header flex sticky top-0 items-center w-full  justify-around bg-white p-4 border-b-4">
        {header.map((item, index) => (
          <div
            key={index}
            className={`${
              index != 0
                ? "header-item text-[14px] text-[#1AA5C0] font-[500]"
                : "text-[#5E5E5E]			"
            } flex-1 text-center  flex flex-col justify-center`}
          >
            <span>{item.name} </span>
            <span className="text-[#858585]">{item.sign}</span>
          </div>
        ))}
      </div>
      <div className="body">
        {data.length > 0 &&
          data?.map((item, index) => (
            <div
              className={`${
                index % 2 == 0 ? "bg-[#EFEFEF]" : ""
              } flex items-center w-full  justify-around  flex-1 text-[#5E5E5E] font-[400] text-[16px] text-center p-2 rounded-lg`}
            >
              <div className="flex-1">
                {new Date(item.createdAt).toLocaleDateString()}
              </div>
              <div className="flex-1">
                {new Date(item.createdAt).toLocaleTimeString(
                  undefined,
                  timeOptions
                )}
              </div>
              <div className="flex-1">{item.weight}</div>
              <div className="flex-1">{item.height}</div>
              <div className="flex-1">{item.sys}</div>
              <div className="flex-1">{item.dia}</div>
              <div className="flex-1">{item.respiratoryRythm}</div>
              <div className="flex-1 ">{item.temperature}</div>
              <div className="flex-1 ">{item.spo2}</div>
              <div className="flex-1">{item.pulse}</div>
              <div className="flex-1 ">{item.bloodSugar}</div>
            </div>
          ))}
      </div>
    </div>
  );
};

export default VitalTable;
