// HERE WE DEFINE ALL APP CONSTANTS
import PeriDeBase from "./assets/periDeBase.svg";
import EcgSvg from "./assets/ecgSvg.svg";
import DermatoscopeSvg from "./assets/dermaSvg.svg";
import OtoscopeSvg from "./assets/otoscopeSvg.svg";
import IriScope from "./assets/iriscope.svg";
import EchographSvg from "./assets/echoSvg.svg";
import CameraSvg from "./assets/cameraSvg.svg";
import { ExeName } from "./enums/vitalSigns";
import ThermoSvg from "./assets/thermo.svg";
import PressionSvg from "./assets/pressionSvg.svg";
import OxymetreSvg from "./assets/oxymetreSvg.svg";
import PoidsSvg from "./assets/poidsSvg.svg";
import GlecimieSvg from "./assets/glecimieSvg.svg";
import HeartSvg from "./assets/hearSvg.svg";
import RespiratoirSvg from "./assets/resperatoirSvg.svg";
import Stethoscope from "./assets/Stethoscope.svg";
import NoStreamSvg from "./assets/noStreamSvg.svg";
import ArrRight from "./assets/arrowRight.svg";

const APP_CONSTANTS = {
  LOCALSTORAGE_PREFIX: "vs_",
  ROUTES: {
    HOME: "/",
    LOGIN: "/login",
    FORGOT_PASSWORD: "/forgotPassword",
    PATIENTS_LIST: "/doctor/searchPatient",
    WAITING_LIST: "/doctor/waitingList",
    ADD_PATIENT: "/doctor/addPatient",
    ADD_DOCTOR: "/doctor/addDoctor",
    STATISTICS: "/doctor/statistics",
    EXAMINATION: "/doctor/steps/:id/:consultationId",
    CALENDAR: "/doctor/calendar",
    DEVICES: "/doctor/devices",
    BASIC_DEVICES: "/doctor/basicDevices",
    ADVANCED_DEVICES: "/doctor/advancedDevices",
    DEVICES_WITHOUT_AUTHENTICATION: "/no-auth/devices",
    BASIC_DEVICES_WITHOUT_AUTHENTICATION: "/no-auth/basicDevices",
    ADVANCED_DEVICES_WITHOUT_AUTHENTICATION: "/no-auth/advancedDevices",

    CALL: "/doctor/call/:id",

    // SPECIALISTE ROUTES
    CALLSPECIALIST: "/specialiste/doctor/call/:id",
    CALL_HISTORY: "/specialiste/doctor",
    SPECIALISR_CALENDAR: "/specialiste/doctor/calendar",
    SPECIALIST_AVAILABILITY: "/specialiste/doctor/availability",
    SPECIALIST_RDV_TABLE: "/specialiste/doctor/rdv-table",
    SPECIALIST_DOCTOR_HISTORIQUE: "/specialiste/doctor/historique",
  },
};

export const baseParamsMinValues = {
  TEMP: 30.01,
  SATURATION: 50,
  SYS: 50,
  DIA: 30,
  GLYCEMIE: 0.5,
  RYTHMCARDIAQUE: 28,
  FREQUENCE_RESPIRATOIRE: 10,
  POIDS: 2,
  TAILLE: 40,
};
export const baseParamsMaxValues = {
  TEMP: 47.0,
  SATURATION: 100,
  SYS: 370,
  DIA: 360,
  GLYCEMIE: 10.0,
  RYTHMCARDIAQUE: 220,
  FREQUENCE_RESPIRATOIRE: 70,
  POIDS: 200,
  TAILLE: 220,
};

export const videoCallDevices = [
  {
    displayedName: "ECG",
    icon: EcgSvg,
    name: "ecg",
  },
  {
    displayedName: "Dermatoscope",
    icon: DermatoscopeSvg,
    name: "dermatoscope",
  },
  {
    displayedName: "Otoscope",
    icon: OtoscopeSvg,
    name: "otoScope",
  },
  {
    displayedName: "iriscope",
    icon: IriScope,
    name: "iriscope",
  },
  {
    displayedName: "Caméra mobile",
    icon: CameraSvg,
    name: "cameraMobile",
  },
  {
    displayedName: "Échographe",
    icon: EchographSvg,
    name: ExeName.ECHO,
  },

  {
    displayedName: "Tension artérielle",
    icon: PressionSvg,
    name: "nibp",
  },
  {
    displayedName: "Saturation O2",
    icon: OxymetreSvg,
    name: "oxy",
  },
  // {
  //   displayedName: "Poids et taille",
  //   icon: PoidsSvg,
  //   name: "weight_height",
  // },
  // {
  //   displayedName: "Glycémie",
  //   icon: GlecimieSvg,
  //   name: "glycemi",
  // },
  {
    displayedName: "Rythme cardiaque",
    icon: HeartSvg,
    name: "rc",
  },
  {
    displayedName: "Fréquence respiratoire",
    icon: RespiratoirSvg,
    name: "rr",
  },
  {
    displayedName: "Stethoscope",
    icon: Stethoscope,
    name: "styto",
  },
];

export const AdvancedParamsTools = [
  {
    displayedName: "ECG",
    icon: EcgSvg,
    name: "ecg",
  },
  {
    displayedName: "Dermatoscope",
    icon: DermatoscopeSvg,
    name: "dermatoscope",
  },
  {
    displayedName: "Otoscope",
    icon: OtoscopeSvg,
    name: "otoScope",
  },
  {
    displayedName: "iriscope",
    icon: IriScope,
    name: "iriscope",
  },
  {
    displayedName: "Caméra mobile",
    icon: CameraSvg,
    name: "cameraMobile",
  },
  {
    displayedName: "Échographe",
    icon: EchographSvg,
    name: ExeName.ECHO,
  },
];

// medicalDevices.ts (or any other appropriate file)
export const MEDICAL_DEVICES = {
  WEIGHT_AND_HEIGHT: "Weight and Height",
  TEMPERATURE: "Temperature",
  OXYMETER: "Oximeter",
  BLOOD_PRESSURE: "Blood Pressure Monitor",
  BODY_COMPOSITION: "Body Composition Analyzer",
  HEART_RATE_MONITOR: "Heart Rate Monitor",
  RESPIRATORY_RATE_MONITOR: "Respiratory Rate Monitor",
  DERMATOSCOPE: "Dermatoscope",
  OTOSCOPE: "Otoscope",
  ECG: "Electrocardiogram (ECG)",
  ULTRASOUND: "Ultrasound Machine",
  MOBILE_CAMERA: "Mobile Camera",
};
export const TABLE_HEAD = ["Device Name", "", ""];

export const PATIENT_RECORD_TABS = {
  PATIENT_FILE: 0,
  MEDICAL_HISTORY: 1,
  VITAL_SIGNS: 2,
  BIOLOGY: 3,
  REPORTS: 4,
};

export const AppUserId = Number(
  localStorage.getItem(`${APP_CONSTANTS.LOCALSTORAGE_PREFIX}userId`)
);
export default APP_CONSTANTS;
