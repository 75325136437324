import { SELECT_DATA_STATE } from "../../../../../enums/stepper";
import * as React from "react";
import SelectInput from "../../../../FormBuilder/FormElements/SearchableSelect";
import { PlusIcon, TrashIcon } from "@heroicons/react/24/solid";
import { Option, Select } from "@material-tailwind/react";
import TextInput from "../../../../../Components/FormBuilder/FormElements/TextInput";
import { useDispatch, useSelector } from "react-redux";
import {
  addMedication,
  decrementEveningValue,
  decrementMorningValue,
  decrementNoonValue,
  decrementPeriodValue,
  incrementEveningValue,
  incrementMorningValue,
  incrementNoonValue,
  incrementPeriodValue,
  setMedicationValue,
  setNoteValue,
  spliceMedication,
} from "../../../../../features/medicationConsults/medicationConsultsSlice";
import SelectListBox from "../../../../../Components/FormBuilder/FormElements/SelectListBox";
import { setMedicationConsults } from "../../../../../features/consultationData/consultationDataSlice";
import { getMedicationsByIndex } from "../../../../../axiosAPI/Network";
import { setMedicationNames } from "../../../../../features/persistedPrescriptionData/persistedPrescriptionDataSlice";

export interface IOrdonnaceMedicaleProps {}
function NumberInput(props: any) {
  const [inputValue, setInputValue] = React.useState(
    useSelector((state: any) => {
      switch (props.value) {
        case "Matin":
          return (
            state.medicationConsultation.medicationConsults[props.index]
              .morning ?? 0
          );
        case "Midi":
          return (
            state.medicationConsultation.medicationConsults[props.index]
              .evening ?? 0
          );
        case "Soir":
          return (
            state.medicationConsultation.medicationConsults[props.index].noon ??
            0
          );
        case "Period":
          return (
            state.medicationConsultation.medicationConsults[props.index]
              .period ?? 0
          );
      }
    })
  );
  const dispatch = useDispatch();
  console.log(inputValue);
  return (
    <div className="custom-number-input h-10 w-20 mx-1">
      {props.value && props.value != "Period" && (
        <label
          htmlFor="custom-input-number"
          className="h-full text-sm text-[#5E5E5E] "
        >
          {props.value}
        </label>
      )}
      <div className="flex flex-row h-10 w-full rounded-lg relative bg-transparent mt-1">
        <button
          data-action="decrement"
          className=" bg-gray-300 text-gray-600 hover:text-gray-700 hover:bg-gray-400 h-full w-20 rounded-l cursor-pointer outline-none"
          onClick={() => {
            switch (props.value) {
              case "Matin":
                dispatch(
                  decrementMorningValue({
                    index: props.index,
                  })
                );
                break;
              case "Midi":
                dispatch(
                  decrementEveningValue({
                    index: props.index,
                  })
                );
                break;

              case "Soir":
                dispatch(
                  decrementNoonValue({
                    index: props.index,
                  })
                );
                break;
              case "Period":
                dispatch(
                  decrementPeriodValue({
                    index: props.index,
                  })
                );
                break;
            }
          }}
        >
          <span className="m-auto text-2xl font-thin">−</span>
        </button>
        <input
          type="number"
          className="outline-none focus:outline-none min-w-[50px] text-center w-full bg-gray-300 font-semibold text-md hover:text-black focus:text-black  md:text-base cursor-default flex items-center text-gray-700"
          name="custom-input-number"
          defaultValue={inputValue}

          value={useSelector((state: any) => {
            switch (props.value) {
              case "Matin":
                return (
                  state.medicationConsultation.medicationConsults[props.index]
                    .morning ?? 0
                );
              case "Midi":
                return (
                  state.medicationConsultation.medicationConsults[props.index]
                    .evening ?? 0
                );
              case "Soir":
                return (
                  state.medicationConsultation.medicationConsults[props.index]
                    .noon ?? 0
                );
              case "Period":
                return (
                  state.medicationConsultation.medicationConsults[props.index]
                    .period ?? 0
                );
            }
          })}
        />
        <button
          data-action="increment"
          className="bg-gray-300 text-gray-600 hover:text-gray-700 hover:bg-gray-400 h-full w-20 rounded-r cursor-pointer"
          onClick={() => {
            switch (props.value) {
              case "Matin":
                dispatch(
                  incrementMorningValue({
                    index: props.index,
                  })
                );
                break;
              case "Midi":
                dispatch(
                  incrementEveningValue({
                    index: props.index,
                  })
                );
                break;

              case "Soir":
                dispatch(
                  incrementNoonValue({
                    index: props.index,
                  })
                );
                break;
              case "Period":
                dispatch(
                  incrementPeriodValue({
                    index: props.index,
                  })
                );
                break;
            }
          }}
        >
          <span className="m-auto text-2xl font-thin">+</span>
        </button>
      </div>
    </div>
  );
}

export default function OrdonnaceMedicale(props: IOrdonnaceMedicaleProps) {
  const [medicationsList, setMedicationsList] = React.useState(
    useSelector((state: any) => state.medicationConsultation.medicationConsults)
  );
  const periods = [
    "Jours",
    // , "Mois", "Années"
  ];
  const medicationPeriods = ["Matin", "Midi", "Soir"];
  const dispatch = useDispatch();
  const storedMedicalConsults = useSelector(
    (state: any) => state.medicationConsultation.medicationConsults
  );
  React.useEffect(()=> {
    console.log("stored changed",storedMedicalConsults);
  }, [storedMedicalConsults])
  const storedMedicationNames=useSelector(
    (state: any) =>
      state.persistedPrescriptionData.persistedData.medicationNames
  )
  React.useEffect(() => {

    // need to be fixed a
    dispatch(setMedicationConsults(storedMedicalConsults));
    storedMedicalConsults.map((medication: any, index: number) => {

      getMedicationsByIndex(medication.medicationId).then((res) => {
  
        if (res?.data?.name) {
          dispatch(
            setMedicationNames({
              index: index,
              value: res.data.name,
            })
          );
        }
      });
    });
  }, [storedMedicalConsults.map((item: any) => item.medicationId).join(',')]);
  
  const [medicationName, setMedicationName] = React.useState<any>(null);
  return (
    <div className="flex flex-col h-full  py-4 border-1">
      <label htmlFor="" className="h-full text-lg text-[#5E5E5E]">
        Traitement
      </label>
      {/* <div className="p-6 border-2 overflow-auto rounded-md border-[#06657826]  scrollbar-thin scrollbar-thumb-gray-300 scrollbar-track-gray-100"> */}
      <div className="">
        {useSelector(
          (state: any) => state.medicationConsultation.medicationConsults
        )?.map((medication: any, index: number) => {
          return (
            <div className="flex mb-4" key={index}>
              <div className="">
                <SelectInput
                key={index}
                  name={`medicationConsult`}
                  index={index}
                  
                  defaultValue={{
                    index: index,
                    name: storedMedicationNames?storedMedicationNames[index]:"",
                  }}
                  label={`Médicament #${index + 1}`}
                  // placeholder={`Médicament #${index + 1}`}
                  dataState={SELECT_DATA_STATE.MEDICATION}
                  actions={[setMedicationValue]}
                  style="mt-4 h-full text-sm text-[#5E5E5E] min-w-[35rem]"
                />
              </div>
              <div className="flex mt-5 ml-3">
                {medicationPeriods.map((value) => {
                  return <NumberInput value={value} index={index} />;
                })}
              </div>
              <div className="flex flex-col mt-6 ml-3">
                <p className="text-sm text-[#5E5E5E] ml-1">
                  Durée de traitement
                </p>
                <div className="flex relative">
                  <div>
                    <NumberInput value={"Period"} index={index} />
                  </div>

                  <div className="min-w-[10rem] -mt-2 ml-3 overflow-x-visible  overflow-y-visible">
                    <SelectListBox
                      data={periods}
                      name=""
                      defaultValue={"Jours"}
                      placeholder=""
                      label=""
                      style=""
                    />
                  </div>
                  <div className="ml-3 mt-1">
                    <TextInput
                      name="note"
                      label="Commentaire"
                      defaultValue={medication?.note ?? ""}
                      style=""
                      onChange={(val: string) => {
                        console.log(val);
                        dispatch(
                          setNoteValue({
                            index: index,
                            value: val,
                          })
                        );
                      }}
                    />
                  </div>
                  <div>
                    <TrashIcon
                      onClick={() => {
                        dispatch(spliceMedication(index));
                      }}
                      width={25}
                      className="mt-2 ml-4 cursor-pointer"
                    />
                  </div>
                </div>
              </div>
            </div>
          );
        })}
      </div>

      <button
        type="button"
        className="bg-[#1AA5C0] mt-6 flex p-2 w-[15rem] justify-center text-white text-center rounded-md"
        onClick={() => {
          dispatch(addMedication());
        }}
      >
        Nouveau traitement <PlusIcon width={23} className="ml-4" />
      </button>
    </div>
  );
}
