import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import APP_CONSTANTS from '../../constants';
import WebSocketService from '../../services/Notification/socketService';
import store from '../../store';
import { setNotifications } from './notificationsSlice';

const token = localStorage.getItem(`${APP_CONSTANTS.LOCALSTORAGE_PREFIX}token`);
interface INotification {}
const notifsApi = createApi({
  reducerPath: 'notifsApi',
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_SERVER_URL,
    prepareHeaders: (headers) => {
      headers.set('Authorization', `Bearer ${token}`);
      return headers;
    },
  }),

  endpoints: (builder) => ({
    getNotifications: builder.query({
      query: (id:number) => `notification?where={"to":{"some":{"id":${id}}}}&orderBy={"createdAt":"desc"}`,
    }),
 
  }),
});

export const { useGetNotificationsQuery} = notifsApi;

export default notifsApi;
