import { SELECT_DATA_STATE } from "../../../../../enums/stepper";
import * as React from "react";
import SearchableSelect from "../../../../FormBuilder/FormElements/SearchableSelect";
import { PlusIcon, TrashIcon } from "@heroicons/react/24/solid";
import { useDispatch, useSelector } from "react-redux";
import {
  setBioAnalyses,
  setBioAnalysesByIndex,
  setNoteByIndex,
  spliceBioAnalyses,
} from "../../../../../features/bioAnalyses/bioAnalysesSlice";
import TextInput from "../../../../../Components/FormBuilder/FormElements/TextInput";
import { setBioAnalyse } from "../../../../../features/consultationData/consultationDataSlice";
import { getAnalysisByIndex } from "../../../../../axiosAPI/Network";
import { setAnalysesNames } from "../../../../../features/persistedPrescriptionData/persistedPrescriptionDataSlice";

export interface IBioAnalysesProps {}

export default function BioAnalyses(props: IBioAnalysesProps) {
  const dispatch = useDispatch();
  console.log(useSelector((state: any) => state.bioAnalyses.bioAnalyses));
  const storedBioAnalyses = useSelector(
    (state: any) => state.bioAnalyses.bioAnalyses
  );
  const storedAnalysesNames=useSelector(
    (state: any) =>
      state.persistedPrescriptionData.persistedData.analysesNames
  )
  React.useEffect(() => {
    dispatch(setBioAnalyse(storedBioAnalyses));
    storedBioAnalyses.map((bioAnalyses: any, index: number) => {
      
      bioAnalyses?.analyseId&&getAnalysisByIndex(bioAnalyses.analyseId).then((res) => {
        if (res?.data?.name) {
          dispatch(
            setAnalysesNames({
              index: index,
              value: res.data.name,
            })
          );
        }
      });
    });
  }, [storedBioAnalyses]);
  return (
    <div className="flex flex-col h-full  py-4 border-1">
      <label htmlFor="" className="h-full text-lg text-[#5E5E5E]">
        Analyses biologiques
      </label>
      {useSelector((state: any) => state.bioAnalyses.bioAnalyses).map(
        (bioAnalyse: string, index: number) => {
          return (
            <div className="flex">
              <SearchableSelect
                name={`bioAnalyses`}
                index={index}
                label={``}
                defaultValue={{
                  index: index,
                  name: storedAnalysesNames?storedAnalysesNames[index]:"",
                }}
                // placeholder={`Chercher`}
                dataState={SELECT_DATA_STATE.ANALYSE}
                actions={[setBioAnalysesByIndex]}
                style="mt-4 h-full text-sm text-[#5E5E5E] min-w-[25rem]"
              />
              <div className="mt-4 ml-3 w-full">
                <TextInput
                  name=""
                  defaultValue={bioAnalyse?.note ?? ""}
                  label="Commentaire"
                  style=""
                  onChange={(value: string) => {
                    dispatch(
                      setNoteByIndex({
                        index: index,
                        value: value,
                      })
                    );
                  }}
                />
              </div>
              <div className="flex justify-center rounded-full bg-[#FF375E] w-14 h-10 m-4">
                <TrashIcon
                  color="white"
                  onClick={() => {
                    dispatch(spliceBioAnalyses(index));
                  }}
                  width={20}
                  className=" cursor-pointer "
                />
              </div>
            </div>
          );
        }
      )}
      <button
        type="button"
        className="bg-[#1AA5C0] mt-6 flex p-2 w-[15rem] justify-center text-white text-center rounded-md"
        onClick={() => {
          dispatch(
            setBioAnalyses({
              analyseId: null,
              note: "",
            })
          );
        }}
      >
        Nouvelle analyse <PlusIcon width={23} className="ml-4" />
      </button>
    </div>
  );
}
