import { useEffect, useState } from "react";
import {
  getConnectedDevices,
  toastConstraint,
} from "../helper/mediaDeviceHelper";
import DevicesInfo from "../helper/devicesInfos.json";
import { toast } from "react-toastify";

export const useCreateMediaMediaStream = (
  streamDeviceId: string | null,
  deviceRef: React.RefObject<HTMLVideoElement>
) => {
  const [MultimediaStream, setMultimediaStream] = useState<MediaStream | null>(
    null
  );
  useEffect(() => {
    const createStream = async () => {
      try {
          console.log("stream changed", streamDeviceId);
          const stream = await navigator?.mediaDevices?.getUserMedia({
            audio: false,
            video: {
              deviceId: streamDeviceId!,
            },
          });
          setMultimediaStream(stream);
          if (deviceRef.current) {
            console.log("[deviceStream] deviceRef.current", deviceRef.current);
            deviceRef.current.srcObject = stream;
          }
        
      } catch (error: any) {
        toast.error(error, toastConstraint);
      }
      console.log("[deviceStream] deviceId", streamDeviceId);

    };
    if (streamDeviceId) createStream();
  }, [streamDeviceId]);

  return { MultimediaStream, setMultimediaStream };
};
