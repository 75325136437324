import instance from "../config/axios.config";
import APP_CONSTANTS from "../constants";

export const refreshToken = async () => {
    try {
      const refreshToken = localStorage.getItem(
        `${APP_CONSTANTS.LOCALSTORAGE_PREFIX}refreshToken`
      );
  
      const res = await instance.post(
        `${process.env.REACT_APP_SERVER_URL}/auth/refreshToken`,
        {
          refreshToken: refreshToken,
        }
      );
  
      const newAccessToken = res.data.accessToken;
      localStorage.setItem(
        `${APP_CONSTANTS.LOCALSTORAGE_PREFIX}token`,
        newAccessToken
      );
  
      const newRefreshToken = res.data.refreshToken;
      localStorage.setItem(
        `${APP_CONSTANTS.LOCALSTORAGE_PREFIX}refreshToken`,
        newRefreshToken
      );
      return newAccessToken;
    } catch (error) {
      console.error("Error refreshing token:", error);
      throw error;
    }
  };

  