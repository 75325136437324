import { createContext, useContext, useEffect, useState } from "react";
import { getConsultation, getPatient } from "../axiosAPI/Network";
import { useLocation, useParams } from "react-router-dom";
import { getVitalSigns } from "../axiosAPI/params";
interface consultationContextPropsType {
  children: React.ReactNode;
  consultationId: number
}
export const consultationContext = createContext<any>({});
export const useConsultationContext = () => useContext(consultationContext);
export const ConsultationContextProvider: React.FC<consultationContextPropsType> = ({
    consultationId,
  children,

}) => {

    const [patient, setPatient] = useState<any | null>();
    const [consultation, setConsultation] = useState<any | null>();
    const [vitalSignsc, setVitalSignsc] = useState<any | null>();
    const [showIframe, setShowIframe] = useState<boolean | null>(null);
    // received diagnostics from AI
    const [receivedDiagnostics, setReceivedDiagnostics] = useState(null);
    const location = useLocation();
    const params = useParams();
  

    useEffect(()=> {
        (async () => {
      const patientId = parseInt(params.id || "");
            const [patientRes,consultationRes, vitalSignsRes] = await Promise.all([
                getPatient(patientId),
                consultationId ? await getConsultation(consultationId) : {data:{}},
                getVitalSigns(patientId)
            ]) ;
            setPatient(patientRes.data);
            setConsultation(consultationRes.data);
            setVitalSignsc(vitalSignsRes.results)

          })();
    },[location])

    useEffect(()=> {
        if(receivedDiagnostics) setShowIframe(!showIframe);
    }, [receivedDiagnostics])

  return <consultationContext.Provider value={{
    patient,
    setPatient,
    vitalSignsc,
    setVitalSignsc,
    consultation,
    setConsultation,
    showIframe,
    setShowIframe,
    setReceivedDiagnostics,
    receivedDiagnostics
  }}>{children}</consultationContext.Provider>;
};
