import { WaitingListTable } from "../table/WaitingListTable"




export const WaitingList = () => {

     

    return (<><WaitingListTable
    
             headers={['ordre', 'Prénom', 'Nom','Spécialité','status', `Heure d'arrivé`,'Actions']} />

    </>)
}