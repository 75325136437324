import { CONSULTATION_TYPES } from "../../enums/consultation";
import {
    Dialog,
    DialogHeader,
    DialogBody,
    DialogFooter,
    Button,
    Spinner,
} from "@material-tailwind/react";



function StartConsultationDialog({
    handleOpen,
    open,
    HandleSelectChange,
    submmitBtn,
    submitFunction,
    isLoading }) {
    return (
        <div>
            <Dialog open={open} handler={handleOpen}>
                <DialogHeader className="h-10 text-primary text-md">Démarrer une consultation</DialogHeader>


                <DialogBody className="py-6 text-[#0F2552] font-semibold text-sm" divider>
                    <div className="w-full">
                        <h2>Vous êtes sur le point de démarrer une nouvelle consultation pour ce patient, veuillez choisir le type:</h2>
                        <select defaultValue={CONSULTATION_TYPES.FIRST_CONSULTATION}
                            onChange={HandleSelectChange}
                            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500">
                            <option value={CONSULTATION_TYPES.FIRST_CONSULTATION}>Première consultation</option>
                            <option value={CONSULTATION_TYPES.FOLLOW_UP_CONSULTATION}>Consultation de suivi</option>
                            <option value={CONSULTATION_TYPES.CONTROL_CONSULTATION}>Consultation de controle</option>
                            <option value={CONSULTATION_TYPES.CARE_CONSULTATION}>Soins</option>
                            <option value={CONSULTATION_TYPES.OPERATION}>Caravane</option>

                        </select>
                    </div>
                </DialogBody>

                <DialogFooter>
                    <Button
                        variant="text"
                        color="white"
                        onClick={handleOpen}
                        className="mr-1 bg-[#858585]"                    >
                        <span>Annuler</span>
                    </Button>
                    {!isLoading && <Button
                        className="bg-secondary" color="teal"
                        disabled={submmitBtn} onClick={submitFunction}>
                        <span>Confirmer</span>
                    </Button>}

                    {isLoading && <Button
                        disabled={true}
                        className="bg-secondary" color="teal">
                        <div className='flex items-center gap-2'><Spinner /><span>Confirmer</span></div>
                    </Button>}
                </DialogFooter>

            </Dialog>
        </div>
    )
}

export default StartConsultationDialog