// analysesRadioSlice.ts

import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { SLICE } from "../../enums/store";
import { IAnalysesRadioState } from "@interfaces/prescriptionStep";
const initialState: datesTypes = {
  availableDates: [],
  availableHours: [],
  selectedDate: new Date(),
  specialtyId: 0,
  selectedSlot: {},
  selectedSlotId: null,
  doctorId: 0,
  stationId: 1,
  patientId: 0,
  reason: "",
  isModalOpen: false,
  patientName: null
};

type datesTypes = {
  availableDates: string[];
  availableHours: any;
  selectedDate: Date;
  selectedSlotId?: number | null,
  selectedSlot: any;
  doctorId: number;
  specialtyId: number;
  stationId: number;
  patientId: number;
  reason: any;
  isModalOpen: boolean;
  patientName: string | null
};
const appointmentSlice = createSlice({
  name: SLICE.APPOINTMENT_MODAL,
  initialState,
  reducers: {
    setDays: (state: datesTypes, action: PayloadAction<string[]>) => {
      state.availableDates = action.payload;
    },
    setHours: (state, action: PayloadAction<any>) => {
      state.availableHours = action.payload;
    },
    setPatientName: (state, action: PayloadAction<any>) => {
      state.patientName = action.payload;
    },
    setSelectedDate: (state, action: PayloadAction<Date>) => {

      state.selectedDate = action.payload;
    },
    setSelectedSlot: (state, action: PayloadAction<any>) => {
      state.selectedSlot = action.payload;
    },
    setSelectedSlotId: (state, action: PayloadAction<any>) => {
      state.selectedSlotId = action.payload;
    },

    setSpecialtyId: (state, action: PayloadAction<number>) => {

      state.specialtyId = action.payload;
    },
    setDoctorId: (state, action: PayloadAction<number>) => {

      state.doctorId = action.payload;
    },
    setPatientId: (state, action: PayloadAction<number>) => {
      console.log(JSON.parse(JSON.stringify(state)));

      state.patientId = action.payload;
    },
    setStationId: (state, action: PayloadAction<number>) => {

      state.stationId = action.payload;
    },
    setReason: (state, action: PayloadAction<string>) => {
      state.reason = action.payload;
    },
    setIsModalOpen: (state, action: PayloadAction<boolean>) => {
      state.isModalOpen = action.payload;
    },

    clear: (state) => {
      state.availableDates = [],
        state.availableHours = [],
        state.selectedDate = new Date(),
        state.specialtyId = 0,
        state.selectedSlot = {},
        state.doctorId = 0,
        state.stationId = 1,
        state.reason = "",
        state.isModalOpen = false,
        state.patientName = null

      console.log("cleared app", state)
    }
  },
});

export const {
  setDays,
  setIsModalOpen,
  setHours,
  setDoctorId,
  setPatientName,
  setSelectedDate,
  setSelectedSlotId,
  setSelectedSlot,
  setReason,
  setPatientId,
  clear,
  setStationId,
  setSpecialtyId,

} = appointmentSlice.actions;
export const selectedDate = (state: any) => state.appointment.selectedDate;
export default appointmentSlice.reducer;
