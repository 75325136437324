import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import APP_CONSTANTS from "../../constants";
import { api } from "../../features/api/api";
const token = localStorage.getItem(`${APP_CONSTANTS.LOCALSTORAGE_PREFIX}token`);

const specialityApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getSpeciality: builder.query({
      query: () => `/speciality`,
      //providesTags: [],
      transformResponse: (response: any) => response?.results,
    }),
    getDaysOfSpeciality: builder.query({
      query: (id) => `/speciality/days/${id}`,
    }),
  }),
});

export const { useGetSpecialityQuery, useGetDaysOfSpecialityQuery } =
  specialityApi;

export default specialityApi;
