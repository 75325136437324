// api.ts
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import APP_CONSTANTS from '../../constants';
import { waitingListStatus } from '../../enums/waitingList';

const API_BASE_URL = '/api'; // Adjust this based on your API


const token = localStorage.getItem(`${APP_CONSTANTS.LOCALSTORAGE_PREFIX}token`);

export const waitingListApi = createApi({
  reducerPath: 'waitingListApi',
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_SERVER_URL,
    prepareHeaders: (headers) => {
      headers.set('Authorization', `Bearer ${token}`);
      return headers;
    },
     }),
  endpoints: (builder) => ({

    fetchWaitingListByCenter: builder.query<any[], any>({
        query: (id: number) => `/admission?where={"centerId":${id},"status":"waiting"}`,
      }),

      addPatientToWaitingList: builder.mutation<any, any>({
      query: (newPatient) => ({
        url: '/admission',
        method: 'POST',
        body: newPatient,
      }),
    }),
    
   
  }),
});

export const {
  useFetchWaitingListByCenterQuery,
  useAddPatientToWaitingListMutation
} = waitingListApi;

export default waitingListApi;
