import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import APP_CONSTANTS from "../../constants";
import { setAllergy } from "./allergySlice";
const token = localStorage.getItem(`${APP_CONSTANTS.LOCALSTORAGE_PREFIX}token`);

const allergyApi = createApi({
  reducerPath: "allergyApi",
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_SERVER_URL,
    prepareHeaders: (headers) => {
      // Add your custom headers here
      headers.set("Authorization", `Bearer ${token}`);
      return headers;
    },
  }),

  endpoints: (builder) => ({
    getAllergy: builder.query<[], number>({
      query: (id) => `consultation/${id}?include=patient.medicalHistory`,
      transformResponse: (response: any) =>
        response.patient.medicalHistory.allergy,
      providesTags: [],
    }),
  }),
});

export const { useGetAllergyQuery } = allergyApi;

export default allergyApi;
