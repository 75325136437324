import { SLICE } from "../../../../enums/store";
import { Textarea } from "@material-tailwind/react";
import React from "react";
import { useDispatch, useSelector } from "react-redux";

export interface ITextAreaProps {}

export default function TextArea(props: any) {
  const [internalDefaultValue, setInternalDefaultValue] = React.useState()
  const compteRendu = useSelector((state: any) => state.compteRendu.text);
  console.log(compteRendu);
  
  React.useEffect(() => {
    switch (props.useCase) {
      case SLICE.COMPTE_RENDU:
        compteRendu && setInternalDefaultValue(compteRendu)
        break;
    }
  }, [props.useCase,compteRendu]);
  const dispatch = useDispatch();
  console.log(internalDefaultValue);
  
  return (
    <Textarea
      {...props}
      defaultValue={props.defaultValue ?? internalDefaultValue}
      onChange={(e: any) => {
        props.formik && props.formik(e);
        props.onChange && props.onChange(e.target.value);
        props?.actions &&
          props.actions?.map((action: any) => {
            dispatch(action(e.target.value));
          });
      }}
    />
  );
}
