// connectionSlice.ts

import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ConnectionState } from "../../interfaces/app";
import { SLICE } from "../../enums/store";

interface AvailableDevicesState {
  [category: string]: string;
}

const initialState: AvailableDevicesState = {};

const mediaDevicesSlice = createSlice({
  name: SLICE.MEDIA_DEVICES,
  initialState,
  reducers: {
    updateAvailableVideoDevices: (
      state,
      action: PayloadAction<{ category: string; deviceId: string }>
    ) => {
      const { category, deviceId } = action.payload;
      state[category] = deviceId;
    },

    updateAvailableAudioDevices: (
      state,
      action: PayloadAction<{ category: string; deviceId: string }>
    ) => {
      const { category, deviceId } = action.payload;
      state[category] = deviceId;
    },
  },
});

export const { updateAvailableVideoDevices } = mediaDevicesSlice.actions;
export default mediaDevicesSlice.reducer;