// currentStepSlice.ts

import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { SLICE } from "../../enums/store";
import { IcurrentStepState } from "../../interfaces/Stepper";
const initialState: IcurrentStepState = {
  previousStep: 0,
  currentStep: 0,
  isFirstStep: false,
  isLastStep: false,
};

const currentStepSlice = createSlice({
  name: SLICE.CURRENT_STEP,
  initialState,
  reducers: {
    setPreviousStep: (state, action: PayloadAction<number>) => {
      state.isFirstStep = false;
      state.isLastStep = false;
      state.previousStep = action.payload;
    },
    setCurretStep: (state, action: PayloadAction<number>) => {
      state.isFirstStep = false;
      state.isLastStep = false;
      state.currentStep = action.payload;
    },
    setIsFirstStep: (state) => {
      state.isFirstStep = true;
    },
    setIsLastStep: (state) => {
      state.isLastStep = true;
    },
  },
});

export const { setPreviousStep, setCurretStep, setIsFirstStep, setIsLastStep } =
  currentStepSlice.actions;
export default currentStepSlice.reducer;
