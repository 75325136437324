// slices/counterSlice.ts
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface SidebarState {
    leftIsOpen: boolean;
    rightIsOpen: boolean;
    sideBarData:any,
    motif:any
}

const initialState: SidebarState = {
    leftIsOpen: true,
    rightIsOpen: false,
    sideBarData:[],
    motif:[]
};

const sidebarSlice = createSlice({
    name: 'sidebar',
    initialState,
    reducers: {
        setLeftIsOpen(state, action: PayloadAction<boolean>) {
            state.leftIsOpen = action.payload;
        },
        setRightIsOpen(state,action: PayloadAction<boolean>) {
            state.rightIsOpen =  action.payload;
        },
        setSideBarData(state,action: PayloadAction<any>) {
            state.sideBarData =  action.payload;

        }, 
        setMotif(state,action: PayloadAction<any>) {
            state.motif =  action.payload;

        },

    },
});

export const { setLeftIsOpen, setRightIsOpen,setSideBarData,setMotif } = sidebarSlice.actions;
export default sidebarSlice.reducer;
