import React, { useEffect, useState, useRef } from "react";
import { Link, useParams } from "react-router-dom";
import styled from "styled-components";
import PeripheriquesDeBase from "../periphiqueDeBase";
import AdvancedParametere from "../periphiriqueAvancee";
import { vitalSignsType } from "../../interfaces/baseParams";
import { postVitalSigns } from "../../axiosAPI/params";
import SocketIOContextProvider from "../../services/socketIOContext";
import { useConsultationContext } from "../../services/ConsultationContext";
import CustomIframe from "../iframe";
import { useDispatch, useSelector } from "react-redux";
import { setVitalSignsConsultation } from "../../features/consultationData/consultationDataSlice";
import { baseParamsMinValues } from "../../constants";
import { RootState } from "src/store";

const Card = styled.div<{ color: any }>`
  width: 412px;
  height: 290px;
  background: #ffffff;
  border-radius: 13.1235px;
  border-top: 12px solid ${(props) => props.color};
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: "Ubuntu";
  font-style: normal;
  font-weight: 500;
  font-size: 38px;
  line-height: 44px;
  color: ${(props) => props.color};
  box-shadow: 10px 10px 20px rgba(0, 0, 0, 0.25);
`;
const Examen = (props: any) => {
  const [selectedPeripherique, setSelectedPeripherique] = React.useState(-1);



  return (
    <div className=" h-full w-full flex flex-col ">
      {selectedPeripherique === -1 && (
        <div className="">
          <div className="flex items-center gap-1 justify-center text-center text-[#858585] font-bold mb-10 mt-10 ">
            <svg
              width="12"
              height="12"
              viewBox="0 0 12 12"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <circle cx="6" cy="6" r="6" fill="#20A8D3" />
            </svg>
            Choisir le type de dispositif médical
            <svg
              width="12"
              height="12"
              viewBox="0 0 12 12"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <circle cx="6" cy="6" r="6" fill="#1AA5C0" />
            </svg>
          </div>
          <div className="cards  items-center justify-center flex gap-10">
            <div
              onClick={() => setSelectedPeripherique(0)}
              className="cursor-pointer"
            >
              <Card
                color="#20A8D3"
                className="text-center"
                style={{
                  background:
                    "linear-gradient(180deg, #FFFFFF 0%, #FDFBEA 100%)",
                }}
              >
                Dispositifs
                <br /> de base
              </Card>
            </div>
            <div
              onClick={() => setSelectedPeripherique(1)}
              className="cursor-pointer"
            >
              <Card
                color="#1AA5C0"
                className="text-center"
                style={{
                  background:
                    "linear-gradient(180deg, #FFFFFF 0%, #EEF7F7 100%)",
                }}
              >
                Dispositifs
                <br /> avancés
              </Card>
            </div>
          </div>
        </div>
      )}
      {selectedPeripherique === 0 && (
        <PeripheriquesDeBase
        setSelectedPeripherique={setSelectedPeripherique}
        />
      )}
      {selectedPeripherique === 1 && (
        <AdvancedParametere setSelectedPeripherique={setSelectedPeripherique} />
      )}
    </div>
  );
};

export default Examen;
