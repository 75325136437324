// Timer.tsx
import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "../../store"; // Make sure to import RootState
import {
  setTimer,
  startTimer,
  updateTimer,
} from "../../features/consultationCounter/consultationCounterSlice";
import moment from "moment";
import { useParams } from "react-router-dom";
import { useGetConsultationDataQuery } from "../features/consultationData/consultationDataApi";

interface ITimerProps {
  start?: any;
}

const Timer = (props: ITimerProps) => {
  const dispatch = useDispatch();
  const { consultationId } = useParams();
  const startTime = useSelector((state: RootState) => state.timer.startTime);
  const currentTime = useSelector(
    (state: RootState) => state.timer.currentTime 
  );
  useEffect(() => {
    const originalDuration = moment.duration(currentTime, "seconds");
 
    const interval = setInterval(() => {
      console.log("updating timer 4")
      const updatedDuration = originalDuration.add(1, "second");
      
      dispatch(setTimer(updatedDuration.asSeconds()));
    }, 1000);
    return () => {
      clearInterval(interval);
    };
  }, [dispatch, currentTime]);

  /**
   * @param time
   * @returns
   */
  const formatTime = (time: number): string => {
    const hours = Math.floor(time / 3600)
      .toString()
      .padStart(2, "0");
    const minutes = Math.floor((time % 3600) / 60)
      .toString()
      .padStart(2, "0");
    const seconds = Math.floor(time % 60)
      .toString()
      .padStart(2, "0");

    return `${hours}:${minutes}:${seconds}`;
  };

  return <div>{formatTime(currentTime)}</div>;
};

export default Timer;
