import Lottie from "react-lottie";
import animationData from "../../assets/loty.json";
import { GlobalContent, GlobalStyle } from "../SearchPatient/SearchPatient";

const Loader = () => {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };
  return (
    <GlobalStyle>
      <GlobalContent>
        <div
          style={{ flex: 1, display: 'flex',justifyContent: "center", alignItems: "center" }}
        >
          <Lottie
            options={defaultOptions}
            height={150}
            width={150}
            // style={{ alignSelf: "center" }}
          />
          ;
        </div>
      </GlobalContent>
    </GlobalStyle>
  );
};

export default Loader;
