// selectedDoctorSlice.ts

import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { SLICE } from "../../enums/store";
import { ISlectedDoctorState } from "../../interfaces/Appointment";
const initialState: ISlectedDoctorState = {
  doctors: null,
  isLoading: false,
  isUpdated: false,
  isRefetchRequired:true,
  error: null,
};

const selectedDoctorSlice = createSlice({
  name: SLICE.SELECTED_DOCTOR,
  initialState,
  reducers: {
    setDoctors: (state, action: PayloadAction<number>) => {
      console.log("DATA",JSON.parse(JSON.stringify(state)));
      
      state.doctors=action.payload
      
    }
  },
});

export const { setDoctors } = selectedDoctorSlice.actions;
export default selectedDoctorSlice.reducer;
