// surgicalSlice.ts

import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { SLICE } from "../../enums/store";
const initialState: any = {
  isSurgical:false,
};

const surgicalSlice = createSlice({
  name: SLICE.SURGICAL,
  initialState,
  reducers: {
    
    setIsSurgicals: (state, action: PayloadAction<boolean>) => {
          state.isSurgical = action.payload;
      
    },
   
  },
});

export const { setIsSurgicals } = surgicalSlice.actions;
export default surgicalSlice.reducer;
