import { useSelector } from "react-redux";
import { app } from "../../enums/app"
import { NetworkIndicators } from "./NetworkIndicators"
import { SystemIndicators } from "./SystemIndicators"
import { RootState } from "../../store";

export const Indicators = () => {


  const currentApp = useSelector((state: RootState) => state?.auth.currentApp);
  const isConnected = useSelector((state: RootState) => state?.auth.isConnected);

    return (<>
     <span className="flex justify-around gap-3 my-5 mx-4">
            <div className="flex relative "> 
            {/* {(currentApp === app.VISIO_STATION || !isConnected) &&  <SystemIndicators />} */}
           {/* <NetworkIndicators /> */}

         
            </div>
          </span>
    </>)
}

