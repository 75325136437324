import { useEffect, useState } from "react";
import AgoraRTC from "agora-rtc-sdk-ng";
import { getDevicesWithLabel } from "../../helper/mediaDeviceHelper";
import VideoPlayer from "./VideoPlayer";
import devices from "../../helper/devicesInfos.json";
import styled from "styled-components";
import { toast } from "react-toastify";

const APP_ID =
  process.env.REACT_APP_APP_ID ;
const client = AgoraRTC.createClient({
  mode: "rtc",
  codec: "vp8",
  //TODO: turn server
});

export const AgoraScreenShare = ({
  token,
  channel,
  deviceUid,
  isShareScreen,
  setCurrentShareScreen,
}: any) => {
  const [users, setUsers] = useState<any>([]);

  const handleUserLeft = (user: any) => {
    setUsers((previousUsers: any) =>
      previousUsers.filter((u: any) => u.uid !== user.uid)
    );
  };
  useEffect(() => {
    console.log("*SHAREDEVICE* deviceuid", deviceUid);
  }, [deviceUid]);

  /**
   *
   * @param user
   * @param mediaType
   */
  const handleUserJoined = async (user: any, mediaType: any) => {
    await client.subscribe(user, mediaType);
    const newUser = {
      kind: "deviceCamera",
      uid: user.uid,
      videoTrack: user.videoTrack,
      // specialAttribute: generateUniqueAttribute(), // Generate a unique attribute for each user
    };

    if (mediaType === "video") {
      setUsers((previousUsers: any) => [...previousUsers, newUser]);
    }

    // if (mediaType === "audio") {
    //   user.audioTrack.play();
    // }
  };

  useEffect(() => {
    console.log("*SHAREDEVICE* inside to execute echographe");
    // if (token && isShareScreen) {
    const handelScreenShare = async () => {
      console.log("*SHAREDEVICE* sharing the screen ");
      
      client.on("user-published", handleUserJoined);
      client.on("user-left", handleUserLeft);
      client
        .join(APP_ID, channel, token, null)
        .then((uid) =>
          Promise.all([
            AgoraRTC.createScreenVideoTrack({
              encoderConfig: "720p_3",
              optimizationMode: "detail",
              displaySurface: "window"
              // scalabiltyMode: "1SL1TL",
            },"disable"),
            uid,
          ])
        )
        .then(([tracks, uid]) => {
          setCurrentShareScreen({
            videoTrack: tracks,
            client: client,
          });
          setUsers((previousUsers: any) => [
            ...previousUsers,
            {
              kind: "echographe",
              uid,
              echographeTracks: tracks,
            },
          ]);
          client.publish(tracks);
          //TODO: detect cancel chrom tab
        }).catch(() => {
          toast.info("permission denied")
        });
    };
    if (isShareScreen) {
      handelScreenShare();
    }

    return () => {
      client.off("user-published", handleUserJoined);
      client.off("user-left", handleUserLeft);
      client.unpublish().then(() => client.leave());
    };
  }, [isShareScreen]);

  // return <>{users[0] && <VideoPlayer key={users[0].uid} user={users[0]} />}</>;
  return (
    <>
      {users.map((user: any) => {
        if (user.uid === deviceUid) {
          console.log("*SHAREDEVICE* deviceuid check", deviceUid);
        }
        return (
          // user.uid === deviceUid ?? <VideoPlayer key={user.uid} user={user} />
          <></>
        );
      })}
    </>
  );
};
