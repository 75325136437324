import React, { useState } from "react";
import ReactApexChart from "react-apexcharts";
import styled from "styled-components";

type ChartData = {
  name: string;
  data: number[];
};

type ChartProps = {
  data: ChartData[];
  categories: string[];
  height?: number;
  typeChart:
    | "line"
    | "area"
    | "bar"
    | "histogram"
    | "pie"
    | "donut"
    | "radialBar"
    | "scatter"
    | "bubble"
    | "heatmap"
    | "treemap"
    | "boxPlot"
    | "candlestick"
    | "radar"
    | "polarArea"
    | "rangeBar"
    | undefined;
};

const GlobalContent = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 10px;
`;
const HeadChart = styled.div`
  padding-left: 1rem;
  padding-top: 10px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 10px;
  .title{
    font-family: 'Ubuntu';
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 14px;
    color: #20A8D3; 
  }
  .date{
    width: 100%;
    display: flex;
    gap: 10px;
    .date-label{
      background: #FFFFFF;
      border: 0.883495px solid #858585;
      border-radius: 17.6699px;
      font-family: 'Ubuntu';
      width: 100px;
      padding-block: 10px;
      font-style: normal;
      font-weight: 400;
      font-size: 14.6699px;
      line-height: 13px;
      color: #AEAEAE;
      cursor: pointer;
    }
  }
`;

const MyChart: React.FC<ChartProps> = ({
  data,
  categories,
  height = 300,
  typeChart,
}) => {
  const [options, setOptions] = useState<any>({
    chart: {
      height: height,
      width: "100%",
      type: typeChart,
      toolbar: {
        show: false,
      },
    },
    plotOptions: {
      bar: {
        borderRadius: 15, // set the border radius to 5px for all bars
      },
    },
    xaxis: {
      categories: categories,
    },
    colors: ["#20A8D3"],
    dataLabels: {
      enabled: false,
    },
    tooltip: {
      enabled: false,
    },
  });
  if (!options)
  {
    setOptions({})
  }
  return (
    <GlobalContent>
      <HeadChart>
        <div className="title">titre</div>
        <div className="date">
          <div className="date-label">7 jours</div>
          <div className="date-label">30 jours</div>
          <div className="date-label">90 jours</div>
          <div className="date-label">Tous le temps</div>
        </div>
      </HeadChart>
      <ReactApexChart
        options={options}
        series={data}
        type={typeChart}
        width={480}
        height={250}
      />
    </GlobalContent>
  );
};

export default MyChart;
