import axios from "axios";
import instance from "./Network";

export const handleAnalyseAIClick = async (image: File) => {
  try {
    const formData = new FormData();
    formData.append("file", image);
    return await axios.post(
      "https://myskinai.visiostation.com/upload",
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );
  } catch (error) {
    // console.log("mySkinAi post request [ERROR]", error);
  }
};
