import React, { useState, useCallback, useEffect } from "react";
import type { Dayjs } from 'dayjs';
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { debounce } from "lodash";
import { getPatientByStr, searchPatientData } from "../../axiosAPI/Network";
import { useMyContext } from "../../Providers/SearchPatientProv";
import { BiSearchAlt } from 'react-icons/bi'
import { useSelector } from "react-redux";
import { RootState } from "../../store";
import { app, role } from "../../enums/app";
import APP_CONSTANTS from "../../constants";
import { Option, Select } from "@material-tailwind/react";
import { PATIENT_FILTER } from "../../enums/table";
import { DatePicker } from "antd";
type RangeValue = [Dayjs | null, Dayjs | null] | null;




const { RangePicker } = DatePicker;


const GlobalStyle = styled.div`
  width: 100%;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const SearchPart = styled.div`
  width: 555.36px;
  height: 53.54px;
  background: #ffffff;
  border: 1px solid #1AA5C0;
  border-radius: 8.7557px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  input {
    all: unset;
    width: 90%;
    border-style: none;
    height: 80%;
    font-size: 22px;
    padding: 10px;
  }
`;

const AddPatientFile = styled.button`
  width: 346px;
  height: 50px;
  background: #20A8D3;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;
  font-family: "Ubuntu";
  font-style: normal;
  font-weight: 400;
  font-size: 20.8344px;
  line-height: 16px;
  color: #ffffff;
  cursor: pointer;
`;

const SearchBar = ({ onChange, value }: any) => {
  let context: any = useMyContext();
  const [dateRange, setDateRange] = useState<any>(null)
  const storedUser = useSelector((state: RootState) => state.auth.user);

  const currentApp = useSelector((state: RootState) => state?.auth.currentApp);
  console.log(currentApp)

  const handleSearch = useCallback(
    debounce(async (value) => {
      if (value) {
        const res: any = await searchPatientData({ skip: 5 }, value);
        context.setPatientdata(res);
      }
      else {
        const res: any = await searchPatientData({ skip: 5 });
        context.setPatientdata(res);
      }
    }, 500),
    []
  );


  /**
   * 
   */
  const handlePatientFilterChange = (e: any) => {

    if (e) context.setPatientFilter(e);
    console.log("filter changed", e)
  }

  /**
   * 
   * @param e 
   */
  // const handleRangeDateChange = (e: Dayjs) => {
  //   setDateRange(e);
  // }



  useEffect(() => {
    console.log("date range is", dateRange);
    if (storedUser.roles[0].name != role.SPECIALIST) context.setDateRange(dateRange);
  }, [dateRange])

  const handleChange = (event: any) => {
    console.log('changed term', event.target.value)
    context.setSearchTerm(event.target.value);
    // handleSearch(event.target.value);
  };

  const nav = useNavigate();

  return (
    <GlobalStyle>
      <SearchPart>
        <input
          placeholder="Recherche multiple ..."
          type="text"
          value={value}
          onChange={handleChange}
        />
        <span className="text-white bg-[#1AA5C0] mt-2 w-24
         font-medium rounded-lg text-sm px-10 py-2.5 text-center  items-center mr-2 mb-2">
          <BiSearchAlt size={25} />
        </span>




      </SearchPart>
      <div className="flex gap-5">
        {/* date filter */}
        <div className="flex w-[100%] gap-5 items-center ">
          <RangePicker
            format="YYYY-MM-DD"
            onCalendarChange={(val, stringVal) => {
              console.log("string val", stringVal)
              setDateRange(stringVal)
            }} />

        </div>

        {/* patients filter */}
        {storedUser.roles[0].name == role.GENERALIST && <Select onChange={handlePatientFilterChange} defaultValue={PATIENT_FILTER.ALL_PATIENTS} label="Tous les patients">
          <Option value={PATIENT_FILTER.ALL_PATIENTS}>Tous les patients</Option>
          <Option value={PATIENT_FILTER.CENTER_PATIENTS}>Patients de ce centre</Option>
          <Option value={PATIENT_FILTER.DOCTOR_PATIENTS}>Mes patients</Option>
          <Option value={PATIENT_FILTER.CURRENT_BOX}>Ce box</Option>
        </Select>}
      </div>
      {currentApp && currentApp != app.SPECIALIST &&
        <AddPatientFile onClick={() => nav(APP_CONSTANTS.ROUTES.ADD_PATIENT)}>
          <img src="/searchPage/addpatient.svg" alt="" />
          <div>Nouveau dossier patient</div>
        </AddPatientFile>
      }
    </GlobalStyle>
  );
};

export default SearchBar;
