  // isTabacConsumingSlice.ts

  import { createSlice, PayloadAction } from "@reduxjs/toolkit";
  import { SLICE } from "../../enums/store";
  const initialState: any = {
    isTabacConsuming:false,
  };

  const isTabacConsumingSlice = createSlice({
    name: SLICE.IS_TABAC,
    initialState,
    reducers: {
      
      setisTabacConsuming: (state, action: PayloadAction<boolean>) => {
            state.isTabacConsuming= action.payload;
        
      },
    
    },
  });

  export const { setisTabacConsuming } = isTabacConsumingSlice.actions;
  export default isTabacConsumingSlice.reducer;
