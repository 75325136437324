import { useSelector } from 'react-redux';
import OrdonnaceMedicale from './OrdonnanceMedicale';
import BioAnalyses from './BioAnalyses';
import RadioExams from './RadioExams';


export default function Prescription () {
  switch(useSelector((state:any)=>state.selectedPrescription.selectedPrescription)){
    case 0:
        return <OrdonnaceMedicale/>
    case 1:
        return <BioAnalyses/>
    case 2:
        return <RadioExams/>
  }
  
}
