import { Radio } from '@material-tailwind/react';

export interface IRadioInputProps {
    id:string,
    name:string,
    checked:boolean,
    onChange:any,
    label:string
}

export default function RadioInput ({id,name,checked,label,onChange}: IRadioInputProps) {
  return (
    <div className='flex'>
      <input type='radio' className='mt-4 mr-3 p-2 cursor-pointer' id={id} name={name} checked={checked}  onChange={onChange} />
      <p className='mt-3 text-gray-600'>{label&&label}</p>
      </div>
  );
}
