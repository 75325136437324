// authApi.ts

import { IUser } from "../../interfaces/user";
import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

const authApi = createApi({
  reducerPath: "authApi",
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_SERVER_URL,
  }),
  endpoints: (builder) => ({
    login: builder.mutation<
      { token: string; user: IUser },
      { login: string; password: string; centerId?: number; stationId?: number }
    >({
      query: ({ login, password, centerId, stationId }) => {
        console.log("sending request", {
          login,
          password,
          centerId,
          stationId,
        });
        return {
          url: "auth/login?include=user",
          method: "POST",
          body: { login, password, centerId, stationId },
        };
      },
    }),

    register: builder.mutation<
      { token: string; user: IUser },
      { login: string; password: string }
    >({
      query: (userData) => ({
        url: "/auth/register",
        method: "POST",
        body: userData,
      }),
    }),
    logout: builder.mutation<void, { refreshToken: "" }>({
      // to change must have o token to check with team
      query: () => ({
        url: "/auth/SignOut",
        method: "POST",
      }),
    }),
  }),
});

export const { useLoginMutation, useRegisterMutation, useLogoutMutation } =
  authApi;

export default authApi;
