import * as React from "react";
import DynamicButton from "../DynamicButton";
import { BUTTON_COLORS } from "../../enums/stepper";
import { PaperClipIcon, TrashIcon } from "@heroicons/react/24/solid";
import FileInput from "./FileInput";
import { Transition } from "@headlessui/react";
import TextInput from "../FormBuilder/FormElements/TextInput";
import { IFileData } from "@interfaces/Stepper";
import SelectInput from "../FormBuilder/FormElements/SearchableSelect";
const moment = require("moment");

export interface IFileUploaderProps {}

export default function FileUploader(props: IFileUploaderProps) {
  const [filesList, setFilesList] = React.useState<any>([]);
  
  const handleDeleteFile = (index: number) => {
    setFilesList((prevFilesList:any) => {
      const updatedFilesList = [...prevFilesList];
      updatedFilesList.splice(index, 1);
      return updatedFilesList;
    });
  };
  return (
    <div className="mt-3">
      {/* <div className="text-[#5E5E5E] mb-2">Documents joints</div> */}

      <FileInput
        onFileUpload={(fileData: IFileData) => {
          
        
          setFilesList((prevFilesList:any) => [...prevFilesList, fileData]);
      
        }}
      />
      <Transition
        show={filesList?.length>0}
        enter="transition-opacity ease-linear duration-300"
        enterFrom="opacity-0"
        enterTo="opacity-100"
        leave="transition-opacity ease-linear duration-300"
        leaveFrom="opacity-100"
        leaveTo="opacity-0"
      >
        <div className="w-full border border-sky-500 mt-4 p-4 rounded-md overflow-x-auto">
          {filesList?.map((file: any,index:number) => {
            return (
              <div key={index} className="mx-2 my-4 flex  relative gap-3 text-[#5E5E5E]">
                <div className="flex flex-col">
                  <p>Date</p> <p className="mt-4 text-[#1AA5C0]">{file.date}</p>
                </div>
                <div className="flex flex-col min-w-[18rem]">
                  <p>Fichier</p>
                  <div className=" max-w-[19rem] rounded-md mt-1 p-2 bg-[#cbcacacd]">
                    {file.fileName}
                  </div>
                </div>
                <div className="flex flex-col mt-7">
                  <TextInput name="" label="Nom" style="" />
                
                </div>
                <div className="flex flex-col mt-7 min-w-[15rem]">
                  <SelectInput name="" label="" style="" placeholder="Type de fichier.."  dataState={"fileType"}/>
                
                </div>
                <div className="flex  flex-col mt-7">
                  <TextInput name="" label="Commentaire" style="" />
                
                </div>
                <button onClick={() => handleDeleteFile(index)}>

                    <TrashIcon className="absolute top-8 mr-8  h-6 w-6 text-[#5E5E5E]" />
                  </button>
              </div>
            );
          })}
        </div>
      </Transition>
    </div>
  );
}
