import { Formik } from "formik";
import React, { useEffect, useMemo, useState } from "react";
import styled from "styled-components";
import MyInput from "./MyInput";
import MySelect from "./MySelect";

import {
  addPatient,
  getLocation,
  getCountries,
  getCities,
} from "../../axiosAPI/Network";
import { useMutation } from "react-query";
import InputWithButton from "./inputGenerator";
import { useNavigate } from "react-router-dom";

import moment from "moment";
import { set } from "lodash";
// import { useQuery } from 'react-query'
// import axios from "axios";
// import { addPatient } from "../../axiosAPI/Network";

const StyledForm = styled.form`
  width: 100%;
  display: flex;
  gap: 1rem;
  flex-wrap: wrap;
  .myform {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 1rem;
    flex-wrap: wrap;
    .line {
      width: 100%;
      display: flex;
      gap: 2rem;
      flex-wrap: wrap;
      .duble-size {
        width: 100%;
      }
      .half-size {
        width: 50%;
      }
    }
  }
`;

const WrraperBotton = styled.div`
  margin-top: 1rem;
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: space-between;
  color: #ffff;
  .but {
    background: #858585;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
    border-radius: 3px;
    padding-inline: 4rem;
    padding-block: 0.5rem;
    margin-bottom: 1rem;
  }
`;

interface MyPropsDataType {
  ischild: boolean | undefined;
  iswife: boolean | undefined;
  isSearchAndAdd: boolean | undefined;
  setSearchAndAdd: any;
  setIsChild: any;
  isModif: boolean;
  setIswife: any;
  setIsModif: any;
  userSelected?: any;
  setModal: (x: boolean) => void;
  setSuccess: (x: boolean) => void;
  patientCine?: any;
}

const AddAdherant = ({
  setSuccess,
  ischild,
  setIsModif,
  iswife,
  setSearchAndAdd,
  isSearchAndAdd,
  isModif,
  setIsChild,
  setModal,
  setIswife,
  userSelected,
  patientCine,
}: MyPropsDataType) => {
  const { mutate, isLoading } = useMutation(
    (data: any) => addPatient(data, true, ischild, iswife, patientCine),
    {
      onSuccess: (res: any) => {
      
        setModal(false);
        setSuccess(true);
      },
      onError: () => {
        // toast.error("An error occured when submitting form");
      },
    }
  );
  const [dataToAdd, setDataToAdd] = useState<any>({});
  const [countryArr, setCountryArr] = useState<any>([]);
  const [provinceArr, setProvinceArr] = useState<any>([]);
  const [regionArr, setRegionArr] = useState<any>([]);
  const [cityArr, setCityArr] = useState<any>([]);
  const [stateObject, setStateObject] = useState<any>();
  const [age, setAge] = useState<any>(0);
  const [birthDate, setBirthDate] = useState("");

  const handleBirthDateChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setBirthDate(e.target.value);
  };

  const nav = useNavigate();

  /**
   *
   * @param obj
   * @returns
   */

  useEffect(() => {

    (async () => {
      const [countriesData, citiesData] = await Promise.all([
        getCountries(),
        getCities(),
      ]);

      setCityArr(citiesData.data.results.map((city: any) => city.name));
      setCountryArr(
        countriesData.data.results.map((country: any) => country.name)
      );

      if (userSelected) setStateObject(flattenObject(userSelected));
    })();
  }, [userSelected]);

  function flattenObject(obj: any) {
    let result: any = {};

    function recurse(current: any, path = "") {
      for (const key in current) {
        const value = current[key];
        const newPath = path ? `${key}` : key;

        if (typeof value === "object" && value !== null) {
          recurse(value, newPath);
        } else {
          result[newPath] = value;
        }
      }
    }

    recurse(obj);
    return result;
  }

  //   const dataMemo = useMemo(() => {
  //     // console.log("in use memo", dataToAdd);
  //   }, [dataToAdd]);

  if (isLoading) return <div>Loading ...</div>;

  return (
    <>
      <Formik
        initialValues={isModif ? {} : stateObject}
        onSubmit={async (values) => {
          if (!ischild && !iswife && !isSearchAndAdd) mutate({ data: values });
          if (isModif) {
            setIsModif(false);
          }
          // if (isSearchAndAdd) {
          //   mutate({ data: { dataToAdd, values } });
          //   // setDataToAdd({dataToAdd, values});
          // }
          if (ischild || iswife) {
            setDataToAdd(values);
            //   setSearchAndAdd(true);
            //   setIsChild(false);
            //   setIswife(false);

            mutate({ data: { dataToAdd, values } });
          }
        }}
      >
        {({ values, handleChange, handleSubmit }) => (
          <StyledForm onSubmit={handleSubmit}>
            <div className="myform ">
              <div className="line">
                <MyInput
                  ismodif={isModif}
                  label={"Nº CINE"}
                  placeholder={"CINE"}
                  isImportant={false}
                  name={"cine"}
                  handleChange={handleChange}
                  hasLeft={false}
                  hasRight={false}
                  type={"text"}
                />

                <MyInput
                  ismodif={isModif}
                  label={"Nom"}
                  placeholder={"Nom"}
                  isImportant={true}
                  name={"lastName"}
                  handleChange={handleChange}
                  hasLeft={false}
                  hasRight={false}
                  type={"text"}
                />
                <MyInput
                  ismodif={isModif}
                  label={"Prénom"}
                  placeholder={"Prénom"}
                  isImportant={true}
                  name={"firstName"}
                  handleChange={handleChange}
                  hasLeft={false}
                  hasRight={false}
                  type={"text"}
                />
                <MySelect
                  ismodif={isModif}
                  label={"Sexe"}
                  placeholder={"Sexe"}
                  isImportant={true}
                  options={["female", "male"]}
                  name={"gender"}
                  handleChange={handleChange}
                />
                <MyInput
                  ismodif={isModif}
                  label={"Date de naissance"}
                  placeholder={"Date de naissance"}
                  isImportant={true}
                  name={"birthDate"}
                  handleChange={handleChange}
                  hasLeft={false}
                  hasRight={false}
                  type={"date"}
                  isAge={true}
                />
                {!ischild && !iswife && (
                  <MySelect
                    ismodif={isModif}
                    label={"Couverture"}
                    placeholder={"Couverture"}
                    isImportant={true}
                    options={["CNSS PIPC", "EXONÉRÉ","CNSS","CNOPS","FAR"]}
                    name={"medicalCover"}
                    handleChange={handleChange}
                  />
                )}
                {!ischild && !iswife && (
                  <MyInput
                    ismodif={isModif}
                    label={"Nº adhérent"}
                    placeholder={"Nº adhérent"}
                    isImportant={true}
                    name={"affiliationNumber"}
                    handleChange={handleChange}
                    hasLeft={false}
                    hasRight={false}
                    type={"text"}
                  />
                )}

                <MySelect
                  options={[
                    "Maroc"
                  ]}
                  
                  ismodif={isModif}
                  label={"Pays"}
                  placeholder={"Pays"}
                  isImportant={true}
                  name={"country"}
                  handleChange={handleChange}
                />
                {/* <MySelect
                ismodif={isModif}
                label={"Region"}
                placeholder={"Region"}
                isImportant={true}
                name={"Region"}
                handleChange={handleChange}
                options={regionArr}
              /> */}
                <div className={`${ischild ? "flex-1" : ""}`}>
                  <MySelect
                    ismodif={isModif}
                    label={"Ville"}
                    placeholder={"Ville"}
                    isImportant={true}
                    name={"city"}
                    handleChange={handleChange}
                    options={[
                    "Casablanca",
                    "Rabat",
                    "Marrakech",
                    "Tanger",
                    "Fès",
                    "Salé",
                    "Meknès",
                    "Oujda",
                    "Kénitra",
                    "Agadir",
                    "Tétouan",
                    "Témara",
                    "Safi",
                    "Mohammédia",
                    "Khouribga",
                    "El Jadida",
                    "Béni Mellal",
                    "Aït Melloul",
                    "Nador",
                    "Dar Bouazza",
                    "Taza",
                    "Settat",
                    "Larache",
                    "Ksar El Kebir",
                    "Guelmim",
                    "Berkane",
                    "Tifelt",
                    "Skhirat",
                    "Khenifra",
                    "Sidi Slimane",
                    "Kelaat Sraghna",
                    "Oulad Teima",
                    "Sefrou",
                    "Essaouira",
                    "Al Hoceima",
                    "Beni Ansar",
                    "Taourirt",
                    "Youssoufia",
                    "Tan-Tan",
                    "Ouazzane",
                    "Midelt",
                    "Sidi Bennour",
                    "Azrou",
                    "Dakhla",
                    "Zaio",
                    "Martil",
                    "Bouznika",
                    "Inezgane",
                    "El Kelaa des Sraghna",
                    "Chefchaouen"
                    ]}
                  />
                </div>
                {!ischild && (
                  <MySelect
                    ismodif={isModif}
                    label={"Province"}
                    placeholder={"Province"}
                    isImportant={true}
                    name={"province"}
                    handleChange={handleChange}
                    options={provinceArr}
                  />
                )}
                <div className={`${ischild ? "w-1/2" : ""}`}>
                  <MyInput
                    ismodif={isModif}
                    label={"Adresse"}
                    placeholder={"Adresse"}
                    isImportant={true}
                    name={"address"}
                    handleChange={handleChange}
                    hasLeft={false}
                    hasRight={false}
                    type={"text"}
                  />
                </div>
                {!ischild && (
                  <MyInput
                    ismodif={isModif}
                    label={"Complément d’adresse"}
                    placeholder={"Complément d’adresse"}
                    isImportant={false}
                    name={"address2"}
                    handleChange={handleChange}
                    hasLeft={false}
                    hasRight={false}
                    type={"text"}
                  />
                )}
                {!ischild && (
                  <MyInput
                    ismodif={isModif}
                    label={"Code postal"}
                    placeholder={"Code postal"}
                    isImportant={true}
                    name={"postalCode"}
                    handleChange={handleChange}
                    hasLeft={false}
                    hasRight={false}
                    type={"number"}
                  />
                )}
                {!ischild && (
                  <MyInput
                    ismodif={isModif}
                    label={"Téléphone mobile"}
                    placeholder={"Téléphone mobile"}
                    isImportant={true}
                    name={"phoneNumber"}
                    handleChange={handleChange}
                    hasLeft={true}
                    hasRight={false}
                    type={"text"}
                  />
                )}
                {/* {!ischild && (
                  <MyInput
                    ismodif={isModif}
                    label={"Téléphone fixe"}
                    placeholder={"Téléphone fixe"}
                    isImportant={false}
                    name={"phoneNumber2"}
                    handleChange={handleChange}
                    hasLeft={true}
                    hasRight={false}
                    type={"text"}
                  />
                )}
                {!ischild && (
                  <MyInput
                    ismodif={isModif}
                    label={"Adresse électronique"}
                    placeholder={"Adresse électronique"}
                    isImportant={false}
                    name={"email"}
                    handleChange={handleChange}
                    hasLeft={false}
                    hasRight={false}
                    type={"text"}
                  />
                )} */}
              </div>
            </div>
            {isModif && (
              <WrraperBotton>
                <button
                  onClick={() => {
                    setModal(false);
                  }}
                  type="button"
                  className="but"
                >
                  Annuler
                </button>
                <button
                  className="but"
                  type="submit"
                  style={{ background: "#2699DA" }}
                >
                  {"Valider"}
                </button>
              </WrraperBotton>
            )}
          </StyledForm>
        )}
      </Formik>
    </>
  );
};

export default AddAdherant;
