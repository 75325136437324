import { Textarea } from "@material-tailwind/react";
import React, { useContext, useEffect, useState } from "react";

import styled, { css } from "styled-components";
import { Select } from "antd";
import { IDiagnostic as IDiagnostic } from "../../../../interfaces";
import Multiselect from "../../../Stepss/Multiselect";
import {
  getAIIframeLink,
  getChiefComplaint,
  getChiefComplaints,
} from "../../../../axiosAPI/Network";
import CustomIframe from "../../../iframe";
import { useConsultationContext } from "../../../../services/ConsultationContext";
import { useDispatch, useSelector } from "react-redux";
import { setCurretStep } from "../../../../features/currentStep/currentStepSlice";

// Styled components
const SelectWrapper = styled.div`
  position: relative;
  display: inline-block;
  width: 100%;
`;

const SelectButton = styled.button`
  width: 100%;
  height: 60px;
  padding: 10px;
  background-color: #ffffff;
  border: 1px solid gray;
  border-radius: 4px;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const ArrowIcon = styled.span<{ flipped: boolean }>`
  transition: transform 0.3s ease-in-out;

  ${(props) =>
    props.flipped &&
    css`
      transform: rotate(180deg);
    `}
`;

const DropdownList = styled.ul`
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  padding: 0;
  margin: 0;
  list-style-type: none;
  background-color: #ffffff;
  border: 1px solid gray;
  border-top: none;
  z-index: 999;
  border-radius: 0 0 4px 4px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
`;

const DropdownItem = styled.li`
  padding: 10px;
  cursor: pointer;

  &:hover {
    background-color: #f0f0f0;
  }
`;
type SelectProps = {
  options: { value: string; label: string }[];
  defaultLabel?: string;
  onChange: (e: any) => void;
};
// Example options
// const options = [
//   { value: "option1", label: "Option 1" },
//   { value: "option2", label: "Option 2" },
//   { value: "option3", label: "Option 3" },
// ];

// Custom Select component
export const CustomSelect = ({
  options,
  defaultLabel,
  onChange,
}: SelectProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState("");
  const [isFlipped, setIsFlipped] = useState(false);

  const handleOptionClick = (value: string) => {
    setSelectedOption(value);
    onChange(value);
    setIsOpen(false);
  };

  const handleButtonClick = () => {
    setIsOpen(!isOpen);
    setIsFlipped(!isFlipped);
  };

  return (
    <div className="w-full">
      <span className="text-xl text-[#1AA5C0]">{defaultLabel}</span>
      <SelectWrapper>
        <SelectButton onClick={handleButtonClick}>
          {selectedOption ? selectedOption : "Select an option"}
          <ArrowIcon flipped={isFlipped}>&#9662;</ArrowIcon>
        </SelectButton>
        {isOpen && (
          <DropdownList>
            {options.map((option) => (
              <DropdownItem
                key={option.value}
                onClick={() => handleOptionClick(option.value)}
              >
                {option.label}
              </DropdownItem>
            ))}
          </DropdownList>
        )}
      </SelectWrapper>
    </div>
  );
};

const AntdSelect = styled(Select)`
  width: 100%;
`;

const Diagnostic = (props: any) => {
  const {
    patient,
    vitalSignsc,
    setShowIframe,
    showIframe,
    receivedDiagnostics,
  } = useConsultationContext();
  const [secondDiagnostic, setSecondDiagnostic] = useState<number>(2024);
  const [firstItems, setFirstItems] = useState<Array<any>>([]);
  const [secondItems, setSecondItems] = useState<Array<any>>([]);
  const [chiefComplaints, setChiefComplaints] = useState<Array<any>>([]);
  const [diagnosticPrincipale, setDiagnosticPrincipale] = useState<string>();
  const [diagnosticSecondaire, setDiagnosticSecondaire] = useState<string>();
  const [isLoading, setIsLoading] = useState(true);
  const [firstDiagnostic, setFirstDiagnostic] = useState<number>(2025);
  const [conclusion, setConclusion] = useState<string>("");
  const [diagnostic, setDiagnostic] = useState<IDiagnostic>();
  const [iframeLink, setIframeLink] = useState<string | null>(null);
  const dispatch = useDispatch();
  const currentStep = useSelector((state: any) => state.currentStep.currentStep)
  // const {
  //   consultationForm,
  //   setConsultationForm,
  //   setConsultationInfos,
  //   consultationInfos,
  //   returnedStep,
  // } = props;

  // useEffect(() => {
  //   getChiefComplaint(props.consultationId).then((res: any) => {
  //     setChiefComplaints(res.data.chiefComplaintConsultations);
  //     setConclusion(res.data.conclusion);
  //   });
  // }, [props.consultationId]);
  // useEffect(() => {
  //   if (chiefComplaints?.length > 0) {
  //     setDiagnosticPrincipale(chiefComplaints[0].chiefComplaint.name);
  //     if (chiefComplaints?.length > 1) {
  //       setDiagnosticSecondaire(chiefComplaints[1].chiefComplaint.name);
  //     }
  //   }
  // }, [chiefComplaints]);

  useEffect(() => {
    (async () => {
      try {

        const { data } = await getAIIframeLink({
          patient,
          vitalSigns: vitalSignsc[vitalSignsc?.length - 1],
        });
        setIframeLink(data.link);
        setIsLoading(false);
      } catch (error) {
        console.log(error);
      }
    })();
  }, []);

  useEffect(() => {
    if (iframeLink) setShowIframe(true);
  }, [iframeLink]);
  useEffect(() => {
    if (showIframe === false) { dispatch(setCurretStep(currentStep + 1)); setShowIframe(null) }

  }, [showIframe])
  const handleSecondDiagnosticChange = (e: any) => {
    setSecondDiagnostic(e[0]);
  };

  const handleFirstDiagnosticChange = (e: any) => {
    setFirstDiagnostic(e[0]);
  };
  const handleConclusionChange = (e: any) => {
    setConclusion(e.target.value);
  };

  useEffect(() => {
    setDiagnostic({
      ...diagnostic,
      conclusion,
      firstDiagnostic,
      secondDiagnostic,
    });
  }, [conclusion, firstDiagnostic, secondDiagnostic]);

  // useEffect(() => {
  //   returnedStep
  //     ? setConsultationForm({
  //         conclusion,
  //         isUpdatedStep: true,
  //         chiefComplaintConsultations: [
  //           { chiefComplaintId: firstDiagnostic },
  //           { chiefComplaintId: secondDiagnostic },
  //         ],
  //       })
  //     : setConsultationForm({
  //         conclusion,
  //         chiefComplaintConsultations: [
  //           { chiefComplaintId: firstDiagnostic },
  //           { chiefComplaintId: secondDiagnostic },
  //         ],
  //       });
  //   setConsultationInfos({
  //     ...consultationInfos,
  //     conclusion,
  //     chiefComplaintConsultations: [
  //       { chiefComplaintId: firstDiagnostic },
  //       { chiefComplaintId: secondDiagnostic },
  //     ],
  //   });
  // }, [diagnostic]);

  if (isLoading) return <div>Loading AI ...</div>;
  if (showIframe && iframeLink)
    return (
      <div className="h-full ">
        <CustomIframe
          style={{
            width: "100%",
            height: "70vh",
          }}
          url={iframeLink}
        /></div>
    );
  // else
  //   return (
  //     <div className="flex flex-col w-full px-16 h-[100%] justify-center items-center pt-6 gap-8">
  //       <div className="flex  gap-10 w-full justify-center items-center">
  //         <div className="flex flex-col gap-2 flex-1">
  //           <h1 className="text-[#1AA5C0]  text-xl font-[500]">
  //             Diagnostic principal
  //           </h1>

  //           <Multiselect
  //             defaultValue={diagnosticPrincipale ? [diagnosticPrincipale] : []}
  //             isApi
  //             type={"id"}
  //             className="medication-search"
  //             selectedItems={[]}
  //             setSelectedItems={handleFirstDiagnosticChange}
  //             searchEntity={getChiefComplaints}
  //             options={[
  //               {
  //                 label: "Diabète",
  //                 value: 1,
  //               },
  //               {
  //                 label: "Hypertension",
  //                 value: 2,
  //               },
  //             ]}
  //           />
  //           <span>
  //             {receivedDiagnostics && (
  //               <>
  //                 Diagnostique 1 intelligente:{" "}
  //                 {receivedDiagnostics.diagnostic_1}
  //               </>
  //             )}
  //           </span>
  //         </div>
  //         <div className="flex flex-col gap-2 flex-1">
  //           <h1 className="text-[#1F8383]  text-xl font-[500]">
  //             Diagnostic Secondaire
  //           </h1>
  //           {/* <Multiselect
  //             defaultValue={diagnosticSecondaire ? [diagnosticSecondaire] : []}
  //             type={"id"}
  //             className="medication-search"
  //             selectedItems={[]}
  //             isApi
  //             setSelectedItems={handleSecondDiagnosticChange}
  //             searchEntity={getChiefComplaints}
  //             options={[
  //               {
  //                 label: "Diabète",
  //                 value: 1,
  //               },
  //               {
  //                 label: "Hypertension",
  //                 value: 2,
  //               },
  //             ]}
  //           /> */}
  //           <span>
  //             {receivedDiagnostics && (
  //               <>
  //                 Diagnostique 2 intelligente:{" "}
  //                 {receivedDiagnostics.diagnostic_2}
  //               </>
  //             )}
  //           </span>
  //         </div>
  //       </div>
  //       {receivedDiagnostics && (
  //         <div className="w-full">
  //           <span className="text-xl w-full text-[#1AA5C0]">
  //             Interpretation
  //           </span>
  //           <Textarea
  //             rows={3}
  //             readOnly
  //             value={receivedDiagnostics.interpretation}
  //             label=""
  //             className="w-full"
  //           />
  //         </div>
  //       )}
  //       <div className="w-full">
  //         <span className="text-xl w-full text-[#1F8383]">Compte rendu</span>
  //         <Textarea
  //           rows={10}
  //           value={conclusion}
  //           defaultValue={conclusion}
  //           onChange={handleConclusionChange}
  //           label=""
  //           className="w-full"
  //         />
  //       </div>
  //     </div>
  //   );
};

export default Diagnostic;
