  // medicalSlice.ts

  import { createSlice, PayloadAction } from "@reduxjs/toolkit";
  import { SLICE } from "../../enums/store";
  const initialState: any = {
    isMedical:false,
  };

  const medicalSlice = createSlice({
    name: SLICE.MEDICAUX,
    initialState,
    reducers: {
      
      setIsMedicals: (state, action: PayloadAction<boolean>) => {
            state.isMedical = action.payload;
        
      },
    
    },
  });

  export const { setIsMedicals } = medicalSlice.actions;
  export default medicalSlice.reducer;
