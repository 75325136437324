// formSlice.ts
import { SLICE } from '../../enums/store';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface FormState {
  data: {
    [key: string]: any;
  };
}

const initialState: FormState = {
  data: {},
};

const formSlice = createSlice({
  name: SLICE.FORM,
  initialState,
  reducers: {
    updateFormData: (state, action: PayloadAction<{ key: string; value: any }>) => {
      const { key, value } = action.payload;
      state.data[key] = value;
    },
  },
});

export const { updateFormData } = formSlice.actions;
export const formReducer = formSlice.reducer;
