import phone from "../../assets/phone.svg";
import mute from "../../assets/mute.svg";
import cam from "../../assets/cam.svg";
import Vector from "../../assets/newVector.svg";
import Dot from "../../assets/dotVector.svg";
import Separator from "../../assets/separator.svg";
import ThreeDots from "../../assets/threeDots.svg";
import styled from "styled-components";
import { useNavigate } from "react-router";
import useDynamicDialog from "../../hooks/useDynamicDialog";

import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { updateConsultation } from "../../axiosAPI/Network";
import { RootState } from "../../store";
import APP_CONSTANTS, { PATIENT_RECORD_TABS } from "../../constants";
import { role } from "../../enums/app";
import { PatientRecord } from "../patientRecord";
import { setLeftIsOpen } from "../../features/sidebar/SidebarSlice";
import toast from "react-hot-toast";

type Props = {
  setDrawerOpen: any;
  callInformations: any;
  consultationId: number;
  drawerOpen: boolean;
  isShareScreen: boolean;
  setIsShareScreen: any;
  isSpecialist: boolean;
  roomId: string;
};
import {
  selectHangUp,
  selectMute,
  selectToggleScreen,
  setHangUp,
  setMute,
  setToggleScreen,
} from "../../features/teleconsultation/CallActionsSlice";
import { setSpecialistConclusion } from "../../features/specialistConclusion/specialistConclusionSlice";
import { useGetOnGoingPatientConsultationQuery } from "../../features/consultationData/consultationDataApi";
import WebSocketService from "../../services/Notification/socketService";

const ButtonStyle = styled.button`
  background: #5e5e5e;
  border: 2.52487px solid #ffffff;
  border-radius: 100%;
  margin: 0 7px;
  position: relative;
  padding-inline: 0.75rem;
  /* padding-block: 0.5rem; */
  align-self: center;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  height: 60px;
  width: 60px;
  .title {
    font-family: "Ubuntu";
    font-style: normal;
    font-weight: 500;
    font-size: 16.4116px;
    line-height: 24px;
    color: #ffffff;
  }
`;

const CallActions = (props: Props) => {
  // hooks
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { openDynamicDialog, closeDynamicDialog } = useDynamicDialog();
  const [patientId, setPatientId] = useState(null);
  const [lastOnGoingConsultation, setLastOngoingConsultation] = useState(null);

  // props



  const { callInformations } = props;


  /* SELECTORS */
  const consultationMetadata = useSelector(
    (state: RootState) => state.consultationMetadata
  );
  // states
  const specialistConclusion = useSelector(
    (state: any) => state.specialistConclusion.text
  );
  const [isShowConclusionArea, setIsShowConclusionArea] =
    useState<boolean>(false);


  //  get the patient ongoing consultation
  const { data: lastPatientOnGoingConsultations } =
    useGetOnGoingPatientConsultationQuery(JSON.parse(
      localStorage.getItem(`${APP_CONSTANTS.LOCALSTORAGE_PREFIX}consultationMetadata`)
      || '{}')?.patientId);
  // selectors

  useEffect(() => {
    console.log(
      "last ongoing consultation was",
      lastPatientOnGoingConsultations
    );
    if (lastPatientOnGoingConsultations &&
      lastPatientOnGoingConsultations?.results?.length > 0) {
      setLastOngoingConsultation(lastPatientOnGoingConsultations?.results[0])
    }
  }, [lastPatientOnGoingConsultations]);
  const storedUser = useSelector((state: RootState) => state.auth.user);

  /* Temporary get the consultation metadata from localstorage */
  useEffect(() => {
    if (props.isSpecialist) {
      const consultationMetadata = JSON.parse(
        localStorage.getItem(
          `${APP_CONSTANTS.LOCALSTORAGE_PREFIX}consultationMetadata`
        ) || ""
      );
      setPatientId(consultationMetadata?.patientId);
    }
  }, []);

  // handlers
  const handleOpenPatientRecord = () => {
    openDynamicDialog({
      title: "Dossier patient",
      size: "xl",
      message: "",
      component: (
        <>
          {/* Patient Record */}
          <div className="my-2 w-full">
            <PatientRecord
              patientId={
                lastOnGoingConsultation
                  ? lastOnGoingConsultation?.patientId : null}
              options={{
                enable: [
                  PATIENT_RECORD_TABS.PATIENT_FILE,
                  PATIENT_RECORD_TABS.MEDICAL_HISTORY,
                  PATIENT_RECORD_TABS.VITAL_SIGNS,
                  PATIENT_RECORD_TABS.BIOLOGY,
                  PATIENT_RECORD_TABS.REPORTS
                ],
              }}
            />
          </div>
          {/*  END patient record */}
        </>
      ),
      handleCancel: null,
      handleConfirm: null,
    });
  };

  const handleConfirm = async () => {
    // await updateConsultation({conclusion:specialistConclusion},1);
    dispatch(setHangUp());

    navigate(APP_CONSTANTS.ROUTES.SPECIALISR_CALENDAR);
    closeDynamicDialog();
  };
  const handleOpenNote = () => {
    props.setDrawerOpen(!props.drawerOpen);
    dispatch(setLeftIsOpen(false));

  };



  /**
   * hang up action
   */
  const handleHangUpClick = async () => {
    // if the hangup is from specialist, so close the consultation
    if (props.isSpecialist) {
      if (specialistConclusion) {
        await updateConsultation(
          {
            specialistConclusion: specialistConclusion,
            medicalActConsultations: [{ medicalActId: 139 }]
            // endTime: new Date()
          },
          callInformations.consultationId
        );
        WebSocketService.endCall(true, props.roomId, (response) => {
          const { status, data } = response;
          if (status === 200) {
            dispatch(setSpecialistConclusion(null));


            window.location.pathname = `/specialiste/doctor/calendar`;

            /* remove current call */
            localStorage.removeItem(`${APP_CONSTANTS.LOCALSTORAGE_PREFIX}currentCall`)

          }

        })


      } else {
        props.setDrawerOpen(true);
        toast.error("Veuillez remplir le compte rendu de la consultation");
      }
    } else {
      openDynamicDialog({
        message:
          "êtes-vous sûr de vouloir quitter l'appel ?",
        title: "Clôturer la téléconsultation",
        handleConfirm: handleConfirmAction,
        handleCancel: "",
      });

    }
  };



  /**
   * 
   */
  const handleConfirmAction = () => {
    console.log(`redirecting to: /doctor/steps/${callInformations.consultation.patientId}/${callInformations.consultationId}`);
    closeDynamicDialog();
    window.location.pathname = `/doctor/steps/${callInformations.consultation.patientId}/${callInformations.consultationId}`;

    // WebSocketService.endCall(true, props.roomId, (response) => {
    //   const { status, data } = response;
    //   if (status === 200) {

    //   }

    // })
  }

  // render
  return (
    <span className={`flex ${storedUser.roles[0].name !== role.SPECIALIST ? 'bg-[#1F4263] w-[50rem] justify-around rounded-[31px] mx-2 h-[8rem]' : 'ml-[50rem] rounded-[31px] mx-2 h-[8rem]'}`} >

      <ButtonStyle
        onClick={() => {
          dispatch(setMute());
        }}
        style={{
          background: useSelector(selectMute) ? "#FF375E" : "",
          borderStyle: "none",
          animation: useSelector(selectMute)
            ? "pulse 1.5s infinite alternate" // Apply the animation only when someCondition is true
            : "none", // No animation when someCondition is false
        }}
      >
        <img src={mute} alt="" className="w-12 absolute" />
      </ButtonStyle>
      {/* disabled until we fix the issue */}
      {/* <ButtonStyle
        onClick={() => {
          dispatch(setToggleScreen());
        }}
        style={{
          background: useSelector(selectToggleScreen) ? "#FF375E" : "",
          borderStyle: "none",
          animation: useSelector(selectToggleScreen)
            ? "pulse 1.5s infinite alternate" // Apply the animation only when someCondition is true
            : "none", // No animation when someCondition is false
        }}
      >
        <img src={cam} alt="" className="w-8" />
      </ButtonStyle> */}
      {/* hangup button */}
      <ButtonStyle
        style={{ background: "#FF375E", borderStyle: "none" }}
        onClick={handleHangUpClick}
      >
        <img src={phone} alt="" className="w-8" />
      </ButtonStyle>

      {/* patient record button */}
      {(patientId && lastOnGoingConsultation) && (
        <ButtonStyle
          style={{ background: "#ffa537", borderStyle: "none" }}
          onClick={handleOpenPatientRecord}
        >
          <span style={{ color: "white" }}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke-width="1.5"
              stroke="currentColor"
              className="w-6 h-6"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="M2.25 12.75V12A2.25 2.25 0 014.5 9.75h15A2.25 2.25 0 0121.75 12v.75m-8.69-6.44l-2.12-2.12a1.5 1.5 0 00-1.061-.44H4.5A2.25 2.25 0 002.25 6v12a2.25 2.25 0 002.25 2.25h15A2.25 2.25 0 0021.75 18V9a2.25 2.25 0 00-2.25-2.25h-5.379a1.5 1.5 0 01-1.06-.44z"
              />
            </svg>
          </span>
        </ButtonStyle>
      )}

      {/* consultation notes button */}
      {storedUser.roles[0].name == role.SPECIALIST && (
        <ButtonStyle
          style={{ background: "#5474f5", borderStyle: "none" }}
          onClick={handleOpenNote}
        >
          <img src={ThreeDots} />
        </ButtonStyle>
      )}

      {/* the other buttons */}
      {storedUser.roles[0].name !== role.SPECIALIST && (<>
        <img src={Separator} className="h-16 mt-8 mx-5" />
        <ButtonStyle
          onClick={() => { props.setIsShareScreen(!props.isShareScreen) }}

          style={{
            background: useSelector(selectMute) ? "#FF375E" : "",
            borderStyle: "none",
            animation: useSelector(selectMute)
              ? "pulse 1.5s infinite alternate" // Apply the animation only when someCondition is true
              : "none", // No animation when someCondition is false
          }}
        >
          <img src={Vector} alt="" className="w-9 absolute" />
        </ButtonStyle>

        <ButtonStyle

          style={{
            background: useSelector(selectMute) ? "#FF375E" : "",
            borderStyle: "none",
            animation: useSelector(selectMute)
              ? "pulse 1.5s infinite alternate" // Apply the animation only when someCondition is true
              : "none", // No animation when someCondition is false
          }}
        >
          <img src={Dot} alt="" className="w-9 absolute" />
        </ButtonStyle>
      </>)
      }
    </span>
  );
};

export default CallActions;
