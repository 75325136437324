import React from 'react'
import { useSelector } from 'react-redux'
import { RootState } from '../../../../store'
import Timer from "../../../common/Timer";
import { Link } from 'react-router-dom';

const ConsultationStatus = () => {

    const status = useSelector((state: RootState) => state.consultationStatus.status)
    const location = useSelector((state: RootState) => state.consultationStatus.locationStatus)
    const consultationCounter = useSelector((state: RootState) => state.timer.currentTime);


    return (<>
        {status == "starting" ?
            <div className="flex justify-end bg-white align-middle rounded-l-full" style={{
                position: 'relative',
                alignSelf: 'end',
                top: "-8rem",
                paddingLeft: '12px',
                padding: '5px 8px',
            }
            }>
                <Link to={location} type="button" className="text-white flex mt-1 bg-gradient-to-br from-[#FF375E] to-[#FFC061] hover:bg-gradient-to-bl focus: ring-4 focus: outline-none focus: ring-pink-200 dark: focus:ring-pink-800 font-medium rounded-full text-sm px-5 py-2.5 text-center mr-2 mb-2 ">
                    <svg className="w-5 h-5 text-white dark:text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 10 16" >
                        <path d="M3.414 1A2 2 0 0 0 0 2.414v11.172A2 2 0 0 0 3.414 15L9 9.414a2 2 0 0 0 0-2.828L3.414 1Z" />
                    </svg>
                    Reprendre la téléconsultation
                </Link>
                < div style={{ marginTop: '9px' }}>

                    <Timer start={consultationCounter}  />
                </div>
            </div>
            : ""}
    </>)
}

export default ConsultationStatus;