import { Fragment, useCallback, useEffect, useMemo, useState } from "react";
import { Listbox, Transition } from "@headlessui/react";
import { CheckIcon, ChevronUpDownIcon } from "@heroicons/react/20/solid";
import { ChevronDownIcon } from "@heroicons/react/24/solid";
import { getYears } from "../../../../../src/utils";
import { useDispatch, useSelector } from "react-redux";
import { Action, AnyAction } from "@reduxjs/toolkit";
import { SELECT_DATA_OF } from "../../../../enums/telemedecine";
import { getStations } from "../../../../../src/axiosAPI/Network";
import { SLICE } from "../../../../enums/store";

const people = [
  { name: "Wade Cooper" },
  { name: "Arlene Mccoy" },
  { name: "Devon Webb" },
  { name: "Tom Cook" },
  { name: "Tanya Fox" },
  { name: "Hellen Schmidt" },
];
export interface ISelectListBoxProps {
  name: string;
  label: string;
  placeholder: string;
  index?: number;
  dataState?: string;
  defaultValue?: any;
  actions?: any;
  data: any;
  style: string;
  useCase:any;
}
export default function SelectListBox({
  name,
  label,
  placeholder,
  dataState,
  defaultValue,
  useCase,
  actions,
  data,
  index,
  style,
}: ISelectListBoxProps) {
  const [selected, setSelected] = useState();
  const [fetchedData, setFetchedData] = useState<any>(null);
  const [insiderDefaulValue, setInsiderDefaulValue] = useState()
  const dispatch = useDispatch();
  const storedMedical = useSelector(
    (state: any) =>
      state.consultationData?.consultationData?.medicalHistory?.medical
  );

  const handleSelectedItem = (item: any) => {
    setSelected(item);
    actions?.map((action: any) => {
      
      dispatch(action(fetchedData?fetchedData.filter((dataItem:any)=>dataItem.name==item)[0]?.id:{ index: index, value: item }));
    });
  };
  const storedUser = useSelector((state: any) => state.auth.user);
  console.log(useCase);
  
  useMemo(()=>{
    switch(useCase){
      case SLICE.MEDICAUX:
        !defaultValue&& index&& storedMedical&&storedMedical[index]?.year&& setInsiderDefaulValue(storedMedical[index].year)
        break;
      }
  },[storedMedical])
  useMemo(() => {

    
    switch (dataState) {
      case SELECT_DATA_OF.STATIONS:
        if(storedUser?.center?.id) getStations(storedUser?.center?.id).then((res: any) => {
          const stations = res.data.results?.map((station: any) => {
            return {
              id: station.id,
              name: station.user.firstName + " " + station.user.lastName,
            };
          });

          setFetchedData(stations);
        });
        break;
      default:
        break;
    }
  }, [dataState]);

  useEffect(() => {
    !selected && defaultValue && setSelected(defaultValue);
  }, [defaultValue]);
  return (
    <Listbox
      defaultValue={selected ?? ""}
      value={selected}
      onChange={handleSelectedItem}
    >
      {label && <Listbox.Label>{label ?? ""}</Listbox.Label>}
      <div className="relative mt-4">
        <Listbox.Button
          placeholder={placeholder ?? ""}
          className="min-h-[2.25rem] relative w-full cursor-pointer rounded-lg bg-white py-2 pl-3 pr-10 text-left shadow-md focus:outline-none focus-visible:border-indigo-500 focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 focus-visible:ring-offset-teal-300 sm:text-sm"
        >
          <span className="block truncate">{selected}</span>
          <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
            <ChevronDownIcon
              className="h-5 w-5 text-gray-400"
              aria-hidden="true"
            />
          </span>
        </Listbox.Button>
        <Transition
          as={Fragment}
          leave="transition ease-in duration-100"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <Listbox.Options
            placeholder={placeholder ?? ""}
            className="absolute z-10 mt-1  max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm"
          >
            {(fetchedData ?? data)?.map((item: any, id: number) => (
              <Listbox.Option
                key={id}
                className={({ active }) =>
                  `cursor-pointer relative select-none py-2 pl-10 pr-4 min-h-[2.25rem]
        `
                }
                value={fetchedData ? item.name : item}
              >
                {({ selected }) => (
                  <>
                    <span
                      className={`block truncate ${
                        selected ? "font-medium" : "font-normal"
                      }`}
                    >
                      {fetchedData ? item.name : item}
                    </span>
                    {selected ? (
                      <span className="absolute inset-y-0 left-0 flex items-center pl-3 text-cyan-300">
                        <CheckIcon className="h-5 w-5" aria-hidden="true" />
                      </span>
                    ) : null}
                  </>
                )}
              </Listbox.Option>
            ))}
          </Listbox.Options>
        </Transition>
      </div>
    </Listbox>
  );
}
