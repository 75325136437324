import { Suspense, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Dialog, Spinner } from "@material-tailwind/react";
import DayPicker from "./DayPicker";
import {
  useGetDaysOfSpecialityQuery,
  useGetSpecialityQuery,
} from "../../features/specialitys/specialityApi";
import { useSearchPatientQuery } from "../../features/patient/patientApi";
import { useDebounce } from "use-debounce";
import {
  useAddAppointmentMutation,
  useGetSlotsforDayQuery,
  useLazyGetSlotsforDayQuery,
} from "../../features/Appointment/appointmentApi";
import SearchInput from "../common/SearchInput";
import { setIsModalOpen } from "../../features/Appointment/appointmentSlice";
import moment from "moment";
import { RootState } from "src/store";
import { Toaster, toast } from "react-hot-toast";
import { CiNoWaitingSign } from "react-icons/ci";
import Loader from "../common/Loader";

interface IProps {
  open: boolean;
  patientId?: number;
  handleOpen?: () => void;
}

export default function AppointementModal({
  open,
  patientId: patientIdParams,
  handleOpen,
}: IProps) {
  const center = useSelector((state: RootState) => state.auth.center);
  const box = useSelector((state: RootState) => state.auth.box);

  //Button save
  const [isDisableButton, setIsDisableButton] = useState(true);

  //Patient
  const [patientInput, setPatientInput] = useState("");
  const [patientId, setPatientId] = useState<number | undefined>(
    patientIdParams ? patientIdParams : undefined
  );
  const [debouncedValuePatient] = useDebounce(patientInput, 2000);
  const {
    data: patientData,
    isLoading: isLoadingPatientData,
    isFetching: isFetchingPatientData,
  } = useSearchPatientQuery(debouncedValuePatient);
  const [patientList, setPatientList] = useState([]);

  //Specialities
  const { data: specialitiesData } = useGetSpecialityQuery("");
  const [specialityId, setSpecialityId] = useState(1);

  //Reason
  const [reason, setReason] = useState("");

  //Dates
  const [availibleDays, setAvailibleDays] = useState<any[]>([]);
  const [selectedDate, setSelectedDate] = useState<Date | undefined>(undefined);
  const {
    data: daysDataBySpeciality,
    isLoading: isLoadingDates,
    isFetching: isFetchingDates,
    isSuccess: isSuccessLoadDays,
    isError: isErrorLoadDays,
    refetch: refetchDaysDataBySpeciality,
  } = useGetDaysOfSpecialityQuery(specialityId);

  //Slots
  const [availibleSlots, setAvailibleSlots] = useState<undefined | any>({
    slots: [],
    doctors: [],
  });
  const [selectedSlot, setSelectedSlot] = useState<any | undefined>(undefined);

  const [
    getSlotsForDayQyery,
    {
      data: slotsData,
      isLoading: isLoadingSlots,
      isFetching: isFetchingSlots,
      isError: isErrorLoadSlots,
      isSuccess: isSuccessLoadSlots,
    },
  ] = useLazyGetSlotsforDayQuery();

  //Doctors
  const [selectedDoctor, setSelectedDoctor] = useState<any | undefined>(
    undefined
  );

  //call save appointment api
  const [
    addAppointment,
    {
      isLoading: isLoadingAddAppointment,
      isSuccess: isSuccessAddApointment,
      isError: isErrorAddAppointemnt,
      error: errorAddApointment,
    },
  ] = useAddAppointmentMutation();

  const dispatch = useDispatch();

  //handlers
  // const handleOpen = () => {
  //   console.log("handle open ");
  // };

  const handleNewPatientSelected = (patient: any) => {
    console.log("taping input search patient", patient);
    setPatientId(patient.id);
  };

  const handleChangeSpeciality = (e: any) => {
    //clear states concerned
    setSelectedDate(undefined);
    setSelectedSlot(undefined);
    setAvailibleSlots({ slots: [], doctors: [] });
    setSelectedDoctor(undefined);
    refetchDaysDataBySpeciality();
    // set state of new speciality id
    const [specialityDetails] = specialitiesData?.filter(
      (sp: any) => sp.name === e.target.value
    );
    console.log("speciality Details", specialityDetails.id);
    setSpecialityId(specialityDetails.id);
  };

  const handleChangeSelectedDate = async (date: Date) => {
    //clear states concerned
    setSelectedSlot(undefined);
    setSelectedDoctor(undefined);
    //process: fetch data for new date and set state of new date
    setSelectedDate(date);
    const slots: any = await getSlotsForDayQyery({
      id: specialityId,
      day: moment(date).format("YYYY-MM-DD"),
    });
    console.log("Slot", slots.data);
    setAvailibleSlots(slots.data);
  };

  const handleSelectedSlot = (slot: any) => {
    console.log(slot);
    //clear state concerned
    setSelectedDoctor(undefined);
    //set state of new slot
    setSelectedSlot(slot);
  };

  const handleSaveAppointment = async () => {
    setIsDisableButton(true);
    const appointementInfo = {
      reason,
      startTime: selectedSlot.start,
      endTime: selectedSlot.end,
      patientId,
      centerId: center?.id,
      stationId: box?.id,
      doctorId: selectedDoctor?.id,
    };

    console.log("Appointment Info", appointementInfo);
    await addAppointment(appointementInfo);
    setIsDisableButton(false);
  };

  const clearStatesOfComponent = () => {
    dispatch(setIsModalOpen(false));
    setIsDisableButton(false);
    setSpecialityId(1);
    setPatientInput("");
    setPatientId(patientIdParams);
    setPatientList([]);
    setReason("");
    setAvailibleDays([]);
    setSelectedDate(undefined);
    setAvailibleSlots({ slots: [], doctors: [] });
    setSelectedSlot(undefined);
    setSelectedDoctor(undefined);
  };

  //Hook useEffect set states concerned with new Data
  useEffect(() => {
    console.log("patientIdParams", patientIdParams);
    setPatientId(patientIdParams);
  }, [patientIdParams]);

  useEffect(() => {
    if (patientData && patientData?.totalResults > 0) {
      const patientListMaped = patientData?.results?.map((patient: any) => {
        console.log(patient.user.firstName + " " + patient.user.lastName);
        return {
          id: patient.id,
          details:
            patient.user.firstName +
            " " +
            patient.user.lastName +
            (patient.cine !== null ? " (" + patient.cine + ")" : ""),
        };
      });
      setPatientList(patientListMaped);
    }
  }, [patientData]);

  useEffect(() => {}, [isLoadingPatientData, isFetchingPatientData]);

  useEffect(() => {
    console.log("days Data By Speciality", daysDataBySpeciality);
    setAvailibleDays(daysDataBySpeciality);
  }, [daysDataBySpeciality]);

  // hook for save Button
  useEffect(() => {
    console.log(
      " patientId specialityId selectedDate && selectedSlot && selectedDoctor && center?.id && box?.id",
      patientId,
      selectedDoctor,
      center?.id,
      box?.id
    );
    if (
      patientId &&
      specialityId &&
      selectedDate &&
      selectedSlot &&
      selectedDoctor &&
      center?.id &&
      box?.id
    ) {
      setIsDisableButton(false);
    } else setIsDisableButton(true);
  }, [patientId, specialityId, selectedDate, selectedSlot, selectedDoctor]);

  // handle Errors
  useEffect(() => {
    if (isErrorAddAppointemnt) {
      console.log("error add appointmnt", errorAddApointment.data.message);
      if (
        errorAddApointment?.data?.message.includes("doctor is already reserved")
      )
        toast.error("Ce Rendez-vous est déjà pris", {
          id: "1",
        });
      else if (
        errorAddApointment?.data?.message.includes("patient already booked")
      )
        toast.error("Le patient a deja un rdv le jour meme/meme temps ", {
          id: "1",
        });
      else
        toast.error(
          "Un problème technique est survenu. Veuillez réessayer ultérieurement.",
          {
            id: "1",
          }
        );
    }
    // setIsDisableButton(false);
  }, [isErrorAddAppointemnt]);

  useEffect(() => {
    if (
      patientId &&
      specialityId &&
      selectedDate &&
      selectedSlot &&
      selectedDoctor &&
      center?.id &&
      box?.id &&
      isSuccessAddApointment
    ) {
      toast.success("Le rendez-vous est pris avec succès", {
        id: "1",
      });
      clearStatesOfComponent();
    }

    // setIsDisableButton(false);
  }, [isSuccessAddApointment]);

  //React virtual DOM
  return (
    <Dialog
      placeholder=""
      open={useSelector((state: any) => state.appointment.isModalOpen)}
      handler={handleOpen}
      className="py-2 px-8"
      size="xl"
    >
      <Toaster position="top-left" reverseOrder={false} />

      {/* select fields  speciality - patient - dates - slots*/}
      <Suspense fallback={<Spinner />}>
        <h1 className="text-[#34b0d7] text-lg my-4 font-bold">
          Nouveau Rendez-vous
        </h1>
        <div>
          <div className="grid grid-cols-4 gap-10 mb-10">
            <div>
              {/* specilaity  */}
              <label htmlFor="" className="text-[#34b0d7]">
                Spécialité médicale:
              </label>
              <select
                className="w-full rounded-lg px-3"
                onChange={handleChangeSpeciality}
              >
                {specialitiesData?.map((speciality: any) => (
                  <option value={speciality.name}>{speciality.name}</option>
                ))}
              </select>
            </div>
            <div>
              {/* patient */}
              {!patientIdParams && (
                <>
                  <label htmlFor="" className="text-[#34b0d7]">
                    Patient concerné:
                  </label>
                  <SearchInput
                    patient={patientList}
                    onChange={(e) => setPatientInput(e.target.value)}
                    selectedPatient={handleNewPatientSelected}
                    isLoading={isLoadingPatientData || isFetchingPatientData}
                  />
                </>
              )}
            </div>

            {/* description message */}
            <div className="col-span-2">
              <label htmlFor="" className="text-[#34b0d7]">
                Correspondance médicale
              </label>
              <input
                onChange={(e) => setReason(e.target.value)}
                placeholder="Saisissez votre message au médecin spécialiste"
                name=""
                id=""
                className="w-full rounded-lg h-[2.6rem] border border-black px-3"
              />
            </div>
          </div>

          {/* dates */}
          <div className="grid grid-cols-3 gap-3 h-[21rem]">
            <div>
              <DayPicker
                days={availibleDays}
                isLoading={isFetchingDates}
                onChangeSelectedDate={(date) => handleChangeSelectedDate(date)}
              />
            </div>
            {/* slots */}
            <div className="flex-col">
              {selectedDate && (
                <span className="text-[#34b0d7] text-base">
                  Selectionner l’horaire pour la date:
                  {" " + moment(selectedDate).format("YYYY-MM-DD")}
                </span>
              )}
              {!isFetchingSlots && isSuccessLoadSlots && availibleSlots && (
                <div className="grid grid-cols-2 overflow-y-auto max-h-60 gap-2 cursor-pointer">
                  {console.log("availible slot", availibleSlots)}
                  {availibleSlots.slots?.length > 0 &&
                    availibleSlots?.slots
                      .map((s: any, index: number) => {
                        return { ...s, id: index };
                      })
                      .map((slot: string, index: number) => {
                        return (
                          <div
                            className={
                              "flex justify-center items-center h-10 border rounded-lg cursor-pointer" +
                              (selectedSlot &&
                                selectedSlot.id === index &&
                                " bg-[#34b0d7] text-white")
                            }
                            onClick={() => handleSelectedSlot(slot)}
                          >
                            <span>
                              {moment(slot.start).format("HH:mm") +
                                "  -  " +
                                moment(slot.end).format("HH:mm")}
                            </span>
                          </div>
                        );
                      })}
                  {selectedDate && availibleSlots?.slots.length === 0 && (
                    <div className="flex flex-col justify-center items-center h-full ">
                      <CiNoWaitingSign size={35} color="#1AA5C0" />
                      <p className="text-center">Aucun horaire disponible </p>
                    </div>
                  )}
                </div>
              )}
              {isFetchingSlots && (
                <div className="flex flex-col justify-center items-center self-center h-full">
                  <Spinner width={70} className="mx-auto" />
                  <span>Chargement des Horaires</span>
                  <span>disponibles</span>
                </div>
              )}
            </div>
            {/* doctors availible*/}

            <div className="flex flex-col overflow-y-auto h-96 justify-start items-center py-5 gap-3 cursor-pointer">
              {selectedSlot &&
                selectedSlot.doctors?.map((doctorId: number, index: number) => {
                  const doctorInfo = availibleSlots.doctors.filter(
                    (doc: any) => doc.id === doctorId
                  )[0];
                  return doctorInfo ? (
                    <div
                      onClick={() => {
                        setSelectedDoctor({
                          id: doctorId,
                          fullName:
                            doctorInfo.user.firstName +
                            " " +
                            doctorInfo.user.lastName,
                        });
                      }}
                      className={
                        "border border-gray-300 px-6 py-2 w-60 rounded-lg flex items-center justify-center cursor-pointer" +
                        (selectedDoctor &&
                          selectedDoctor.id &&
                          selectedDoctor.id === doctorId &&
                          " bg-[#34b0d7] text-white")
                      }
                    >
                      {doctorInfo.user.firstName +
                        " " +
                        doctorInfo.user.lastName}
                    </div>
                  ) : (
                    <div />
                  );
                })}
            </div>
          </div>
        </div>
      </Suspense>

      {/* briefing of appointement */}
      {!isDisableButton && (
        <div className="w-full h-10 bg-[#335D84] text-white mb-3 rounded-lg flex justify-start items-center pl-10">
          Details rendez-vous: Spécialité:{" "}
          {specialitiesData &&
            specialitiesData.filter(
              (speciality: any) => speciality.id === specialityId
            )[0]?.name}
          {selectedDate &&
            "   Date: " + moment(selectedDate).format("YYYY-MM-DD")}
          {selectedSlot &&
            "   Horaire: " +
              moment(selectedSlot.start).format("HH:mm") +
              "  -  " +
              moment(selectedSlot.end).format("HH:mm")}
          {selectedDoctor && "  Dr:  " + selectedDoctor.fullName}
        </div>
      )}
      <div className="flex justify-between ">
        <button
          className="border rounded-lg w-32 px-5 py-2 bg-[#849392] text-white cursor-pointer"
          onClick={clearStatesOfComponent}
        >
          Annuler
        </button>
        <button
          onClick={handleSaveAppointment}
          disabled={isDisableButton}
          className={
            isDisableButton
              ? "border rounded-lg w-32 px-5 py-2 text-white bg-[#849392]"
              : "border rounded-lg w-32 px-5 py-2  bg-[#1AA5C0] text-white"
          }
        >
          {isLoadingAddAppointment ? (
            <div className="w-full h-full grid place-content-center">
              <Spinner />
            </div>
          ) : (
            "Reserver"
          )}
        </button>
      </div>
    </Dialog>
  );
}
