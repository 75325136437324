import { useState, useEffect } from "react";
import {
  Button,
  Dialog,
  DialogBody,
  DialogFooter,
  DialogHeader,
} from "@material-tailwind/react";
import MySkinAiModal from "./MySkinAiModal";
import { ReactComponent as ExclamtionSvg } from "../../assets/exclamationSvg.svg";
import { toast } from "react-hot-toast";
import { NoStreamPropType } from "../../types";

export const NoStream = (props: NoStreamPropType) => {
  const {  deviceRef, toolsIndex, toolName } = props;
  const [isOpen, setIsOpen] = useState(false);
  const [max, setMax] = useState([]);
  const [startCapturing, setStartCapturing] = useState(false);
  const [capturedImages, setCapturedImages] = useState<File[]>([]);
  const [capturedImagesInterval, setCapturedImagesInterval] =
    useState<NodeJS.Timer | null>(null);

  useEffect(() => {
    if (max.length > 0) setIsOpen(true);
  }, [max]);

  const handleConfirm = () => {
    setIsOpen(!isOpen);
  };
  const handleConfirmOk = () => {
    setIsOpen(!isOpen);
  };

  const captureDeviceImage = async () => {
    if (!startCapturing) {
      if (capturedImages.length > 0) {
        if (
          confirm(
            "current Images will be deleted,if you're sure press enter to continue"
          )
        ) {
          setCapturedImages([]);
        }
      }
      setStartCapturing(!startCapturing);
      const intervalId = setInterval(() => {
        console.log("props*", props);
        const stream = deviceRef.current.srcObject;
        const track = stream?.getVideoTracks()[0];
        const imageCapture = new ImageCapture(track);
        imageCapture.takePhoto().then((blob) => {
          const file = new File(
            [blob],
            // `${checkedDevice ? checkedDevice : "screenShot"}-${Date.now()}.png`,
            `${(toolName && toolName !== "") ?? "screenShot"
            }-${Date.now()}.jpeg`,
            {
              type: "image/jpeg",
            }
          );
          setCapturedImages((prevState) => [...prevState, file]);
        });
      }, 1000);
      setCapturedImagesInterval(intervalId);
    } else {
      if (capturedImagesInterval) {
        setStartCapturing(!startCapturing);
        //clear interval
        clearInterval(capturedImagesInterval);
        setCapturedImagesInterval(null);
      }
    }
  };
  // const lastImage =
  //   capturedImages.length > 0
  //     ? URL.createObjectURL(capturedImages[capturedImages.length - 1])
  //     : null;
  return (
    <>
      <div className="flex flex-wrap justify-center  gap-2 bg-[#F1F1F1] rounded-md max-w-[30%] relative">
        <div className="flex justify-center  gap-2 bg-[#F1F1F1] absolute top-6 rounded-md max-w-[90%]" >
          <ExclamtionSvg />
          <p>Cliquer sur l’icône du dispositif pour commencer</p>
          { (toolsIndex && (toolsIndex !== 5 && toolsIndex !==0)) && (
            //TODO: reuse the exit button giving text
            <button
              onClick={() => captureDeviceImage()}
              className={`bg-[#1AA5C0] text-white text-center py-3 w-[10rem] rounded-sm cursor-pointer disabled:bg-[#D7D7D7] disabled:cursor-not-allowed ${startCapturing ? "animate-pulse bg-red-500" : null
                }`}
            >
              {startCapturing ? "Arrêter la capture" : "Capture d'image"}
            </button>
          )}
        </div>

        <MySkinAiModal
          capturedImages={capturedImages}
          clickedDeviceIndex={toolsIndex}
          fileType={toolName}
        />
      </div>

      <Dialog size="md" open={isOpen} handler={handleConfirm}>
        <DialogHeader className=" h-10 text-primary text-md">
          Résultats de l'analyse
        </DialogHeader>
        <DialogBody
          className="py-6 text-[#0F2552] font-semibold text-sm"
          divider
        >
          {max.map((item: any) => (
            <>item</>
          ))}
        </DialogBody>
        <DialogFooter className="">
          <Button
            // variant="text"
            color="red"
            className="mr-1 bg-[#858585]"
            onClick={handleConfirm}
          >
            <span>Annuler</span>
          </Button>
          <Button
            className="bg-secondary"
            color="teal"
            onClick={handleConfirmOk}
          >
            <span>Confirmer</span>
          </Button>
        </DialogFooter>
      </Dialog>
    </>
  );
};
