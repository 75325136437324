export enum SELECT_DATA_OF {
  HOSPITAL = "hospital",
  DOCTOR = "doctor",
  SPECIALITY = "speciality",
  MEDICATIONS="medications",
  ANALYSE="analyse",
  IMAGING="imaging",
  STATIONS="stations",
  PATIENT="patient",
  DIAGNOSTIC_ASSOCIE='diagnosticAssocie'
}
