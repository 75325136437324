export function getFormattedDateTime() {
  // Array of day names
  const dayNames = [
    "dimanche",
    "lundi",
    "mardi",
    "mercredi",
    "jeudi",
    "vendredi",
    "samedi",
  ];

  // Array of month names
  const monthNames = [
    "janvier",
    "février",
    "mars",
    "avril",
    "mai",
    "juin",
    "juillet",
    "août",
    "septembre",
    "octobre",
    "novembre",
    "décembre",
  ];

  // Get the current date
  const currentDate = new Date();

  // Get the day of the week (0-6)
  const dayIndex = currentDate.getDay();

  // Get the day of the month (1-31)
  const day = currentDate.getDate();

  // Get the month (0-11)
  const monthIndex = currentDate.getMonth();

  // Create the formatted date string
  const formattedDate =
    dayNames[dayIndex] + " " + day + " " + monthNames[monthIndex];

  // Get the hours (0-23)
  const hours = currentDate.getHours();

  // Get the minutes (0-59)
  const minutes = currentDate.getMinutes();

  // Format the hours and minutes
  const formattedTime =
    ("0" + hours).slice(-2) + ":" + ("0" + minutes).slice(-2);

  // Return the formatted date and time as an array
  return [formattedDate, formattedTime];
}
export function getYears() {
  const currentYear = new Date().getFullYear();

  let yearOptions: [number] = [new Date().getFullYear()];
  for (let i = 1; i < 65; i++) {
    const year: number = currentYear - i;
    yearOptions.push(year);
  }
  return yearOptions;
}

export function updateTime() {
  const currentTime = new Date();
  const minutes = currentTime.getMinutes().toString().padStart(2, "0");
  const seconds = currentTime.getSeconds().toString().padStart(2, "0");
  const formattedTime = `${minutes}:${seconds}`;

  // Update the display
  return formattedTime;
}

export function parseQueryString(url: string): Record<string, string> {
  const queryString = url.substring(url.indexOf("?") + 1);

  const params: any = {};

  if (queryString) {
    const queryParams = queryString.split("&");

    for (const param of queryParams) {
      const [key, value] = param.split("=");

      params[key] = value;
    }
  }

  return params;
}

export function areArraysOfObjectsEqual(array1: any, array2: any) {
  if (array1.length !== array2.length) {
    return false;
  }

  for (let i = 0; i < array1.length; i++) {
    const obj1 = array1[i];
    const obj2 = array2[i];

    // Compare properties of obj1 and obj2
    for (const key in obj1) {
      if (obj1[key] !== obj2[key]) {
        return false;
      }
    }
  }

  return true;
}
export function clearLocalStorageItemsWithPrefix(prefix: string) {
  const keysToRemove = [];

  for (let i = 0; i < localStorage.length; i++) {
    const key = localStorage.key(i);

    if (key && key.startsWith(prefix)) {
      keysToRemove.push(key);
    }
  }

  // Remove the items after collecting all the keys to remove
  keysToRemove.forEach((key) => {
    localStorage.removeItem(key);
  });
}

export const removeNullProperties = (obj: any) => {
  const newObj: any = {};

  for (const [key, value] of Object.entries(obj)) {
    if (value && typeof value === "object") {
      const newNestedObj: any = {};
      for (const [keyNested, nestedValue] of Object.entries(value)) {
        if (nestedValue !== null) {
          newNestedObj[keyNested] = nestedValue;
        }
      }

      return newObj;
    }
    if (value !== null) {
      newObj[key] = value;
    }
  }

  return newObj;
};
