import React, { useEffect, useState } from "react";
import styled from "styled-components";
import SearchBar from "./Searchbar";
import Table from "./Table";
import { useQuery } from "react-query";
import { getPatient } from "../../axiosAPI/Network";
import Loader from "../common/Loader";
import SearchIcon from "../../assets/searchicon.svg";
import {
  SearchPatientProv,
  useMyContext,
} from "../../Providers/SearchPatientProv";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../store";
import { fetchPatientsRequest } from "../../features/patient/patientSlice";
import { updateStatusConsultation } from "../../features/consultationStatus/consultationStatusSlice";
import { CONSULTATION_STATUSES } from "../../enums/consultation";
import { role } from "../../enums/app";

export const GlobalStyle = styled.div`
  background: white;
  border-top-left-radius: 7px;
  border-top-right-radius: 7px;
  height: 100%;
  overflow-y: auto;
`;

export const GlobalContent = styled.div`
  width: 100%;
  padding: 2rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  box-sizing: border-box;
  height: 100%;
  .title {
    font-family: "Ubuntu";
    font-style: normal;
    font-weight: 500;
    font-size: 35.0127px;
    line-height: 17px;
    display: flex;
    align-items: center;
    color: #50799e;
  }
`;

const WrapperTable = styled.div`
  background: rgba(249, 249, 249, 0.27);
  border: 1.45967px solid #858585;
  border-radius: 10.2177px;
  padding: 10px;
  flex-grow: 1;
  max-height: 650px;
  overflow-y: scroll;
  &::-webkit-scrollbar {
    width: 8px;
    right: 5px;
    margin: 10px;
  }

  &::-webkit-scrollbar-track {
    background: #f1f1f1;
    border-radius: 14.4896px;
    right: 5px;
    margin: 10px;
  }

  &::-webkit-scrollbar-thumb {
    background: #c1c1c1;
    border-radius: 14.4896px;
    right: 5px;
    margin: 10px;
  }
`;

const PaginationStyle = styled.div`
  display: flex;
  justify-content: end;
  align-items: center;
  background: #ffffff;
  box-shadow: 0px 1.30033px 5.20132px rgba(0, 0, 0, 0.12);
  border-radius: 5.20132px;
  padding: 0.5rem;
  .btns {
    display: flex;
    gap: 0.2rem;
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    font-size: 15.9559px;
    button {
      /* background-color: red; */
      width: 25px;
      aspect-ratio: 1;
      cursor: pointer;
      font-family: "Inter";
      color: #5c6265;
    }
    .actv-btn {
      background: #ffffff;
      box-shadow: 0px 1.46287px 5.85148px rgba(26, 26, 67, 0.1);
      border-radius: 5.85148px;
    }
    .next {
      width: 25px;
      aspect-ratio: 1;
      background: #1aa5c0;
      border-radius: 5.85148px;
      text-align: center;
      color: white;
      cursor: pointer;
    }
  }
`;
const Imgicon = styled.img`
  width: 56.433px;
  height: 56.433px;
  flex-shrink: 0;
`;

const SearchPatientIndex = () => {
  let context: any = useMyContext();
  console.log(context);

  const dispatch = useDispatch();
  const storedUser = useSelector((state: RootState) => state.auth.user);

  const header =
    storedUser?.roles[0].name == role.HOST
      ? [
          "N°CINE",
          "Prénom",
          "Nom",
          "Sexe",
          "Date de naissance",
          "Couverture",
          "Dérnière mise à jour",
          "Actions",
        ]
      : [
          "N°CINE",
          "Prénom",
          "Nom",
          "Sexe",
          "Date de naissance",
          "Couverture",
          "Dérnière mise à jour",
        ];
  const { patients, isLoading, error } = useSelector(
    (state: RootState) => state.patient
  );

  useEffect(() => {
    dispatch(fetchPatientsRequest());
    dispatch(updateStatusConsultation(CONSULTATION_STATUSES.ENDED));
  }, [dispatch]);
  // const consultationCounter = useSelector((state: RootState) => state.timer.currentTime);

  // if (context.isError) {
  //   return <div>Error fetching data</div>;
  // }
  if (context.isLoading) {
    return <Loader />;
  }
  const [activeBtn, setActiveBtn] = useState(1);

  const handlePageChange = (e: any) => {
    console.log(e);
    context.setCurrentPage(e);
  };

  return (
    <>
      <GlobalStyle>
        <GlobalContent>
          <div className="flex items-center gap-3 mt-5 mb-3">
            <div className="title">
              {/* <Imgicon
                src={SearchIcon}
                alt=""
                style={{ marginRight: "20px" }}
              />{" "} */}
              Recherche de patients
            </div>
            <span className="text-lg text-gray-500">
              <em>
                <strong>{`(${context?.patientdata?.data?.totalResults} patients trouvés)`}</strong>
              </em>
            </span>
          </div>
          <div className="flex">
            <SearchBar />
          </div>
          {context.patientdata && !context.isError ? (
            <>
              <WrapperTable>
                <Table header={header} data={context.patientdata} />
              </WrapperTable>
              <PaginationStyle>
                <div className="btns">
                  {activeBtn > 1 && ( // Show the previous button if activeBtn is greater than 1
                    <div
                      className="previous"
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        setActiveBtn(activeBtn - 1);
                        handlePageChange(activeBtn + 1);
                      }}
                    >
                      &#x3c;
                    </div>
                  )}
                  {context.pages?.map(
                    (btn: number) =>
                      (btn === activeBtn ||
                        btn === activeBtn - 1 ||
                        btn === activeBtn + 1) && (
                        <button
                          onClick={() => {
                            setActiveBtn(btn);
                            handlePageChange(btn);
                          }}
                          className={`${btn === activeBtn ? "actv-btn" : ""}`}
                          key={btn}
                        >
                          {btn}
                        </button>
                      )
                  )}
                  {activeBtn < context.pages.length && ( // Hide the next button if activeBtn is the last page
                    <div
                      className="next"
                      onClick={() => {
                        setActiveBtn(activeBtn + 1);
                        handlePageChange(activeBtn + 1);
                      }}
                    >
                      &#x3e;
                    </div>
                  )}
                </div>
              </PaginationStyle>
            </>
          ) : (
            <Loader />
          )}
        </GlobalContent>
      </GlobalStyle>
    </>
  );
};
export default SearchPatientIndex;
