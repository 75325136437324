import React, { useEffect, useState } from "react";
import SpeechRecognition, {
  useSpeechRecognition,
} from "react-speech-recognition";
import { setSpecialistConclusion } from "../../features/specialistConclusion/specialistConclusionSlice";
import { useDebounce } from "./useDebounce";
import { useDispatch } from "react-redux";
import { MicSpan } from "../../StyledComponents";
import { useUpdateConsultationDataMutation } from "../../features/consultationData/consultationDataApi";
import toast from "react-hot-toast";

const Transcript = (props: any) => {
  const {
    toggle,
    setToggle,
    finalConsultationText,
    setFinalConsultationText,
    textareaValue,
    setTextareaValue,
    consultationId,
    specialistId,
    canReload,
    setCanReload,
  } = props;


  let {
    transcript,
    listening,
    resetTranscript,
    interimTranscript,
    finalTranscript,
    browserSupportsSpeechRecognition,
  } = useSpeechRecognition({
    clearTranscriptOnListen: true,
  });
  const [note, setNote] = useState<string>("");
  const debouncedNote = useDebounce(note, 2500);
  const dispatch = useDispatch();

  const [
    setConsultationData,
    { isLoading: isPatchLoading, isSuccess: isPatchSuccess },
  ] = useUpdateConsultationDataMutation();

  if (!browserSupportsSpeechRecognition) {
    return <span>Browser doesn't support speech recognition.</span>;
  }

  useEffect(() => {
    console.log("Speech recognition", transcript);

    setNote(textareaValue + " " + transcript);
    setTextareaValue(textareaValue + " " + transcript);
    setCanReload(false);
    const mainReportElement = document.getElementById("mainReport");
    if (mainReportElement) {
      mainReportElement.style.height = mainReportElement.scrollHeight + "px";
    }
    resetTranscript();
  }, [finalTranscript]);

  useEffect(() => {
    dispatch(setSpecialistConclusion(debouncedNote));
    if (debouncedNote?.trim() != "") {
      setConsultationData(
        {
          id: consultationId,
          body: {
            specialistConclusion: debouncedNote,
            specialist: { id: specialistId },
            indexStep: 7,
          },
        },
        1
      );

      setCanReload(true);
      if (isPatchSuccess) {
        setCanReload(true);
        toast.success("Compte rendu enregistré avec succès");
      }
    }
  }, [debouncedNote]);

  useEffect(() => {
    if (toggle) {
      SpeechRecognition.startListening({
        continuous: true,
        language: "fr-FR",
        interimResults: true,
      });
    } else {
      SpeechRecognition.stopListening();
    }
  }, [toggle]);
  return (
    <>
      {navigator.onLine ? (
        <>
          {/* {" "}
          <p>
            {" "}
            Microphone:
            <span
              className={`justify-center font-bold ${
                listening ? "text-green-500" : "text-red-500"
              }`}
            >
              {" "}
              {listening ? "ON" : "OFF"}
            </span>
          </p> */}
          <span className="flex ">
            {toggle && (
              <span className="animate-ping absolute inline-flex right-[12px] top-[12px] rounded-full bg-red-400 opacity-75 w-[38px] h-[38px]"></span>
            )}
            <MicSpan
              className={`${
                toggle
                  ? "relative inline-flex rounded-full  bg-red-500"
                  : "relative inline-flex rounded-full bg-gray-300"
              }`}
              onClick={() => {
                setToggle(!toggle);
              }}
            >
              <svg
                fill="#000000"
                height="800px"
                width="800px"
                version="1.1"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 512 512"
                enable-background="new 0 0 512 512"
                className={`${toggle ? "activeSvg" : "inactiveSvg"}`}
              >
                <g>
                  <g>
                    <path d="m439.5,236c0-11.3-9.1-20.4-20.4-20.4s-20.4,9.1-20.4,20.4c0,70-64,126.9-142.7,126.9-78.7,0-142.7-56.9-142.7-126.9 0-11.3-9.1-20.4-20.4-20.4s-20.4,9.1-20.4,20.4c0,86.2 71.5,157.4 163.1,166.7v57.5h-23.6c-11.3,0-20.4,9.1-20.4,20.4 0,11.3 9.1,20.4 20.4,20.4h88c11.3,0 20.4-9.1 20.4-20.4 0-11.3-9.1-20.4-20.4-20.4h-23.6v-57.5c91.6-9.3 163.1-80.5 163.1-166.7z" />
                    <path d="m256,323.5c51,0 92.3-41.3 92.3-92.3v-127.9c0-51-41.3-92.3-92.3-92.3s-92.3,41.3-92.3,92.3v127.9c0,51 41.3,92.3 92.3,92.3zm-52.3-220.2c0-28.8 23.5-52.3 52.3-52.3s52.3,23.5 52.3,52.3v127.9c0,28.8-23.5,52.3-52.3,52.3s-52.3-23.5-52.3-52.3v-127.9z" />
                  </g>
                </g>
              </svg>
            </MicSpan>
          </span>

          {/* <button
              onClick={() => {
                if (browserSupportsSpeechRecognition) {
                  SpeechRecognition.startListening({
                    continuous: true,
                    language: "fr-FR",
                    interimResults: true,
                  });
                } else {
                  alert("Speech recognition not supported on this browser");
                }
              }}
              className="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 w-24 rounded-full"
            >
              Start
            </button>
            <button
              onClick={() => {
                SpeechRecognition.stopListening();
              }}
              className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 w-24 rounded-full"
            >
              Stop
            </button> */}
          {/* <button
            onClick={resetTranscript}
            className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded-full"
          >
            Reset
          </button>     */}

          {toggle && <p className="text-gray-400">{interimTranscript}</p>}
        </>
      ) : (
        <span> you're OFFline the speech recongnition won't be available</span>
      )}
    </>
  );
};
export default Transcript;
