// import { Accordion, AccordionBody } from "@material-tailwind/react";
import React, { useEffect, useState } from "react";
import { ReactComponent as DropClosed } from "../../../assets/dropClosed.svg";
import { ReactComponent as DropOpen } from "../../../assets/dropOpen.svg";
import moment from "moment";

type MedicationProps = {
  name: string;
  startDate: string;
  noon?: number,
  night?: number,
  morning?: number,
  period?: string;
  endDate: string;
  status: boolean;
  instruction: string;
  info: string;
  ix?: number;
};
const Medications = ({
  name,
  startDate,
  endDate,
  noon, morning, evening,
  period,
  status,
  instruction,
  info, 
  ix,
}: MedicationProps) => {
  const [open, setOpen] = useState(false);
  const [index, setIndex] = useState(0);

  const [formattedStartDate, setFormattedStartDate] = useState("");
  const [formattedEndDate, setFormattedEndDate] = useState("");

  useEffect(()=> {
    if(startDate) {
      const s = moment(startDate);
      setFormattedStartDate(s.format("DD/MM/YYYY"))
      setFormattedEndDate(s.add(period, 'days').format("DD/MM/YYYY").toString())
    
    }

  }, [startDate])

  return (
    <div className="w-full flex flex-col">
      {ix === 0 ? (
        <header className="h-14 grid grid-cols-12 justify-center items-center ">
          <span className="text-primary text-lg font-bold   col-span-6">
            Traitements prescrits{" "}
          </span>
          <span className="text-primary text-lg font-bold   col-span-2">
            Date de début
          </span>
          <span className="text-primary text-lg font-bold   col-span-2">
            Date de fin
          </span>
          <span className="text-primary text-lg font-bold   col-span-2">
            Prises
          </span>
        </header>
      ) : (
        <></>
      )}
      <div className=" p-4 grid grid-cols-12 justify-center items-center bg-[#F4F4F4] rounded-md px-2 ">
        <span className="font-bold text-[#5E5E5E] col-span-6 ">{name}</span>
        <span className="col-span-2 text-[#5E5E5E] font-medium">{formattedStartDate} </span>
        
        <span className="col-span-2 text-[#5E5E5E] font-medium">{formattedEndDate} </span>

        <span className="col-span-2 text-[#5E5E5E] font-medium">
        {(morning && morning > 0) ? `${morning} matin`: ""}
        {(noon && noon > 0) ? `, ${noon} midi`: ""}
        {(evening && evening > 0) ? `, ${evening} le soir.`: ""}
        </span>
     

        {open ? (
          <span
            className="col-span-1 flex justify-end text-[#5E5E5E]"
            onClick={() => setOpen(!open)}
          >
            <DropOpen />
          </span>
        ) : (
          <span
            className="col-span-1 flex justify-end text-[#5E5E5E]"
            onClick={() => setOpen(!open)}
          >
            <DropClosed />
          </span>
        )}

        {open && (
          <>
            <div className="col-span-2 text-secondary font-semibold ">
              {instruction}
            </div>
            <div className="col-span-10 text-secondary">{info}</div>
          </>
        )}
        {/* <div className={`${open ? "none" : "h-0"} col-span-12`}>
          Lorem ipsum dolor sit amet consectetur adipisicing elit. Aliquam neque
          quod iusto fuga provident ad dicta ratione mollitia? Eos reiciendis
          iste atque. Corrupti non debitis cupiditate blanditiis modi maiores
          quia.
        </div> */}
      </div>
      {/* <Accordion open={open}>
          <AccordionBody>
            We're not always in the position that we want to be at. We're
            constantly growing. We're constantly making mistakes. We're
            constantly trying to express ourselves and actualize our dreams.
          </AccordionBody>
        </Accordion> */}
      {/* </div> */}
    </div>
  );
};

export default Medications;
