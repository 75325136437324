import { IStepItem } from "@interfaces/Forms";
import { Stepper, Step } from "@material-tailwind/react";

import styled from "styled-components";
import { useDispatch, useSelector } from "react-redux";
import {
  setPreviousStep,
  setCurretStep,
  setIsFirstStep,
  setIsLastStep,
} from "../../features/currentStep/currentStepSlice";
import { useGetConsultationDataQuery } from "../../features/consultationData/consultationDataApi";
import { useParams } from "react-router-dom";
import { useEffect, useMemo, useState } from "react";
import {
  clearConsultationData,
  clearDiagnostics,
  setAllergy,
  setConsultationData,
  setDiagnosticPrincipale,
  setDiagnosticSecondaire,
  setFamilyOthers,
  setFamilySelected,
  setMedicalMotifs,
  setMedicalYear,
  setMedicationConsults,
  setMotifsData,
  setSurgicalMotifs,
  setSurgicalYear,
  setToxicsAlcohol,
  setToxicsOthers,
  setToxicsTabacBoxPerYear,
  setToxicsTabacCigarttesPerDay,
  setToxicsTabacDuration,
  setToxicsTabacType,
} from "../../features/consultationData/consultationDataSlice";
import { CheckIcon } from "@heroicons/react/24/solid";
import { getChiefComplaint, getConsultation } from "../../axiosAPI/Network";
import { setIsMedicals } from "../../features/medical/medicalSlice";
import { setIsSurgicals } from "../../features/surgical/surgicalSlice";
import { setIsAllergic } from "../../features/allergy/allergySlice";
import { setisTabacConsuming } from "../../features/isTabacConsuming/isTabacConsumingSlice";
import { setIsAlcoholic } from "../../features/alcoholic/alcoholSlice";
import { sethasOtherSubs } from "../../features/hasOtherSubs/hasOtherSubsSlice";
import {
  clearDiagnosticDisplayData,
  setDiagnosticAssocie,
  setDiagnosticPrincip,
} from "../../features/diagnosticAssocie/diagnosticAssocieSlice";
import { setCompteRendu } from "../../features/compteRendu/compteRenduSlice";
import { setMedicationConsultationData } from "../../features/medicationConsults/medicationConsultsSlice";
import { setAnalysesRadioData } from "../../features/analysesRadio/analysesRadioSlice";
import { setBioAnalysesData } from "../../features/bioAnalyses/bioAnalysesSlice";
import {
  removeUnnecessaryFieldsAnalyses,
  removeUnnecessaryFieldsImagings,
  removeUnnecessaryFieldsMedications,
} from "../../../src/utils/medicationDetails";

import { removeNullProperties } from "../../../src/utils";
import { CHIEF_COMPLAINT_TYPE } from "../../enums/chiefComplaintConsultations";
import { setMotifs, setMotifsFetched } from "../../features/motifs/motifsSlice";

export interface IStepsProps {
  items: Array<IStepItem>;
  loadingConsultationData: (arg: boolean) => void;
  movingToAnotherStep: () => void;
}
const StepsWrapper = styled.div`
  margin: 0;
  padding: 0;
`;

export default function Steps({
  items,
  loadingConsultationData,
  movingToAnotherStep,
}: IStepsProps) {
  const currentStep = useSelector(
    (state: any) => state.currentStep.currentStep
  );
  const { consultationId } = useParams();
  const dispatch = useDispatch();
  const [isCompteRenduSet, setisCompteRenduSet] = useState(false);
  const newChiefComplaints: any = null;
  const [isCCFetched, setIsCCFetched] = useState(false);
  const consultationData = useSelector((state: any) => state.consultationData);
  const {
    data: consultationQueriedData,
    refetch: refetchConsultation,
    isFetching: isFetchingConsultationData,
    isLoading: isloadingConsultationData,
  } = useGetConsultationDataQuery(Number(consultationId));
  const [stepsStates, setStepsStates] = useState(null);
  const [fetchedMedicalHistory, setFetchedMedicalHistory] = useState<any>();

  useEffect(() => {
    loadingConsultationData(
      isloadingConsultationData || isFetchingConsultationData
    );
  }, [isloadingConsultationData, isFetchingConsultationData]);

  const dispatchMedicalHistory = (fetchedMedicalHistory: any) => {
    console.log("medical history from server", fetchedMedicalHistory);
    if (fetchedMedicalHistory?.medical?.length > 0) {
      dispatch(setIsMedicals(true));
      fetchedMedicalHistory?.medical.map((medical: any, index: number) => {
        medical?.motif?.map((medicalMotif: any) => {
          dispatch(
            setMedicalMotifs({
              index,
              value: medicalMotif,
            })
          );
        });
        medical?.year &&
          dispatch(
            setMedicalYear({
              index: index,
              value: medical?.year,
            })
          );
      });
    }
    if (fetchedMedicalHistory?.surgical?.length > 0) {
      dispatch(setIsSurgicals(true));
      fetchedMedicalHistory?.surgical.map((surgical: any, index: number) => {
        surgical?.motif?.map((surgicalMotif: any) => {
          dispatch(
            setSurgicalMotifs({
              index: index,
              value: surgicalMotif,
            })
          );
        });
        surgical?.year &&
          dispatch(
            setSurgicalYear({
              index: index,
              value: surgical?.year,
            })
          );
      });
    }

    if (fetchedMedicalHistory?.family) {
      if (fetchedMedicalHistory?.family?.selected) {
        fetchedMedicalHistory?.family?.selected?.map(
          (selected: any, index: number) => {
            dispatch(setFamilySelected(selected));
          }
        );
      }
      if (fetchedMedicalHistory?.family?.others) {
        fetchedMedicalHistory?.family?.others?.map(
          (other: any, index: number) => {
            dispatch(setFamilyOthers(other));
          }
        );
      }
    }
    if (fetchedMedicalHistory?.toxic) {
      if (fetchedMedicalHistory?.toxic?.tabac) {
        dispatch(setisTabacConsuming(true));

        fetchedMedicalHistory?.toxic?.tabac.boxPerYear &&
          dispatch(
            setToxicsTabacBoxPerYear(
              fetchedMedicalHistory?.toxic?.tabac.boxPerYear
            )
          );
        fetchedMedicalHistory?.toxic?.tabac.duration &&
          dispatch(
            setToxicsTabacDuration(fetchedMedicalHistory?.toxic?.tabac.duration)
          );
        fetchedMedicalHistory?.toxic?.tabac.perDay &&
          dispatch(
            setToxicsTabacCigarttesPerDay(
              fetchedMedicalHistory?.toxic?.tabac.perDay
            )
          );
        fetchedMedicalHistory?.toxic?.tabac.type &&
          dispatch(
            setToxicsTabacType(fetchedMedicalHistory?.toxic?.tabac.type)
          );
      }
      if (fetchedMedicalHistory?.toxic?.alcohol) {
        console.log("alcohol");
        const isAlcoholic = fetchedMedicalHistory?.toxic?.alcohol?.isAlcoholic;
        dispatch(setIsAlcoholic(isAlcoholic));
        dispatch(setToxicsAlcohol(isAlcoholic));
      }
      if (fetchedMedicalHistory?.toxic?.others) {
        dispatch(sethasOtherSubs(true));
        dispatch(setToxicsOthers(fetchedMedicalHistory?.toxic?.others.nature));
      }
    }
    if (fetchedMedicalHistory?.allergy) {
      dispatch(setIsAllergic(true));
      fetchedMedicalHistory?.allergy?.map((alergy: any, index: number) => {
        dispatch(setAllergy(alergy));
      });
    }
  };
  const displayDiagnostics = () => {
    // dispatch(clearDiagnostics());
    // dispatch(clearDiagnosticDisplayData());
    if (consultationQueriedData?.chiefComplaintConsultations?.length > 0) {
      const newChiefComplaints =
        consultationQueriedData?.chiefComplaintConsultations.map(
          (chiefComplaint: any) => {
            return {
              data: chiefComplaint.chiefComplaint,
              type: chiefComplaint.type,
            };
          }
        );
      newChiefComplaints?.length > 0 &&
        newChiefComplaints.map((chiefComp: any, index: number) => {
          if (chiefComp.type === CHIEF_COMPLAINT_TYPE.PRIMARY) {
            dispatch(setDiagnosticPrincipale(chiefComp.data.id));
            dispatch(setDiagnosticPrincip(chiefComp.data.name));
          }
          if (chiefComp.type === CHIEF_COMPLAINT_TYPE.SECONDARY) {
            dispatch(setDiagnosticSecondaire(chiefComp.data.id));
            dispatch(setDiagnosticAssocie(chiefComp.data.name));
          }
        });
    }
  };

  useMemo(() => {
    console.log("consultation quiered data ", consultationQueriedData);
    if (consultationQueriedData?.motifComplaint?.length > 0) {
      console.log(
        "consultationQueriedData?.motifComplaint",
        consultationQueriedData?.motifComplaint
      );
      dispatch(setMotifsData(consultationQueriedData?.motifComplaint));
      dispatch(setMotifsFetched(consultationQueriedData?.motifComplaint));
      // dispatch(setMotifs(consultationQueriedData?.motifComplaint));
    }
    if (consultationQueriedData?.medicationConsultations?.length > 0) {
      dispatch(
        setMedicationConsultationData(
          /** delete consultationId  && empty values*/
          removeUnnecessaryFieldsMedications(
            consultationQueriedData?.medicationConsultations
          )
        )
      );
      dispatch(
        setMedicationConsults(
          /** delete consultationId  && empty values*/
          removeUnnecessaryFieldsMedications(
            consultationQueriedData.medicationConsultations
          )
        )
      );
    }
    if (consultationQueriedData?.analyseConsultations?.length > 0) {
      dispatch(
        /** delete consultationId  && empty values*/

        setBioAnalysesData(
          removeUnnecessaryFieldsAnalyses(
            consultationQueriedData.analyseConsultations
          )
        )
      );
      dispatch(
        setMedicationConsults(
          removeUnnecessaryFieldsImagings(
            consultationQueriedData.analyseConsultations
          )
        )
      );
    }
    if (consultationQueriedData?.imagingConsultations?.length > 0) {
      dispatch(
        setAnalysesRadioData(consultationQueriedData.imagingConsultations)
      );
      dispatch(
        setMedicationConsults(consultationQueriedData.imagingConsultations)
      );
    }
    if (consultationQueriedData?.medicalHistory) {
      dispatchMedicalHistory(consultationQueriedData.medicalHistory);
    }
    consultationQueriedData?.steps &&
      setStepsStates(consultationQueriedData.steps);

    /** delete all fields nul */
    const filteredConsultationData = consultationQueriedData
      ? removeNullProperties(consultationQueriedData)
      : {};

    !consultationData.consultationData &&
      dispatch(
        setConsultationData({
          ...consultationData,
          consultationData: filteredConsultationData,
        })
      );

    consultationQueriedData?.chiefComplaintConsultations &&
      displayDiagnostics();
    consultationQueriedData?.conclusion &&
      dispatch(setCompteRendu(consultationQueriedData?.conclusion));
  }, [consultationQueriedData]);

  useMemo(() => {
    const res =
      consultationData?.consultationData?.currentStep &&
      currentStep != consultationData.consultationData.currentStep;
    if (res) {
      dispatch(setCurretStep(consultationData.consultationData.currentStep));
    }
  }, [consultationData?.consultationData?.currentStep]);

  const fetchStepsStates = async () => {
    const response = await getConsultation(Number(consultationId));

    try {
      response?.data?.medicalHistory &&
        setFetchedMedicalHistory(response?.data?.medicalHistory ?? {});
      setStepsStates(response.data.steps);
    } catch (error) {
      // Handle error
      console.error("Error fetching steps states data:", error);
    }
  };

  useEffect(() => {
    fetchStepsStates();
    if (currentStep == 5 && !newChiefComplaints) {
      getChiefComplaint(Number(consultationId))
        .then((res: any) => {
          const newChiefComplaints = res.data.chiefComplaintConsultations.map(
            (chiefComplaint: any) => {
              return {
                data: chiefComplaint.chiefComplaint,
                type: chiefComplaint.type,
              };
            }
          );
          // dispatch(clearDiagnostics());
          // dispatch(clearDiagnosticDisplayData());
          newChiefComplaints?.length > 1 &&
            newChiefComplaints.map((chiefComp: any, index: number) => {
              // console.log(chiefComp);
              if (chiefComp.type == "primary") {
                dispatch(setDiagnosticPrincipale(chiefComp.data.id));
                dispatch(setDiagnosticPrincip(chiefComp.data.name));
              }
              if (chiefComp.type == "secondary") {
                dispatch(setDiagnosticSecondaire(chiefComp.data.id));
                dispatch(setDiagnosticAssocie(chiefComp.data.name));
              }
            });
        })
        .finally(() => setIsCCFetched(true));
    }

    consultationQueriedData?.conclusion?.length > 0 &&
      dispatch(setCompteRendu(consultationQueriedData?.conclusion));
    // consultationQueriedData?.medicalHistory &&
    //   dispatchMedicalHistory(consultationQueriedData.medicalHistory);

    console.log("changing Steps refretch consultation");
    refetchConsultation();
  }, [currentStep]);

  useEffect(() => {
    refetchConsultation();
  }, [currentStep]);

  useEffect(() => {
    dispatchMedicalHistory(fetchedMedicalHistory);
  }, [fetchedMedicalHistory]);

  const currentStepState = useSelector(
    (state: any) => state.currentStep.currentStep
  );
  return (
    <>
      <Stepper
        activeStep={useSelector((state: any) => state.currentStep.currentStep)}
        lineClassName="opacity-0"
        isLastStep={() => {
          currentStepState == 6 && dispatch(setIsLastStep());
        }}
        isFirstStep={() => {
          currentStepState == 0 && dispatch(setIsFirstStep());
        }}
        className="w-[90%] m-auto mb-10 color-[#1AA5C0] text-[#1AA5C0]  "
      >
        {items.map((item, index) => {
          return (
            <Step
              onClick={() => {
                // console.log("set current step by index", currentStep, index);
                movingToAnotherStep();
                dispatch(setPreviousStep(currentStep));
                dispatch(setCurretStep(index));
              }}
              className="cursor-pointer "
              activeClassName="bg-[#20A8D3] ring-[#49b6d870]"
              completedClassName={
                stepsStates && stepsStates[index] === 1
                  ? "bg-[#25c371e2]"
                  : "bg-[#B0BEC5] "
              }
              key={item.title}
            >
              {stepsStates && stepsStates[index] === 1 && (
                <CheckIcon className="w-6 text-white" />
              )}
              <div className="absolute  text-sm font-thin text-[#1AA5C0] top-[2.8rem] max-w-[10rem] 2xl:w-max text-center ">
                {item.title}
              </div>
            </Step>
          );
        })}
      </Stepper>
      <StepsWrapper>
        {items.map((item, index: number) => {
          return item.id ==
            useSelector((state: any) => state.currentStep.currentStep) ? (
            <div key={index}>{item.component}</div>
          ) : null;
        })}
      </StepsWrapper>
    </>
  );
}