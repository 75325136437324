// CRUD axios for vital signs
// Path: src\axiosAPI\params.ts
// Compare this snippet from src\axiosAPI\Network.ts:

import APP_CONSTANTS from "../constants";
import { vitalSignsType } from "@interfaces/baseParams";
import instance from "./Network";

export const postVitalSigns = async (
  data: vitalSignsType,
  patientId: number
  // consultationId: number
) => {
  const doctorId = window.localStorage.getItem(
    `${APP_CONSTANTS.LOCALSTORAGE_PREFIX}doctorId`
  );
  const token = window.localStorage.getItem(
    `${APP_CONSTANTS.LOCALSTORAGE_PREFIX}token`
  );
  const endPointData = {
    weight: data.weight,
    height: data.height,
    temperature: data.temp,
    sys: data.sys,
    dia: data.dia,
    pulse: data.rc,
    spo2: data.oxy,
    bloodSugar: data.glycemi,
    respiratoryRythm: data.rr,
    fatMass: data.SMM,
    leanMass: data.graisse,
    patientId: patientId,
    doctorId: parseInt(doctorId!),
    // consultationId: consultationId,
  };
  try {
    const res = await instance.post(`/vitalSigns`, endPointData);
    // TODO: `based on status return a response
    return res.status === 201;
  } catch (error) {
  }
};

export const getVitalSigns = async (patientId: number) => {
  try {
    const res = await instance.get(
      `/vitalSigns?where={"patientId":${patientId}}`
    );
    return res.data;
  } catch (error) {
  }
};

//get specialite of doctor with id
export const getDoctorSpecialite = async (doctorId: number) => {
  try {
    const res = await instance.get(
      `${process.env.REACT_APP_SERVER_URL}/doctor/${doctorId}?include=speciality`
    );
    return res.data?.speciality;
  } catch (error) {
  }
};
export {};
