import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import APP_CONSTANTS from "../../constants";
import { CallState } from "../../enums/call";

const callApi: any = createApi({
  reducerPath: "callApi",
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_SERVER_URL,
    prepareHeaders: (headers) => {
      headers.set(
        "Authorization",
        `Bearer ${window.localStorage.getItem(
          `${APP_CONSTANTS.LOCALSTORAGE_PREFIX}token`
        )}`
      );
      return headers;
    },
  }),
  endpoints: (builder) => ({
    getCall: builder.query<[], number>({
      query: (id) =>
        `call/${id}?include=consultation.doctor.user,user,station.center`,
    }),
    getConsultationOngoingCall: builder.query<[], number>({
      query: (id) =>
        `call?where={"consultationId":${id}, "status":"joined"}&include=consultation.doctor.user,user,station.center`,
    }),

    updateCall: builder.mutation({
      query: ({ id, body }) => ({
        url: `call/${id}`,
        method: "PATCH",
        body,
      }),
    }),
  }),
});

export const {
  useGetCallQuery,
  useGetConsultationOngoingCallQuery,
  useUpdateCallMutation,
} = callApi;

export default callApi;
