
import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import APP_CONSTANTS from "../../constants";
import { useSelector } from "react-redux";
import { RootState } from "src/store";

const token = localStorage.getItem(`${APP_CONSTANTS.LOCALSTORAGE_PREFIX}token`);


const staticDataApi = createApi({
    reducerPath: "staticDataApi",
    baseQuery: fetchBaseQuery({
        baseUrl: process.env.REACT_APP_SERVER_URL,
        prepareHeaders: (headers) => {
            headers.set(
              "Authorization",
              `Bearer ${window.localStorage.getItem(
                `${APP_CONSTANTS.LOCALSTORAGE_PREFIX}token`
              )}`
            );
            return headers;
          },
    }),

    endpoints: (builder) => ({
        // GET 
        getMedications: builder.query<any, { search?: string, source?: 'ANAM' | 'DMP' | 'VIDAL' }>({
            query: ({ search, source }) => {

                let q = `/medication?take=5000&`;


                if (source) q += `where={"source":${source}}&`;
                if (search) q += `search=${search}&`

                return q
            },
            transformResponse: (response: any) => response.results,



        }),

        getMedicalActs: builder.query<any, { search?: string }>({
            query: ({ search }) => {

                let q = `/medicalact?take=5000&`;


                if (search) q += `search=${search}`

                return q
            },
            transformResponse: (response: any) => response.results,



        }),
        getRegions: builder.query<any, { search?: string }>({
            query: ({ search }) => {

                let q = `/region?include=provinces.cities.centers.stations&take=5000&where={"provinces":{"some":{"cities":{"some":{"centers":{ "some":{}}}}}}}`;
                if (search) q += `search=${search}`

                return q
            },
            transformResponse: (response: any) => response.results,



        }),
        getCenters: builder.query<any, { regionId?: number | null }>({
            query: ({ regionId }) => {

                let q = `/center?take=5000`;
               q += `&include=stations.user&where={"city":{"province":{"region":{"id":${regionId}}}}}`

                return q
            },
            transformResponse: (response: any) => response.results,



        }),
   
     

    }),
});

export const {
    useGetMedicationsQuery,
    useLazyGetMedicationsQuery,
    useGetMedicalActsQuery,
    useGetRegionsQuery,
    useGetCentersQuery
    

 } = staticDataApi;

export default staticDataApi;
