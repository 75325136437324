// api.ts
import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import APP_CONSTANTS from "../../constants";
import { api } from "../../features/api/api";

const API_BASE_URL = "/api"; // Adjust this based on your API

interface IPatient {}
const token = localStorage.getItem(`${APP_CONSTANTS.LOCALSTORAGE_PREFIX}token`);

export const patientApi = api.injectEndpoints({
  endpoints: (builder) => ({
    fetchPatients: builder.query<IPatient[], void>({
      query: () => "/patient",
    }),
    fetchPatient: builder.mutation<any, number>({
      query: (patientId) => `/patient/${patientId}?include=user`,
    }),
    searchPatient: builder.query<IPatient[], string>({
      query: (searchStr) => `/patient?search=${searchStr}&include=user`,
    }),
    createPatient: builder.mutation<IPatient, Partial<IPatient>>({
      query: (newPatient) => ({
        url: "/patient",
        method: "POST",
        body: newPatient,
      }),
    }),
    updatePatient: builder.mutation<
      IPatient,
      { id: number; data: Partial<IPatient> }
    >({
      query: ({ id, data }) => ({
        url: `/patient/${id}`,
        method: "PUT",
        body: data,
      }),
    }),
    deletePatient: builder.mutation<void, number>({
      query: (patientId) => ({
        url: `/patient/${patientId}`,
        method: "DELETE",
      }),
    }),
  }),
});

export const {
  useFetchPatientsQuery,
  useFetchPatientMutation,
  useCreatePatientMutation,
  useSearchPatientQuery,
  useUpdatePatientMutation,
  useDeletePatientMutation,
} = patientApi;

export default patientApi;
