import { Switch } from "@material-tailwind/react";
import { ReactComponent as ThermoSvg } from "../../assets/thermo.svg";
import { useEffect, useState } from "react";
import { ReactComponent as PressionSvg } from "../../assets/pressionSvg.svg";
import { ReactComponent as OxymetreSvg } from "../../assets/oxymetreSvg.svg";
import { ReactComponent as PoidsSvg } from "../../assets/poidsSvg.svg";
import CardWithSingleValue from "./CardWithSingleValue";
import CardWithTowValues from "./CardWithTowValues";
import { useAgentSocket } from "../../services/socketIOContext";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { ToastOptions } from "react-hot-toast";
import { vitalSignsType } from "@interfaces/baseParams";
import { toastConstraint } from "../../helper/mediaDeviceHelper";
import { baseParamsMaxValues, baseParamsMinValues } from "../../constants";
import { useDispatch, useSelector } from "react-redux";
import { updateBaseParams } from "../../features/Examen/baseParamsSlice";
import { RootState } from "../../store";

const PeriphiriqueLeft = () => {
  const [tempValue, setTempValue] = useState(0);
  const [oxymetreValue, setOxymetreValue] = useState(0);
  const [pressionValue, setPressionValue] = useState(0);
  const [poidsValue, setPoidsValue] = useState(0);
  const [tailleValue, setTailleValue] = useState(0);
  const [imcValue, setImcValue] = useState(0);
  const [systoliqueValue, setSystoliqueValue] = useState(0);
  const [diastoliqueValue, setDiastoliqueValue] = useState(0);
  const [selectedDevice, setSelectedDevice] = useState<string | null>(null);
  const [gettingData, setGettingData] = useState(false);
  const [myCharacteristic, setMyCharacteristic] = useState<any>(null);
  const [cancelCheck, setCancelCheck] = useState(false);
  const baseParamsData = useSelector(
    (state: RootState) => state.baseParamsData
  );


  const {
    agentSocket,
    oxymeterCallBackStatus,
    nibpCallBackStatus,
    availableSensors,
  } = useAgentSocket();

  const dispatch = useDispatch();
  useEffect(() => {
    // Dispatch action when oxymetreValue changes
    dispatch(updateBaseParams({ messure: "oxy", value: oxymetreValue }));
  }, [oxymetreValue, dispatch]);

  useEffect(() => {
    // Dispatch action when oxymetreValue changes
    dispatch(updateBaseParams({ messure: "temp", value: tempValue }));
  }, [tempValue, dispatch]);

  useEffect(() => {
    // Dispatch action when oxymetreValue changes
    dispatch(updateBaseParams({ messure: "weight", value: poidsValue }));
  }, [poidsValue, dispatch]);

  useEffect(() => {
    // Dispatch action when oxymetreValue changes
    dispatch(updateBaseParams({ messure: "height", value: tailleValue }));
  }, [tailleValue, dispatch]);

  useEffect(() => {
    // Dispatch action when oxymetreValue changes
    dispatch(updateBaseParams({ messure: "imc", value: imcValue }));
  }, [imcValue, dispatch]);

  useEffect(() => {
    console.log("systoliqueValue", systoliqueValue);

    // Dispatch action when oxymetreValue changes
    dispatch(updateBaseParams({ messure: "sys", value: systoliqueValue }));
  }, [systoliqueValue, dispatch]);

  useEffect(() => {
    // Dispatch action when oxymetreValue changes
    dispatch(updateBaseParams({ messure: "dia", value: diastoliqueValue }));
  }, [diastoliqueValue, dispatch]);

  useEffect(() => {
    if (agentSocket) {
      if (selectedDevice === "oxy") {
        agentSocket?.emit("spo2_cmd");
        agentSocket?.on("spo2", (data: any) => {
          const parse = JSON.parse(data);
          if (
            parse.spo2_status === oxymeterCallBackStatus?.No_finger_inserted ||
            parse.spo2_status ===
              oxymeterCallBackStatus?.SPO2_is_abnormal_timeOut
          ) {
            toast.warn("👈🏼", toastConstraint(toast.POSITION.BOTTOM_CENTER));
            console.log("sorry no finger inserted [OFF]");
            // disableOxymeter();
            agentSocket.off();
            setGettingData(false);
            setSelectedDevice(null);
          } else {
            setGettingData(true);
            console.log(parse);
            setOxymetreValue(parse.saturation);
          }
        });
      }
      if (selectedDevice === "nibp") {
        agentSocket.emit("nibp_cmd", JSON.stringify({ patientMode: "1" }));
        agentSocket?.on("nibp", (data: any) => {
          const { nibp_status, sys_pressure, dia_pressure } = JSON.parse(data);
          console.log("[nibp]: ", JSON.parse(data));
          if (
            nibp_status === nibpCallBackStatus?.cuf_leak_or_unattached ||
            nibp_status === nibpCallBackStatus?.disturb_detected
          ) {
            console.log("please put your arm first in the cuff [OFF]");
            toast.warn(
              "Veuillez maintenir votre bras stable 💪🏽",
              toastConstraint(toast.POSITION.BOTTOM_CENTER)
            );
            setGettingData(false);
            agentSocket.off();
            setSelectedDevice(null);
          } else {
            setGettingData(true);
            setSystoliqueValue(sys_pressure);
            setDiastoliqueValue(dia_pressure);
            if (sys_pressure !== 0 && sys_pressure !== 0) {
              setSelectedDevice(null);
              setGettingData(false);
            }
          }
        });
      }
      if (selectedDevice === "temp") {
        agentSocket?.emit("temp_cmd");
        agentSocket?.on("temp", (data: any) => {
          // setTempValue(data);
          const tempValue = JSON.parse(data);
          console.log(tempValue);
        });
      }
      //else if (!selectedDevice) {
      //   console.log("selectedDevice", selectedDevice);
      // }
    }
    if (selectedDevice === "weight_height") {
      agentSocket?.emit("height_cmd", JSON.stringify({ patientMode: "1" }));
      // agentSocket?.on("height_cmd", (data: any) => {
      //   // setTempValue(data);
      //   const weightValue = JSON.parse(data);
      //   console.log(weightValue);
      // });
   
        getWeightData();
 
    }
    return () => {
      console.log("selectedevice [clearnUp fonction]", selectedDevice);
      if (selectedDevice === "oxy") {
        disableOxymeter;
      }
      if (selectedDevice === "nibp") {
        disableNibp();
      }
      if (selectedDevice === "weight_height") {
        disableWeights();
      }
    };
  }, [selectedDevice, agentSocket, nibpCallBackStatus, oxymeterCallBackStatus]);

  const disableNibp = () => {
    //TODO: add a side pop up that indicates the possible problems with the nibp
    console.log("off nibp");
    agentSocket?.emit("nibp_cmd_off");
    agentSocket?.off("nibp_cmd");
    agentSocket?.off("nibp_cmd_off");
  };

  const disableWeights = () => {
    stopNotifications();
    agentSocket?.emit("height_cmd_off");
    agentSocket?.off("height");
    agentSocket?.off("height_cmd_off");
    console.log("socket weight height offs");
  };

  const disableOxymeter = () => {
    agentSocket?.off("spo2_cmd");
    agentSocket?.emit("spo2_cmd_off");
    agentSocket?.off("spo2_cmd_off");
  };

  useEffect(() => {
    return () => {
      console.log("[leftParamsData]", baseParamsData);
    };
  }, []);

  useEffect(() => {
    if (cancelCheck) {
      console.log("cancelCheck", cancelCheck);
      if (selectedDevice === "nibp") {
        disableNibp();
      }
      if (selectedDevice === "weight_height") {
        disableWeights();
      }
      if (selectedDevice === "oxy") {
        disableOxymeter();
      }
      setSelectedDevice(null);
      setGettingData(false);
      setCancelCheck(false);
    }
  }, [cancelCheck]);
  const getWeightData = () => {
    onBLEStart();
    setGettingData(true);
    // agentSocket!.emit("height_cmd", JSON.stringify({ patientMode: "1" }));
    // socket.on("weight", (data) => {
    //   console.log(data);
    //   setEquipement({
    //     ...equipement,
    //     weight: data,
    //   });
    // });
    agentSocket!.on("height", (data: any) => {
      console.log("height", data);
      //   setEquipement({
      //     ...equipement,
      //     ...data,
      //   });
      setTailleValue(data.height);
    });
  };

  const optionsBLE = {
    // filters: [{ services: [serviceUuid] }],
    filters: [{ services: ["weight_scale"] }, { namePrefix: "MI_SCALE" }],
  };

  async function onBLEStart() {
    let serviceUuid = "weight_scale";
    let characteristicUuid = "weight_measurement";

    try {
      console.log("Requesting Bluetooth Device...");
      const device = await navigator?.bluetooth.requestDevice(optionsBLE);

      console.log("Connecting to GATT Server...");
      const server = await device.gatt.connect();

      console.log("Getting Service...");
      const service = await server.getPrimaryService(serviceUuid);

      console.log("Getting Characteristic ...");
      const Chracteristics = await service.getCharacteristic(
        characteristicUuid
      );

      setMyCharacteristic(Chracteristics);

      console.log("myCharacteristic", Chracteristics);

      await Chracteristics.startNotifications();

      console.log("> Notifications started");
      Chracteristics.addEventListener(
        "characteristicvaluechanged",
        handleNotifications
      );
    } catch (error) {
      stopNotifications();
      console.log("Argh! " + error);
    }
  }

  async function stopNotifications() {
    setGettingData(false);
    setSelectedDevice(null);
    console.log("socket weight height off");
    console.log("myCharacteristic", myCharacteristic, "stopNotifications");
    if (myCharacteristic) {
      try {
        await myCharacteristic.stopNotifications();
        console.log("> Notifications stopped");
        myCharacteristic.removeEventListener(
          "characteristicvaluechanged",
          handleNotifications
        );
      } catch (error) {
        console.log("Argh! " + error);
      }
    }
  }

  function handleNotifications(event: any) {
    let value = event.target.value;
    // let a = [];
    //console.log(event);
    // Convert raw data bytes to hex values just for the sake of showing something.
    // In the "real" world, you'd use data.getUint8, data.getUint16 or even
    // TextDecoder to process raw data bytes.
    // for (let i = 0; i < value.byteLength; i++) {
    //   a.push("0x" + ("00" + value.getUint8(i).toString(16)).slice(-2));
    // }
    // console.log("> " + a.join(" "));

    // const last = value.getUint8(value.byteLength - 1);
    // const beforeLast = value.getUint8(value.byteLength - 2);
    // console.log("last", last, "beforeLast", beforeLast);
    // console.log("result", hexToDec(last) * 256 + hexToDec(beforeLast) / 200);
    // setEquipement({ ...equipement, weight: computeData(value) });s
    const weightData = computeData(value);
    setPoidsValue(weightData);
  }

  const computeData = (data: any) => {
    const buffer = new Uint8Array(data.buffer);
    //console.log("buffer", buffer);
    // const ctrlByte1 = buffer[1];
    // const stabilized = ctrlByte1 & (1 << 5);
    const weight = ((buffer[2] << 8) + buffer[1]) / 200;
    console.log("Weight", weight);
    //if the weight value came 3 times in a row, then we can consider it as stable
    // if (stabilized) {
    //   console.log("Weight", weight);
    //   setWeight(weight);
    // }

    return weight;
  };

  // useEffect for weight and height calculations
  useEffect(() => {
    if (poidsValue && tailleValue) {
      const imc = poidsValue / Math.pow(tailleValue / 100, 2);
      setImcValue(parseFloat(imc.toFixed(2)));
    }
  }, [poidsValue, tailleValue]);

  // useEffect(() => {
  // console.log("vitalSignChanged", vitalSignIndex);
  //   console.log("[leftParamsData]", baseParamsData);
  //   console.log("availableSensors", availableSensors);
  // }, [oxymetreValue]);

  return (
    <div className=" w-90% m-auto grid gap-x-20 gap-y-4 grid-cols-12 ">
      <div className="col-span-5">
        <CardWithSingleValue
          title="Température"
          icon={<ThermoSvg />}
          sign="&#8451;"
          id="temp"
          sensorExists={availableSensors.includes("temp")}
          value={tempValue}
          setValue={setTempValue}
          gettingData={gettingData}
          setGettingData={setGettingData}
          maxValue={baseParamsMaxValues.TEMP}
          minValue={baseParamsMinValues.TEMP}
          setCancelCheck={setCancelCheck}
        />
      </div>
      <div className="col-span-7">
        <CardWithTowValues
          title="Tension artérielle"
          icon={<PressionSvg />}
          sign={[
            {
              sign: "mmHg",
              name: "Systolique",
              value: systoliqueValue,
              cmpntId: selectedDevice,
              gettingData: gettingData,
              sensorExists: availableSensors.includes("nibp"),
              setValue: setSystoliqueValue,
              setCmpntId: setSelectedDevice,
              setGettingData: setGettingData,
              maxValue: baseParamsMaxValues.SYS,
              minValue: baseParamsMinValues.SYS,
              setCancelCheck: setCancelCheck,
            },
            {
              sign: "mmHg",
              name: "Diastolique",
              value: diastoliqueValue,
              setValue: setDiastoliqueValue,
              gettingData: gettingData,
              setGettingData: setGettingData,
              maxValue: baseParamsMaxValues.DIA,
              minValue: baseParamsMinValues.DIA,
              sensorExists: availableSensors.includes("nibp"),
            },
          ]}
          id="nibp"
          escapeLastValue={false}
        />
      </div>
      <div className="col-span-5">
        <CardWithSingleValue
          title="Saturation O2"
          icon={<OxymetreSvg />}
          sign="%"
          id="oxy"
          value={oxymetreValue}
          setValue={setOxymetreValue}
          setCmpntId={setSelectedDevice}
          gettingData={gettingData}
          setGettingData={setGettingData}
          cmpntId={selectedDevice}
          sensorExists={availableSensors.includes("oxy")}
          maxValue={baseParamsMaxValues.SATURATION}
          minValue={baseParamsMinValues.SATURATION}
          setCancelCheck={setCancelCheck}
        />
      </div>
      <div className="col-span-7">
        <CardWithTowValues
          title="Poids et taille"
          icon={<PoidsSvg />}
          sign={[
            {
              sign: "Kg",
              name: "Poids",
              value: poidsValue,
              gettingData: gettingData,
              cmpntId: selectedDevice,
              sensorExists: true,
              setValue: setPoidsValue,
              setCmpntId: setSelectedDevice,
              setGettingData: setGettingData,
              maxValue: baseParamsMaxValues.POIDS,
              minValue: baseParamsMinValues.POIDS,
              setCancelCheck: setCancelCheck,
            },
            {
              sign: "Cm",
              name: "Taille",
              value: tailleValue,
              setValue: setTailleValue,
              gettingData: gettingData,
              setGettingData: setGettingData,
              sensorExists: availableSensors.includes("height"),
              maxValue: baseParamsMaxValues.TAILLE,
              minValue: baseParamsMinValues.TAILLE,
            },
            {
              sign: "Kg/m²",
              name: "IMC",
              value: imcValue,
              setValue: setImcValue,
              gettingData: gettingData,
              setGettingData: setGettingData,
              sensorExists: false,
            },
          ]}
          escapeLastValue={true}
          id="weight_height"
        />
      </div>
    </div>
  );
};

export default PeriphiriqueLeft;
