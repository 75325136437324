import { Button, Chip } from "@material-tailwind/react";
import { ReactComponent as Doc } from "../../../assets/doc.svg";
import { ReactComponent as Doc1 } from "../../../assets/doc2.svg";
import { ReactComponent as NotFound } from "../../../assets/fileNotFound.svg";
import { ReactComponent as Right } from "../../../assets/right.svg";
import React, { Dispatch, SetStateAction, useEffect, useMemo } from "react";
import { truncate } from "../../../services/utils.service";
import { Divider } from "antd";
type Props = {
  date: string;
  name: string;
  speciality: string;
  specialist: object | null;
  specialistConclusion: string | null;
  conclusion: string;
  setPage: (n: number) => void;
  setOverView: Dispatch<SetStateAction<boolean>>;
  setItem: Dispatch<SetStateAction<{}>>;
  item: any;
};
const Rendu = ({
  date,
  name,
  speciality,
  conclusion,
  specialist,
  specialistConclusion,
  item,
  setPage,
  setOverView,
  setItem,
}: Props) => {
  const fileCount = useMemo(() => item?.attachements?.length, []);
  useEffect(() => {
    console.log("-----", specialist)
  }, [conclusion])
  return (

    <div
      onClick={() => {
        setOverView(true);
        setPage(2);
        setItem(item);
        console.log("clicked");
      }}
      className="cursor-pointer  relative h-[183px] rounded-md border-2 flex  justify-between items-center py-2"
    >
      <div className="  absolute left-0 h-[9.5rem]   w-[5px] ml-1 items-center rounded-md bg-[#1AA5C0] "></div>

      <div className="ml-8  h-full flex flex-col justify-start items-start gap-2 w-72">
        <Chip
          value={date}
          style={{
            fontSize: "21px",
            borderRadius: "100px",
            background: "rgba(31, 131, 131, 0.1)",
            color: "#1AA5C0",
            fontWeight: "normal",
            marginBottom: "12px",
          }}
        />

        <div className="flex">
          {/* generalist */}
          <div className="flex flex-col">
            <span className=" text-xl   font-semibold text-[#1AA5C0]">{name}</span>
            <span className="text-[22.98px] font-thin">{speciality}</span>
          </div>
          {/* generalist */}

          {/* specialist */}
          {specialist &&
            <>
              <Divider type="vertical" dashed={true} className="bg-green-400" style={{ height: "100%", width: "5px" }} />
              <div className="flex flex-col">

                <span className=" text-xl   font-semibold text-[#1AA5C0]">{`${specialist?.user?.firstName} ${specialist?.user?.lastName}`}</span>
                <span className="text-[22.98px] font-thin">{specialist?.speciality?.name}</span>
              </div>

            </>}
          {/* specialist */}

        </div>
      </div>
      <div className="flex  flex-col h-[150px] flex-1  justify-start items-start gap-4 ">
        <span className="text-[22.98px] text-[#5E5E5E] font-semibold">
          {!specialist && <span className="bg-blue-100 text-blue-800 text-xl font-large mr-2 px-2.5 py-0.5 rounded-full dark:bg-blue-900 dark:text-blue-300">{`consultation locale`}</span>}
          {specialist && <span className="bg-green-100 text-green-800 text-xl font-large mr-2 px-2.5 py-0.5 rounded-full dark:bg-green-900 dark:text-green-300">{`Télé-expertise`}</span>}
        </span>
        <div className="flex gap-4">
          {/* generalist report */}
          <span>
            {conclusion && <>{truncate(conclusion)}</>}

            {
              (!conclusion && specialist) && (<span className="text-red-400">Compte rendu géneraliste non disponible</span>)
            }
          </span>

          {/* end generalist report */}
          <Divider type="vertical" dashed={true} className="bg-green-400" style={{ height: "100%", width: "5px" }} />
            {/* specialist report */}
          <span className="">

            {specialistConclusion && <>{truncate(specialistConclusion)}</>}
            {
              (!specialist && !specialistConclusion) && (<span className="text-red-400">Compte rendu spécialiste non disponible</span>)
            }
          </span>
            {/* end specialist report */}

        </div>
      </div>
      <div className="flex   flex-col h-[150px] justify-start items-center gap-2 ">
        <span className="text-[22.98px] text-[#5E5E5E] font-semibold">
          Documents
        </span>
        <span className="flex flex-col   justify-center items-center flex-wrap w-[150px]  gap-2">
          {item?.attachements.length > 0 ? (
            <>
              {item.attachements
                .slice(0, 2)
                .map((attachment: any, index: number) => (
                  <span
                    className="bg-[#D7E9E9] rounded-[5px] text-[#1AA5C0] flex justify-center items-center h-8 w-full"
                    key={index}
                  >
                    {attachment?.name.length
                      ? attachment?.name.slice(0, 15) + "..."
                      : attachment?.path.slice(0, 15) + "..."}
                  </span>
                ))}
              {item.attachements.length > 2 && (
                <span className="bg-[#EFEFEF] rounded-[5px] gap-2 text-[#5E5E5E] flex justify-center items-center h-8 w-full">
                  <span className="text-[#1AA5C0]">
                    +{item.attachements.length - 2}
                  </span>{" "}
                  Fichiers
                </span>
              )}
            </>
          ) : (
            <div className="mt-4 flex flex-col justify-center items-center">
              <NotFound className="w-[35px] h-[35px]" />
              <span className="text-primary/60">Aucune pièce jointe</span>
            </div>
          )}
        </span>
      </div>
      <span className="mx-4">
        <Right />
      </span>
    </div>
  );
};

export default Rendu;
