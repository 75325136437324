import {
  setToxicsTabacBoxPerYear,
  setToxicsTabacCigarttesPerDay,
  setToxicsTabacDuration,
} from "../../../../../../features/consultationData/consultationDataSlice";
import * as React from "react";
import { useDispatch, useSelector } from "react-redux";

export interface IActifProps {}

export default function Actif(props: IActifProps) {
  const dispatch = useDispatch();
  const [selectedTabac, setSelectedTabac] = React.useState<number>(0);
  const [isTabacConsuming, setIsTabacConsuming] =
    React.useState<boolean>(false);
  const [cigarettePerDay, setcigarettePerDay] = React.useState<any>();
  const [totalPaquets, setTotalPaquets] = React.useState<number>();
  const storedTabacData = useSelector(
    (state: any) =>
      state.consultationData?.consultationData.medicalHistory?.toxic?.tabac
  );

  React.useMemo(() => {
    cigarettePerDay > 0 &&
      dispatch(
        setToxicsTabacBoxPerYear(
          Math.ceil((Number(cigarettePerDay) * 365) / 20)
        )
      );
    setTotalPaquets(Math.ceil((Number(cigarettePerDay) * 365) / 20));
  }, [cigarettePerDay]);

  return (
    <div className="w-full flex flex-col items-center justify-start gap-3 ">
      <div>
        <h1 className="text-[#5E5E5E] text-sm mb-1 mt-2">
          Cigarettes par jour
        </h1>
        <div
          className="input border p-1 flex items-
                     border-[#5E5E5E] rounded-md w-full"
        >
          <input
            type="number"
            defaultValue={storedTabacData?.perDay}
            onChange={(e) => {
              dispatch(setToxicsTabacCigarttesPerDay(e.target.value));
              setcigarettePerDay(e.target.value);
            }}
            className="border-none w-[100%] outline-none focus:border-none"
          />
          <span className="text-[#1AA5C0] text-sm font-[500] ">Jours</span>
        </div>
      </div>
      <div>
        <h1 className="text-[#5E5E5E] text-sm mb-1 mt-2">Durée</h1>
        <div
          className="input border p-1 flex items-
                     border-[#5E5E5E] rounded-md"
        >
          <input
            type="number"
            defaultValue={storedTabacData?.duration}
            onChange={(e) => {
              dispatch(setToxicsTabacDuration(e.target.value));
            }}
            className="border-none w-[100%] outline-none focus:border-none"
          />
          <span className="text-[#1AA5C0] text-sm font-[500] ">Années</span>
        </div>
      </div>
      <div>
        {/* <h1 className="text-[#5E5E5E] text-sm mb-1 mt-2">
                      Paquet / Année
                    </h1>
                    <div className="input border  p-1 flex items-center border-[#5E5E5E] rounded-md">
                      <input
                        type="number"
                        readOnly
                        value={0}
                        className="border-none w-[100%] outline-none     focus:border-none"
                      />
                      <span className="text-[#1AA5C0] text-sm font-[500] ">
                        Paquets
                      </span>
                    </div> */}
      </div>
    </div>
  );
}
