import React, { useEffect } from 'react';
import { Dialog, DialogHeader, DialogBody, DialogFooter, Button, Spinner } from '@material-tailwind/react';
import { size as sizeEnum } from '@material-tailwind/react/types/components/avatar';
import { useSelector } from 'react-redux';
import { RootState } from '../../store';

interface DynamicDialogProps {
  open: boolean;
  size?: sizeEnum,
  handleClose: () => void;
  handleConfirm?: () => void;
  handleCancel?: () => void;
  message: string;
  component?: React.ReactNode,
  title: string;
  redirectLink?: string;
}

const DynamicDialog: React.FC<DynamicDialogProps> = ({
  open,
  component,
  size,
  handleClose,
  handleConfirm,
  handleCancel,
  title,
  message,
}) => {

  const isLoading = useSelector((state: RootState) => state.dialog.isLoading);


  useEffect(()=> {

    console.log("loading state changed", isLoading)
  }, [isLoading])

  return (
    <Dialog size={size ? size : 'md'} open={open} handler={handleClose}>
      <DialogHeader className="h-10 text-primary text-md">
        {title}
      </DialogHeader>
      <DialogBody className="py-6 text-[#0F2552] font-semibold text-sm" divider>
        {message}
        {component}
      </DialogBody>


      {/* actions are defined below */}
      {(handleConfirm || handleCancel) &&<DialogFooter className="">
        {handleCancel && <Button color="red" className="mr-1 bg-[#858585]" onClick={handleClose}>
          <span>Annuler</span>
        </Button>}

        {handleConfirm && !isLoading && <Button className="bg-secondary" color="teal" onClick={handleConfirm}>
          <span>Confirmer</span>
        </Button>}

        {handleConfirm && isLoading && <Button
        disabled={true}
        className="bg-secondary" color="teal">
          <div className='flex items-center gap-2'><Spinner /><span>Confirmer</span></div>
        </Button>}

      </DialogFooter>}
      {/* end actions definition */}
    </Dialog>
  );
};

export default DynamicDialog;
