import {
  DISPLAY_CONDITION_STATE,
  TEXT_INPUT_ICON_TYPE,
} from "../../../../enums/stepper";
import { Input, Button } from "@material-tailwind/react";
import { ReactComponent as PlusSvg } from "../../../../assets/plus.svg";
import { PlusIcon } from "@heroicons/react/24/solid";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Transition } from "@headlessui/react";
import { SLICE } from "../../../../enums/store";
import toast from "react-hot-toast";
import { generateUniqueId } from "../../../../../src/services/utils.service";
export interface ITextInputProps {
  name: string;
  label: string;
  style: string;
  icon?: string;
  displayConditionState?: string;
  containerStyle?: string;
  actions: any;
  addButton?: any;
  defaultValue: string;
  onChange?: any;
  preDefined?: string;
}

export default function TextInput({
  style,
  name,
  defaultValue,
  label,
  preDefined,
  addButton,
  displayConditionState,
  icon,
  actions,
  containerStyle,
  onChange,
}: ITextInputProps) {
  const [textInputValue, setTextInputValue] = useState<string>();
  const dispatch = useDispatch();
  const [preDefinedDefault, setPreDefinedDefault] = useState<string>();
  const motifsState = useSelector((state: any) => state.motifs.motifs);

  const defaultPatientName = useSelector(
    (state: any) => state.appointment.patientName
  );
  React.useMemo(() => {
    switch (preDefined) {
      case SLICE.APPOINTMENT_MODAL:
        location.pathname.includes("patient") &&
          setPreDefinedDefault(defaultPatientName);
        break;
      default:
        break;
    }
  }, [preDefined]);
  return (
    <Transition
      show={useSelector((state: any) => {
        switch (displayConditionState) {
          case DISPLAY_CONDITION_STATE.ALLERGY:
            return state.allergy.isAllergic;
            break;
          case DISPLAY_CONDITION_STATE.OTHER_FAMILIAUX:
            return state.otherFamiliaux.hasOtherFamiliaux;
            break;
          default:
            return true;
        }
      })}
    >
      <div className={containerStyle}>
        <Input
          name={name}
          value={textInputValue}
          defaultValue={preDefined ? preDefinedDefault : defaultValue}
          onChange={(event) => {
            actions
              ? actions.map((action: any) =>
                  dispatch(action(event.target.value))
                )
              : onChange && onChange(event.target.value);
            setTextInputValue(event.target.value);
          }}
          label={label}
          className={style}
          containerProps={{
            className: "min-w-0",
          }}
        />
        {addButton?.shown && (
          <button
            onClick={() => {
              if (
                textInputValue?.trim() &&
                textInputValue?.trim()?.length > 1
              ) {
                typeof addButton.dispatchAction == "object"
                  ? addButton.dispatchAction.map(
                      (action: any, index: number) => {
                        if (
                          textInputValue?.trim() &&
                          textInputValue.trim().length > 1
                        ) {
                          const isAlreadyPresent = motifsState.some(
                            (element: string) => element === textInputValue
                          );
                          if (!isAlreadyPresent) {
                            switch (index) {
                              case 0:
                                dispatch(action(textInputValue));
                                break;
                              case 1:
                                dispatch(
                                  action([...motifsState, textInputValue])
                                );
                                break;
                            }
                          } else {
                            toast.error("Cet element a déjà été ajouté", {
                              id: "130",
                            });
                          }
                        } else {
                          toast.error("Veuillez entrer une valeur valide", {
                            id: "140",
                          });
                        }
                      }
                    )
                  : dispatch(addButton.dispatchAction(textInputValue));
                setTextInputValue("");
              } else {
                toast.error("Veuillez entrer une valeur valide", {
                  id: "150",
                });
              }
            }}
            className="flex items-center  !absolute right-1 top-1 w-[6rem] rounded-md
           h-8 px-2 bg-[#1AA5C0]"
          >
            <p className="text-white ">Ajouter</p>{" "}
            <PlusSvg width={24} className="" />
          </button>
        )}
      </div>
    </Transition>
  );
}
