import React, { useEffect, useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import logo from "../assets/logo-v2.svg";
import { useQuery } from "react-query";
import styled from "styled-components";
import syncIcon from "../assets/sync.svg";
import { getDoctorNamebyId } from "../axiosAPI/Network";
import APP_CONSTANTS from "../constants";
import { button } from "@material-tailwind/react";
import { getFormattedDateTime, updateTime } from "../utils";
import LiveTime from "../Components/LiveTime";
import img1 from "../../src/assets/vector.svg";
import img3 from "../../src/assets/debit.png";
import SolidConnection from "../../src/assets/vector-1.svg";
import averageConnection from "../../src/assets/averageConnection.svg";
import weakConnection from "../../src/assets/weakConnection.svg";
import Notif from "../../src/assets/Notif.svg";
import img4 from "../../src/assets/Group (4).png";
import exit from "../../src/assets/disconnect.png";
import Frame from "../../src/assets/Frame.png";
import liveConnectionCircle from "../../src/assets/liveConnectionCircle.png";
import Notifications from "../../src/Components/Notifications";
import { Popover } from "@headlessui/react";
import { useLoginMutation, useLogoutMutation } from "../features/auth/authApi";
import store, { RootState } from "../store";
import { NOTIFICATION_TYPE } from "../../src/enums/notification";
import Menu from "./Menu";
import { useDispatch } from "react-redux";
import { setNotifications } from "../../src/features/notifications/notificationsSlice";
import { Indicators } from "../Components/indicators";
import { app } from "../enums/app";
import DeviceData from "../Components/DevicesStatus/DeviceData";
import DeviceList from "../Components/DevicesStatus/DeviceList";
import { useGetNotificationsQuery } from "../../src/features/notifications/notificationsApi";
import { useConnectionSpeed } from "../hooks/useConnectionSpeed";

const GlobalStyle = styled.div<{ isLoginPage: Boolean }>`
  width: 88%;
  margin-top: 15px;
  background: #ffffff;
  border-bottom-left-radius: 15px;
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
  border-bottom-right-radius: 15px;
  display: flex;
  align-items: center;
  justify-content: ${(props) =>
    !props.isLoginPage ? "space-between" : "center"};
`;

export const LogoStyle = styled.div`
  display: flex;
  align-items: center;
  margin-left: 10px;
  justify-content: center;
  gap: 20px;
  .titles {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 5px;
    .viseo {
      font-family: "Ubuntu";
      font-style: normal;
      font-weight: 500;
      font-size: 20.044px;
      line-height: 19px;
      color: #20a8d3;
    }
    .far {
      font-family: "Ubuntu";
      font-style: normal;
      font-weight: 500;
      font-size: 20.044px;
      line-height: 19px;
      color: #e5deaf;
    }
  }
`;

const ProfilePart = styled.div`
  position: relative;
  cursor: pointer;
  margin-right: 30px;
  display: flex;
  gap: 10px;

  border-radius: 7.21264px;
  padding: 10px;
  .title {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: space-around;
    .name {
      font-family: "Ubuntu";
      font-style: normal;
      font-weight: 500;
      width: 200px;
      display: flex;
      align-items: center;
      justify-content: center;

      font-size: 18.3979px;
      line-height: 13px;
      color: balck;
    }
    .work {
      font-family: "Ubuntu";
      font-style: normal;
      font-weight: 400;
      font-size: 16.1621px;
      line-height: 13px;
      color: #20a8d3;
    }
  }
`;

const DropdowneElement = styled.div`
  @keyframes mymove {
    from {
      top: 120px;
    }

    to {
      top: 80px;
    }
  }
  animation: mymove 0.3s;
  position: absolute;
  z-index: 1;
  box-sizing: border-box;
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  top: 80px;
  right: 0px;
  background: rgba(255, 255, 255, 0.4);
  border: 1px solid #1aa5c0;
  box-shadow: 0px 3px 15px rgba(56, 156, 163, 0.49);
  border-radius: 8.7557px;
  padding: 10px;
  transition: max-height 10s ease-out;
  .signout {
    box-sizing: border-box;
    width: 100%;
    padding: 1rem;
    background: #f4f4f4;
    border-radius: 3px;
    font-family: "Ubuntu";
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 18px;
    color: #1aa5c0;
  }
`;

const DateContent = styled.span`
  margin-left: 10px;
  color: #50799e;
  font-size: 24.478px;
  font-family: Ubuntu;
  line-height: 18.564px;
  letter-spacing: -1px;
`;

const CalendarIcon = styled.img`
  width: 21px;
  height: 23.333px;
  flex-shrink: 0;
`;

const TimerIcon = styled.img`
  margin-left: 20px;
  width: 23px;
  height: 23px;
  flex-shrink: 0;
`;

const TimeContent = styled.span`
  color: #50799e;
  font-size: 24px;
  margin-left: 2px;
  font-family: Ubuntu;
  line-height: 18.564px;
  letter-spacing: -1px;
`;
const Divdate = styled.div`
  color: #50799e;
  font-feature-settings: "clig" off, "liga" off;
  font-family: Ubuntu;
  font-size: 13.552px;
  font-style: normal;
  font-weight: 400;
  line-height: 21.84px; /* 161.164% */
`;
const StyledIcon = styled.img`
  width: 31px;
  height: 25.527px;
  flex-shrink: 0;
`;

const DateTime = (props: any) => {};

export const Logo = (props: any) => {
  return (
    <LogoStyle>
      <img
        width={200}
        className="cursor-pointer"
        onClick={props.onClick}
        src={logo}
        alt=""
      />
    </LogoStyle>
  );
};

const Header = ({ isLoginPage }: { isLoginPage: boolean }) => {
  const nav = useNavigate();
  const speed = useConnectionSpeed();

  // speed.downloadSpeedMbps.toFixed(2)} Mbps

  const storedUser = useSelector((state: RootState) => state?.auth.user);
  const currentCenter = useSelector((state: RootState) => state?.auth.center);
  const currentBox = useSelector((state: RootState) => state?.auth.box);
  const access = useSelector((state: RootState) => state?.auth.access);
  const currentApp = useSelector((state: RootState) => state?.auth.currentApp);
  const isConnected = useSelector(
    (state: RootState) => state?.auth.isConnected
  );

  const [isHovered, setIsHovered] = useState(false);

  useEffect(() => {
    console.log("connection speed is", speed);
  }, [speed]);

  const handleHover = () => {
    setIsHovered(true);
  };

  const handleHoverEnd = () => {
    setIsHovered(false);
  };
  const dispatch = useDispatch();
  const [logoutMutation, { isError }] = useLogoutMutation();

  const [frenchDate, setFrenchDate] = useState("");
  const [time, setTime] = useState("");
  const [show, setShow] = useState(false);
  const [downloadSpeed, setDownloadSpeed] = useState(null);
  const [showDeviceData, setShowDeviceData] = useState(false);

  const { data: notifsData } = storedUser?.id
    ? useGetNotificationsQuery(storedUser?.id)
    : { data: {} };

  React.useMemo(() => dispatch(setNotifications(notifsData)), [notifsData]);

  function useOutsideAlerter(ref: React.RefObject<HTMLElement>) {
    useEffect(() => {
      function handleClickOutside(event: MouseEvent) {
        if (ref.current && !ref.current.contains(event.target as Node)) {
          setShow(false);
        }
      }

      window.addEventListener("click", handleClickOutside);

      return () => {
        window.removeEventListener("click", handleClickOutside);
      };
    }, [ref]);
  }
  const url = window.location.pathname;
  const [doctorId, setDoctorId] = useState("");
  const { data, isLoading, error, refetch } = useQuery(
    ["getDoctorName", doctorId],
    () => getDoctorNamebyId(+doctorId)
  );
  const wrapperRef = useRef(null);
  useOutsideAlerter(wrapperRef);
  useEffect(() => {
    const [date, t] = getFormattedDateTime();
    setFrenchDate(date);
    setTime(t);
    setDoctorId(
      localStorage.getItem(`${APP_CONSTANTS.LOCALSTORAGE_PREFIX}doctorId`) || ""
    );
  }, []);

  useEffect(() => {
    console.log(currentCenter);
  }, [currentCenter]);

  // useEffect(() => {
  //   // console.log("notifications are getted", notifsData)
  // }, [notifsData])
  useEffect(() => {
    const updateDownloadSpeed = () => {
      setDownloadSpeed(navigator.connection?.downlink || "N/A");
    };

    updateDownloadSpeed();

    const intervalId = setInterval(updateDownloadSpeed, 1000);

    return () => clearInterval(intervalId);
  }, []);
  const navigate = useNavigate();

  /**
   * DISCONNECT USER FROM THE APPLICATION
   */
  const disconnect = async () => {
    //  logoutMutation({refreshToken:''}).unwrap();
    // Get all keys from localStorage
    const keys = Object.keys(localStorage);

    // Iterate through the keys and remove the items with the desired prefix
    keys.forEach((key) => {
      if (key.startsWith(APP_CONSTANTS.LOCALSTORAGE_PREFIX)) {
        localStorage.removeItem(key);
      }
    });
    // NAVIGATE TO LOGIN PAGE
    window.location.pathname = APP_CONSTANTS.ROUTES.LOGIN;
  };
  if (!isLoginPage)
    return (
      <GlobalStyle isLoginPage={isLoginPage}>
        <div className="flex">
          {/* FIRST */}
          <Menu
            PopoverButton={
              <Popover.Button className="outline-none">
                <img
                  src={Frame}
                  width={40}
                  className="ml-4 mr-5 cursor-pointer"
                />
              </Popover.Button>
            }
          />

          <Logo
            onClick={() => {
              // REDIRECTING USER TO THE APPROPRIATE APP
              if (currentApp) {
                if (currentApp === app.APPOINTMENTS)
                  navigate(APP_CONSTANTS.ROUTES.CALENDAR);
                else if (currentApp === app.PATIENT_RECORD)
                  navigate(APP_CONSTANTS.ROUTES.PATIENTS_LIST);
                else if (currentApp === app.VISIO_STATION)
                  navigate(APP_CONSTANTS.ROUTES.DEVICES);
                else if (currentApp === app.WAITING_LIST)
                  navigate(APP_CONSTANTS.ROUTES.WAITING_LIST);
                else navigate(APP_CONSTANTS.ROUTES.LOGIN);
              }
            }}
          />
          {/* HIDED until we change centers logic */}
          <div className="flex gap-4">
            <span className="text-sm font-light italic">
              {currentCenter?.name}
            </span>{" "}
            {currentBox && (
              <span className="text-sm font-light italic">
                {currentBox?.name}
              </span>
            )}
          </div>
        </div>

        {/* END FIRST */}
        {/* SECOND */}
        <div style={{ display: "inline-flex" }}>
          <CalendarIcon src="/assets/calendar.svg" />
          <DateContent>{frenchDate}</DateContent>
          <TimerIcon src="/assets/timer.svg" />
          <TimeContent>
            <LiveTime />
          </TimeContent>
        </div>
        {/* END SECOND */}
        {/* THIRD */}
        <div className="flex items-center">
          <Notifications
            PopoverButton={
              <Popover.Button className="outline-none mt-1">
                <div
                  className="relative"
                  onClick={() => {
                    dispatch(setNotifications(notifsData?.results));
                  }}
                >
                  <img src={Notif} className="mr-5 cursor-pointer" />
                  {notifsData?.results?.length > 0 && (
                    <span className="absolute -top-3 right-3 inline-flex items-center justify-center w-5 h-5 ml-2 text-xs font-semibold text-white bg-red-400 rounded-full">
                      {notifsData?.results?.length}
                    </span>
                  )}
                </div>
              </Popover.Button>
            }
            Notifs={[
              {
                message: "Votre consultation est dans 1min",
                type: NOTIFICATION_TYPE.APPOINTMENT,
                title: "Nouvelle Consultation",
              },
              {
                message: "Votre consultation est dans 5min",
                type: NOTIFICATION_TYPE.APPOINTMENT,
                title: "Nouvelle Consultation",
              },
            ]}
          />
          {/* <Notifications
            PopoverButton={
              <Popover.Button className="outline-none">
                <div className="relative">
                  <img src={Notif} className="mr-5 cursor-pointer" />
                  <span className="absolute -top-2 right-3 inline-flex items-center justify-center w-4 h-4 ml-2 text-xs font-semibold text-white bg-red-400 rounded-full">
                    2
              <Popover.Button className="outline-none mt-1" >
                <div className="relative" onClick={()=>{
                dispatch(setNotifs(notifsData.results))
              }}>
                  <img src={Notif} className="mr-5 cursor-pointer" />
                  <span className="absolute -top-3 right-3 inline-flex items-center justify-center w-5 h-5 ml-2 text-xs font-semibold text-white bg-red-400 rounded-full">
                    {notifsData?.results?.length}
                  </span>
                </div>
              </Popover.Button>
            }
            Notifs={[
              {
                message: "Votre consultation est dans 1min",
                type: NOTIFICATION_TYPE.APPOINTMENT,
                title: "Nouvelle Consultation",
              },
              {
                message: "Votre consultation est dans 5min",
                type: NOTIFICATION_TYPE.APPOINTMENT,
                title: "Nouvelle Consultation",
              },
            ]}
          />
          <div className="w-px h-16 bg-gray-300"></div> */}
          <span className="flex justify-around gap-3 my-5 mx-4">
            {/* <div className="flex relative ">
              <div className="flex items-center">
                <img src={syncIcon} alt="Description de l'image" className="mr-4" />
                <Divdate className="flex justify-around gap-3 my-5 mr-3">
                  Dernière sync.  07:30:05
                </Divdate>
              </div>
            </div> */}
          </span>

          <Indicators />

          <div className="w-px h-16 bg-gray-300"></div>
          <div onClick={disconnect} className="mx-3">
            <img src={exit} width={30} alt="icons" className="cursor-pointer" />
          </div>
        </div>
        {/* {!isLoginPage && (
          <ProfilePart onClick={() => setShow(!show)} ref={wrapperRef}>
            <img src="/user.svg" alt="" />
            <div className="title">
              <div className="name">
                {" "}
                {`${data?.user?.firstName || ""}    ${data?.user?.lastName || ""
                  }`}
              </div>
              <div className="work">
                Médecin {`${data?.speciality?.name || ""}`}
              </div>
            </div>
            {show && (
              <DropdowneElement>
                <div className="signout" onClick={disconnect}>
                  Déconnexion
                </div>
              </DropdowneElement>
            )}
          </ProfilePart>
        )} */}
        {/* END THIRD */}
      </GlobalStyle>
    );
  else return <></>;
};
export default Header;
