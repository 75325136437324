// slices/counterSlice.ts
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface DoctorState {
    doctorId:any
}

const initialState: DoctorState = {
    doctorId:''
};

const doctorSlice = createSlice({
    name: 'doctor',
    initialState,
    reducers: {
     setDoctorId(state,action: PayloadAction<any>) {
            state.doctorId =  action.payload;

        },

    },
});

export const { setDoctorId } = doctorSlice.actions;
export default doctorSlice.reducer;
