import React, { useEffect } from "react";
import styled from "styled-components";
import moment from "moment"
export const MyInputContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.2rem;
  flex: 1;
  min-width: 300px;
`;

export const MyInputLabel = styled.label`
  font-family: "Ubuntu";
  font-style: normal;
  font-weight: 700;
  font-size: 15.0024px;
  line-height: 15px;
  color: #1AA5C0;
  margin-bottom: 5px;
  display: flex;
  gap: 4px;
  .req {
    align-self: center;
    color: #f90b33df;
  }
`;

export const MyInputstyle = styled.input`
  box-sizing: border-box;
  width: 100%;
  height: 50.26px;
  border: 1.67499px solid ${(props) => (props.disabled ? "#ffffff" : "#858585")};
  border-radius: 11.7249px;
  outline: none;
  transition: border-color 0.2s ease-in-out;
  font-family: "Ubuntu";
  font-style: normal;
  font-weight: 400;
  line-height: 15px;
  color: #5e5e5e;
  padding: 0.5rem;
  font-size: 1rem;
  color: #333;
  &:focus {
    border-color: rgba(31, 131, 131, 1);
  }
`;

export const InputWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  gap: 1rem;
`;

export const SideDiv = styled.div`
  font-family: "Ubuntu";
  font-style: normal;
  font-weight: 500;
  font-size: 17.6551px;
  line-height: 15px;
  text-align: center;
  background: #1AA5C0;
  border-radius: 11.7249px;
  color: #ffffff;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-inline: 1.5rem;
  white-space: nowrap;
`;
export interface MyInputProps {
  label: string;
  placeholder: string;
  isImportant: boolean;
  ismodif: boolean;
  name: string;
  handleChange: any;
  hasLeft: boolean;
  hasRight: boolean;
  type: string;
  isAge?: boolean;
  value?: any;
  pattern?:string
}

const MyInput = ({
  ismodif,
  label,
  placeholder,
  isImportant,
  name,
  handleChange,
  hasLeft,
  hasRight,
  type,
  pattern,
  isAge,
  value, 
}: MyInputProps) => {
  const [age, setAge] = React.useState<any>(null);
  const [text, setText] = React.useState<string>("--ans");
  const onChange = (e: any) => {
    handleChange(e);
    const today = new Date();
    const birthDate = new Date(e.target.value);
    const differ = moment(today).diff(moment(birthDate), 'months');

    const ageInMonths = (today.getFullYear() - birthDate.getFullYear()) * 12 + (today.getMonth() - birthDate.getMonth());
    const ageInDays = Math.floor((today - birthDate) / (1000 * 60 * 60 * 24));
    const ageInYears = today.getFullYear() - birthDate.getFullYear();

    if(ageInYears > 0) {setAge(ageInYears); setText("ans")}
    else if(ageInMonths > 0) {setAge(ageInMonths); setText("mois")}
    else  {setAge(ageInDays); setText("jours")}
  };

  useEffect(()=> {
    if(age && age < 1) setText('mois')
  }, [age])
  return (
    <MyInputContainer>
      <MyInputLabel>
        {label} {isImportant && <div className="req">*</div>}
      </MyInputLabel>
      <InputWrapper>
        <MyInputstyle
          autoComplete="off"
          disabled={!ismodif}
          placeholder={placeholder}
          // pattern={pattern}
          name={name}
          onChange={isAge ? onChange : handleChange}
          type={type}
          value={value}
        />
        {hasRight && <SideDiv>{`${age ? age : ""} ${text}`}</SideDiv>}
      </InputWrapper>
    </MyInputContainer>
  );
};

export default MyInput;
