
import { useState } from 'react';
import statistic from '../../assets/statistic.svg'
import Chart from "react-apexcharts";


function Statistics() {

  const [state, setState] = useState({
    options: {
       colors: ['#E91E63'],
      chart: {
        width: '100%',
        type: "bar",
        toolbar: {
          show: false,
          offsetX: 0,
          offsetY: 0,
          tools: {
            download: false,
            selection: false,
            zoom: false,
            zoomin: false,
            zoomout: false,
            pan: false,
            reset: false,
            customIcons: []
          },
          export: {
            csv: {  },
            svg: {
              filename: undefined,
            },
            png: {
              filename: undefined,
            }
          },
          autoSelected: 'zoom' 
        },
      },
       xaxis: {
         categories: []
       }
    },
    series: [
      {
        // name: "series-1",
        data: [30, 40, 45, 50]
      }
    ]
  })


  return (
    <div className='bg-white p-6'>
      <div className='flex gap-4 m-5 mt-7' >
        <img src={statistic} className='h-10 w-10' />
        <h1 className='font-bold text-2xl text-[#50799E] mt-1'>Statistics</h1>
      </div>
      <p className='text-[#07485E] m-5'>Nombre des patients traités</p>
      <div className='w-full'>
        <div className="mixed-chart">
          <Chart
            options={state.options}
            series={state.series}
            type="bar"
            width="1500"
            height="400"
          />
        </div>
      </div>
    </div>
  )
}

export default Statistics