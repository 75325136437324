import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import APP_CONSTANTS from "../../constants";
import { api } from "../api/api";

const advancedParamsApi = api.injectEndpoints({
  endpoints: (builder) => ({
    // GET
    postAttachement: builder.mutation({
      query: (body: any) => ({
        url: `attachement/`,
        method: "POST",
        body,
      }),
    }),
  }),
});

export const { usePostAttachementMutation } = advancedParamsApi;

export default advancedParamsApi;
