import { SLICE } from '../../enums/store';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface PatientRecordTabState {
  activeTab: number; 
}

const initialState: PatientRecordTabState = {
  activeTab: 0, 
};



const patientRecordTabSlice = createSlice({
  name: SLICE.PATIENT_RECORD_TAB,
  initialState,


  reducers: {
    setActiveTab: (state, action: PayloadAction<number>) => {
      state.activeTab = action.payload;
    },
  },
});

export const { setActiveTab } = patientRecordTabSlice.actions;

export default patientRecordTabSlice.reducer;
