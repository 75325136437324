// connectionSlice.ts

import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ConnectionState } from '../../interfaces/app';
import { SLICE } from '../../enums/store';

const initialState: ConnectionState = {
  isConnected: true, // Assuming the initial state is connected
};

const connectionSlice = createSlice({
  name: SLICE.CONNECTION,
  initialState,
  reducers: {
    setConnectionStatus: (state, action: PayloadAction<boolean>) => {
      state.isConnected = action.payload;
    },
  },
});

export const { setConnectionStatus } = connectionSlice.actions;
export default connectionSlice.reducer;
