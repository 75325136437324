import React, { InputHTMLAttributes, useState } from "react";
import styled from "styled-components";

const StyledRangeSlider = styled.input`
  -webkit-appearance: none;
  width: 100%;
  max-width: 15rem;
  height: 30px;
  border-radius: 10px 30px 20px 10px;
  background-image: linear-gradient(
    175deg,
    hsl(0deg 0% 100%) 1%,
    hsl(0deg 0% 100%) 33%,
    hsl(0deg 0% 99%) 41%,
    hsl(0deg 0% 99%) 46%,
    hsl(0deg 0% 98%) 48%,
    hsl(0deg 0% 98%) 50%,
    hsl(0deg 0% 97%) 51%,
    hsl(0deg 0% 97%) 51%,
    hsl(0deg 0% 97%) 51%,
    hsl(0deg 0% 96%) 51%,
    hsl(0deg 0% 96%) 50%,
    hsl(0deg 0% 95%) 50%,
    hsl(0deg 0% 95%) 49%,
    hsl(0deg 0% 94%) 49%,
    hsl(0deg 0% 94%) 49%,
    hsl(0deg 0% 94%) 49%,
    hsl(0deg 0% 93%) 50%,
    hsl(0deg 0% 93%) 52%,
    hsl(0deg 0% 92%) 54%,
    hsl(0deg 0% 92%) 59%,
    hsl(0deg 0% 91%) 67%,
    hsl(0deg 0% 91%) 99%
  );
  position: relative;
  outline: none;
  z-index: 100;

  &::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    background: white;
    border: 3px solid #1aa5c0;
    cursor: pointer;
    z-index: 100;
    position: absolute;
    // set left to value of slider
    /* left: ${(props) => (Number(props.value) / Number(props.max)) * 100}%; */
    left: ${(props) =>
      props.value
        ? ((Number(props.value) - Number(props.min)) /
            (Number(props.max) - Number(props.min))) *
          100
        : 0.0}%;
    top: 0px;
  }
`;

interface Props {
  value: number;
  setValue: (value: number) => void;
  maxValue: number;
  minValue: number;
}

function RangeSlider(props: Props) {
  const { value, setValue, maxValue, minValue } = props;
  const handleChange = (event: any) => {
    setValue(parseInt(event.target.value));
  };

  return (
    <div>
      <StyledRangeSlider
        type="range"
        min={minValue}
        max={maxValue}
        value={value}
        onChange={handleChange}
      />
    </div>
  );
}

export default RangeSlider;
