/* DEFINING POSSIBLE VALUES FOR LOADING STATE */
export enum LOADING_STATE {
  IDLE = "idle",
  PENDING = "pending",
  SUCCEEDED = "succeeded",
  FAILED = "failed",
}

/* DEFINING STORE SLICES */
export enum SLICE {

  /* basic slices */
  CONNECTION = "connection", // this slice is to detect if the internet connection is up or down
  FORM = "form", // this is form slice
  USERS = "users",
  AUTH = "auth",
  CURRENT_STEP = "currentStep",
  SELECTED_PRESCRIPTION = "selectedPrescription",
  CONSULTATION_DATA = "consultationData",
  IS_VOIR_ANTECEDENT_SHOWN = "isVoirAntecedentShown",
  SELECTED_DOCTOR = "selectedDoctor",
  SELECTED_SPECIALTY = "selectedSpecialty",
  SPECIALIST_CONSLUSION = "specialistConclusion",


  /* business slices */
  CONSULTATIONS = "consultations",
  MEDICATIONS = "medications",
  CHIEF_COMPLAINTS = "chiefComplaints",
  APPOINTMENTS = "appointments",
  MOTIFS = "motifs",
  ANALYSES_RADIO = "analysesRadio",
  BIO_ANALYSES = "bioAnalyses",
  OTHER_FAMILIAUX = "otherFamiliaux",
  SELECTED_FAMILIAUX = "selectedFamiliaux",
  ALLERGY = "allergy",
  ALCOHOL = "alcohol",
  MEDICATION_CONSULTATION = "medicationConsult",
  MEDICALS_ANTECEDENTS = "medicalsAntecedents",
  BASEPARAMS = "baseParams",
  ADVANCEDPARAMS = "advancedParams",
  ANTECEDENTS = "antecedents",
  TRAITEMENT = "traitement",
  HOSPITALIZATION = "hospitalization",
  CONSULTATION_STATUS = "consultaionStatus",
  CONSULTATION_COUNTER = "consultationCounter",
  CONSULTATION_METADATA = "consultationMetadata",
  APPOINTMENT_MODAL = "appointmentModal",
  HOSPITAL = "Hôpital",
  SPECIALITY = "Spécialité médicale",
  DOCTOR = "Médecine concerné",
  ANALYSE = "bioAnalyses",
  IMAGING = "imaging",
  PRINCIPALE_DIAGNOSTIC = "diagnosticPrincipale",
  DIAGNOSTIC_SECONDAIRE = "diagnosticSecondaire",
  PATIENT = "patient",
  MEDICAUX = "medicaux",
  SURGICAL = "surgical",
  SELECTED_ADHERANT = "selectedAdherant",
  COMPAGNIONAGENT = "compagnionAgent",
  IS_TABAC = "isTabac",
  COMPTE_RENDU = "compteRendu",
  HAS_OTHER_SUBS = "hasOtherSubs",
  MEDIA_DEVICES = "mediaDevices",
  MAIN_CAMERAS = "mainCameras",
  PATIENT_RECORD_TAB = "patientRecordTab",
  MEDICAL_DEVICES = "medicalDevices",
  CALL_SLICE = "callSlice",
  CALLDEVICES = "callDevices",
  CALL_ACTIONS = "callActions",
  TABLE = "table",
  WAITING_LIST= "waitingList",
  CALLINITINFO="CallInfos",
  MAINCAMERA_DEVICE_ID="mainCameraDeviceId",
  NOTIFIED_CONSULTATION = "notifiedConsultation",
  SEARCHEABLE_SELECT = 'searcheableSelect'

}

/* DEFINING ACTION TYPES */
export enum CONSULTATION_ACTION_TYPES {
  FETCH_BY_ID = "consultations/fetchById",
  CREATE = "consultations/create",
  DELETE = "consultations/delete",
  UPDATE = "consultations/update",
}
