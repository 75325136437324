import React, { useEffect, useState, Fragment } from "react";
import { Input } from "@material-tailwind/react";
import {
  getMedicationHistory,
  getPatient,
  updateConsultation,
} from "../../../../axiosAPI/Network";
import { BsTrash } from "react-icons/bs";
import {
  setPatientId,
  setMedications,
  setListNewMedication,
} from "../../../../features/traitement/traitementSlice";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../store";
import { SELECT_DATA_STATE } from "../../../../../src/enums/stepper";
import SelectInput from "../../../FormBuilder/FormElements/SearchableSelect";
import {
  setConsultationData,
  setTraitement,
} from "../../../../features/consultationData/consultationDataSlice";
import dayjs from "dayjs";
import moment from "moment";

type props = {
  consultationId: number;
  consultationInfos: string;
  setConsultationInfos?: any;
  consultationForm?: any;
  setConsultationForm?: any;
};

interface List {
  medicationId: number;
  startTime: string;
  endTime: string;
  note: string;
}

function Traitement({ consultationId }: props) {
  const dispatch = useDispatch();
  let newDate = new Date();
  // let date = newDate.getUTCFullYear();
  const [isDisabled, setIsDisabled] = useState(true);
  const [startTime, setStartTime] = useState<any>();
  const [endTime, setEndTime] = useState<any>();
  const [treatmentsArr, setTreatmentsArr] = useState<any>([]);

  const [treatment, setTreatment] = useState<any>({});
  const [note, setNote] = useState<string>("");
  const [isForeverTreatment, setIsForeverTreatment] = useState<
    boolean | undefined
  >(false);
  const [today, setToday] = useState<any>();

  const patientId = useSelector(
    (state: RootState) => state.traitement.patientId
  );
  const medications = useSelector(
    (state: RootState) => state.traitement.medications
  );
  const listNewMedication = useSelector(
    (state: RootState) => state.traitement.listNewMedication
  );

  // console.log(listNewMedication);

  useEffect(() => {
    if (isForeverTreatment) {
      // setStartTime('à vie')
      setEndTime("à vie");
    }
  }, [isForeverTreatment]);

  useEffect(() => {
    if (!endTime && !isForeverTreatment) setIsDisabled(true);
    else setIsDisabled(false);
  }, [endTime]);
  useEffect(() => {
    const medication = medications.medicationName;
    const medicationId = medications.medicationId;
    setTreatment({
      medicationId,
      patientId,
      medication,
      note,
      startTime,
      endTime,
      newMedication: true,
    });
  }, [patientId, note, medications, startTime, endTime]);

  useEffect(() => {
    getMedicationHistory(consultationId).then((res) => {
      // console.log(res.data);
      dispatch(setPatientId(res.data.patientId));
      const tempArr = res.data.patient.medicationHistory;
      // console.log("setting treatments...", tempArr);
      // console.log(tempArr);
      setTreatmentsArr(tempArr);
    });
  }, [consultationId]);

  interface YourObjectType {
    medicationId: any;
    startTime: string;
    note: string;
  }
  const handleAddMedicationClick = async () => {
    const updatedTreatmentsArr = [...treatmentsArr, treatment];
    setTreatmentsArr(updatedTreatmentsArr);

    const selectedList = updatedTreatmentsArr.map(
      ({ medicationId, startTime, endTime, note }: List) => {
        const properties: any = {
          medicationId,
          startTime,
          note,
        };
        if (endTime && endTime.length > 9) {
          properties.endTime = endTime;
        }
        return properties;
      }
    );
    // removing duplicate treatment
    const filtredArray = Array.from(
      new Set(selectedList.map((obj: any) => JSON.stringify(obj)))
    ).map((str) => JSON.parse(str) as YourObjectType);

    // console.log("send to backend", { medicationHistory: filtredArray });
    dispatch(setTraitement({ medicationHistory: filtredArray }));
    setStartTime("");
    setEndTime("");
    // setIsForeverTreatment(false)
  };
  useEffect(() => {
    // console.log("treatment is", treatment);
    if (treatment.medicationId && startTime) {
      setIsDisabled(false);
    } else {
      setIsDisabled(true);
    }
  }, [treatment]);

  useEffect(() => {
    dispatch(
      setListNewMedication(
        treatmentsArr?.filter(
          (item: { newMedication: boolean }) => item.newMedication == true
        )
      )
    );
  }, [treatmentsArr]);
  useEffect(() => {
    if (treatmentsArr) {
      const selectedList = treatmentsArr.map(
        ({ medicationId, startTime, endTime, note }: List) => {
          const properties: any = {
            medicationId,
            startTime,
            note,
          };
          if (endTime && endTime.length > 9) {
            properties.endTime = endTime;
          }
          return properties;
        }
      );

      // console.log("send to backend ", { medicationHistory: selectedList });
      dispatch(setTraitement({ medicationHistory: selectedList }));
    }
  }, [treatmentsArr]);
  // useEffect(() => {

  // }, [treatmentsArr])

  useEffect(() => {
    setToday(dayjs().format("2023-01-01"));
  }, []);

  const datee = new Date();

  let day = datee.getDate();
  let month = datee.getMonth() + 1;
  let year = datee.getFullYear();
  let currentDate = `${day}-${month}-${year}`;
  // console.log("list des traitwmwnt", treatmentsArr);

  // console.log("start time", startTime);
  const tomorrow = new Date();
  tomorrow.setDate(tomorrow.getDate() + 1); // Get tomorrow's date

  const tomorrowDate = tomorrow.toISOString().split("T")[0];
  const todayDate = new Date().toISOString().split("T")[0];

  return (
    <>
      <div className="h-full w-full ">
        <div className="grid grid-cols-4 gap-4 my-4 mt-5">
          <div className="mlt-s">
            {/* selected input */}
            <SelectInput
              actions={[setMedications]}
              name={`traitement`}
              label={``}
              placeholder={`Choisir médicament`}
              dataState={SELECT_DATA_STATE.MEDICATION}
              style="text-sm text-[#000000] max-w-[26rem] border  border-black-900 rounded mt-5"
              consultationData={undefined}
            />
          </div>
          <div className="flex justify-end mb-4 mt-5">
            <input
              id="default-checkbox"
              type="checkbox"
              onChange={(e) => setIsForeverTreatment(e.target.checked)}
              className="w-8 h-8
           text-primary bg-gray-100 border-gray-300 rounded    dark:border-gray-600"
            />
            <label
              htmlFor="default-checkbox"
              className="text-lg ml-2 font-medium text-primary dark:text-gray-300"
            >
              Traitement à vie
            </label>
          </div>
          <div className="w-18 mt-5">
            <Input
              defaultValue={currentDate}
              type="date"
              value={startTime}
              onChange={(e: any) => setStartTime(e.target.value)}
              label="Date de début"
              max={todayDate}
            />
          </div>

          <div className="w-18 mt-5">
            <Input
              min={todayDate}
              defaultValue={tomorrowDate}
              disabled={isForeverTreatment === true}
              className="mr-0"
              type="date"
              value={endTime}
              onChange={(e: any) => setEndTime(e.target.value)}
              label="Date de fin"
            />
          </div>
        </div>

        <div className="frequence flex gap-4 my-2">
          <Input
            type="text"
            value={note}
            onChange={(e) => setNote(e.target.value)}
            placeholder="Ex : Prendre 1 comprimé par voie orale toutes les 8 heures pendant 7 jours"
          />
          <button
            onClick={handleAddMedicationClick}
            disabled={isDisabled}
            className="bg-[#5E5E5E] text-white w-[14rem] rounded-[5rem]"
          >
            Ajouter le traitement
          </button>
        </div>

        <div
          className="relative overflow-y-scroll rounded-md mt-4 p-2 border"
          style={{ maxHeight: "18rem" }}
        >
          <table className="w-full text-sm text-left  text-gray-500 dark:text-gray-400">
            <thead className="text-xs table-fixed text-gray-700 uppercase mt-6 dark:bg-gray-700 dark:text-gray-400">
              <tr>
                <th scope="col" className="px-6 py-3 text-secondary text-base">
                  Traitement
                </th>
                <th scope="col" className="px-6 py-3 text-secondary text-base">
                  Date de début
                </th>
                <th scope="col" className="px-6 py-3 text-secondary text-base">
                  Date de fin
                </th>
                <th scope="col" className="px-6 py-3"></th>
              </tr>
            </thead>
            {
              <tbody className="relative overflow-y-scroll">
                {treatmentsArr?.map((item: any, index: number) => (
                  <>
                    <tr
                      className="bg-[#E5F4F7] border-b dark:bg-gray-800 dark:border-gray-700 border-4
                 border-white m-8 "
                    >
                      <th
                        scope="row"
                        className="px-6 py-4 font-medium text-[#335D84] whitespace-nowrap dark:text-white"
                      >
                        {typeof item?.medication == "string"
                          ? item?.medication
                          : item?.medication?.name}
                        <div className="sp text-[#5E5E5E]">{item.note}</div>
                      </th>
                      <td className="px-6 py-4 font-medium text-gray-600">
                        {item.endTime == "à vie"
                          ? dayjs(item?.startTime).format("YYYY-MM-DD")
                          : dayjs(item?.startTime).format("YYYY-MM-DD")}
                      </td>
                      <td className="px-6 py-4 font-medium text-gray-600">
                        {item.endTime == null
                          ? "a vie"
                          : item?.endTime?.split("T")[0]}
                      </td>
                      <td className="px-6 py-4 ">
                        {item.newMedication ? (
                          <BsTrash
                            size={18}
                            color="red"
                            onClick={() => {
                              setTreatmentsArr((prevTreatmentsArr: any) => {
                                let updatedTreatmentsArr = [
                                  ...prevTreatmentsArr,
                                ];
                                updatedTreatmentsArr.splice(index, 1);
                                return updatedTreatmentsArr;
                              });
                            }}
                            className="cursor-pointer bg-red hover:scale-110 transition-all ease-in-out duration-200 absolute mb-8"
                          />
                        ) : (
                          ""
                        )}
                      </td>
                    </tr>
                  </>
                ))}
              </tbody>
            }
          </table>
        </div>
      </div>
    </>
  );
}

export default Traitement;
