// slices/counterSlice.ts
import { SLICE } from '../../enums/store';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface SearcheableSelectState {
    filters: any;

}

const initialState: SearcheableSelectState = {
    filters: {}
};

const searcheableSelectSlice = createSlice({
    name: SLICE.SEARCHEABLE_SELECT,
    initialState,
    reducers: {
        setSearcheableSelectFilters(state, action: PayloadAction<any>) {
            state.filters = action.payload;
        }

    },
});

export const { setSearcheableSelectFilters } = searcheableSelectSlice.actions;
export default searcheableSelectSlice.reducer;
