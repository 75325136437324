import React from "react";
import styled from "styled-components";
const MyInputContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.2rem;
  flex: 1;
  min-width: 300px;
`;

const MyInputLabel = styled.label`
  font-family: "Ubuntu";
  font-style: normal;
  font-weight: 700;
  font-size: 15.0024px;
  line-height: 15px;
  color: #1aa5c0;
  margin-bottom: 5px;
  display: flex;
  gap: 4px;
  .req {
    align-self: center;
    color: #F90B33DF;
  }
`;

const StyledSelect = styled.select`
  box-sizing: border-box;
  width: 100%;
  height: 50.26px;
  border: 1.67499px solid #858585;
  border-radius: 11.7249px;
  outline: none;
  transition: border-color 0.2s ease-in-out;
  font-family: "Ubuntu";
  font-style: normal;
  font-weight: 400;
  font-size: 18.5029px;
  line-height: 15px;
  color: #5e5e5e;
  padding: 0.5rem;
  font-size: 1rem;
  color: #333;
  &:focus {
    outline: none;
    border-color: #1aa5c0 !important;
  }
  &:hover {
    outline: none;
    border-color: #1aa5c0 !important;
  }
  option {
    padding: 1rem;
    background-color: #f4f4f4;
    border-radius: 3.44252px;
    height: 100px;
  }
  option:checked {
    padding: 1rem;
    background-color: #1aa5c0;
    border-radius: 3.44252px;
    color: white;
  }
`;
interface MyInputProps {
  canBeEmpty?: boolean;
  label: string;
  placeholder: string;
  isImportant: boolean;
  ismodif: boolean;
  value?: string | number;
  options: any;
  name: string;
  handleChange: any;
  slected: any;
}

const MySelect = ({
  canBeEmpty,
  ismodif,
  label,
  placeholder,
  isImportant,
  value,
  options,
  name,
  handleChange,
  slected,
}: MyInputProps) => {
  let selectoptions = [];

  if (options.length > 0) {
    if (typeof options[0] === "string") {
      selectoptions = options.map((el: string, key: number) => {
        return (
          <option value={el} key={key} style={{ height: "100px" }}>
            {el}
          </option>
        );
      });
    } else if (typeof options[0] === "object") {
      selectoptions = options.map((el: any, key: number) => {
        return (
          <option value={el.id} key={key} style={{ height: "100px" }}>
            {el.name}
          </option>
        );
      });
    }
  }

  return (
    <MyInputContainer>
      <MyInputLabel>
        {label} {isImportant && <div className="req">*</div>}
      </MyInputLabel>
      <StyledSelect
        autoComplete="off"
        defaultValue={value ?? ""}
        disabled={!ismodif}
        placeholder={placeholder}
        name={name}
        onChange={handleChange}
      >
        {canBeEmpty && (
          <option value={slected?.id ?? ""} selected style={{ height: "100px" }}>
            {slected?.name ?? slected}
          </option>
        )}
        {selectoptions}
      </StyledSelect>
    </MyInputContainer>
  );
};

export default MySelect;
