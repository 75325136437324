// consultationMetadataSlice.ts
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import APP_CONSTANTS from '../../constants';

interface ConsultationMetadataState {
  patientId?: number;
  consultationId?: number;
  isSpecialistNotifed?: boolean;
  startTime?: Date;
  endTime?: Date;
  stationId?: number,
  patientOngoingConsultation?: any;
  userId?: number,
  //TODO: should be consultationId:
  centerId?: number,
}

const initialState: ConsultationMetadataState = 
localStorage.getItem(`${APP_CONSTANTS.LOCALSTORAGE_PREFIX}consultationMetadata`)
 ? JSON.parse(localStorage.getItem(`${APP_CONSTANTS.LOCALSTORAGE_PREFIX}consultationMetadata`) ||"") 
 : {
  patientId: undefined,
  isSpecialistNotifed: false,
  consultationId: undefined,
  startTime: undefined,
  endTime: undefined,
};


/**
 * 
 */
const consultationMetadataSlice = createSlice({
  name: 'consultationMetadata',
  initialState,
  reducers: {
    setConsultationMetadata(state, action: PayloadAction<Partial<ConsultationMetadataState>>) {
      return { ...state, ...action.payload };
    },
  },
});

export const { setConsultationMetadata } = consultationMetadataSlice.actions;

export default consultationMetadataSlice.reducer;
