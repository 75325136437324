 
import { SLICE } from '../../enums/store';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface WaitingListState {
  isLoading: boolean;
  data: any[];
  error: string | null;
}

const initialState: WaitingListState = {
  isLoading: false,
  data: [],
  error: null,
};

const waitingListSlice = createSlice({
  name: SLICE.WAITING_LIST,
  initialState,
  reducers: {
    setLoading(state, action: PayloadAction<boolean>) {
      state.isLoading = action.payload;
    },
    setData(state, action: PayloadAction<any[]>) {
      state.data = action.payload;
    },
    setError(state, action: PayloadAction<string | null>) {
      state.error = action.payload;
    },
  },
});

export const { setLoading, setData, setError } = waitingListSlice.actions;
export default waitingListSlice.reducer;
