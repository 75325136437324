// consultationCounterSlice.ts
import { SLICE } from '../../enums/store';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import moment from 'moment';

interface ConsultationCounterState {
  startTime?: Date;
  currentTime: any;
}

const initialState: ConsultationCounterState = {
  startTime: undefined,
  currentTime: 0,
};

const consultationCounterSlice = createSlice({
  name: SLICE.CONSULTATION_COUNTER,
  initialState,
  reducers: {
    startTimer(state, action: PayloadAction<Date>) {
      state.startTime = action.payload;
      state.currentTime = 0;
    },
    setTimer(state,action:PayloadAction<number>){
      state.currentTime = action.payload
    },
    updateTimer(state) {
      if (state.startTime) {
        const start = moment(state.startTime);
        const end = moment(new Date());
        const diff = end.diff(start, 'second');
        state.currentTime = diff;
      }
    },
  },
});

export const { startTimer, updateTimer, setTimer } = consultationCounterSlice.actions;

export default consultationCounterSlice.reducer;
