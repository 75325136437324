import Calendar from "react-calendar";
import styled from "styled-components";

import { Spinner } from "@material-tailwind/react";
import moment from "moment";
import { useEffect, useState } from "react";
import { CiNoWaitingSign } from "react-icons/ci";


const CalendarStyle = styled.div`
  margin: 0 auto;
  padding-top: 0.5rem;
  .react-calendar {
    height: 100%;
    width: 90%;
    background-color: #ffffff;
    color: #222;
    border-radius: 8px;
    font-family: Arial, Helvetica, sans-serif;
    line-height: 1.125em;
    border: none;
    .react-calendar__navigation {
      height: 2em !important;
      display: flex;
      justify-items: center;
      text-align: center;
      color: #0f2552;
      .react-calendar__navigation__label {
        .react-calendar__navigation__label__labelText {
          text-transform: capitalize;
          font-weight: 500;
        }
      }
    }

    .react-calendar--doubleView {
      width: 700px;
    }
    .react-calendar--doubleView .react-calendar__viewContainer {
      display: flex;
      margin: -0.5em;
    }
    .react-calendar--doubleView .react-calendar__viewContainer > * {
      width: 50%;
      margin: 0.5em;
    }
    .react-calendar,
    .react-calendar *,
    .react-calendar *:before,
    .react-calendar *:after {
      -moz-box-sizing: border-box;
      -webkit-box-sizing: border-box;
      box-sizing: border-box;
    }
    .react-calendar button {
      margin: 0;
      border: 0;
      outline: none;
    }
    .react-calendar button:enabled:hover {
      cursor: pointer;
    }
    .react-calendar__navigation {
      height: 64px;
      /* padding-bottom: 2em; */
      border-bottom: 1px solid #e4e5e7;
    }
    .react-calendar__navigation button {
      min-width: 44px;
      background: none;
    }
    .react-calendar__navigation button:enabled:hover,
    .react-calendar__navigation button:enabled:focus {
      background-color: #e6e6e6;
    }
    .react-calendar__navigation button[disabled] {
      background-color: #f0f0f0;
    }
    .react-calendar__month-view__weekdays {
      font-style: normal;
      font-weight: 500;
      font-size: 10.75px;
      line-height: 13px;
      text-align: center;
      text-transform: uppercase;
      color: #7e818c !important;
      margin-bottom: 1rem;
    }
    .react-calendar__month-view__weekdays__weekday {
      padding: 0.5em;
      abbr {
        /* font-family: "SF Pro Display"; */
        font-style: normal;
        font-weight: 500;
        font-size: 10.75px;
        line-height: 13px;
        text-align: center;
        text-transform: uppercase;
        color: #7e818c !important;
        text-decoration: none;
        margin-bottom: 0.5rem;
      }
    }
    .react-calendar__month-view__weekNumbers {
      font-weight: bold;
    }
    .react-calendar__month-view__weekNumbers .react-calendar__tile {
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 0.75em;
      padding: calc(0.75em / 0.75) calc(0.5em / 0.75);
    }
    .react-calendar__month-view__days__day--weekend {
      color: #443333;
    }
    .react-calendar__month-view__days__day--neighboringMonth {
      color: #757575;
    }
    .react-calendar__year-view .react-calendar__tile,
    .react-calendar__decade-view .react-calendar__tile,
    .react-calendar__century-view .react-calendar__tile {
      padding: 2em 0.5em;
    }
    .react-calendar__tile {
      max-width: 50%;
      text-align: center;
      /* padding: 1em 1em; */
      background: none;
      /* height: 35% ; */
      aspect-ratio: 1;
      border-radius: 50%;
      abbr {
        color: #000000;
      }
    }
    .react-calendar__tile:disabled {
      /* background-color: #f0f0f0; */
      abbr {
        color: #d0d0d0;
      }
    }
    .react-calendar__tile:enabled:hover,
    .react-calendar__tile:enabled:focus {
      background-color: #e6e6e6;
    }
    .react-calendar__tile--now {
      background: #ededed;
    }
    .react-calendar__tile--now:enabled:hover,
    .react-calendar__tile--now:enabled:focus {
      background: #ededed;
    }
    .react-calendar__tile--hasActive {
      background: #1aa5c0;
    }
    .react-calendar__tile--hasActive:enabled:hover,
    .react-calendar__tile--hasActive:enabled:focus {
      background: #a9d4ff;
    }
    .react-calendar__tile--active {
      background: #1aa5c0;
      abbr {
        color: white;
      }
    }
    .react-calendar__tile--active:enabled:hover,
    .react-calendar__tile--active:enabled:focus {
      background: #1aa5c0;
    }
    .react-calendar--selectRange .react-calendar__tile--hover {
      background-color: #e6e6e6;
    }
  }
`;

interface Props {
  days?: Date[];
  isLoading: boolean;
  onChangeSelectedDate: (date: Date) => void;
  setDate?: (date: Date) => void;
}
const DayPicker = ({ days, isLoading, onChangeSelectedDate }: Props) => {
  const [selectedDate, setSelectedDate] = useState<Date | undefined>(undefined);

  const isDateAvailable = (date: Date) => {
    if (!days) {
      console.log("Days data is undefined.");
      return false;
    }

    const formattedDate = moment(date).format("YYYY-MM-DD");

    return days.some((day) => moment(day).isSame(formattedDate, "day"));
  };
  //renderTitle
  const renderTileContent = ({ date, view }: { date: any; view: any }) => {
    if (view === "month") {
      return isDateAvailable(date) ? <span></span> : null;
    }
    return null;
  };
  //title class
  const tileClassName = ({ date }: { date: any }) => {
    return isDateAvailable(date) ? "available-date" : null;
  };

  useEffect(() => {
    if (days && days?.length > 0) setSelectedDate(new Date(days[0]));
    else setSelectedDate(undefined);
  }, [days]);

  return (
    <CalendarStyle>
      {
        <div className="flex flex-col justify-center items-center h-full">
          {
            <div className={isLoading ? "opacity-5" : ""}>
              {/* <Calendar
                onClickDay={(date) => {
                  setSelectedDate(date);
                  onChangeSelectedDate && onChangeSelectedDate(date);
                }}
                className="mb-4"
                tileContent={renderTileContent}
                tileClassName={tileClassName}
                {...(selectedDate && { value: selectedDate })}
                minDate={new Date()}
                maxDetail="month"
                // Set available dates as an array
                tileDisabled={({ date }) => !isDateAvailable(date)}
              /> */}
            </div>
          }
          {isLoading && (
            <div className="absolute flex flex-col justify-center items-center self-center h-full">
              <Spinner width={70} className="mx-auto" />
              <span>Chargement des dates</span>
              <span>disponibles</span>
            </div>
          )}
          {!isLoading &&
            (!days ||
              (days.length === 0 ? (
                <div className="flex flex-col justify-center items-center h-full">
                  <CiNoWaitingSign size={35} color="#1AA5C0"/>
                  <p className="text-center">Il n'y a pas de dates disponibles pour cette Spécialité</p>
                </div>
              ) : <>
                <Calendar
                  onClickDay={(date) => {
                    setSelectedDate(date);
                    onChangeSelectedDate && onChangeSelectedDate(date);
                  }}
                  className="mb-4"
                  tileContent={renderTileContent}
                  tileClassName={tileClassName}
                  {...(selectedDate && { value: selectedDate })}
                  minDate={new Date()}
                  maxDetail="month"
                  // Set available dates as an array
                  tileDisabled={({ date }) => !isDateAvailable(date)}
                />
              </>))}
        </div>
      }
    </CalendarStyle>
  );
};

export default DayPicker;
