//socket io context provider with typescript
import React, {
  createContext,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { io, Socket } from "socket.io-client";
// import { useAuth } from './authContext';
import { useQueryClient } from "react-query";
import { useNavigate } from "react-router-dom";
import { Alert, Typography } from "@material-tailwind/react";
import { CheckCircleIcon } from "@heroicons/react/24/solid";
import { AgentStatus } from "../enums/agent";
import { updateAgentStatus } from "../features/indicators/systemIndicatorSlice";
import { useDispatch } from "react-redux";

type NibpStatustype = {
  test_finished: number;
  test_ongoing: number;
  test_stopped: number;
  over_pressure_protected: number;
  cuf_leak_or_unattached: number;
  test_timeout: number;
  test_error: number;
  disturb_detected: number;
  result_OutofRange: number;
  initializing: number;
  initialized: number;
};

type OxymeterStatustype = {
  SPO2_is_normal: number;
  Sensor_is_off: number;
  No_finger_inserted: number;
  Searching_for_value: number;
  SPO2_is_abnormal_timeOut: number;
};

type oxymeterDataType = {
  pulse_rate: number;
  saturation: number;
  spo2_status: number;
};

interface SocketAgentContextProps {
  agentSocket: Socket | null;
  nibpCallBackStatus: NibpStatustype | null;
  oxymeterCallBackStatus: OxymeterStatustype | null;
  availableSensors: string[];
  availableServices: string[];
  socketCompagnion?: WebSocket | null;
}

const SocketAgentContext = createContext<SocketAgentContextProps>({
  agentSocket: null,
  nibpCallBackStatus: null,
  oxymeterCallBackStatus: null,
  availableSensors: [],
  availableServices: [],
  socketCompagnion: null,
});

export const useAgentSocket = () => useContext(SocketAgentContext);

interface SocketServerContextProps {
  socket: Socket | null;
  setServerSocket: React.Dispatch<React.SetStateAction<Socket | null>>;
}

const SocketServerContext = createContext<SocketServerContextProps>({
  socket: null,
  setServerSocket: () => null,
});

export const useServerSocket = () => useContext(SocketServerContext);

interface SocketIOContextProviderProps {
  children: React.ReactNode;
}

const SocketIOContextProvider: React.FC<SocketIOContextProviderProps> = ({
  children,
}) => {
  const [agentSocket, setAgentSocket] = useState<Socket | null>(null);
  const dispatch = useDispatch();
  const [socketCompagnion, setSocketCompagnion] = useState<WebSocket | null>(
    null
  );
  const [open, setOpen] = useState<boolean>(false);
  const [message, setMessage] = useState("");
  const [availableSensors, setAvailableSensors] = useState<string[]>([]);
  const user = { token: localStorage.getItem("token") };
  const queryClient = useQueryClient();

  const [nibpCallBackStatus, setNibpCallBackStatus] =
    useState<NibpStatustype | null>({
      test_finished: 0, //the data returned is the final result
      test_ongoing: 1, //the data not finished yet
      test_stopped: 2, //uncklick the button test
      over_pressure_protected: 3, //
      cuf_leak_or_unattached: 4, //fuit ou brassard non attaché
      test_timeout: 5, //echeque de la mesur
      test_error: 6, //echeque de la mesureany
      disturb_detected: 7, //patient est entrain de bouger
      result_OutofRange: 8, // the range depends on the patient's age
      initializing: 9, // NaN
      initialized: 10, // NaN
    });

  const [oxymeterCallBackStatus, setOxymeterCallBackStatus] =
    useState<OxymeterStatustype | null>({
      //Parse Oxymeter Status
      SPO2_is_normal: 0,
      Sensor_is_off: 1,
      No_finger_inserted: 2,
      Searching_for_value: 3, //after the detection of the finger start the measure
      SPO2_is_abnormal_timeOut: 4, //proximetly 7s
    });
  const [availableServices, setAvailableServices] = useState<string[]>([]);

  useEffect(() => {
    const newAgent = io("http://localhost:3000", {
      reconnection: true,
      reconnectionDelay: 5000,
      reconnectionAttempts: 10,
    });

    newAgent.on("connect_error", (reason) => {
      console.log("dispatching error ...")
      dispatch(updateAgentStatus(AgentStatus.Error));

      // console.log("connect_error ", { reason });
      setOpen(true);
      setMessage("Veuillez essayer d'exécuter l'Agent sur votre VisioStation.");
      setTimeout(() => {
        setOpen(false);
      }, 3000);
    });

    newAgent.on("connect", () => {
      console.log("agent..")
      dispatch(updateAgentStatus(AgentStatus.Connected));

      // console.log("Agent connected");

      newAgent.on("type", (data) => {
        //TODO: set on local storage
        // console.log("type", data);
      });

      //TODO: set the availability of the sensors based on the data received
      newAgent.on("available_sensors", (data) => {
        const values = JSON.parse(data);

        // get the name of the only true values
        const sensors = Object.keys(values).filter((key) => values[key]);
        console.log("sensor", sensors);

        setAvailableSensors(sensors);
      });
      setAgentSocket(newAgent);

      // setOpen(true);
      // setMessage("Agent is connected");
      // setTimeout(() => {
      //   setOpen(false);
      // }, 3000);
    });

    // on_off of oxymeter
    newAgent.on("oxy_status", (data: boolean) => {
      if (data && typeof data === "boolean") {
        //check if oxy in available sensors
        if (availableSensors.includes("oxy")) {
          //remove oxy from available sensors
          setAvailableSensors((prev) =>
            prev.filter((sensor) => sensor !== "oxy")
          );
        }
      } else {
        //check if oxy in available sensors
        if (!availableSensors.includes("oxy")) {
          //add oxy to available sensors
          setAvailableSensors((prev) => [...prev, "oxy"]);
        }
      }
    });

    // on_off of nibp
    newAgent.on("nibp_status", (data: boolean) => {
      if (data && typeof data === "boolean") {
        //check if nibp in available sensors
        if (availableSensors.includes("nibp")) {
          //remove nibp from available sensors
          setAvailableSensors((prev) =>
            prev.filter((sensor) => sensor !== "nibp")
          );
        }
      } else {
        //check if nibp in available sensors
        if (!availableSensors.includes("nibp")) {
          //add nibp to available sensors
          setAvailableSensors((prev) => [...prev, "nibp"]);
        }
      }
    });

    // on_off of ecg
    newAgent.on("ecg_status", (data: boolean) => {
      if (data && typeof data === "boolean") {
        //check if ecg in available sensors
        if (availableSensors.includes("ecg")) {
          //remove ecg from available sensors
          setAvailableSensors((prev) =>
            prev.filter((sensor) => sensor !== "ecg")
          );
        }
      } else {
        //check if ecg in available sensors
        if (!availableSensors.includes("ecg")) {
          //add ecg to available sensors
          setAvailableSensors((prev) => [...prev, "ecg"]);
        }
      }
    });

    return () => {
      newAgent.close();
    };
    // }, [user, queryClient, navigate]);
  }, []);

  //compagnion socket
  useEffect(() => {
    const socket = new WebSocket("ws://localhost:24808");
    setSocketCompagnion(socket);
    //listen to socket events
    socket.addEventListener("open", () => {
      // console.log("socketCompagnion useEffect open");
    });

    // Listen for messages
    socket.addEventListener("message", (event) => {
      if (event.type === "message") {
        const { messageType } = JSON.parse(event.data);
        if (messageType?.toLowerCase() === "info") {
          const { info } = JSON.parse(event.data);
          // console.log("exe services to run ", info.services);
              setAvailableServices(info.services);
        } else if (messageType === "command") {
        }
      }
      //listen to other messages
      //else code ...
    });

    // Listen for close
    socket.addEventListener("close", (reason) => {
      // console.log("socketCompagnion useEffect closed", reason);
    });

    // Listen for errors
    socket.addEventListener("error", (error: any) => {
      // console.log("socketCompagnion useEffect error > reason: ", error);
      // console.log(error.currentTarget.readyState);
      if (error.currentTarget.readyState === 3) {
        // console.log("Failed to connect to compagnion");
      }
    });

    return () => {
      // console.log("socketCompagnion useEffect cleanup");
      socket.close();
    };
  }, []);

  return (
    <SocketAgentContext.Provider
      value={{
        agentSocket,
        nibpCallBackStatus,
        oxymeterCallBackStatus,
        availableSensors,
        availableServices,
        // socketCompagnion,
      }}
    >
      {children}
      {/* <Alert
        show={open}
        onTap={() => setOpen(false)}
        color="orange"
        className="max-w-screen-md"
        icon={<CheckCircleIcon className="mt-px h-6 w-6" />}
        // onClose={() => setOpen(false)}
      >
        <Typography variant="h5" color="white">
          Warning
        </Typography>
        <Typography color="white" className="mt-2 font-normal">
          {message}
        </Typography>
      </Alert> */}
    </SocketAgentContext.Provider>
  );
};

export default SocketIOContextProvider;
