import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import { ITraitementState } from '../../interfaces/traitement'
import { SLICE } from "../../enums/store";

const initialState: ITraitementState = {
    medicationId: 0,
    startTime: new Date(),
    endTime: new Date(),
    medicationHistory: "",
    treatmentsArr: [],
    treatment: {},
    medicationsArr: [],
    note: "",
    medicationName: "",
    patientId: 0,
    medications: "",
    listNewMedication: [] ,
    isRefetchRequired: true,
}

export const traitementSlice = createSlice({
    name: SLICE.TRAITEMENT,
    initialState,
    reducers: {

        setMedicationId: (state, action: PayloadAction<number>) => {
            state.medicationId = action.payload
        },
        setTreatmentsArr: (state, action: PayloadAction<[]>) => {
            state.treatmentsArr = action.payload
        },
        setPatientId: (state, action: PayloadAction<number>) => {
            state.patientId = action.payload
        },
        setMedications: (state, action: PayloadAction<any>) => {
            state.medications = action.payload,
                state.isRefetchRequired = action.payload.isRefetchRequired
        },
        setListNewMedication: (state, action: PayloadAction<any>) => {
            state.listNewMedication = action.payload,
            console.log(setListNewMedication)
                state.isRefetchRequired = action.payload.isRefetchRequired
        }
    },
})

// Action creators are generated for each case reducer function
export const { setMedicationId, setTreatmentsArr, setPatientId, setMedications, setListNewMedication } = traitementSlice.actions
export default traitementSlice.reducer