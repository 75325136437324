// diagnosticAssocieSlice.ts

import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { SLICE } from "../../enums/store";
const initialState: any = {
  list: [],
  princip: "",
};

const diagnosticAssocieSlice = createSlice({
  name: SLICE.DIAGNOSTIC_SECONDAIRE,
  initialState,
  reducers: {
    setDiagnosticAssocie: (state, action: PayloadAction<any>) => {
      return {
        ...state,
        list:
          state?.list?.length > 0
            ? [...new Set([...state.list, action.payload])]
            : [action.payload],
      };
    },
    spliceDiagnosticAssocie: (state, action: PayloadAction<number>) => {
      const splicedList = [...state.list];
      splicedList.splice(action.payload, 1);

      console.log("spliceDiagnosticAssocie", state.list);
      return {
        ...state,
        list: splicedList,
      };
    },
    setDiagnosticPrincip: (state, action: PayloadAction<any>) => {
      return {
        ...state,
        princip: action.payload,
      };
    },
    clearDiagnosticDisplayData: () => {
      return {
        list: [],
        princip: "",
      };
    },
  },
});

export const {
  setDiagnosticAssocie,
  clearDiagnosticDisplayData,
  setDiagnosticPrincip,
  spliceDiagnosticAssocie,
} = diagnosticAssocieSlice.actions;
export default diagnosticAssocieSlice.reducer;
