// consultationDataSlice.ts

import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { SLICE } from "../../enums/store";
import { IConsultationDataState } from "../../interfaces/Consultation";
import { CHIEF_COMPLAINT_TYPE } from "../../enums/chiefComplaintConsultations";
const initialState: IConsultationDataState = {
  isFirstCommit: true, // that mean that this is the first time we will assign somthing to consultationData
  consultationData: null,
  consultationCheck: null,
  lastPatientOngoingConsultation: null,
  isLoading: false,
  isUpdated: false,
  isRefetchRequired: true,
  error: null,
  medicalHistory: undefined,
};

const consultationDataSlice = createSlice({
  name: SLICE.CONSULTATION_DATA,
  initialState,
  reducers: {
    setConsultationDataCheck: (
      state: IConsultationDataState,
      action: PayloadAction<any>
    ) => {
      state.consultationCheck = action.payload;
    },
    setLastPatientOngoingConsultation: (
      state: IConsultationDataState,
      action: PayloadAction<any>
    ) => {
      state.lastPatientOngoingConsultation = action.payload;
    },
    setIsFirstCommmit: (
      state: IConsultationDataState,
      action: PayloadAction<boolean>
    ) => {
      state.isFirstCommit = action.payload;
    },
    setConsultationData: (
      state: IConsultationDataState,
      action: PayloadAction<IConsultationDataState>
    ) => {
      state.consultationData = action.payload.consultationData;
      state.isRefetchRequired = action.payload.isRefetchRequired;
      state.isUpdated = true;
    },
    setMotifsData: (state: any, action: PayloadAction<any>) => {
      return {
        ...state,
        consultationData: {
          ...state.consultationData,
          motifComplaint: action.payload,
        },
      };
    },
    setVitalSignsConsultation: (
      state: IConsultationDataState,
      action: PayloadAction<any>
    ) => {
      state.consultationData = {
        vitalSigns: action.payload,
      };
    },
    setTraitement: (
      state: IConsultationDataState,
      action: PayloadAction<any>
    ) => {
      state.consultationData = action.payload;
      console.log("consultationData traitement", action.payload);
    },
    spliceMotifsData: (
      state: IConsultationDataState,
      action: PayloadAction<any>
    ) => {
      if (state?.consultationData?.motifComplaint?.length > 0) {
        const splicedMotifs = JSON.parse(
          JSON.stringify(state?.consultationData?.motifComplaint)
        );
        splicedMotifs.splice(action.payload, 1);
        state.consultationData = { motifComplaint: splicedMotifs };
      }
    },
    spliceDignosticSecondaire: (
      state: IConsultationDataState,
      action: PayloadAction<any>
    ) => {
      const splicedDiagnosticSec = state.consultationData
        ?.chiefComplaintConsultations
        ? JSON.parse(
          JSON.stringify(state.consultationData?.chiefComplaintConsultations)
        ).filter((e: any) => e.type === CHIEF_COMPLAINT_TYPE.SECONDARY)
        : [];

      splicedDiagnosticSec.splice(action.payload, 1);
      state.consultationData = {
        chiefComplaintConsultations: [
          ...state.consultationData?.chiefComplaintConsultations.filter(
            (e: any) => e.type === CHIEF_COMPLAINT_TYPE.PRIMARY
          ),
          ...splicedDiagnosticSec,
        ],
      };
      console.log(
        "spliceDignosticSecondaire",
        state.consultationData.chiefComplaintConsultations
      );
      return state;
    },
    spliceDignosticPrincipal: (state: IConsultationDataState) => {
      state.consultationData = {
        chiefComplaintConsultations: [],
      };
    },
    spliceSurgicalMotifs: (
      state: IConsultationDataState,
      action: PayloadAction<any>
    ) => {
      const splicedSurgicalMotifs = JSON.parse(
        JSON.stringify(
          state.consultationData?.medicalHistory?.surgical[
            action.payload.itemIndex
          ]?.motif
        )
      );
      const surgicals = JSON.parse(
        JSON.stringify(state.consultationData?.medicalHistory?.surgical)
      );
      splicedSurgicalMotifs.splice(action.payload.index, 1);
      surgicals[action.payload.itemIndex] = {
        year: surgicals[action.payload.itemIndex]?.year,
        comment: surgicals[action.payload.itemIndex]?.comment,
        date: surgicals[action.payload.itemIndex]?.date,
        motif: splicedSurgicalMotifs,
      };

      state.consultationData.medicalHistory = {
        ...state.consultationData.medicalHistory,
        surgical: surgicals,
      };
    },
    spliceMedicalMotifs: (
      state: IConsultationDataState,
      action: PayloadAction<any>
    ) => {
      const splicedMedicalMotifs = JSON.parse(
        JSON.stringify(
          state.consultationData?.medicalHistory?.medical[
            action.payload.itemIndex
          ]?.motif
        )
      );
      const medicals = JSON.parse(
        JSON.stringify(state.consultationData?.medicalHistory?.medical)
      );
      splicedMedicalMotifs.splice(action.payload.index, 1);
      medicals[action.payload.itemIndex] = {
        year: medicals[action.payload.itemIndex]?.year,
        motif: splicedMedicalMotifs,
      };

      state.consultationData.medicalHistory = {
        ...state.consultationData.medicalHistory,
        medical: medicals,
      };
    },
    spliceSurgicalData: (
      state: IConsultationDataState,
      action: PayloadAction<number>
    ) => {
      const splicedSurgical = state.consultationData?.medicalHistory?.surgical
        ? JSON.parse(
          JSON.stringify(state.consultationData?.medicalHistory?.surgical)
        )
        : [];
      console.log(splicedSurgical, action.payload);
      splicedSurgical.splice(action.payload, 1);

      return state.consultationData?.medicalHistory
        ? {
          ...state,
          consultationData: {
            ...state.consultationData,
            medicalHistory: {
              ...state.consultationData.medicalHistory,
              surgical: splicedSurgical,
            },
          },
        }
        : {
          ...state,
          consultationData: {
            ...state.consultationData,
            medicalHistory: {
              surgical: splicedSurgical,
            },
          },
        };
    },
    spliceMedicalData: (
      state: IConsultationDataState,
      action: PayloadAction<number>
    ) => {
      const splicedMedical = state.consultationData?.medicalHistory?.medical
        ? JSON.parse(
          JSON.stringify(state.consultationData?.medicalHistory?.medical)
        )
        : [];
      splicedMedical.splice(action.payload, 1);
      return state.consultationData?.medicalHistory
        ? {
          ...state,
          consultationData: {
            ...state.consultationData,
            medicalHistory: {
              ...state.consultationData.medicalHistory,
              medical: splicedMedical,
            },
          },
        }
        : {
          ...state,
          consultationData: {
            ...state.consultationData,
            medicalHistory: {
              medical: splicedMedical,
            },
          },
        };
    },
    spliceHospitalizationData: (
      state: IConsultationDataState,
      action: PayloadAction<number>
    ) => {
      const splicedHospitalizations = state.consultationData?.medicalHistory
        ?.hospitalizations
        ? JSON.parse(
          JSON.stringify(
            state.consultationData?.medicalHistory?.hospitalizations
          )
        )
        : [];
      splicedHospitalizations.splice(action.payload, 1);
      state.consultationData.medicalHistory = {
        hospitalizations: splicedHospitalizations,
      };
    },
    spliceAntecedentsMedicals: (
      state: IConsultationDataState,
      action: PayloadAction<number>
    ) => {
      const splicedMedical = state.consultationData?.medicalHistory?.medical;
      splicedMedical.splice(action.payload, 1);
      state.consultationData.medicalHistory.medical = splicedMedical;
    },
    setAntecedentsMedicals: (
      state: IConsultationDataState,
      action: PayloadAction<string>
    ) => {
      const editedMedicals = state.consultationData?.medicalHistory?.medical
        ? [
          ...JSON.parse(
            JSON.stringify(state.consultationData?.medicalHistory?.medical)
          ),
          action.payload,
        ]
        : [action.payload];
      state.consultationData.medicalHistory.medical = editedMedicals;
    },
    setSurgicalYear: (
      state: IConsultationDataState,
      action: PayloadAction<any>
    ) => {
      const editedSurgical = state.consultationData?.medicalHistory?.surgical
        ? [
          ...JSON.parse(
            JSON.stringify(state.consultationData?.medicalHistory?.surgical)
          ),
        ]
        : [];

      editedSurgical[action.payload.index] = state.consultationData
        ?.medicalHistory?.surgical
        ? {
          year: action.payload.value,
          comment: state.consultationData?.medicalHistory?.surgical
            ? JSON.parse(
              JSON.stringify(
                state.consultationData?.medicalHistory?.surgical
              )
            )[action.payload.index]?.comment
            : "",
          date: state.consultationData?.medicalHistory?.surgical
            ? JSON.parse(
              JSON.stringify(
                state.consultationData?.medicalHistory?.surgical
              )
            )[action.payload.index]?.date
            : "",
          motif: state.consultationData?.medicalHistory?.surgical
            ? JSON.parse(
              JSON.stringify(
                state.consultationData?.medicalHistory?.surgical
              )
            )[action.payload.index]?.motif
            : [],
        }
        : {
          year: action.payload.value,
          comment: "",
          date: "",
          motif: [],
        };

      return state.consultationData?.medicalHistory
        ? {
          ...state,
          consultationData: {
            ...state.consultationData,
            medicalHistory: {
              ...state.consultationData.medicalHistory,
              surgical: editedSurgical,
            },
          },
        }
        : {
          ...state,
          consultationData: {
            ...state.consultationData,
            medicalHistory: {
              surgical: editedSurgical,
            },
          },
        };
    },
    setSurgicalComment: (
      state: IConsultationDataState,
      action: PayloadAction<any>
    ) => {
      const editedSurgical = state.consultationData?.medicalHistory?.surgical
        ? [
          ...JSON.parse(
            JSON.stringify(state.consultationData?.medicalHistory?.surgical)
          ),
        ]
        : [];

      editedSurgical[action.payload.index] = state.consultationData
        ?.medicalHistory?.surgical
        ? {
          year: state.consultationData?.medicalHistory?.surgical
            ? JSON.parse(
              JSON.stringify(
                state.consultationData?.medicalHistory?.surgical
              )
            )[action.payload.index]?.year : "",
          date: state.consultationData?.medicalHistory?.surgical
            ? JSON.parse(
              JSON.stringify(
                state.consultationData?.medicalHistory?.surgical
              )
            )[action.payload.index]?.date : "",

          comment: action.payload.value,

          motif: state.consultationData?.medicalHistory?.surgical
            ? JSON.parse(
              JSON.stringify(
                state.consultationData?.medicalHistory?.surgical
              )
            )[action.payload.index]?.motif
            : [],
        }
        : {
          year: "",
          date: "",
          comment: action.payload.value,
          motif: [],
        };


      return state.consultationData?.medicalHistory
        ? {
          ...state,
          consultationData: {
            ...state.consultationData,
            medicalHistory: {
              ...state.consultationData.medicalHistory,
              surgical: editedSurgical,
            },
          },
        }
        : {
          ...state,
          consultationData: {
            ...state.consultationData,
            medicalHistory: {
              surgical: editedSurgical,
            },
          },
        };
    },
    setSurgicalDate: (
      state: IConsultationDataState,
      action: PayloadAction<any>
    ) => {
      console.log("updating date", action.payload)
      const editedSurgical = state.consultationData?.medicalHistory?.surgical
        ? [
          ...JSON.parse(
            JSON.stringify(state.consultationData?.medicalHistory?.surgical)
          ),
        ]
        : [];

      editedSurgical[action.payload.index] = state.consultationData
        ?.medicalHistory?.surgical
        ? {
          year: new Date(action.payload.value).getFullYear(),
          date:action.payload.value,
        

          comment:    state.consultationData?.medicalHistory?.surgical
          ? JSON.parse(
            JSON.stringify(
              state.consultationData?.medicalHistory?.surgical
            )
          )[action.payload.index]?.comment : "",
          motif: state.consultationData?.medicalHistory?.surgical
            ? JSON.parse(
              JSON.stringify(
                state.consultationData?.medicalHistory?.surgical
              )
            )[action.payload.index]?.motif
            : [],
        }
        : {
          year: "",
          date: action.payload.value,
          comment: "",
          motif: [],
        };


      return state.consultationData?.medicalHistory
        ? {
          ...state,
          consultationData: {
            ...state.consultationData,
            medicalHistory: {
              ...state.consultationData.medicalHistory,
              surgical: editedSurgical,
            },
          },
        }
        : {
          ...state,
          consultationData: {
            ...state.consultationData,
            medicalHistory: {
              surgical: editedSurgical,
            },
          },
        };
    },
    setMedicalYear: (
      state: IConsultationDataState,
      action: PayloadAction<any>
    ) => {
      const editedMedical = state.consultationData?.medicalHistory?.medical
        ? [
          ...JSON.parse(
            JSON.stringify(state.consultationData?.medicalHistory?.medical)
          ),
        ]
        : [];

      editedMedical[action.payload.index] = state.consultationData
        ?.medicalHistory?.medical
        ? {
          year: action.payload.value,
          motif: state.consultationData?.medicalHistory?.medical
            ? JSON.parse(
              JSON.stringify(
                state.consultationData?.medicalHistory?.medical
              )
            )[action.payload.index]?.motif
            : [],
        }
        : {
          year: action.payload.value,
          motif: [],
        };

      return state.consultationData?.medicalHistory
        ? {
          ...state,
          consultationData: {
            ...state.consultationData,
            medicalHistory: {
              ...state.consultationData.medicalHistory,
              medical: editedMedical,
            },
          },
        }
        : {
          ...state,
          consultationData: {
            ...state.consultationData,
            medicalHistory: {
              medical: editedMedical,
            },
          },
        };
    },
    setHospitalizationDate: (
      state: IConsultationDataState,
      action: PayloadAction<any>
    ) => {
      const editedHospitalizations = state.consultationData?.medicalHistory
        ?.hospitalizations
        ? [
          ...JSON.parse(
            JSON.stringify(
              state.consultationData?.medicalHistory?.hospitalizations
            )
          ),
        ]
        : [];

      editedHospitalizations[action.payload.index] = state.consultationData
        ?.medicalHistory?.hospitalizations
        ? {
          date: action.payload.value,
          motif: state.consultationData?.medicalHistory?.hospitalizations
            ? JSON.parse(
              JSON.stringify(
                state.consultationData?.medicalHistory?.hospitalizations
              )
            )[action.payload.index]?.motif
            : [],
        }
        : {
          date: action.payload.value,
          motif: [],
        };
      state.consultationData.medicalHistory = {
        ...state.consultationData?.medicalHistory,
        hospitalizations: editedHospitalizations,
      };
    },
    setSurgicalMotifs: (
      state: IConsultationDataState,
      action: PayloadAction<any>
    ) => {
      const editedSurgical: any = state.consultationData?.medicalHistory
        ?.surgical
        ? [
          ...JSON.parse(
            JSON.stringify(state.consultationData?.medicalHistory?.surgical)
          ),
        ]
        : [];

      editedSurgical[action.payload.index] = state.consultationData
        ?.medicalHistory?.surgical
        ? {
          year: editedSurgical[action.payload.index]?.year,
          comment: editedSurgical[action.payload.index]?.comment
            ? editedSurgical[action.payload.index].comment
            : "",
          date: editedSurgical[action.payload.index]?.date
            ? editedSurgical[action.payload.index].date
            : "",
          motif: editedSurgical[action.payload.index]?.motif
            ? [
              ...new Set([
                ...editedSurgical[action.payload.index].motif,
                action.payload.value,
              ]),
            ]
            : [action.payload.value],
        }
        : {
          year: "",
          date: "",
          comment: "",
          motif: [action.payload.value],
        };

      return state.consultationData?.medicalHistory
        ? {
          ...state,
          consultationData: {
            ...state.consultationData,
            medicalHistory: {
              ...state.consultationData.medicalHistory,
              surgical: editedSurgical,
            },
          },
        }
        : {
          ...state,
          consultationData: {
            ...state.consultationData,
            medicalHistory: {
              surgical: editedSurgical,
            },
          },
        };
    },
    setMedicalMotifs: (
      state: IConsultationDataState,
      action: PayloadAction<any>
    ) => {
      console.log("action.payload", action.payload);
      const editedMedical: any = state.consultationData?.medicalHistory?.medical
        ? [
          ...JSON.parse(
            JSON.stringify(state.consultationData?.medicalHistory?.medical)
          ),
        ]
        : [];

      editedMedical[action.payload.index] = state.consultationData
        ?.medicalHistory?.medical
        ? {
          year: editedMedical[action.payload.index]?.year,
          motif: editedMedical[action.payload.index]?.motif
            ? [
              ...new Set([
                ...editedMedical[action.payload.index].motif,
                action.payload.value,
              ]),
            ]
            : [action.payload.value],
        }
        : {
          year: "",
          motif: [action.payload.value],
        };

      return state.consultationData?.medicalHistory
        ? {
          ...state,
          consultationData: {
            ...state.consultationData,
            medicalHistory: {
              ...state.consultationData.medicalHistory,
              medical: editedMedical,
            },
          },
        }
        : {
          ...state,
          consultationData: {
            ...state.consultationData,
            medicalHistory: {
              medical: editedMedical,
            },
          },
        };
    },
    setHopitalizationMotifs: (
      state: IConsultationDataState,
      action: PayloadAction<any>
    ) => {
      const editedHospitalizations: any = state.consultationData?.medicalHistory
        ?.hospitalizations
        ? [
          ...JSON.parse(
            JSON.stringify(
              state.consultationData?.medicalHistory?.hospitalizations
            )
          ),
        ]
        : [];

      editedHospitalizations[action.payload.index] = state.consultationData
        ?.medicalHistory?.hospitalizations
        ? {
          date: editedHospitalizations[action.payload.index]?.date,
          motif: editedHospitalizations[action.payload.index]?.motif
            ? [
              ...editedHospitalizations[action.payload.index].motif,
              action.payload.value,
            ]
            : [action.payload.value],
        }
        : {
          date: "",
          motif: [action.payload.value],
        };
      state.consultationData.medicalHistory = {
        ...state.consultationData?.medicalHistory,
        hospitalizations: editedHospitalizations,
      };
    },
    addNewSurgical: (state) => {
      return {
        ...state,
        consultationData: {
          ...state.consultationData,
          medicalHistory: state.consultationData?.medicalHistory?.surgical
            ? {
              ...state.consultationData?.medicalHistory,

              surgical: [
                ...state.consultationData?.medicalHistory.surgical,
                { year: 2024, motif: [], comment: "", date:"" },
              ],
            }
            : {
              surgical: [{ year: 2024, motif: [], comment: "", date:"" }],
            },
        },
      };
    },
    addNewMedical: (state) => {
      return {
        ...state,
        consultationData: {
          ...state.consultationData,
          medicalHistory: state.consultationData?.medicalHistory?.medical
            ? {
              ...state.consultationData?.medicalHistory,

              medical: [
                ...state.consultationData?.medicalHistory.medical,
                { year: 2024, motif: [] },
              ],
            }
            : {
              medical: [{ year: 2024, motif: [] }],
            },
        },
      };
    },
    addNewHospitalization: (state) => {
      return {
        ...state,
        consultationData: {
          ...state.consultationData,
          medicalHistory: state.consultationData?.medicalHistory
            ?.hospitalizations
            ? {
              ...state.consultationData?.medicalHistory,

              hospitalizations: [
                ...state.consultationData?.medicalHistory.hospitalizations,
                { date: "", motif: [] },
              ],
            }
            : {
              ...state.consultationData?.medicalHistory,

              hospitalizations: [{ date: "", motif: [] }],
            },
        },
      };
    },
    clearHospitalization: (state) => {
      state.consultationData.medicalHistory.hospitalizations = [];
    },
    clearSurgical: (state) => {
      return {
        ...state,
        consultationData: {
          ...state.consultationData,
          medicalHistory: state?.consultationData?.medicalHistory
            ? {
              ...state.consultationData.medicalHistory,
              surgical: [],
            }
            : {
              surgical: [],
            },
        },
      };
      state.consultationData.medicalHistory.surgical = null;
    },
    clearMedical: (state) => {
      return {
        ...state,
        consultationData: {
          ...state.consultationData,
          medicalHistory: state?.consultationData?.medicalHistory
            ? {
              ...state.consultationData.medicalHistory,
              medical: [],
            }
            : {
              medical: [],
            },
        },
      };
      state.consultationData.medicalHistory.medical = null;
    },
    clearConsultationData: (state) => {
      state.consultationData = null;
      // state.isRefetchRequired=action.payload.isRefetchRequired
      state.isUpdated = true;
    },
    spliceFamilySelected: (
      state: IConsultationDataState,
      action: PayloadAction<any>
    ) => {
      const splicedFamilySelected =
        state.consultationData?.medicalHistory?.family?.selected;
      splicedFamilySelected.splice(action.payload, 1);
      state.consultationData.medicalHistory.family.selected =
        splicedFamilySelected;
    },
    spliceFamilyOthers: (
      state: IConsultationDataState,
      action: PayloadAction<any>
    ) => {
      const splicedFamilyOthers =
        state.consultationData?.medicalHistory?.family?.others;
      splicedFamilyOthers.splice(action.payload, 1);
      state.consultationData.medicalHistory.family.others = splicedFamilyOthers;
    },
    spliceAllergy: (
      state: IConsultationDataState,
      action: PayloadAction<any>
    ) => {
      const splicedAllergy = state.consultationData?.medicalHistory?.allergy;
      splicedAllergy.splice(action.payload, 1);
      state.consultationData.medicalHistory.allergy = splicedAllergy;
    },
    setFamilySelected: (
      state: IConsultationDataState,
      action: PayloadAction<any>
    ) => {
      const selectedFamily =
        state.consultationData?.medicalHistory?.family?.selected?.length > 0
          ? [
            ...new Set([
              ...state.consultationData?.medicalHistory.family.selected,
              action.payload,
            ]),
          ]
          : [action.payload];

      return {
        ...state,
        consultationData: {
          ...state.consultationData,
          medicalHistory: {
            ...state.consultationData?.medicalHistory,
            family: {
              selected: selectedFamily,
              others: state.consultationData?.medicalHistory?.family?.others
                ? state.consultationData.medicalHistory.family.others
                : [],
            },
          },
        },
      };
    },
    setAllergy: (state: IConsultationDataState, action: PayloadAction<any>) => {
      const selectedAllergies =
        state.consultationData?.medicalHistory?.allergy?.length > 0
          ? [
            ...new Set([
              ...state.consultationData?.medicalHistory.allergy,
              action.payload,
            ]),
          ]
          : [action.payload];

      return {
        ...state,
        consultationData: {
          ...state.consultationData,
          medicalHistory: {
            ...state.consultationData?.medicalHistory,
            allergy: selectedAllergies,
          },
        },
      };
    },
    setFamilyOthers: (
      state: IConsultationDataState,
      action: PayloadAction<any>
    ) => {
      const othersFamily =
        state.consultationData?.medicalHistory?.family?.others?.length > 0
          ? [
            ...new Set([
              ...state.consultationData?.medicalHistory.family.others,
              action.payload,
            ]),
          ]
          : [action.payload];

      return {
        ...state,
        consultationData: {
          ...state.consultationData,
          medicalHistory: {
            ...state.consultationData?.medicalHistory,
            family: {
              others: othersFamily,
              selected: state.consultationData?.medicalHistory?.family?.selected
                ? state.consultationData?.medicalHistory.family.selected
                : [],
            },
          },
        },
      };
    },
    setToxicsTabacCigarttesPerDay: (
      state: IConsultationDataState,
      action: PayloadAction<any>
    ) => {
      return {
        ...state,
        consultationData: {
          ...state.consultationData,
          medicalHistory: {
            ...state.consultationData?.medicalHistory,
            toxic: {
              ...state.consultationData?.medicalHistory?.toxic,
              tabac: {
                ...(state.consultationData?.medicalHistory?.toxic?.tabac || {}),
                perDay: action.payload,
              },
            },
          },
        },
      };
    },
    clearToxicsTabac: (state: IConsultationDataState) => {
      return {
        ...state,
        consultationData: {
          ...state.consultationData,
          medicalHistory: {
            ...state.consultationData?.medicalHistory,
            toxic: {
              ...state.consultationData?.medicalHistory?.toxic,
              tabac: null,
            },
          },
        },
      };
    },
    setToxicsTabacType: (
      state: IConsultationDataState,
      action: PayloadAction<any>
    ) => {
      console.log(action.payload);

      return {
        ...state,
        consultationData: {
          ...state.consultationData,
          medicalHistory: {
            ...state.consultationData?.medicalHistory,
            toxic: {
              ...state.consultationData?.medicalHistory?.toxic,
              tabac: {
                ...(state.consultationData?.medicalHistory?.toxic?.tabac || {}),
                type: action.payload,
              },
            },
          },
        },
      };
    },
    setToxicsTabacDuration: (
      state: IConsultationDataState,
      action: PayloadAction<any>
    ) => {
      return {
        ...state,
        consultationData: {
          ...state.consultationData,
          medicalHistory: {
            ...state.consultationData?.medicalHistory,
            toxic: {
              ...state.consultationData?.medicalHistory?.toxic,
              tabac: {
                ...(state.consultationData?.medicalHistory?.toxic?.tabac || {}),
                duration: action.payload,
              },
            },
          },
        },
      };
    },
    setToxicsTabacBoxPerYear: (
      state: IConsultationDataState,
      action: PayloadAction<any>
    ) => {
      return {
        ...state,
        consultationData: {
          ...state.consultationData,
          medicalHistory: {
            ...state.consultationData?.medicalHistory,
            toxic: state.consultationData?.medicalHistory?.toxic
              ? {
                ...state.consultationData?.medicalHistory?.toxic,
                tabac: {
                  ...(state.consultationData?.medicalHistory?.toxic?.tabac ||
                    {}),
                  boxPerYear: action.payload,
                },
              }
              : {
                tabac: {
                  ...(state.consultationData?.medicalHistory?.toxic?.tabac ||
                    {}),
                  boxPerYear: action.payload,
                },
              },
          },
        },
      };
    },
    setToxicsOthers: (
      state: IConsultationDataState,
      action: PayloadAction<any>
    ) => {
      return {
        ...state,
        consultationData: {
          ...state.consultationData,
          medicalHistory: {
            ...state.consultationData?.medicalHistory,
            toxic: {
              ...state.consultationData?.medicalHistory?.toxic,
              others: {
                nature: action.payload,
              },
            },
          },
        },
      };
    },
    setToxicsAlcohol: (
      state: IConsultationDataState,
      action: PayloadAction<any>
    ) => {
      return {
        ...state,
        consultationData: {
          ...state.consultationData,
          medicalHistory: {
            ...state.consultationData?.medicalHistory,
            toxic: {
              ...state.consultationData?.medicalHistory?.toxic,
              alcohol: {
                isAlcoholic: action.payload,
              },
            },
          },
        },
      };
    },
    setMedicationConsults: (
      state: IConsultationDataState,
      action: PayloadAction<any>
    ) => {
      return {
        ...state,
        consultationData: {
          ...state.consultationData,
          medicationConsultations: action.payload,
        },
      };
    },
    setBioAnalyse: (
      state: IConsultationDataState,
      action: PayloadAction<any>
    ) => {
      return {
        ...state,
        consultationData: {
          ...state.consultationData,
          analyseConsultations: action.payload,
        },
      };
    },
    setAnalyseRadio: (
      state: IConsultationDataState,
      action: PayloadAction<any>
    ) => {
      return {
        ...state,
        consultationData: {
          ...state.consultationData,
          imagingConsultations: action.payload,
        },
      };
    },
    setDiagnosticPrincipale: (
      state: IConsultationDataState,
      action: PayloadAction<number>
    ) => {
      const chiefs =
        state.consultationData?.chiefComplaintConsultations?.length > 0
          ? JSON.parse(
            JSON.stringify(state.consultationData.chiefComplaintConsultations)
          )
          : [];
      //only one primary chiefComplaint
      const filtredChiefs = chiefs.filter(
        (e: { chiefComplaintId: number; type: "secondary" }) =>
          e.type === "secondary"
      );

      filtredChiefs.unshift({
        chiefComplaintId: action.payload,
        type: "primary",
      });
      return {
        ...state,
        consultationData: {
          ...state.consultationData,
          chiefComplaintConsultations: filtredChiefs,
        },
      };
    },
    clearDiagnostics: (state: IConsultationDataState) => {
      return {
        ...state,
        consultationData: {
          ...state.consultationData,
          chiefComplaintConsultations: [],
        },
      };
    },
    clearToxicsOthers: (state: IConsultationDataState) => {
      return {
        ...state,
        consultationData: {
          ...state.consultationData,
          medicalHistory: {
            ...state.consultationData?.medicalHistory,
            toxic: {
              ...state.consultationData?.medicalHistory?.toxic,
              others: null,
            },
          },
        },
      };
    },
    setDiagnosticSecondaire: (
      state: IConsultationDataState,
      action: PayloadAction<number>
    ) => {
      const chiefs =
        state.consultationData?.chiefComplaintConsultations?.length > 0
          ? JSON.parse(
            JSON.stringify(state.consultationData.chiefComplaintConsultations)
          )
          : [];

      if (
        chiefs.filter(
          (e: any) =>
            e.chiefComplaintId === action.payload && e.type === "secondary"
        ).length === 0
      ) {
        chiefs.push({
          chiefComplaintId: action.payload,
          type: "secondary",
        });
      }

      return {
        ...state,
        consultationData: {
          ...state.consultationData,
          chiefComplaintConsultations: chiefs,
        },
      };
    },
    setConclusion: (
      state: IConsultationDataState,
      action: PayloadAction<string>
    ) => {
      return {
        ...state,
        consultationData: {
          ...state.consultationData,
          conclusion: action.payload,
        },
      };
    },
    addNewMedicationConsultation: (state: IConsultationDataState) => {
      const defaultData = {
        medicationId: 0,
        morning: 0,
        noon: 0,
        evening: 0,
        period: 0,
        note: "",
        route: "",
        startTime: "",
        endTime: "",
      };
      return {
        ...state,
        consultationData: {
          ...state.consultationData,
          medicationConsultations: state.consultationData
            ?.medicationConsultations
            ? [...state.consultationData.medicationConsultations, defaultData]
            : [defaultData],
        },
      };
    },
  },
});

export const {
  setMedicalMotifs,
  setMedicalYear,
  setConsultationData,
  setIsFirstCommmit,
  setDiagnosticSecondaire,
  spliceMotifsData,
  setAnalyseRadio,
  spliceSurgicalData,
  setMedicationConsults,
  setToxicsAlcohol,
  spliceAntecedentsMedicals,
  setSurgicalYear,
  setSurgicalComment,
  setSurgicalDate,
  setBioAnalyse,
  clearConsultationData,
  clearHospitalization,
  setAllergy,
  clearSurgical,
  clearToxicsTabac,
  setFamilyOthers,
  setLastPatientOngoingConsultation,
  setFamilySelected,
  setConclusion,
  setConsultationDataCheck, // this is a validator of the consultation data
  setHopitalizationMotifs,
  setSurgicalMotifs,
  setAntecedentsMedicals,
  addNewHospitalization,
  clearDiagnostics,
  addNewMedicationConsultation,
  addNewSurgical,
  setToxicsTabacType,
  setToxicsTabacDuration,
  spliceMedicalData,
  setToxicsTabacBoxPerYear,
  setToxicsTabacCigarttesPerDay,
  spliceFamilySelected,
  setVitalSignsConsultation,
  setMotifsData,
  setToxicsOthers,
  spliceFamilyOthers,
  spliceSurgicalMotifs,
  spliceMedicalMotifs,
  spliceAllergy,
  setDiagnosticPrincipale,
  addNewMedical,
  clearMedical,
  spliceDignosticPrincipal,
  spliceDignosticSecondaire,
  setTraitement,
  setHospitalizationDate,
  clearToxicsOthers,
} = consultationDataSlice.actions;
export default consultationDataSlice.reducer;
