// persistedPrescriptionDataSlice.ts

import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { SLICE } from "../../enums/store";
import { IotherFamiliauxState } from "../../interfaces/otherFamiliaux";
import { IpersistedPrescriptionDataState } from "../../interfaces/PersistedData";
const initialState: IpersistedPrescriptionDataState = {
  persistedData: {},
  error: null,
  isLoading: false,
  isUpdated: false,
};

const persistedPrescriptionDataSlice = createSlice({
    name: "prescs",
    initialState,
    reducers: {
        setMedicationNames: (
            state: IpersistedPrescriptionDataState,
            action: PayloadAction<{ index: number; value: string }>
          ) => {
            const { index, value } = action.payload;
          
            const updatedMedicationNames = [...(state.persistedData.medicationNames || [])];
          
            if (index >= 0 && index < updatedMedicationNames.length) {
              updatedMedicationNames[index] = value;
            }
            else if (index === updatedMedicationNames.length) {
              updatedMedicationNames.push(value);
            }
          
            return {
              ...state,
              persistedData: {
                ...state.persistedData,
                medicationNames: updatedMedicationNames,
              },
            };
          },
          setAnalysesNames: (
            state: IpersistedPrescriptionDataState,
            action: PayloadAction<{ index: number; value: string }>
          ) => {
            const { index, value } = action.payload;
          
            const updatedAnalysesNames = [...(state.persistedData.analysesNames || [])];
          
            if (index >= 0 && index < updatedAnalysesNames.length) {
              updatedAnalysesNames[index] = value;
            }
            else if (index === updatedAnalysesNames.length) {
              updatedAnalysesNames.push(value);
            }
          
            return {
              ...state,
              persistedData: {
                ...state.persistedData,
                analysesNames: updatedAnalysesNames,
              },
            };
          },
          setRadioNames: (
            state: IpersistedPrescriptionDataState,
            action: PayloadAction<{ index: number; value: string }>
          ) => {
            const { index, value } = action.payload;
          
            const updatedRadioNames = [...(state.persistedData.radioNames || [])];
          
            if (index >= 0 && index < updatedRadioNames.length) {
              updatedRadioNames[index] = value;
            }
            else if (index === updatedRadioNames.length) {
              updatedRadioNames.push(value);
            }
          
            return {
              ...state,
              persistedData: {
                ...state.persistedData,
                radioNames: updatedRadioNames,
              },
            };
          },
          
    },
  });
  

export const { setMedicationNames,setAnalysesNames,setRadioNames } = persistedPrescriptionDataSlice.actions;
export default persistedPrescriptionDataSlice.reducer;
