import React from "react";
import styled from "styled-components";

export const MyInputContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.2rem;
  flex: 1;
  min-width: 300px;
`;

export const MyInputLabel = styled.label`
  font-family: "Ubuntu";
  font-style: normal;
  font-weight: 700;
  font-size: 15.0024px;
  line-height: 15px;
  color: #1aa5c0;
  margin-bottom: 5px;
  display: flex;
  gap: 4px;
  .req {
    align-self: center;
    color: rgba(159, 146, 44, 1);
  }
`;

export const MyInputstyle = styled.input`
  box-sizing: border-box;
  width: 100%;
  height: 50.26px;
  border: 1.67499px solid ${(props) => (props.disabled ? "#ffffff" : "#858585")};
  border-radius: 11.7249px;
  outline: none;
  transition: border-color 0.2s ease-in-out;
  font-family: "Ubuntu";
  font-style: normal;
  font-weight: 400;
  line-height: 15px;
  color: #5e5e5e;
  padding: 0.5rem;
  font-size: 1rem;
  color: #333;
  &:focus {
    border-color: rgba(31, 131, 131, 1);
  }
`;

export const InputWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  gap: 1rem;
`;

export const SideDiv = styled.div`
  font-family: "Ubuntu";
  font-style: normal;
  font-weight: 500;
  font-size: 17.6551px;
  line-height: 15px;
  text-align: center;
  background: #1aa5c0;
  border-radius: 11.7249px;
  color: #ffffff;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-inline: 1.5rem;
  white-space: nowrap;
`;
export interface MyInputProps {
  label: string;
  placeholder: string;
  isImportant: boolean;
  ismodif: boolean;
  name: string;
  handleChange: any;
  hasLeft: boolean;
  hasRight: boolean;
  type: string;
  isAge?: boolean;
  value?: any;
  min?: any;
  max?: any;
}

const MyInput = ({
  ismodif,
  label,
  placeholder,
  isImportant,
  name,
  handleChange,
  hasLeft,
  hasRight,
  type,
  isAge,
  value,
  min,
  max,
}: MyInputProps) => {
  const [age, setAge] = React.useState<number | null>(null);
  React.useEffect(() => {
    if (isAge && value) {
      const birthDate = new Date(value);
      const today = new Date();
      const ageDifference = today.getFullYear() - birthDate.getFullYear();
      setAge(ageDifference);
    }
  }, [isAge, value]);
  const onChange = (e: any) => {
    handleChange(e);
    const today = new Date();
    const birthDate = new Date(e.target.value);
    setAge(today.getFullYear() - birthDate.getFullYear());
  };
  return (
    <MyInputContainer>
      <MyInputLabel>
        {label} {isImportant && <div className="req">*</div>}
      </MyInputLabel>
      <InputWrapper>
        <MyInputstyle
          //   disabled={!ismodif}
          //   required={isImportant}
          autoComplete="off"
          min={min}
          max={max}
          placeholder={placeholder}
          name={name}
          onChange={isAge ? onChange : handleChange}
          type={type}
          defaultValue={value}
        />
        {hasRight && (
          <SideDiv>{age !== null ? age + " ans" : "-- ans"}</SideDiv>
        )}
      </InputWrapper>
    </MyInputContainer>
  );
};

export default MyInput;
