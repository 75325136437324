// src/slices/tableSlice.ts

import { SLICE } from '../../enums/store';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface TableState {
    data: any[];
    headers: string[];
    loading: boolean;
    error: string | null;
}

const initialState: TableState = {
    data: [{id:1, name: "zakaria"}],
    headers: ["id","name"],
    loading: false,
    error: null,
};

const customTableSlice = createSlice({
    name: SLICE.TABLE,
    initialState, 
    reducers: {
        setData: (state, action: PayloadAction<any[]>) => {
            state.data = action.payload;
        },
        setHeaders: (state, action: PayloadAction<any[]>) => {
            state.data = action.payload;
        },
        setLoading: (state, action: PayloadAction<boolean>) => {
            state.loading = action.payload;
        },
        setError: (state, action: PayloadAction<string | null>) => {
            state.error = action.payload;
        },
        clearError: (state) => {
            state.error = null;
        },
    },
});

export const { setData, setLoading, setError, clearError,setHeaders } = customTableSlice.actions;

export default customTableSlice.reducer;
