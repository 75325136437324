type traitementProps = {
  medicament: string;
  start: string;
  end: string;
  status: string;

  instruction: string;
  info: string;
};
const Traitement: React.FC<traitementProps> = ({
  medicament,
  start,
  end,
  status,
  instruction,
  info,
}: traitementProps) => {
  return (
    <div className="px-5    rounded-md  flex flex-col  justify-start items-center bg-[#F4F4F4]">
      <span className=" truncate w-full flex justify-start items-start   text-[20px] mt-5 mb-4 font-bold text-[#5E5E5E]">
        {medicament}
      </span>
      <div className=" flex  justify-between items-center gap-10 w-full">
        <div className="flex flex-col justify-center items-start">
          <span className="text-[18px] font-semibold text-[#1AA5C0]">
            Date de debut
          </span>
          <span className="text-[16px] text-[#5E5E5E]">{start}</span>
        </div>
        {/*
        Need fix
        
        <div className="flex flex-col justify-center items-start">
          <span className="text-[18px] font-semibold text-[#1AA5C0]">
            Date de fin
          </span>
          <span className="text-[16px] text-[#5E5E5E]">{end}</span>
        </div> */}
        <div className="flex flex-col justify-center items-start">
          <span className="text-[18px] font-semibold text-[#1AA5C0]">
            Statut
          </span>
          <span className="text-[16px] text-[#5E5E5E]">{status}</span>
        </div>
      </div>
      <div className="h-[1px] w-full mt-7 bg-[#A4A4A4] "></div>
      <div className="flex w-full flex-col  gap-1   my-6 ">
        <span className="text-[#858585]  text-[20px]">{instruction}</span>
        <span className="text-[#20A8D3] line-clamp-2 truncate-overflow text-[20px]  h-[60px] ">
          {info}
        </span>
      </div>
    </div>
  );
};

export { Traitement };
