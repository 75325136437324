// analysesRadioSlice.ts

import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { SLICE } from "../../enums/store";
import { IAnalysesRadioState } from "@interfaces/prescriptionStep";
const initialState: datesTypes = {
  selectedEvent: {},
  reason: "",
  start: new Date(),
  end: new Date(),
  patientName: "",
  specialityName: "",
};

type datesTypes = {
  selectedEvent: any;
  reason: any;
  start: Date;
  end: Date;
  patientName: string;
  specialityName: string;
};
const appointmentSlice = createSlice({
  name: SLICE.APPOINTMENT_MODAL,
  initialState,
  reducers: {
    setReason: (state, action: PayloadAction<string>) => {
      state.reason = action.payload;
    },
    setSelectedEvent: (state, action: PayloadAction<any>) => {
      state.selectedEvent = action.payload;
    },
    setSpecialityName: (state, action: PayloadAction<string>) => {
      state.specialityName = action.payload;
    },
    setStart: (state, action: PayloadAction<Date>) => {
      state.start = action.payload;
    },
    setEnd: (state, action: PayloadAction<Date>) => {
      state.end = action.payload;
    },
    setPatientName: (state, action: PayloadAction<string>) => {
      state.patientName = action.payload;
    },
  },
});

export const {
  setReason,
  setSpecialityName,
  setPatientName,
  setStart,
  setEnd,
  setSelectedEvent
} = appointmentSlice.actions;
export const selectedDate = (state: any) => state.appointment.selectedDate;
export default appointmentSlice.reducer;
