import React from "react";

const getImcInfo = (imc:number) => {
  if (imc === 0) {
    return {
      classification: "",
      color: "transparent",
    };
  } else if (imc < 18.5) {
    return {
      classification: "Maigreur",
      color: "green-300",
    };
  } else if (imc >= 18.5 && imc < 25) {
    return {
      classification: "Normal",
      color: "green-500",
    };
  } else if (imc >= 25 && imc < 30) {
    return {
      classification: "Surpoids",
      color: "orange-500",
    };
  } else if (imc >= 30 && imc < 35) {
    return {
      classification: "Obésité",
      color: "red-500",
    };
  } else if (imc >= 35) {
    return {
      classification: "Obésité morbide",
      color: "red-500",
    };
  } else {
    return {
      classification: "",
      color: "transparent",
    };
  }
};

type imcType = {
  imc: number;
};
const IMC = (props: imcType) => {
  const { imc } = props;
  const imcInfo = getImcInfo(imc);
  return (
    <div className="flex justify-center iteums-center gap-2">
      <span className="bg-red-700 bg-red-500 bg-orange-500 bg-green-500 bg-green-300"></span>
      <span className={`h-10 w-10 bg-${imcInfo.color} rounded-full`}></span>
      <div className="text-xl font-bold text-[#3b7189]">
        {imcInfo.classification}
      </div>
    </div>
  );
};

export default IMC;
