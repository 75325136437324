// analysesRadioSlice.ts

import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { SLICE } from "../../enums/store";
const initialState: datesTypes = {
  selectedSpecialty: {},
};

type datesTypes = {
  selectedSpecialty: any;
};
const appointmentSlice = createSlice({
  name: SLICE.SELECTED_SPECIALTY,
  initialState,
  reducers: {
    setSelectedSpecialty: (state, action: PayloadAction<any>) => {
      state.selectedSpecialty = action.payload;
    },
  },
});

export const { setSelectedSpecialty } = appointmentSlice.actions;
export const selectedDate = (state: any) => state.appointment.selectedDate;
export default appointmentSlice.reducer;
