// bioAnalysesSlice.ts

import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { SLICE } from "../../enums/store";
import { IBioAnalysesState } from "@interfaces/prescriptionStep";
import { dataStringByIndex } from "@interfaces/Stepper";
const initialState: IBioAnalysesState = {
  bioAnalyses: [],
  isLoading: false,
  isUpdated: false,
  isRefetchRequired: true,
  error: null,
};

const bioAnalysesSlice = createSlice({
  name: SLICE.BIO_ANALYSES,
  initialState,
  reducers: {
    setBioAnalysesData: (state, action: PayloadAction<any>) => {
      state.bioAnalyses = action.payload
    },
    setBioAnalyses: (state, action: PayloadAction<any>) => {
      state.bioAnalyses = [
        ...JSON.parse(JSON.stringify(state.bioAnalyses)),
        action.payload,
      ];
      state.isRefetchRequired = false;
      state.isUpdated = true;
    },
    setBioAnalysesByIndex: (
      state,
      action: PayloadAction<dataStringByIndex>
    ) => {
      const newBioAnalyses = [...JSON.parse(JSON.stringify(state.bioAnalyses))];

      newBioAnalyses[action.payload.index] = {
        analyseId: action.payload.value,
        note: newBioAnalyses[action.payload.index]?.note ?? "",
      };
      state.bioAnalyses = newBioAnalyses;
      state.isRefetchRequired = false;
      state.isUpdated = true;
    },
    setNoteByIndex: (state, action: PayloadAction<dataStringByIndex>) => {
      const newBioAnalyses = [...JSON.parse(JSON.stringify(state.bioAnalyses))];

      newBioAnalyses[action.payload.index] = {
        analyseId: newBioAnalyses[action.payload.index]?.analyseId ?? null,
        note: action.payload.value,
      };
      state.bioAnalyses = newBioAnalyses;
      state.isRefetchRequired = false;
      state.isUpdated = true;
    },
    spliceBioAnalyses: (state, action: PayloadAction<number>) => {
      let splicedbioAnalyses = JSON.parse(JSON.stringify(state.bioAnalyses));
      splicedbioAnalyses.splice(action.payload, 1);
      state.bioAnalyses = splicedbioAnalyses;
      state.isRefetchRequired = false;
      state.isUpdated = true;
    },
    clearBioAnalyses: (state) => {
      state.bioAnalyses = [];
      state.isUpdated = true;
    },
  },
});

export const {
  setBioAnalyses,
  setNoteByIndex,
  setBioAnalysesByIndex,
  spliceBioAnalyses,
  setBioAnalysesData,
  clearBioAnalyses,
} = bioAnalysesSlice.actions;
export default bioAnalysesSlice.reducer;
