import { FC, useEffect, useState } from "react";
import {
  Calendar,
  dateFnsLocalizer,
  Event,
  Views,
  momentLocalizer,
} from "react-big-calendar";
import withDragAndDrop from "react-big-calendar/lib/addons/dragAndDrop";
import format from "date-fns/format";
import parse from "date-fns/parse";
import startOfWeek from "date-fns/startOfWeek";
import getDay from "date-fns/getDay";

import styled from "styled-components";
import { ReactComponent as PlusSvg } from "../../assets/plus.svg";
import { ReactComponent as Arrow } from "../../assets/calendarArrow.svg";
import "react-big-calendar/lib/addons/dragAndDrop/styles.css";
import "react-big-calendar/lib/css/react-big-calendar.css";
import AppointementModal from "./AppointementModal";
import { useAppointmentContext } from "./AppointementContext";
import EventModal from "./EventModal";

import toast, { Toaster } from "react-hot-toast";
import SocketIOContextProvider from "../../services/socketIOContext";
import moment from "moment";
import {
  getAppointmentsByDoctorId,
  getAppointmentsByCenterId,
  getDoctorNamebyId,
} from "../../axiosAPI/Network";
import { Spinner } from "@material-tailwind/react";
import { EventModalData } from "../types";
import APP_CONSTANTS from "../../constants";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../src/store";
import { useParams } from "react-router-dom";
import { setMotif, setRightIsOpen } from "../../features/sidebar/SidebarSlice";
import RighSideBar from "../../layout/specialiste/RightSidebar";
import "./calendar.css";
import { setIsModalOpen } from "../../features/Appointment/appointmentSlice";
import "moment/locale/fr";
import "react-big-calendar/lib/css/react-big-calendar.css";
require("moment/locale/fr.js");
import fr from "date-fns/locale/fr";
import RdvTable from "../../layout/specialiste/AppointementTable";
import { setRefreshCalendar } from "../../features/notifications/notificationsSlice";
import { role } from "../../enums/app";

const TABLE_HEAD = ["Start Time", "End Time", "Station", "Center"];
const notify = () =>
  toast.custom(
    <div className="bg-white p-3 border-2 border-[#20A8D3] rounded-md px-6 shadow-md font-[500] text-[#858585] text-xl      ">
      <p>
        Le médecin <span className="text-[#20A8D3]">Ahmed Idrissi</span> a été
        bien notifié
      </p>
    </div>,
    {
      duration: 2000,
      position: "top-center",

      // Styling
      style: {},
      className: "",

      // Custom Icon

      // Change colors of success/error/loading icon
      iconTheme: {
        primary: "#000",
        secondary: "#fff",
      },

      // Aria
      ariaProps: {
        role: "status",
        "aria-live": "polite",
      },
    }
  );

const CalendarToolbar = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px;
  border-radius: 4px;
  margin-bottom: 16px;
  .rbc-event-time {
    /* Adjust font, color, etc. */
    font-size: 12px;
    color: #555;
    /* Add more custom styles as needed */
  }
`;

const ToolbarButton = styled.button`
  .active {
    background-color: #f4f4f4;
    color: #5e5e5e;
  }
  .inactive {
    background-color: #ff7474;
    color: #5e5e5e;
  }
`;

interface CalendarProps {
  doctorId?: number;
  patientId?: number;
}
const MyCalendar = (props: CalendarProps) => {
  /* CONSTANTS */
  const todays = new Date();
  const views = [Views.DAY, Views.WEEK, Views.MONTH, Views.AGENDA];

  /* HOOKS CALL */
  const dispatch = useDispatch();
  const paramsId = useParams();

  const today = `${todays.getDate()} ${todays.toLocaleString("default", {
    month: "long",
  })} ${todays.getFullYear()}`;

  /* STATES */
  const [open, setOpen] = useState(false);
  const [calendarView, setCalendarView] = useState(views[1]);
  const [backEvents, setBackEvents] = useState<[EventModalData] | []>([]);
  const [appointments, setAppointments] = useState<any>();
  const [loadingData, setLoadingData] = useState(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [patientId, setPatientId] = useState<any | null>();
  const [handelOpen, setHandelOpen] = useState(false);
  const [showTable, setShowTable] = useState<boolean>(false);
  const [showCalendar, setShowCalendar] = useState<boolean>(true);
  const [currentView, setCurrentView] = useState("calendar");
  const [docId, setDocId] = useState<number | null>(null);
  const [avgTime, setAvgTime] = useState<number | null>();

  /* SELECTORS */
  const consultationMetadata = useSelector(
    (state: RootState) => state.consultationMetadata
  );
  const refreshCalendar = useSelector(
    (state: any) => state.notifications.refreshCalendar
  );
  console.log("🚀 ~ MyCalendar ~ refreshCalendar:", refreshCalendar);
  const leftIsOpen = useSelector(
    (state: RootState) => state.sidebar.leftIsOpen
  );
  const rightIsOpen = useSelector(
    (state: RootState) => state.sidebar.rightIsOpen
  );
  const {
    center,
    box,
    user: storedUser,
  } = useSelector((state: RootState) => state.auth);
  const [isSpecialist, setIsSpecialist] = useState<boolean>(
    storedUser?.roles[0].name === role.SPECIALIST
  );
  const [size, setSize] = useState(null);
  const refrshData = useSelector((state: any) => state.appointment.isModalOpen);

  /**
   * main method to get appointments based on the use case
   */
  const getAppointments = (docId?: number | null, centerId?: number) => {
    setIsLoading(true);
    if (isSpecialist) {
      getDoctorNamebyId(docId).then((res: any) => {
        setAvgTime(res?.speciality?.avgTime);
      });
      console.log("getting appointments for specialist", isLoading);
      getAppointmentsByDoctorId(docId)
        .then((data: any) => {
          if (data) {
            console.log("getted appointments for specialist", isLoading, data);

            setAppointments(data?.data?.results);

            setLoadingData(false);
          }
          setIsLoading(false);
        })
        .catch((err: any) => {
          setIsLoading(false);
          console?.error(err);
        });
    } else {
      getAppointmentsByCenterId(centerId)
        .then((data: any) => {
          console.log(
            "🚀 ~ file: MyCalendar.tsx:191 ~ getAppointmentsByCenterId ~ data:",
            data
          );
          setIsLoading(false);
          if (data) {
            setAppointments(data?.data?.results);
            setLoadingData(false);
          }
        })
        .catch((err: any) => {
          setIsLoading(false);
          console?.error(err);
        });
    }
  };

  // updating appointement in calendzr wihthout reloading page
  useEffect(() => {
    if (!isSpecialist) {
      getAppointmentsByCenterId(center?.id)
        .then((data: any) => {
          console.log(
            "🚀 ~ file: MyCalendar.tsx:199 ~ getAppointmentsByCenterId ~ data:",
            data
          );
          setIsLoading(false);
          if (data) {
            setAppointments(data?.data?.results);
            setLoadingData(false);
          }
        })
        .catch((err: any) => {
          setIsLoading(false);
          console?.error(err);
        });
    }
  }, [refrshData]);

  /* WATCHERS */

  useEffect(() => {
    setLoadingData(true);
    if (storedUser || center) {
      const doctorId = storedUser?.doctor?.id;
      setDocId(storedUser?.doctor?.id);
      const centerId = center?.id;
      getAppointments(doctorId, centerId);
    }
  }, [storedUser, center]);

  useEffect(() => {
    console.log("received new appointment");
    if (refreshCalendar) getAppointments(docId, center.id);
    dispatch(setRefreshCalendar(false));
  }, [refreshCalendar]);

  useEffect(() => {
    const setEvents = async () => {
      const events = appointments?.map((item: any) => {
        console.log("appointment xxx", item);
        const momentStartTime = moment(item.startTime);
        const momentEndTime = moment(item.endTime);

        // Get the new ISO 8601 time value
        const start = moment(momentStartTime.toISOString()).toDate();
        const end = moment(momentEndTime.toISOString()).toDate();
        // // console.log("event data", item);

        return {
          reason: item?.reason,
          doctorId: item?.doctorId,
          doctor: item?.doctor,
          patientId: item?.patientId,
          status: item?.status,
          patient: item?.patient,
          //  doctorName: item.doctor.user.name,
          start,
          end,
          // motif: item.reason,
          // stationId: item?.station?.userId,
          stationId: item?.station?.id,
          title: isSpecialist
            ? `${item.station?.center?.name} - ${item.patient?.user?.firstName} ${item.patient?.user?.lastName}`
            : `${item?.patient?.user?.firstName} ${item?.patient?.user?.lastName} -- ${item?.doctor?.speciality?.name}`,
          id: item.id,
          centerId: item.centerId,
          // doctorUserId: item.id,
        };
      });

      setBackEvents(events);
    };
    if (appointments) {
      setEvents();
    }
  }, [appointments]);

  /* END WATCHERS */

  /**
   * toogle methods
   */
  const toogleTableView = () => {
    setShowTable(!false);
    setShowCalendar(!true);
  };
  const toogleCalendarView = () => {
    setShowCalendar(!false);
    setShowTable(!true);
  };

  ////////// set start and end time for the calendar //////////
  const minTime = new Date();
  minTime.setHours(8, 0, 0); // Set minimum time to 8:00 AM

  const maxTime = new Date();
  maxTime.setHours(22, 0, 0); // Set maximum time to 10:00 PM
  ////////////////////////////////////////////////////////////

  const periods = [
    { value: "Aujourd’hui", isSelected: false },
    { value: "Cette semaine", isSelected: false },
    { value: "Ce mois", isSelected: true },
  ];

  const updateSelectedPeriod = (index: number) => {
    periods.forEach((period, i) => {
      if (i === index) {
        period.isSelected = true;
      } else {
        period.isSelected = false;
      }
    });
  };

  //////////////// styling the event ///////////////////////
  const eventStyleGetter = (
    event: any,
    start: any,
    end: any,
    isSelected: any
  ) => {
    const eventStyle = {
      height: "1.2%",
      paddingLeft: 0,
      paddingTop: 0,
      paddingBottom: 0,
      borderRight:
        event.status == "closed" ? "5px solid #ff0008b3" : "5px solid #0597AB",
      backgroundColor: event.status == "closed" ? "#ff0008b3" : "#EFF8F9",
      opacity: 0.8,
      color: "rgba(0, 0, 0, 0.724)",
      fontWeight: 800,
      fontSize: 12,
      borderRadius: "4px",
      boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
    };

    return {
      style: eventStyle,
    };
  };

  /* HANDLERS */
  const handleEventOpen = () => {
    // if (isSpecialist) {
    dispatch(setRightIsOpen(true));
    // }

    if (appointments) {
      appointments.map((item: any) => {
        setPatientId(item.patientId);
        console.log(item.reason);
      });
    }
  };
  const handleEventClick = (event: any) => {
    console.log("cliked", event);
    dispatch(setMotif(event));
    handleEventOpen();
  };

  const handleOpen = () => {
    if (open) {
      getAppointmentsByDoctorId(docId);
      // refetch();
      setLoadingData(true);
    }
    dispatch(setIsModalOpen(true));
    setOpen(!open);
  };

  console.log("the ---------->", appointments);

  return (
    <>
      <div
        style={{ width: "100%" }}
        className={
          leftIsOpen ? "h-full mr-2" : "h-full flex justify-center mr-2 "
        }
      >
        {false ? (
          <div className="flex justify-center items-center h-[100%] w-[92%]">
            <Spinner className="h-20 w-20 text-blue-500/10" />
          </div>
        ) : (
          <>
            {!isLoading && (
              <>
                {showCalendar ? (
                  <Calendar
                    culture="fr"
                    defaultView={calendarView}
                    events={backEvents}
                    view={calendarView}
                    localizer={localizer}
                    eventPropGetter={eventStyleGetter}
                    selectable
                    min={minTime}
                    max={maxTime}
                    defaultDate={new Date()}
                    step={10}
                    onSelectEvent={handleEventClick}
                    components={{
                      toolbar: ({ label, onNavigate }) => (
                        <CalendarToolbar>
                          <div className="flex items-center gap-2">
                            <button
                              onClick={() => onNavigate("PREV")}
                              className="bg-[#F4F4F5] p-3"
                            >
                              <Arrow />
                            </button>
                            <span>{label}</span>
                            <button
                              onClick={() => onNavigate("NEXT")}
                              className="bg-[#F4F4F5] p-3"
                            >
                              <Arrow className="rotate-180" />
                            </button>
                          </div>
                          {/* <div>
                      
                      <div>
                      
                        <button onClick={toogleTableView}>
                          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                            <path stroke-linecap="round" stroke-linejoin="round" d="M3.375 19.5h17.25m-17.25 0a1.125 1.125 0 01-1.125-1.125M3.375 19.5h7.5c.621 0 1.125-.504 1.125-1.125m-9.75 0V5.625m0 12.75v-1.5c0-.621.504-1.125 1.125-1.125m18.375 2.625V5.625m0 12.75c0 .621-.504 1.125-1.125 1.125m1.125-1.125v-1.5c0-.621-.504-1.125-1.125-1.125m0 3.75h-7.5A1.125 1.125 0 0112 18.375m9.75-12.75c0-.621-.504-1.125-1.125-1.125H3.375c-.621 0-1.125.504-1.125 1.125m19.5 0v1.5c0 .621-.504 1.125-1.125 1.125M2.25 5.625v1.5c0 .621.504 1.125 1.125 1.125m0 0h17.25m-17.25 0h7.5c.621 0 1.125.504 1.125 1.125M3.375 8.25c-.621 0-1.125.504-1.125 1.125v1.5c0 .621.504 1.125 1.125 1.125m17.25-3.75h-7.5c-.621 0-1.125.504-1.125 1.125m8.625-1.125c.621 0 1.125.504 1.125 1.125v1.5c0 .621-.504 1.125-1.125 1.125m-17.25 0h7.5m-7.5 0c-.621 0-1.125.504-1.125 1.125v1.5c0 .621.504 1.125 1.125 1.125M12 10.875v-1.5m0 1.5c0 .621-.504 1.125-1.125 1.125M12 10.875c0 .621.504 1.125 1.125 1.125m-2.25 0c.621 0 1.125.504 1.125 1.125M13.125 12h7.5m-7.5 0c-.621 0-1.125.504-1.125 1.125M20.625 12c.621 0 1.125.504 1.125 1.125v1.5c0 .621-.504 1.125-1.125 1.125m-17.25 0h7.5M12 14.625v-1.5m0 1.5c0 .621-.504 1.125-1.125 1.125M12 14.625c0 .621.504 1.125 1.125 1.125m-2.25 0c.621 0 1.125.504 1.125 1.125m0 1.5v-1.5m0 0c0-.621.504-1.125 1.125-1.125m0 0h7.5" />
                          </svg>

                        </button>
                     
                        <button onClick={toogleCalendarView}>
                          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                            <path stroke-linecap="round" stroke-linejoin="round" d="M6.75 3v2.25M17.25 3v2.25M3 18.75V7.5a2.25 2.25 0 012.25-2.25h13.5A2.25 2.25 0 0121 7.5v11.25m-18 0A2.25 2.25 0 005.25 21h13.5A2.25 2.25 0 0021 18.75m-18 0v-7.5A2.25 2.25 0 015.25 9h13.5A2.25 2.25 0 0121 11.25v7.5m-9-6h.008v.008H12v-.008zM12 15h.008v.008H12V15zm0 2.25h.008v.008H12v-.008zM9.75 15h.008v.008H9.75V15zm0 2.25h.008v.008H9.75v-.008zM7.5 15h.008v.008H7.5V15zm0 2.25h.008v.008H7.5v-.008zm6.75-4.5h.008v.008h-.008v-.008zm0 2.25h.008v.008h-.008V15zm0 2.25h.008v.008h-.008v-.008zm2.25-4.5h.008v.008H16.5v-.008zm0 2.25h.008v.008H16.5V15z" />
                          </svg>

                        </button>

                      </div>
                 
                    </div> */}
                          <div>{label}</div>
                          <div className="#5E5E5E text-lg font-semibold">
                            {today}
                          </div>

                          <div className="selected-time flex gap-3">
                            {periods?.map((period, index) => (
                              <ToolbarButton
                                key={index}
                                className={`${
                                  period.isSelected
                                    ? "bg-primary text-white"
                                    : "bg-[#F4F4F4] text-[#5E5E5E]"
                                } rounded-sm  flex gap-2 items-center py-2 px-4 font-semibold`}
                                onClick={() => {
                                  updateSelectedPeriod(index);
                                  setCalendarView(views[index]);
                                }}
                              >
                                {period.value}
                              </ToolbarButton>
                            ))}
                          </div>
                          {!isSpecialist && (
                            <div>
                              <ToolbarButton
                                className="add-appointment rounded-3xl text-white flex gap-2 items-center bg-primary px-4"
                                onClick={handleOpen}
                              >
                                <PlusSvg />
                                Nouveau RDV
                              </ToolbarButton>
                            </div>
                          )}
                        </CalendarToolbar>
                      ),
                    }}
                    style={{
                      height: "100%",
                      width: "100%",
                      backgroundColor: "white",
                      color: "#71717A",
                    }}
                  />
                ) : (
                  ""
                )}
                {showTable && <RdvTable />}
              </>
            )}
            {isLoading && <Spinner />}
            <SocketIOContextProvider>
              <AppointementModal open={open} handleOpen={handleOpen} />
            </SocketIOContextProvider>
            {!isSpecialist ? <EventModal /> : <RighSideBar />}
          </>
        )}
      </div>
    </>
  );
};

const locales = {
  fr: fr,
};
// The types here are `object`. Strongly consider making them better as removing `locales` caused a fatal error
const localizer = dateFnsLocalizer({
  format,
  parse,
  startOfWeek,
  getDay,
  locales,
});
//@ts-ignore
const DnDCalendar = withDragAndDrop(Calendar);

export default MyCalendar;
