// Compte rendu
// Compte rendu

import Biology from "./Components/biology/Biology";
import Documents from "./Components/documents";
import MedicalHistory from "./Components/medicalHistory";
import PatientFile from "./Components/patientFile";
import Report from "./Components/report";
import NewReport from "./Components/report/NewReport";
import VitalSigns from "./Components/vitalSigns";

const Routes = [
  {
    index: 1,
    path: "/patient-file",
    name: "Fiche patient",
    component: <PatientFile />,
    requiredPermissions: ["generalist"],
  },
  {
    index: 2,
    path: "/medical-history",
    name: "Antécédents",
    component: <MedicalHistory />,
    requiredPermissions: ["generalist"],
  },
  {
    index: 3,
    name: "Constantes",
    path: "/vital-signs",
    component: <VitalSigns />,
    requiredPermissions: ["generalist"],
  },
  {
    index: 5,
    name: "Biologie",
    path: "/biology",
    hide: true,
    component: <Biology />,
    requiredPermissions: ["generalist"],
  },
  {
    index: 4,
    path: "/report",
    name: "Compte-rendu",
    component: <Report />,
    requiredPermissions: ["generalist"],
  },
  //   {
  // 	index: 5,
  // 	name: "Documents",
  // 	path: "/documents",
  // 	component: <Documents/>,
  //   },
];

export default Routes;
