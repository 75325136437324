export enum CONSULTATION_STATUSES {
    ON_GOING = 'onGoing',
    ENDED = 'ended',
    CANCELED = 'canceled',
    SCHEDULED = 'scheduled'

}

// those are the different exit modes of a patient from the center
export enum EXIT_MODES {
    NORMAL = 'normal',
    EMERGENGY = 'emergency',
    TRANSFERT = 'tranfert'
}


// those represent the different types of consultations
export enum CONSULTATION_TYPES {
    FIRST_CONSULTATION = 'firstConsultation',
    FOLLOW_UP_CONSULTATION = 'followUpConsultation',
    CONTROL_CONSULTATION = 'controlConsultation',
    CARE_CONSULTATION = 'careConsultation',
    OPERATION = 'operation'

}