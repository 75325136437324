import React from "react";

interface Props {
  rounded?: boolean;
  color?: string;
  text?: string;
  icon?: React.ReactNode;
  className?: string;
  onClick?: () => void;
  style?:any;
  disabled?: boolean;
  type?: "button" | "submit" | "reset";
}

const MyButton = (props: Props) => {
  return (
    <button
      onClick={props.onClick}
      disabled={props.disabled}
      style={props.style}
      type={props.type}
      className={` px-6 py-2   ${props.rounded ? "rounded-3xl" : ""
        } flex items-center gap-2 justify-center ${props.className} ${props.color || " text-white"
        } hover:scale-105 transition-[all] ease-out duration-300 `}
    >
      {props.icon}
      {props.text}
    </button>
  );
};

export default MyButton;
