import React, { useEffect, useState } from "react";
import { Outlet, useLocation, useParams } from "react-router-dom";
import styled from "styled-components";
import Header from "./Header";
import NotificationContextProvider from "../services/NotificationContext";
import { ResumeConsultationButton } from "../Components/Consultation/ResumeConsultationButton";
import { CONSULTATION_STATUSES } from "../enums/consultation";
import { useSelector } from "react-redux";
import { RootState } from "../store";

const GlobalStyle = styled.div`
  width: 100vw;
  height: 100vh;
  background-image: url("/assets/background.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
`;

const ContentStyle = styled.div`
  width: 88%;
  flex: 1;
  margin-top: 14px;
  height: 100%;
  overflow: auto;
`;

const GlobalLayout = () => {
  const location = useLocation();
  const params = useParams();
  const status = useSelector((state: any) => state.consultationStatus.status);
  const patientOngoingConsultation = useSelector(
    (state: RootState) => state.consultationData.lastPatientOngoingConsultation
  );
  const [showResumeButton, setShowResumeButton] = useState(false);

  useEffect(() => {
    const isPatientFile = location.pathname.includes("doctor/patient");
    if (isPatientFile) {
      if (patientOngoingConsultation) {
        if (
          patientOngoingConsultation?.patientId ===
            parseInt(params.id || "", 10) &&
          status === CONSULTATION_STATUSES.ON_GOING
        )
          setShowResumeButton(true);
      }
    } else setShowResumeButton(false);
    console.log("setted show resume to", showResumeButton);
  }, [location, patientOngoingConsultation]);

  return (
    <GlobalStyle>
      <Header isLoginPage={false} />
      {/* {showResumeButton ?  <ResumeConsultationButton /> : ""} */}
      <ContentStyle>
        <Outlet />
      </ContentStyle>
    </GlobalStyle>
  );
};

export default GlobalLayout;
