export enum CONSULTATION_STATUSES {

    STARTED = 'started',
    PAUSED = 'paused',
    CANCELED = 'canceled',
    ENDED = 'ended'
}

export enum CALL_STATUS {
    incoming = 'incoming',
    joined = 'joined',
    missed = 'missed',
    rejected = 'rejected',
    ended = 'ended',
}