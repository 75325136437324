import { spliceMotifs } from "../../features/motifs/motifsSlice";
import {
  ADDITIONAL_COMPONENT_TYPE,
  DISPLAY_CONDITION_STATE,
  TAG_DATA_STATE,
} from "../../enums/stepper";
import React, { useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useGetMotifsQuery } from "../../features/motifs/motifsApi";
import { useParams } from "react-router-dom";
import useDynamicTagDataQuery from "../../hooks/useDynamicTagDataQuery";
import { spliceOtherFamiliaux } from "../../features/otherFamiliaux/otherFamiliauxSlice";
import { spliceSelectedFamiliaux } from "../../features/selectedFamiliaux/selectedFamiliauxSlice";
import { spliceAnalysesRadio } from "../../features/analysesRadio/analysesRadioSlice";
import {
  spliceAllergy,
  spliceAntecedentsMedicals,
  spliceDignosticSecondaire,
  spliceFamilyOthers,
  spliceFamilySelected,
  spliceMedicalData,
  spliceMedicalMotifs,
  spliceMotifsData,
  spliceSurgicalData,
  spliceSurgicalMotifs,
} from "../../features/consultationData/consultationDataSlice";
import { BsShopWindow } from "react-icons/bs";
import { spliceDiagnosticAssocie } from "../../features/diagnosticAssocie/diagnosticAssocieSlice";
import { generateUniqueId } from "../../services/utils.service";
interface ITagProps {
  properties: any;
  tagComponent?: any;
}
interface ITagRemoverButtonProps {
  index: number;
  dataState: string;
  tagsData: any;
  itemIndex: number;
}

function TagRemoverButton({
  index,
  dataState,
  tagsData,
  itemIndex,
}: ITagRemoverButtonProps) {
  const dispatch = useDispatch();
  const spliceTagsData = () => {
    switch (dataState) {
      case TAG_DATA_STATE.MOTIFS:
        dispatch(spliceMotifs(index));
        dispatch(spliceMotifsData(index));
        break;
      case TAG_DATA_STATE.DIAGNOSTIC_ASSOCIE:
        dispatch(spliceDignosticSecondaire(index));
        dispatch(spliceDiagnosticAssocie(index));
        break;
      case TAG_DATA_STATE.OTHER_FAMILIAUX:
        dispatch(spliceFamilyOthers(index));
        break;
      case TAG_DATA_STATE.SELECTED_FAMILIAUX:
        dispatch(spliceFamilySelected(index));
        break;
      case TAG_DATA_STATE.ANALYSES_RADIO:
        dispatch(spliceAnalysesRadio(index));
        break;
      case TAG_DATA_STATE.SURGICAL_MOTIFS:
        dispatch(spliceSurgicalMotifs({ index: index, itemIndex: itemIndex }));
        break;
      case TAG_DATA_STATE.ALLERGY:
        dispatch(spliceAllergy(index));
        break;
      case TAG_DATA_STATE.MEDICAL_MOTIFS:
        dispatch(spliceMedicalMotifs({ index: index, itemIndex: itemIndex }));
        break;
      default:
        break;
    }
  };
  return (
    <span key={index} className="ml-2 cursor-pointer" onClick={spliceTagsData}>
      x
    </span>
  );
}

export default function Tag({ properties, tagComponent }: ITagProps) {
  // const [shown, setShown] = React.useState(true);
  const shown = useSelector((state: any) => {
    switch (properties.displayCondition) {
      case DISPLAY_CONDITION_STATE.ALLERGY:
        return state.allergy.isAllergic;
      default:
        return true;
    }
  });
  const tagsData = useSelector((state: any) => {
    if (properties?.dataState === TAG_DATA_STATE.MOTIFS) {
      return state.motifs;
    }
    if (properties?.dataState == TAG_DATA_STATE.OTHER_FAMILIAUX) {
      return state?.consultationData?.consultationData?.medicalHistory?.family
        ?.others;
    }
    if (properties?.dataState == TAG_DATA_STATE.SELECTED_FAMILIAUX) {
      // console.log(
      //   "OOP",
      //   state?.consultationData?.consultationData?.medicalHistory?.family
      //     ?.selected
      // );

      return state?.consultationData?.consultationData?.medicalHistory?.family
        ?.selected;
    }
    if (properties?.dataState == TAG_DATA_STATE.DIAGNOSTIC_ASSOCIE) {
      return state.diagnosticAssocie.list;
    }
    if (properties?.dataState == TAG_DATA_STATE.ALLERGY) {
      return state.consultationData.consultationData?.medicalHistory?.allergy;
    }
    if (properties?.dataState == TAG_DATA_STATE.ANALYSES_RADIO) {
      return state.analysesRadio;
    }
    if (properties?.dataState == TAG_DATA_STATE.MEDICAL_MOTIFS) {
      return state.consultationData.consultationData?.medicalHistory?.medical
        ?.length > 0
        ? state.consultationData.consultationData?.medicalHistory?.medical[
            properties.index
          ]?.motif
        : [];
    }
    if (properties?.dataState == TAG_DATA_STATE.SURGICAL_MOTIFS) {
      console.log(
        "surgicals",
        state.consultationData.consultationData?.medicalHistory?.surgical
      );

      return state.consultationData.consultationData?.medicalHistory?.surgical
        ?.length > 0
        ? state.consultationData.consultationData?.medicalHistory?.surgical[
            properties.index
          ]?.motif
        : [];
    }
    if (properties?.dataState == TAG_DATA_STATE.PREVIOUS_HOSPITALIZATION) {
      return state.consultationData.consultationData?.medicalHistory
        ?.hospitalization?.length > 0
        ? state.consultationData.consultationData?.medicalHistory
            ?.hospitalization[properties.index]?.motif
        : [];
    }
    return [];
  });

  useDynamicTagDataQuery(properties.dataState, tagsData);
  return (
    <>
      {shown && (
        <div key={generateUniqueId()} className={properties.containerStyle}>
          {properties?.dataState === TAG_DATA_STATE.MOTIFS &&
            tagsData &&
            tagsData.motifs?.map((tag: string, index: number) => {
              return (
                <div className={properties.tagStyle}>
                  {tag}
                  <TagRemoverButton
                    index={index}
                    itemIndex={properties.index}
                    tagsData={tagsData}
                    dataState={properties?.dataState}
                  />
                </div>
              );
            })}
          {properties?.dataState === TAG_DATA_STATE.SURGICAL_MOTIFS &&
            tagsData &&
            tagsData?.map((tag: string, index: number) => {
              return (
                <div className={properties.tagStyle}>
                  {tag}
                  <TagRemoverButton
                    index={index}
                    itemIndex={properties.index}
                    tagsData={tagsData}
                    dataState={properties?.dataState}
                  />
                </div>
              );
            })}
          {properties?.dataState === TAG_DATA_STATE.PREVIOUS_HOSPITALIZATION &&
            tagsData &&
            tagsData?.map((tag: string, index: number) => {
              return (
                <div className={properties.tagStyle}>
                  {tag}
                  <TagRemoverButton
                    index={index}
                    itemIndex={properties.index}
                    tagsData={tagsData}
                    dataState={properties?.dataState}
                  />
                </div>
              );
            })}
          {properties?.dataState === TAG_DATA_STATE.MEDICAL_MOTIFS &&
            tagsData &&
            tagsData?.map((tag: string, index: number) => {
              return (
                <div className={properties.tagStyle}>
                  {tag}
                  <TagRemoverButton
                    index={index}
                    itemIndex={properties.index}
                    tagsData={tagsData}
                    dataState={properties?.dataState}
                  />
                </div>
              );
            })}
          {properties?.dataState === TAG_DATA_STATE.OTHER_FAMILIAUX &&
            tagsData &&
            tagsData?.map((tag: string, index: number) => {
              return (
                <div className={properties.tagStyle}>
                  {tag}
                  <TagRemoverButton
                    index={index}
                    itemIndex={properties.index}
                    tagsData={tagsData}
                    dataState={properties?.dataState}
                  />
                </div>
              );
            })}
          {properties?.dataState === TAG_DATA_STATE.SELECTED_FAMILIAUX &&
            tagsData &&
            tagsData?.map((tag: string, index: number) => {
              return (
                <div className={properties.tagStyle}>
                  {tag}
                  <TagRemoverButton
                    index={index}
                    itemIndex={properties.index}
                    tagsData={tagsData}
                    dataState={properties?.dataState}
                  />
                </div>
              );
            })}
          {properties?.dataState === TAG_DATA_STATE.ALLERGY &&
            tagsData &&
            tagsData?.map((tag: string, index: number) => {
              return (
                <div className={properties.tagStyle}>
                  {tag}
                  <TagRemoverButton
                    index={index}
                    itemIndex={properties.index}
                    tagsData={tagsData}
                    dataState={properties?.dataState}
                  />
                </div>
              );
            })}
          {properties?.dataState === TAG_DATA_STATE.ANALYSES_RADIO &&
            tagsData &&
            tagsData.analysesRadio?.map((tag: string, index: number) => {
              return (
                <div className={properties.tagStyle}>
                  {tag}
                  <TagRemoverButton
                    index={index}
                    itemIndex={properties.index}
                    tagsData={tagsData}
                    dataState={properties?.dataState}
                  />
                </div>
              );
            })}
          {properties?.dataState === TAG_DATA_STATE.DIAGNOSTIC_ASSOCIE &&
            tagsData &&
            tagsData?.map((tag: string, index: number) => {
              return (
                <div className={properties.tagStyle}>
                  {tag}
                  <TagRemoverButton
                    index={index}
                    itemIndex={properties.index}
                    tagsData={tagsData}
                    dataState={properties?.dataState}
                  />
                </div>
              );
            })}
        </div>
      )}
    </>
  );
}
