import { createSlice } from '@reduxjs/toolkit';

const initialState: string[] = [];

const accessSlice = createSlice({
  name: 'access',
  initialState,
  reducers: {
    setAccess: (state, action) => {
      return action.payload;
    },
  },
});

export const { setAccess } = accessSlice.actions;

export default accessSlice.reducer;
