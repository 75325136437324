import React, { useEffect } from "react";
import { GlobalStyle } from "../SearchPatient/SearchPatient";
import { ReactComponent as PeriDeBase } from "../../assets/periDeBase.svg";
import { ReactComponent as ArrRight } from "../../assets/arrowRight.svg";
import { ReactComponent as SaveIcon } from "../../assets/save-base-consult.svg";
import { useState } from "react";
import PeriphiriqueLeft from "./PeriphiriqueLeft";
import PeriphiriqueRight from "./PeriphiriqueRight";
import SocketIOContextProvider from "../../services/socketIOContext";
import { vitalSignsType } from "@interfaces/baseParams";
import { postVitalSigns } from "../../axiosAPI/params";
import { useNavigate, useParams } from "react-router-dom";
import PeriphiriqueHeader from "./PeriphiriqueHeader";
import { ArrowLeftCircleIcon } from "@heroicons/react/24/solid";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../store";
import { CONSULTATION_STATUSES } from "../../enums/consultation";
import { setVitalSignsConsultation } from "../../features/consultationData/consultationDataSlice";
import { ToastContainer } from "react-toastify";

type baseProps = {
  isConsultation?: boolean;
  setSelectedPeripherique?: any;
};
const PeripheriquesDeBase = (props: baseProps) => {
  const { isConsultation } = props;
  const navigat = useNavigate();
  const dispatch = useDispatch();
  const currentStep = useSelector(
    (state: any) => state.currentStep.currentStep
  );

  const [index, setIndex] = useState(0);
  const consultationStatus = useSelector(
    (state: RootState) => state.consultationStatus.status
  );
  const baseParamsData = useSelector(
    (state: RootState) => state.baseParamsData
  );
  const { id: patientId } = useParams<{ id: string }>();

  useEffect(() => {
    console.log("baseParamsData", baseParamsData);
  }, [index]);

  const handleBackButtonClick = () => {
    console.log("consultaion status", consultationStatus);
    if (
      currentStep !== 0 &&
      consultationStatus == CONSULTATION_STATUSES.ON_GOING
    ) {
      try {
        props.setSelectedPeripherique(-1);
      } catch (error) {
        navigat(-1);
      }
    } else {
      navigat(-1);
    }
  };

  /**
   *
   */
  const saveVitalSigns = () => {
    dispatch(
      setVitalSignsConsultation({
        weight: baseParamsData.vitalSignsData.weight,
        height: baseParamsData.vitalSignsData.height,
        temperature: baseParamsData.vitalSignsData.temp,
        sys: baseParamsData.vitalSignsData.sys,
        dia: baseParamsData.vitalSignsData.dia,
        pulse: baseParamsData.vitalSignsData.rc,
        spo2: baseParamsData.vitalSignsData.oxy,
        bloodSugar: baseParamsData.vitalSignsData.glycemi,
        respiratoryRythm: baseParamsData.vitalSignsData.rr,
        fatMass: baseParamsData.vitalSignsData.SMM,
        leanMass: baseParamsData.vitalSignsData.graisse,
        patientId: parseInt(patientId!),
        doctorId: parseInt(localStorage.getItem("vs_doctorId")!),
      })
    );
    props.setSelectedPeripherique(-1);
  };

  return (
    <GlobalStyle>
      <SocketIOContextProvider>
        <div className="flex flex-col   items-center justify-start h-full px-4 py-2  ">
          {/* <PeriphiriqueHeader /> */}
          <div className="title flex gap- items-center  w-full mt-6">
            {/* <PeriDeBase /> */}
            <div className="flex items-center">
              {/* <ArrRight
                width={150}
                className="rotate-180 h-21"
                onClick={() => {
                  navigat(-1);
                }}
              /> */}
              <button onClick={handleBackButtonClick}>
                <ArrowLeftCircleIcon className="w-11" />
              </button>
            </div>
            <div className="text-primary text-xl font-bold ">
              Périphériques de base
            </div>
          </div>
          {/* {index === 0 ? ( */}
          <div
            className={` mesurements flex w-full h-full ${
              index === 0 ? "" : "hidden"
            }`}
          >
            <div className="cards w-[95%] flex items-center">
              <PeriphiriqueLeft
              // vitalSignIndex={index}
              // setvitalSigns={
              //   setVitalSigns === undefined
              //     ? setLocalVitalSigns
              //     : setVitalSigns
              // }
              // vitalSigns={
              //   vitalSigns === undefined ? localVitalSigns : vitalSigns
              // }
              // handelChange={handelLeftSignChange}
              />
            </div>
            <div className="flex items-center">
              <ArrRight width={150} onClick={() => setIndex(1)} />
            </div>
          </div>
          {/* ) : ( */}
          <div
            className={`mesurements flex w-full h-full
            ${index === 0 ? "hidden" : ""}`}
          >
            <div className="flex items-center">
              <ArrRight
                width={150}
                onClick={() => setIndex(0)}
                className="rotate-180"
              />
            </div>
            <div className="cards w-[95%] flex items-center">
              <PeriphiriqueRight
              // setvitalSigns={
              //   setVitalSigns === undefined
              //     ? setLocalVitalSigns
              //     : setVitalSigns
              // }
              // vitalSigns={
              //   vitalSigns === undefined ? localVitalSigns : vitalSigns
              // }
              />
            </div>
            {isConsultation !== false && (
              <div className="flex items-center">
                <SaveIcon
                  className="cursor-pointer"
                  width={150}
                  onClick={saveVitalSigns}
                />
              </div>
            )}
          </div>
        </div>
        <ToastContainer />
      </SocketIOContextProvider>
    </GlobalStyle>
  );
};

export default PeripheriquesDeBase;
