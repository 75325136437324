// motifsSlice.ts

import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { SLICE } from "../../enums/store";
import { ImotifsState } from "../../interfaces/motifs";
import { useGetMotifsQuery } from "./motifsApi";
import { setConsultationData } from "@features/consultationData/consultationDataSlice";
import { useDispatch } from "react-redux";
const initialState: ImotifsState = {
  motifs: [],
  isLoading: false,
  isUpdated: false,
  isRefetchRequired: true,
  error: null,
};

const motifsSlice = createSlice({
  name: SLICE.MOTIFS,
  initialState,
  reducers: {
    setMotifs: (state, action: PayloadAction<ImotifsState>) => {
      switch (typeof action.payload.motifs) {
        case "string":
          state.motifs = [...state.motifs, action.payload.motifs];
          state.isRefetchRequired = action.payload.isRefetchRequired;
          state.isUpdated = true;
          break;
        case "object":
          state.motifs = action.payload.motifs;
          state.isRefetchRequired = action.payload.isRefetchRequired;
          state.isUpdated = true;
          break;

        default:
          break;
      }
    },
    setMotifsValue: (state, action: PayloadAction<string>) => {
      state.motifs = [...state.motifs, action.payload];
    },
    setMotifsFetched: (state, action: PayloadAction<string>) => {
      state.motifs = [...action.payload];
    },
    spliceMotifs: (state, action: PayloadAction<number>) => {
      let splicedMotifs = state.motifs;
      splicedMotifs.splice(action.payload, 1);
      state.motifs = splicedMotifs;
      state.isUpdated = true;
    },
    clearMotifs: (state) => {
      state.motifs = [];
      state.isUpdated = true;
    },
  },
});

export const {
  setMotifs,
  clearMotifs,
  setMotifsFetched,
  spliceMotifs,
  setMotifsValue,
} = motifsSlice.actions;
export default motifsSlice.reducer;
