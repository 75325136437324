// allergySlice.ts

import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { SLICE } from "../../enums/store";
import { useGetAllergyQuery } from "./allergyApi";
import { IAllergyState } from "@interfaces/allergy";
const initialState: IAllergyState = {
  allergy: [],
  isLoading: false,
  isUpdated: false,
  isAllergic:false,
  isRefetchRequired:true,
  error: null,
};

const allergySlice = createSlice({
  name: SLICE.ALLERGY,
  initialState,
  reducers: {
    setAllergy: (state, action: PayloadAction<IAllergyState>) => {
      switch (typeof action.payload.allergy) {
        case "string":
          state.allergy = [...state.allergy, action.payload.allergy];
          state.isRefetchRequired=action.payload.isRefetchRequired
          state.isUpdated = true;
          break;
        case "object":
          state.allergy = action.payload.allergy;
          state.isRefetchRequired=action.payload.isRefetchRequired
          state.isUpdated = true;
          break;
        default:
          break;
      }
    },
    setIsAllergic: (state, action: PayloadAction<boolean>) => {
          state.isAllergic = action.payload;
          state.isRefetchRequired=false
          state.isUpdated = true;
      
    },
    appendAllergy:(state, action: PayloadAction<string>) => {
      state.allergy = [...state.allergy, action.payload];
      state.isUpdated = true;
      state.isRefetchRequired=false;
    },
    spliceAllergy: (state, action: PayloadAction<number>) => {
      let splicedAllergy = state.allergy;
      splicedAllergy.splice(action.payload, 1);
      state.allergy = splicedAllergy;
      state.isUpdated = true;
      
    },
    clearAllergy: (state) => {
      state.allergy = [];
      state.isUpdated = true;
    },
  },
});

export const { setAllergy,appendAllergy,setIsAllergic, clearAllergy, spliceAllergy } = allergySlice.actions;
export default allergySlice.reducer;
