import { imageState } from "@interfaces/Examen";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

const initialState: imageState = {
  image: null,
  otherData: {},
};
const advancedParamSlice = createSlice({
  name: "advancedParam",
  initialState,
  reducers: {
    setImage(state, action: PayloadAction<any>) {
      console.log(action.payload);
      state.image = action.payload;
    },
    setPostData(state, action: PayloadAction<any>) {
      console.log("other data", action.payload);
      state.otherData = action.payload;
    },
    setImageType(state, action: PayloadAction<any>) {

      return {
        ...state,
        otherData: { ...state.otherData, name: action.payload.value },
      };
    },
  },
});
export const { setImage, setPostData, setImageType } =
  advancedParamSlice.actions;
export default advancedParamSlice.reducer;
