import * as React from "react";
import RadioInput from "../../../../../Components/FormBuilder/FormElements/Radio";
import TextArea from "../../../../../Components/FormBuilder/FormElements/TextArea";
import { Transition } from "@headlessui/react";
import { useDispatch, useSelector } from "react-redux";
import {
  clearToxicsOthers,
  setToxicsOthers,
} from "../../../../../features/consultationData/consultationDataSlice";
import { sethasOtherSubs } from "../../../../../features/hasOtherSubs/hasOtherSubsSlice";

export interface IOtherSubstancesProps {}

export default function OtherSubstances(props: IOtherSubstancesProps) {
  const [hasOtherSubtances, setHasOtherSubtances] =
    React.useState<boolean>(false);
  const dispatch = useDispatch();
  const storedSubs = useSelector(
    (state: any) =>
      state.consultationData.consultationData?.medicalHistory?.toxic?.others
        ?.nature
  );

  const storedData = useSelector(
    (state: any) => state.hasOtherSubs.hasOtherSubs
  );
  React.useEffect(() => {
    dispatch(sethasOtherSubs(hasOtherSubtances));
    dispatch(clearToxicsOthers());
  }, [hasOtherSubtances]);
  React.useEffect(() => {
    !hasOtherSubtances && storedData && setHasOtherSubtances(storedData);
  }, [storedData]);
  console.log(storedSubs);

  return (
    <div className="w-full">
      <div className="flex w-full gap-4 mb-6">
        <RadioInput
          id="Oui5"
          // onChange={handleOtherSubstancesChange}
          name="othersubstances"
          label="Oui"
          onChange={() => {
            setHasOtherSubtances(true);
          }}
          checked={hasOtherSubtances}
        />
        <div className="h-[2rem] w-[1px] bg-blue-gray-200"></div>
        <RadioInput
          id="Non5"
          // onChange={handleOtherSubstancesChange}
          name="othersubstances"
          label="Non"
          onChange={() => {
            setHasOtherSubtances(false);
          }}
          checked={!hasOtherSubtances}
        />
      </div>
      <Transition
        enter="transition-opacity ease-linear duration-300 "
        enterFrom="opacity-0"
        enterTo="opacity-100"
        leave="transition-opacity ease-linear duration-300"
        leaveFrom="opacity-100"
        leaveTo="opacity-0"
        show={hasOtherSubtances}
        className={"pr-3"}
      >
        <TextArea
          type="text"
          label="Nature"
          defaultValue={storedSubs ?? ""}
          onChange={(e: any) => {
            dispatch(setToxicsOthers(e));
          }}
          // defaultValue={otherToxicDescription}
          // value={otherToxicDescription}
          className="min-h-[6rem] w-[100%]  border-gray "
        />
      </Transition>
    </div>
  );
}
