import * as React from 'react';
import { useConsultationContext } from '../../../../../../src/services/ConsultationContext';

export interface IDiagnoscticAssocieIntelligentProps {
}

export default function Interpretation(props: IDiagnoscticAssocieIntelligentProps) {
    const {
        patient,
        vitalSignsc,
        setShowIframe,
        showIframe,
        receivedDiagnostics,
    } = useConsultationContext();

    return (
        receivedDiagnostics && <div className="bg-blue-100 border-t border-b border-blue-500 text-blue-700 px-4 py-3 mb-2" role="alert">
        <p className="font-bold">Interprétation</p>
        <p className="text-sm"> {receivedDiagnostics?.interpretation}</p>
      </div>

    );
}
