import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import {
    getConsultation,
    createConsultation,
    updateConsultation,
} from '../../axiosAPI/Network';
import { IConsultation, ICreateConsultation, IUpdateConsultation } from '../../interfaces/Consultation';
import { AxiosResponse } from 'axios';
import { SLICE,LOADING_STATE, CONSULTATION_ACTION_TYPES } from '../../enums/index';

interface ConsultationsState {
    entities: IConsultation[];
    loading: LOADING_STATE.IDLE |  LOADING_STATE.PENDING |  LOADING_STATE.SUCCEEDED |  LOADING_STATE.FAILED;
}

const initialState: ConsultationsState = {
    entities: [],
    loading:  LOADING_STATE.IDLE,
};

 
/**
 * @description get all consultations
 */
export const fetchConsultationById = createAsyncThunk(
    CONSULTATION_ACTION_TYPES.FETCH_BY_ID,
    async (consultationId: number) => {
        const response: AxiosResponse<IConsultation> = await getConsultation(consultationId);
        return response.data;
    }
);


/**
 * @description create a new consultation
 */
export const createNewConsultation = createAsyncThunk(
    CONSULTATION_ACTION_TYPES.CREATE,
    async (consultationData: ICreateConsultation) => {
        const response: AxiosResponse<IConsultation> = await createConsultation(consultationData);
        return response.data;
    }
);

/**
 * @description update a consultations
 */
export const updateExistingConsultation = createAsyncThunk(
    CONSULTATION_ACTION_TYPES.UPDATE,
    async ({ id, data }: { id: number; data: IUpdateConsultation }) => {
        const response: AxiosResponse<IConsultation> = await updateConsultation(data, id);
        return response.data;
    }
);

/**
 * @description Creating new slice
 */
const consultationsSlice = createSlice({
    name: SLICE.CONSULTATIONS,
    initialState,
    reducers: {
        resetConsultations(state) {
            state.entities = [];
        },
    },
    extraReducers: (builder) => {
        builder.addCase(fetchConsultationById.pending, (state) => {
            state.loading = LOADING_STATE.PENDING;
        });
        builder.addCase(fetchConsultationById.fulfilled, (state, { payload }) => {
            state.loading = LOADING_STATE.SUCCEEDED;
            state.entities.push(payload);
        });
        builder.addCase(fetchConsultationById.rejected, (state) => {
            state.loading = LOADING_STATE.FAILED;
        });

        builder.addCase(createNewConsultation.pending, (state) => {
            state.loading = LOADING_STATE.PENDING;
        });
        builder.addCase(createNewConsultation.fulfilled, (state, { payload }) => {
            state.loading = LOADING_STATE.SUCCEEDED;
            state.entities.push(payload);
        });
        builder.addCase(createNewConsultation.rejected, (state) => {
            state.loading = LOADING_STATE.FAILED;
        });

        builder.addCase(updateExistingConsultation.pending, (state) => {
            state.loading = LOADING_STATE.PENDING
        });
        builder.addCase(updateExistingConsultation.fulfilled, (state, { payload }) => {
            state.loading = LOADING_STATE.SUCCEEDED;
            const existingIndex = state.entities.findIndex(entity => entity.id === payload.id);
            if (existingIndex !== -1) {
                state.entities[existingIndex] = payload;
            }
        });
        builder.addCase(updateExistingConsultation.rejected, (state) => {
            state.loading = LOADING_STATE.FAILED;
        });
    },
});

export const { resetConsultations } = consultationsSlice.actions;

export default consultationsSlice.reducer;
