export enum app {

    VISIO_STATION = 'visioStation',
    TELE_EXPERTISE = 'teleExpertise',
    WAITING_LIST = 'WAITING_LIST',
    APPOINTMENTS = 'appointments',
    PATIENT_RECORD = 'patientRecord',
    SPECIALIST = 'specialist',
}

export enum examinationStep {
    MOTIF = 0,
    MEDICAL_HISTORY = 1,
    ON_GOING_TREATMENTS = 2,
    CLINICAL_EXAM = 3,
    AI_ASSISTANT = 4,
    REPORT_DIAGNOSTICS = 5,
    PRESCRIPTIONS = 6
}

export enum role {
    GENERALIST = "generalist",
    SPECIALIST = "specialist",
    NURSE = 'nurse',
    HOST = 'hostess'
}

export enum permission {
    CREATE_PATIENT_RECORD = 'CREATE_PATIENT_RECORD',
    UPDATE_PATIENT_RECORD = 'UPDATE_PATIENT_RECORD',
    ENCLOSE_CONSULTATION = "ENCLOSE_CONSULTATION",
    START_CONSULTATION = 'START_CONSULTATION',

}


export enum userStatus {
    ONLINE = 'online',
    IN_CALL = 'in_call',
    OFFLINE = 'offline'
}


export enum socketEvent {
    // get the real time connected users
    CONNECTED_USERS = 'connectedUsers',
    DOCTOR_STATUS  = 'doctorStatus'
}