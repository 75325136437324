import { AxiosResponse } from "axios";
import { IChiefComplaint } from "@interfaces/utils";
import instance from "../config/axios.config";
import  moment from 'moment';

/**
 * 
 * @param str 
 * @returns 
 */
export const getChiefComplaints = async (str: string): Promise<AxiosResponse<Array<IChiefComplaint>>> => {
    return str ?  instance.get(`/chiefComplaint?search=${str}&skip=${0}&take=${5}`) :  instance.get(`/chiefComplaint?&skip=${0}&take=${5}`);
  }
  

  /**
   * 
   * @returns 
   */
export function generateUniqueId() {
  const timestamp = new Date().getTime();
  const random = Math.floor(Math.random() * 10000);
   
  return `${timestamp}-${random}`;
}

/**
 * 
 * @param lastName 
 * @returns 
 */
export function formatLastName(lastName: string): string {
  return lastName.toUpperCase();
}


/**
 * 
 * @param firstName 
 * @returns 
 */
export function formatFirstName(firstName: string): string {
  return firstName.charAt(0).toUpperCase() + firstName.slice(1).toLowerCase();
}

/**
 * 
 * @param timestamp 
 * @returns 
 */
export function formatTimestamp(timestamp: string) {
  const inputDate = moment.utc(timestamp);
  const currentDate = moment();
  console.log("date is", inputDate);
  
  const formattedDate = inputDate.format('DD-MM-YYYY [à] HH:mm');
  const diffInSeconds = currentDate.diff(inputDate, 'seconds');
  const diffInMinutes = Math.floor(diffInSeconds / 60);
  const diffInHours = Math.floor(diffInMinutes / 60);
  const diffInDays = Math.floor(diffInHours / 24);
  const diffInMonths = currentDate.diff(inputDate, 'months');
  const diffInYears = currentDate.diff(inputDate, 'years');
  
  let diffText = '';
  if (diffInSeconds < 60) {
    diffText = `${diffInSeconds} second${diffInSeconds !== 1 ? 's' : ''}`;
  } else if (diffInMinutes < 60) {
    diffText = `${diffInMinutes} minute${diffInMinutes !== 1 ? 's' : ''}`;
  } else if (diffInHours < 24) {
    diffText = `${diffInHours} heure${diffInHours !== 1 ? 's' : ''}`;
  } else if (diffInDays < 30) {
    diffText = `${diffInDays} jour${diffInDays !== 1 ? 's' : ''}`;
  } else if (diffInMonths < 12) {
    diffText = `${diffInMonths} mois`;
  } else {
    diffText = `${diffInYears} an${diffInYears !== 1 ? 's' : ''}`;
  }
  
  return `${formattedDate} (${diffText})`;
}

/**
 * 
 * @param text 
 */
export function truncate(text: string, numberOfElements: number = 20) {
  return `${text.split(/\s+/).slice(0, numberOfElements).join(" ")} ...`
}
 





 




