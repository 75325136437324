import React, { useEffect, useState } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import {
  getCenters,
  getDoctorById,
  getDoctorNamebyId,
  getStaffById,
  updateDoctor,
  updateStaff,
} from "../../axiosAPI/Network";
import APP_CONSTANTS from "../../constants";
import Statistics from "../statistics/Statistics";
import { Link } from "react-router-dom";
import toast from "react-hot-toast";
import dayjs from "dayjs";
import { Divider } from "antd";




const validationSchema = Yup.object().shape({

  user: Yup.object().shape({
    firstName: Yup.string().required("ce champ est obligatoire"),
    lastName: Yup.string().required("ce champ est obligatoire"),
    email: Yup.string().email().notRequired(),
    gender: Yup.string().notRequired(),
    birthDate: Yup.date().required("ce champ est obligatoire"),
    address: Yup.string().notRequired(),
    phoneNumber: Yup.string().notRequired(),
    postalCode: Yup.number().notRequired(),
    credential: Yup.object().shape({
      login: Yup.string().required("ce champ est obligatoire"),
      password: Yup.string().notRequired(),
      confirm_password: Yup.string().oneOf(
        [Yup.ref("password"), null],
        "Les mots de passe ne sont pas les mêmes"
      ),
    }),
  }),
});



/**
 * 
 * @returns 
 */
const UpdateUser: React.FC = () => {
  const [userData, setUserData] = useState<UserData | null>(null);
  const [show, setShow] = useState<boolean>(false);
  const [centers, setCenters] = useState<any>([]);
  const [age, setAge] = useState<any>();

  const docId = window.localStorage.getItem(`${APP_CONSTANTS.LOCALSTORAGE_PREFIX}doctorId`);
  const hotessId = JSON.parse(window.localStorage.getItem(`${APP_CONSTANTS.LOCALSTORAGE_PREFIX}storedUser`));
  const idHotess = hotessId?.staff?.id

  useEffect(() => {
    if (idHotess) {
      getStaffById(idHotess).then((res) => {
        console.log(res);
        const item = res.data.user;
        const birthDate = new Date(item.birthDate);
        const today = new Date();
        const calculateAge = today.getFullYear() - birthDate.getFullYear();
        setAge(calculateAge);
        setUserData(item);
      })
    } else if (docId) {
      getDoctorById(docId).then((res) => {
        console.log(res);
        const item = res.data.user;
        const birthDate = new Date(item.birthDate);
        const today = new Date();
        const calculateAge = today.getFullYear() - birthDate.getFullYear();
        setAge(calculateAge);
        setUserData(item);
      });
    }
  }, [docId, idHotess]);
  useEffect(() => {
    getCenters().then((res) => {
      setCenters(res?.data?.results);
    });
  }, []);

  const handleSubmit = (values) => {

    const { confirm_password, ...credential } = values.user.credential;
    const updatedData = { user: { ...values.user, credential: credential } };

    const cleanedData = Object.fromEntries(Object.entries(updatedData.user).filter(([_, v]) => v !== "" && v !== null && v !== undefined));
    const cleanedDataCredential = Object.fromEntries(Object.entries(updatedData.user.credential).filter(([_, v]) => v !== "" && v !== null && v !== undefined
    )
    );

    const updatedDatatosend = {
      user: {
        ...cleanedData,
        credential: cleanedDataCredential,
      },
    };
    if (values.user.credential.password != "" && values.user.credential.confirm_password == "") {
      toast.error("Merci de confirmer le mot de pass");
    } else {
      if (docId !== "undefined") {
        console.log("in doctor", docId)
        updateDoctor(docId, updatedDatatosend).then((res) => {
          if (res.status === 200) {
            toast.success("votre compte a été mis à jour avec succès");
          }
        })
      } if (idHotess !== undefined) {

        updateStaff(idHotess, updatedDatatosend).then((res) => {
          if (res.status === 200) {
            toast.success("votre compte a été mis à jour avec succès");
          }
        })
      }

    }



  };

  return (
    <div className="bg-white p-6 rounded">
      <div className="m-2">
        <label className="relative inline-flex items-center cursor-pointer">
          <input
            type="checkbox"
            value=""
            className="sr-only peer"
            name="show"
            onChange={(e) => setShow(e.target.checked)}
          />
          <h1 className="text-primary">Mon profile</h1>
          {/* <div className="w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white  after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-blue-300"></div>
          <span className="ml-3 text-md font-bold text-gray-700 dark:text-gray-300">
            {show ? <p>Afficher Profile</p> : <p>Afficher les Statistique</p>}
          </span> */}
        </label>
      </div>
      {!show ? (
        <>
          {userData ? (
            <Formik

              initialValues={{
                // center: userData.centerId,
                user: {
                  lastName: userData?.lastName || null || undefined,
                  firstName: userData?.firstName || null || undefined,
                  email: userData?.email || null || undefined,
                  gender: userData?.gender || null || undefined,
                  birthDate: dayjs(userData?.birthDate).format("YYYY-MM-DD") || null || undefined,
                  postalCode: userData?.postalCode || null || undefined,
                  address: userData?.address || null || undefined,
                  phoneNumber: userData?.phoneNumber || null || undefined,
                  credential: {
                    login: userData?.credential?.login,
                    password: "",
                    confirm_password: "",
                  },
                },
              }}
              validationSchema={validationSchema}
              onSubmit={handleSubmit}
              validateOnBlur={false}
            >
              <Form autoComplete="off">
                <div className="">

                  {/* personnal informations */}
                  <div className="grid grid-cols-4 gap-4">
                    <div>
                      <label
                        htmlFor="user.firstName"
                        className="block mb-2 text-sm  text-[#50799E] font-semibold dark:text-white"
                      >
                        Prénom<span className="text-red-600 ml-2">*</span>
                      </label>
                      <Field
                        autoComplete="off"
                        type="text"
                        id="user.firstName"
                        name="user.firstName"
                        className="block px-2.5 pb-2.5 pt-4 w-full text-sm text-gray-900 bg-transparent rounded-lg border-1 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600"
                      />
                      <ErrorMessage
                        name="user.firstName"
                        component="div"
                        className="error text-red-600"
                      />
                    </div>
                    <div>
                      <label
                        htmlFor="user.lastName"
                        className="block mb-2 text-sm  text-[#50799E] font-semibold dark:text-white"
                      >
                        Nom<span className="text-red-600 ml-2">*</span>
                      </label>
                      <Field
                        autoComplete="off"
                        type="text"
                        id="user.lastName"
                        name="user.lastName"
                        className="block px-2.5 pb-2.5 pt-4 w-full text-sm text-gray-900 bg-transparent rounded-lg border-1 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600"
                      />
                      <ErrorMessage
                        name="user.lastName"
                        component="div"
                        className="error text-red-600"
                      />
                    </div>
                    <div>
                      <label
                        htmlFor="user.email"
                        className="block mb-2 text-sm  text-[#50799E] font-semibold dark:text-white"
                      >
                        Email<span className="text-[#1AA5C0] ml-2">*</span>
                      </label>
                      <Field
                        autoComplete="new-email"
                        type="email"
                        id="user.email"
                        name="user.email"
                        className="block px-2.5 pb-2.5 pt-4 w-full text-sm text-gray-900 bg-transparent rounded-lg border-1 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600"
                      />
                      <ErrorMessage
                        name="user.email"
                        component="div"
                        className="error"
                      />
                    </div>
                    <div>
                      <label
                        htmlFor="user.gender"
                        className="block mb-2 text-sm  text-[#50799E] font-semibold dark:text-white"
                      >
                        Sexe<span className="text-[#1AA5C0] ml-2">*</span>
                      </label>
                      <Field
                        as="select"
                        id="user.gender"
                        name="user.gender"
                        className="block px-2.5 pb-2.5 pt-4 w-full text-sm text-gray-900 bg-transparent rounded-lg border-1 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600"
                      >
                        <option value="">choisir a gender</option>
                        <option value="male">HOMME</option>
                        <option value="female">FEMME</option>
                      </Field>
                      <ErrorMessage
                        name="user.gender"
                        component="div"
                        className="error"
                      />
                    </div>
                    <div>
                      <label
                        htmlFor="user.phoneNumber"
                        className="block mb-2 text-sm  text-[#50799E] font-semibold dark:text-white"
                      >
                        Téléphone mobile
                        <span className="text-[#1AA5C0] ml-2">*</span>
                      </label>
                      <Field
                        autoComplete="off"
                        type="text"
                        id="user.phoneNumber"
                        name="user.phoneNumber"
                        className="block px-2.5 pb-2.5 pt-4 w-full text-sm text-gray-900 bg-transparent rounded-lg border-1 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600"
                      />
                      <ErrorMessage
                        name="phoneNumber"
                        component="div"
                        className="error"
                      />
                    </div>
                    <div>
                      <div className="flex justify-between gap-3">
                        <div className="w-full">
                          <label
                            htmlFor="user.birthDate"
                            className="block mb-2 text-sm  text-[#50799E] font-semibold dark:text-white"
                          >
                            Date de naissance
                            <span className="text-red-600 ml-2">*</span>
                          </label>
                          <Field
                            autoComplete="off"
                            type="date"
                            id="user.birthDate"
                            name="user.birthDate"
                            className="block px-2.5 pb-2.5 pt-4 w-full text-sm text-gray-900 bg-transparent rounded-lg border-1 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600"
                          />
                          <ErrorMessage
                            name="user.birthDate"
                            component="div"
                            className="error text-red-600"
                          />
                        </div>


                      </div>
                    </div>
                    <div>
                      <label
                        htmlFor="user.postalCode"
                        className="block mb-2 text-sm  text-[#50799E] font-semibold dark:text-white"
                      >
                        Code postal<span className="text-[#1AA5C0] ml-2">*</span>
                      </label>
                      <Field
                        autoComplete="off"
                        type="number"
                        id="user.postalCode"
                        name="user.postalCode"
                        className="block px-2.5 pb-2.5 pt-4 w-full text-sm text-gray-900 bg-transparent rounded-lg border-1 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600"
                      />
                      <ErrorMessage
                        name="user.postalCode"
                        component="div"
                        className="error"
                      />
                    </div>
                    <div>
                      <label
                        htmlFor="user.address"
                        className="block mb-2 text-sm  text-[#50799E] font-semibold dark:text-white"
                      >
                        Adresse<span className="text-[#1AA5C0] ml-2">*</span>
                      </label>
                      <Field
                        autoComplete="off"
                        type="text"
                        id="user.address"
                        name="user.address"
                        className="block px-2.5 pb-2.5 pt-4 w-full text-sm text-gray-900 bg-transparent rounded-lg border-1 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600"
                      />
                      <ErrorMessage
                        name="user.address"
                        component="div"
                        className="error"
                      />
                    </div>

                  </div>
                  {/* end personnal informations */}

                  <div className="mt-5 mb-5">
                    <hr />
                  </div>
                  
                  {/* account informations */}
                  <div className="grid grid-cols-4 gap-4">
                    <div>
                      <label
                        htmlFor="user.credential.login"
                        className="block mb-2 text-sm  text-[#50799E] font-semibold dark:text-white"
                      >
                        Login<span className="text-red-600 ml-2">*</span>
                      </label>
                      <Field
                        autoComplete="off"
                        type="text"
                        id="user.credential.login"
                        name="user.credential.login"
                        className="block px-2.5 pb-2.5 pt-4 w-full text-sm text-gray-900 bg-transparent rounded-lg border-1 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600"
                      />
                      <ErrorMessage
                        name="user.credential.login"
                        component="div"
                        className="error text-red-600"
                      />
                    </div>
                    <div>
                      <label
                        htmlFor="user.credential.password"
                        className="block mb-2 text-sm text-[#50799E] font-semibold dark:text-white"
                      >
                        Mot de passe<span className="text-[#1AA5C0] ml-2">*</span>
                      </label>
                      <Field
                        autoComplete="new-password"
                        type="password"
                        id="user.credential.password"
                        name="user.credential.password"
                        className="block px-2.5 pb-2.5 pt-4 w-full text-sm text-gray-900 bg-transparent rounded-lg border-1 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600"
                      />
                      <ErrorMessage
                        name="user.credential.password"
                        component="div"
                        className="error"
                      />
                    </div>
                    <div>
                      <label
                        htmlFor="user.credential.confirm_password"
                        className="block mb-2 text-sm text-[#50799E] font-semibold dark:text-white"
                      >
                        Confirmer Mot de passe
                        <span className="text-[#1AA5C0] ml-2">*</span>
                      </label>
                      <Field
                        autoComplete="new-confirm_password"
                        type="password"
                        id="user.credential.confirm_password"
                        name="user.credential.confirm_password"
                        className="block px-2.5 pb-2.5 pt-4 w-full text-sm text-gray-900 bg-transparent rounded-lg border-1 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600"
                      />
                      <ErrorMessage
                        name="user.credential.confirm_password"
                        component="div"
                        className="error text-red-600"
                      />
                    </div>
                  </div>
                  {/* end account informations */}



                  {/* actions */}
                  <div className="col-span-4 flex justify-between mt-5">
                    <Link
                      to="/doctor/searchPatient"
                      className="text-white bg-[#858585] w-36 py-2 px-10 rounded"
                    >
                      Annuler
                    </Link>
                    <button
                      type="submit"
                      className="bg-[#1AA5C0] text-white w-36 px-5 py-2 rounded"
                    >
                      Enregistrer
                    </button>
                  </div>
                  {/* end actions */}
                </div>
              </Form>
            </Formik>
          ) : (
            <p>Loading user data...</p>
          )}{" "}
        </>
      ) : (
        <Statistics />
      )}
    </div>
  );
};

export default UpdateUser;
