// import { RootState } from "../../store";
import { useFetchWaitingListByCenterQuery } from "../../features/waitingList/waitingListApi"
// import { CustomTable } from "../table"
// import { useSelector } from "react-redux";
// import { waitingListStatus } from "../../enums/waitingList";



// interface IWaitingListTableProps {

// }

// export const WaitingListTable = ({ }: IWaitingListTableProps) => {
//     const { id:centerId } = useSelector((state: RootState) => state.auth.center);


//     return (<><CustomTable
//         hook={useFetchWaitingListByCenterQuery}
//         hookParams={[centerId]}
//         headers={['ordre', 'Prénom', 'Nom', 'Actions']} />
//     </>) 
// }

import { useSelector } from "react-redux";
import { RootState } from "../../store";
import styled from "styled-components";
import { FunnelIcon } from "@heroicons/react/24/solid";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { admitPatient, deletePatientFromWaitingList, getSpecialities, getWaitingListByCenterId } from "../../axiosAPI/Network";
import { Button } from "@material-tailwind/react";
import { toast } from "react-hot-toast";
import useDynamicDialog from "../../hooks/useDynamicDialog";
import { TiArrowBackOutline } from "react-icons/ti"
import { BsTrash3 } from "react-icons/bs"
import { CgSandClock } from "react-icons/cg"
import waitinglisticon from "../../assets/waitinglisticon.svg"
import { getSpeciality } from "../../axiosAPI/medical-history";
import moment from "moment";


// styles
const GlobalStyle = styled.div`
background: white;
border-top-left-radius: 7px;
border-top-right-radius: 7px;
height: 100%;
overflow-y: auto;
`;

const GlobalContent = styled.div`
width: 100%;
padding: 2rem;
display: flex;
flex-direction: column;
gap: 1rem;
box-sizing: border-box;
height: 100%;
.title {
font-family: "Ubuntu";
font-style: normal;
font-weight: 500;
font-size: 35.0127px;
line-height: 17px;
display: flex;
align-items: center;
color: #50799E;
}
`;
const WrapperTable = styled.div`
background: rgba(249, 249, 249, 0.27);
border: 1.45967px solid #858585;
border-radius: 10.2177px;
padding: 10px;
flex-grow: 1;
overflow-y: scroll;
&::-webkit-scrollbar {
  width: 8px;
  right: 5px;
  margin: 10px;
}



&::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 14.4896px;
  right: 5px;
  margin: 10px;
}

&::-webkit-scrollbar-thumb {
  background: #c1c1c1;
  border-radius: 14.4896px;
  right: 5px;
  margin: 10px;
}
`;

const PaginationStyle = styled.div`
display: flex;
justify-content: end;
align-items: center;
background: #ffffff;
box-shadow: 0px 1.30033px 5.20132px rgba(0, 0, 0, 0.12);
border-radius: 5.20132px;
padding: 0.5rem;
.btns {
  display: flex;
  gap: 0.2rem;
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 15.9559px;
  button {
    /* background-color: red; */
    width: 25px;
    aspect-ratio: 1;
    cursor: pointer;
    font-family: "Inter";
    color: #5c6265;
  }
  .actv-btn {
    background: #ffffff;
    box-shadow: 0px 1.46287px 5.85148px rgba(26, 26, 67, 0.1);
    border-radius: 5.85148px;
  }
  .next {
    width: 25px;
    aspect-ratio: 1;
    background: #1AA5C0;
    border-radius: 5.85148px;
    text-align: center;
    color: white;
    cursor: pointer;
  }
}
`;
const Imgicon = styled.img`

width: 56.433px;
height: 56.433px;
flex-shrink: 0;
`
const TableContainer = styled.table`
  width: 100%;
  border-collapse: collapse;
`;

const HeaderRow = styled.tr`
  background-color: #ffff;
  height: 55px;
  border-radius: 10.6765px;
`;

const HeaderCell = styled.th`
  padding: 8px;
`;

const DataRow = styled.tr<{ isEven: boolean }>`
  background-color: ${(props) => (props.isEven ? "#EFEFEF" : "#ffff")};
  height: 55px;
  border-radius: 16px;
  cursor: pointer;
  color: #858585;
`;

const DataCell = styled.td`
  padding: 8px;
  text-align: center;
`;


interface ICustomTableProps {
  headers: Array<string>,
  //   hook: any,
  //   hookParams?: Array<any>
}

export const WaitingListTable = ({ headers }: ICustomTableProps) => {
  const { id: centerId } = useSelector((state: RootState) => state.auth.center);
  const { openDynamicDialog, closeDynamicDialog } = useDynamicDialog();

  const [dataTable, setDataTable] = useState<any>([]);
  const [totalResults, setTotalResults] = useState<number>([]);
  const [loading, setLoading] = useState<any>(false);


  const nav = useNavigate();
  const className = null;

  useEffect(() => {
    fetchWaitingList();
    getSpecialities().then((res) => console.log(res?.data))
  }, []);


  /**
   * 
   */
  const fetchWaitingList = () => {
    setLoading(true);
    getWaitingListByCenterId(centerId).then((res) => {

      setTotalResults(res?.data?.totalResults);
      setDataTable(res?.data?.results);
      setLoading(false);


    }).catch((err) => {
      setLoading(false);

    })
  }


  const handleAdmitButtonClick = (rowId: any) => {
    admitPatient(rowId).then(() => {
      fetchWaitingList();
      toast.success("Le patient est introduit au box avec succès")
    }).catch(() => {
      toast.error("une erreur est survenue")
    })


  }


  const handleCancelAdmissionButtonClick = (id: any) => {
    const admissionId = 1;
    deletePatientFromWaitingList(id).then(() => {
      toast.success("Le patient est supprimé de la liste d'attente")
      fetchWaitingList();
    }).catch(() => {
      toast.error("une erreur est survenue")
    })
  }


  return (
    <WrapperTable>
      <div className="flex align-middle items-center gap-6 bg-white p-4">
        <img src={waitinglisticon} />
        <h1 className="text-[#50799E] font-semibold text-2xl">Liste d’attente {`(${totalResults} personnes)`}</h1>
      </div>
      <TableContainer>
        <thead>
          <HeaderRow className="text-[#5E5E5E]">

            {headers.map((headerItem: string, key: number) => (
              <HeaderCell key={key} className="bg-[#1AA5C01C]">
                <div className="flex justify-center gap-5 ml-4  text-[#1AA5C0]">
                  <span>{headerItem}</span>
                </div>
              </HeaderCell>
            ))}


          </HeaderRow>
        </thead>


        {dataTable && dataTable.length > 0 && (
          <tbody>
            {dataTable && dataTable.map((dataRow: any, index: any) => (
              <DataRow
                className="bg-white gap-3 border-4 rounded-xl	"
                key={index}
                isEven={index % 2 === 0}
                onClick={() => {
                  //   nav(`/doctor/patient/${dataRow.patient.id}`);
                }}
              >
                <DataCell className="justify-center text-[#50799E]">{dataRow.number}</DataCell>
                <DataCell className=" justify-center text-[#50799E]">{dataRow?.patient?.user?.firstName}</DataCell>
                <DataCell className="justify-center text-[#50799E]">{dataRow?.patient?.user?.lastName}</DataCell>
                <DataCell className={dataRow?.speciality?.name === "Gynécologue" ? "justify-center text-[#FD5FA1]" :
                  dataRow?.speciality?.name === "Généraliste" ? "justify-center text-[#1BA7E3]" :
                    dataRow?.speciality?.name === "ORL" ? "justify-center text-[#FF4C25]" :
                      dataRow?.speciality?.name === "Dermatologue" ? "justify-center text-[#FF9F47]" :
                        dataRow?.speciality?.name === "Pneumologue" ? "justify-center text-[#C54573]" :
                          dataRow?.speciality?.name === "Cardiologue" ? "justify-center text-[#CE1435#271e5f]" :
                            dataRow?.speciality?.name === "Pédiatre" ? "justify-center text-[#26BB62]" :
                              dataRow?.speciality?.name === "Ophtalmologue" ? "justify-center text-[#3163E5]" :
                                dataRow?.speciality?.name === "Endocrinologue" ? "justify-center text-[#8C2FEA]" :
                                  ""}>
                  {dataRow?.speciality?.name}
                </DataCell>
                <DataCell className={dataRow.status == "waiting" ? "text-yellow-400 flex justify-center items-center mt-2 gap-2" : ""}>
                  <CgSandClock size={20} />{dataRow.status == "waiting" ? "En attente" : ""}
                </DataCell>
                <DataCell className="justify-center text-[#50799E]">{moment(dataRow?.createdAt).format('HH:mm')}</DataCell>
                <DataCell className={""}>
                  <div className="flex gap-3 justify-center">

                    <Button className="flex gap-2 items-center rounded-full bg-Bleu"

                      onClick={() => handleAdmitButtonClick(dataRow.id)}>
                      <TiArrowBackOutline size={20} />Introduire
                    </Button>
                    <Button className="bg-red-400 flex items-center rounded-full gap-2"
                      onClick={() => handleCancelAdmissionButtonClick(dataRow.id)
                      }>
                      <BsTrash3 size={20} />
                      Supprimer</Button>

                  </div>
                </DataCell>
              </DataRow>

            ))}
          </tbody>
        )}
      </TableContainer>

    </WrapperTable >
  );
}