// selectedFamiliauxSlice.ts

import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { SLICE } from "../../enums/store";
import { IselectedFamiliauxState } from "../../interfaces/selectedFamiliaux";
// import { useGetSelectedFamiliauxQuery } from "./selectedFamiliauxApi";
const initialState: IselectedFamiliauxState = {
  selectedFamiliaux: [],
  isLoading: false,
  isUpdated: false,
  isRefetchRequired:true,
  error: null,
};

const selectedFamiliauxSlice = createSlice({
  name: SLICE.SELECTED_FAMILIAUX,
  initialState,
  reducers: {
    setSelectedFamiliaux: (state, action: PayloadAction<IselectedFamiliauxState>) => {
      switch (typeof action.payload.selectedFamiliaux) {
        case "string":
          state.selectedFamiliaux = [...state.selectedFamiliaux, action.payload.selectedFamiliaux];
          state.isRefetchRequired=action.payload.isRefetchRequired
          state.isUpdated = true;
          break;
        case "object":
          state.selectedFamiliaux = action.payload.selectedFamiliaux;
          state.isRefetchRequired=action.payload.isRefetchRequired
          state.isUpdated = true;
          break;
        default:
          break;
      }
    },
    appendSelectedFamiliaux:(state, action: PayloadAction<string>) => {
      state.selectedFamiliaux = [...state.selectedFamiliaux, action.payload];
      state.isUpdated = true;
      state.isRefetchRequired=false;
    },
    spliceSelectedFamiliaux: (state, action: PayloadAction<number>) => {
      let splicedSelectedFamiliaux = state.selectedFamiliaux;
      splicedSelectedFamiliaux.splice(action.payload, 1);
      state.selectedFamiliaux = splicedSelectedFamiliaux;
      state.isUpdated = true;
      
    },
    clearSelectedFamiliaux: (state) => {
      state.selectedFamiliaux = [];
      state.isUpdated = true;
    },
  },
});

export const { setSelectedFamiliaux,appendSelectedFamiliaux, clearSelectedFamiliaux, spliceSelectedFamiliaux } = selectedFamiliauxSlice.actions;
export default selectedFamiliauxSlice.reducer;
