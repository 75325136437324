import React from "react";
import styled from "styled-components";
import Header from "../../layout/Header";
import InputLogin from "./InputLogin";
import loginImage from "../../assets/loginImage.png";
import msmaroc from "../../assets/MS-Maroc.png";
import backimg from '../../assets/backimg.svg';
import visos from '../../assets/vs.svg';
import logo from '../../assets/homeLogo.svg';
const GlobalStyle = styled.div`
  width: 100vw;
  height: 100vh;
  background-image: url("/assets/background.jpg");
  background-position: center;
  background-attachment: fixed;
  background-repeat: no-repeat;
  background-size: auto;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  position: relative;
  overflow: hidden;
`;
const ContentStyle = styled.div`
  width: 100%;
  flex-grow: 1;
  margin-top: 34px;
  /* padding-right: 15%; */
  display: flex;
  align-items: center;
  /* justify-content: start; */ 
`;
const Imgm = styled.img`
margin-left:800px;
height:99px;
padding-top:10px;
padding-bottom:10px;
`
const Imgv = styled.img`
width: 73.201px;
height: 38.615px;
margin-top:-95px;
margin-left:10px;`

const Visiom = styled.span`
color: #50799E;
font-feature-settings: 'clig' off, 'liga' off;
font-family: Ubuntu;
font-size: 29.839px;
font-style: normal;
font-weight: 500;
line-height: 23.671px; /* 79.33% */
letter-spacing: -1px;
margin-left:10px;
margin-top:-95px;`



const LoginPage = () => {
  return (
    <div style={{ backgroundImage:`url("/assets/background.jpg")`,
                   backgroundRepeat:'no-repeat',
                   backgroundSize:'auto',
                   backgroundPosition: 'cover',
                   width: '100vw',
                   height: '100vh'
                   }} className="overflow-auto">

      <Header isLoginPage={true} />
      
      <div className="flex justify-between bg-white w-auto mt-5 mr-10 ml-10 pt-5 pb-5 pr-5 pl-5 rounded-lg">
        <img src={logo} alt="logo" />
        <img src={msmaroc} alt="logo" className="h-12"/>
      </div>


      <div className="flex flex-row justify-around flex-wrap m-5">
            <img src={loginImage} className="lg:w-[30%] sm:w-[100%] h-1/2 mt-24 object-fill " />
          <InputLogin />
      </div>
      <span className="flex justify-center absolute bottom-2 text-[#eaeaeae2] text-[16px] font-medium ">
        Copyright © 2023 MEDIOT | Tous droits réservés
      </span>
    </div>
  );
};

export default LoginPage;
