import RadioInput from "../../../../Components/FormBuilder/FormElements/Radio";
import { ReactComponent as TabacSvg } from "../../../../assets/tabac.svg";
import { ReactComponent as AlcoholSvg } from "../../../../assets/alcohol.svg";
import { ReactComponent as PeluleSvg } from "../../../../assets/pelule.svg";
import TabacConsumption from "./TabacConsumption";
import TextArea from "../../../../Components/FormBuilder/FormElements/TextArea";
import OtherSubstances from "./OtherSubstances";
import AlcoholConsumption from "./AlcoholConsumption";

export interface IHabitudesToxiquesProps {
}

export default function HabitudesToxiques (props: IHabitudesToxiquesProps) {
  return (
    <div className="content flex gap-4 mt-6 w-full">
          <div className="relative flex items-start pl-4 w-full flex-col rounded-xl bg-white bg-clip-border text-gray-700 shadow-md">

          <div className="  flex flex-col gap-4 p-1">
            <div className="title flex gap-2 items-center">
              <TabacSvg width={20} />
              <span className="text-[#5E5E5E]">Tabac</span>
            </div>
            
              
               <TabacConsumption/>
          </div></div>
          <div className="relative flex items-start pl-4 w-full flex-col rounded-xl bg-white bg-clip-border text-gray-700 shadow-md">

          <div className="alcohol flex flex-col gap-4 p-1 px-10">
            <div className="title flex gap-2 items-center">
              <AlcoholSvg width={20} />
              <span className="text-[#5E5E5E]">Alcool</span>
            </div>
           <AlcoholConsumption/>
          </div>
                </div>
                <div className="relative w-full flex items-start pl-4  flex-col rounded-xl bg-white bg-clip-border text-gray-700 shadow-md">

          <div className="autres w-full flex flex-col gap-4 p-1">
            <div className="title flex gap-2 items-start">
              <PeluleSvg width={20} />
              <span className="text-[#5E5E5E]">Autres substances</span>
            </div>
            <div className="flex gap-4 items-center w-full">
              <OtherSubstances/>
            </div>
            
          </div></div>
        </div>
  );
}
