import { Card, Typography } from "@material-tailwind/react";

// const header = ["Name", "Job", "Employed", ""];

type rowsType = {
  doctor: string;
  date: string;
  time: string;
  modificationText: string;
};

type headerType = string[];
export default function Table({
  header,
  rows,
}: {
  header: headerType;
  rows: rowsType[];
}) {
  return (
    <Card className="overflow-y-scroll max-h-[80%] h-full w-full rounded-lg border-2 border-gray-600/50 p-4">
      <table className="w-full min-w-max table-auto text-left">
        <thead>
          <tr>
            {header.map((head) => (
              <th
                key={head}
                className="border-b border-blue-gray-100 bg-[#F9F9F945] p-4"
              >
                <Typography
                  variant="small" style={{ font:"Ubuntu"}}
                  className="font-bold text-sm text-[#5E5E5E] leading-none"
                >
                  {head}
                </Typography>
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {rows.map(({ date, time, modificationText, doctor }, index) => (
            <tr key={doctor} className="odd:bg-gray-200">
              <td className="p-4">
                <Typography
                  variant="small"
                  color="blue-gray"
                  className="font-medium text-[#858585] rounded-lg font-xl text-lg"
                >
                  {date}
                </Typography>
              </td>
              <td className="p-4">
                <Typography
                  variant="small"
                  color="blue-gray"
                  className="font-medium text-[#858585] rounded-lg font-xl text-lg"
                >
                  {time}
                </Typography>
              </td>
              <td className="p-4">
                <Typography
                  variant="small"
                  color="blue-gray"
                  className="font-medium text-[#858585] rounded-lg font-xl text-lg"
                >
                  {doctor}
                </Typography>
              </td>
              <td className="p-4">
                <Typography
                  variant="small"
                  color="blue-gray"
                  className="font-medium text-[#858585] font-xl text-lg"
                >
                  {modificationText}
                </Typography>
              </td>
              {/* <td className="p-4">
                <Typography
                  as="a"
                  href="#"
                  variant="small"
                  color="blue"
                  className="font-medium"
                >
                  Edit
                </Typography>
              </td> */}
            </tr>
          ))}
        </tbody>
      </table>
    </Card>
  );
}
