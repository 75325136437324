import { setToxicsTabacDuration } from '../../../../../../features/consultationData/consultationDataSlice';
import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';

export interface ISevreProps {
}

export default function Sevre (props: ISevreProps) {
    const dispatch=useDispatch()
    const storedTabacData= useSelector((state:any)=>state.consultationData?.consultationData.medicalHistory?.toxic?.tabac)

  return (
    <div className="w-full flex flex-col items-center justify-start gap-3 ">
                 
                  
                  <div>
                    <h1 className="text-[#5E5E5E] text-sm mb-1 mt-2">
                      Depuis
                    </h1>
                    <div
                      className="input border p-1 flex items-
                     border-[#5E5E5E] rounded-md"
                    >
                      <input                        
                        type="number"
                        defaultValue={storedTabacData?.duration}

                        onChange={(e)=>{
                          dispatch(setToxicsTabacDuration(e.target.value))
                        }}
                        className="border-none w-[100%] outline-none focus:border-none"
                      />
                      <span className="text-[#1AA5C0] text-sm font-[500] ">
                        Années
                      </span>
                    </div>
                  </div></div>
                  
  );
}
