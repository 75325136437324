import React, { useMemo } from "react";
import MyButton from "../common/Button";
import { ReactComponent as PlusSvg } from "../../assets/plus.svg";
import Search from "../common/Search";
import { Select, Option } from "@material-tailwind/react";
import { ReactComponent as JoinFileSvg } from "../../assets/joinFile.svg";
import { ReactComponent as DeleteSvg } from "../../assets/deleteSvg.svg";
import { Dialog, Input } from "@material-tailwind/react";
import { useState } from "react";
import { useEffect } from "react";
import instance from "../../axiosAPI/Network";
import styled from "styled-components";
import moment from "moment";
import { useParams } from "react-router-dom";
import APP_CONSTANTS from "../../constants";

const header = ["Date", "Titre document", "Médecin", "Classeur"];

// select componet /////////////
interface SelectProps {
  label?: string;
  options?: Array<{
    label: string;
    value: string;
  }>;
  title?: string;
  onChange?: any;
  name?: string;
  defaultLabel?: string;
}
const SearchPart = styled.div`
  height: 62.54px;
  background: #ffffff;
  border: 1px solid #1AA5C0;
  border-radius: 8.7557px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  input {
    all: unset;
    width: 70%;
    border-style: none;
    height: 80%;
    font-size: 22px;
    padding: 10px;
  }
`;

const CellTypo = styled.div`
  font-family: "Ubuntu";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  text-align: center;
  color: #858585;
`;

const SpecialityTypo = styled.div`
  font-family: "Ubuntu";
  font-style: normal;
  font-weight: 500;
  font-size: 16.7345px;
`;

export const MyUISelect = (props: SelectProps) => {
  return (
    <>
      <h4 className="mb-2 text-lg text-[#1AA5C0]">{props.label}</h4>
      <Select
        label={props.defaultLabel || ""}
        onChange={props.onChange}
        color="blue-gray"
        name={props.name}
      >
        {props?.options?.map((item, i) => (
          <Option key={i} value={item.value + ""}>
            {item.label}
          </Option>
        ))}
      </Select>
    </>
  );
};

interface IDocmuentProps {
  patientId?: number
}
const Documents = (props: IDocmuentProps) => {
  //// define the state of the selected item to change the background color
  const [selectedSpeciality, setselectedSpeciality] = React.useState(-1);
  const [selectedFile, setSelectedFile] = useState("");
  const [documents, setDocuments] = useState([]);
  const [search, setSearch] = useState("");

  interface Iparams {
    include: string;
    where?: any;
    search?: string;
  }

  const getAttachement = async () => {
    const where = JSON.stringify({
      specialityId: selectedSpeciality,
    });
    const params: Iparams = {
      include: "speciality,user,consultation",
    };
    if (selectedSpeciality !== -1) {
      params["where"] = where;
    }
    if (search !== "") {
      params["search"] = search;
    }
    const res = await instance.get("/attachement", {
      params,
    });
    setDocuments(res.data.results);
  };
  // fetch attachement when changin speciality
  useEffect(() => {
    getAttachement();
  }, [selectedSpeciality]);

  const formatedDocuments = useMemo(() => {
    return documents.map((item: any) => ({
      date: moment(item.createdAt).format("DD/MM/YYYY"),
      title: item.name,
      doctor: item.user.firstName + " " + item.user.lastName,
      speciality: item.speciality.name,
    }));
  }, [documents]);

  // get speciality
  const getSpeciality = async () => {
    const res = await instance.get("/speciality");
    setSpeciality(res.data.results);
  };
  const [speciality, setSpeciality] = useState([]);
  useEffect(() => {
    getSpeciality();
    getAttachement();
  }, []);

  const specialtyOptions = speciality.map((item: any) => ({
    label: item.name,
    value: item.id,
  }));

  // modal /////////////
  const [open, setOpen] = useState(false);
  const handleOpen = () => {
    setOpen(!open);
  };

  interface inputData {
    name: string;
    type: string;
    specialityId: number;
    comment: string;
    file: File | null;
  }

  /// input search debounced
  useEffect(() => {
    const debounceTimeout = setTimeout(() => {
      getAttachement();
    }, 500);

    return () => {
      clearTimeout(debounceTimeout);
    };
  }, [search]);

  const [inputData, setInputData] = useState({} as inputData);
  const [file, setFile] = useState(null);

  /// file input on change
  function handleFileChange(event: any) {
    const fileName = event.target.value.split("\\").pop(); // Retrieve selected file name
    setSelectedFile(fileName); // Update state with selected file name
    setFile(event.target.files[0]);
  }

  const formData = new FormData();

  const [patientId, setPatientId] = useState("");
  const [doctorId, setDoctorId] = useState("");

  const getPatientId = () => {
    const { id: patientId } = useParams();
    setPatientId(patientId || "");
  };
  const getDoctorId = () => {
    const docId = window.localStorage.getItem(`${APP_CONSTANTS.LOCALSTORAGE_PREFIX}doctorId`);
    setDoctorId(docId || "");
  };
  useMemo(() => {
    getPatientId();
    getDoctorId();
  }, []);

  const handleSubmit = async () => {
    formData.append("name", inputData.name);
    formData.append("type", inputData.type);
    formData.append("specialityId", inputData.specialityId.toString());
    formData.append("comment", inputData.comment);
    formData.append("userId", patientId);
    if (file) formData.append("file", file);
    await instance.post("/attachement", formData);
    getAttachement();
    handleOpen();
  };
  return (
    <div className=" w-full h-full  flex gap-3 ">
      <div className="w-[20%] h-full  rounded-md  border border-[#DADADA]   overflow-y-scroll p-2 flex flex-col gap-y-4">
        {speciality.map((item: any) => (
          <SpecialityTypo
            key={item.id}
            className={`p-2 rounded-lg bg-[#F5F5F5] cursor-pointer text-[#858585] font-bold ${
              selectedSpeciality == item.id ? "bg-primary text-white" : ""
            }`}
            onClick={() => setselectedSpeciality(item.id)}
          >
            {item.name}
          </SpecialityTypo>
        ))}
      </div>
      <div className="flex-1 h-full  ">
        <div className="top flex h-[15%]  justify-between items-center  ">
          <div className="left w-[50%] flex items-center ">
            <div className="search mr-5 w-[50%]">
              <div>
                <SearchPart>
                  <input
                    placeholder="Rechercher un document"
                    type="text"
                    onChange={(e) => setSearch(e.target.value)}
                  />
                  <img
                    src="/searchPage/searchgreen.svg"
                    alt=""
                    style={{ paddingRight: 10 }}
                  />
                </SearchPart>
              </div>
            </div>
            <div className="text-[#858585] border-b border-[#858585] cursor-pointer">
              Voir les documents archivés
            </div>
          </div>
          <MyButton
            text="Ajouter un document"
            color="bg-primary text-white px-4 py-0"
            rounded={true}
            icon={<PlusSvg />}
            onClick={handleOpen}
          />
        </div>
        <div className="table w-full h-[85%] border border-[#DADADA] rounded-lg px-2">
          <div className="header flex text-center text-[#5E5E5E] text-xl items-center   font-bold h-[10%] shadow-sm">
            {header.map((item: any, index: number) => (
              <div key={index} className="flex-1 ">
                {item}
              </div>
            ))}
          </div>
          <div className="content h-[90%] overflow-y-scroll p-1 ">
            {formatedDocuments &&
              formatedDocuments.map((item: any, index: number) => (
                <CellTypo
                  className={`cell flex  py-4 rounded-md ${
                    index % 2 == 0 ? "bg-[#EFEFEF]" : ""
                  } `}
                  key={index}
                >
                  <div className="flex-1 date">{item.date}</div>
                  <div className="flex-1">{item.title}</div>
                  <div className="flex-1">{item.doctor}</div>
                  <div className="flex-1">{item.speciality}</div>
                </CellTypo>
              ))}
          </div>
        </div>
      </div>

      {/* ////////////// modal ///////////// */}
      <Dialog open={open} handler={handleOpen} className="py-5 px-8" size="sm">
        <div className="title text-xl text-secondary mb-4">
          Ajouter document
        </div>
        <div className="inputs flex gap-6 justify-between items-center flex-wrap">
          <div className="input flex-1 mb-6">
            <MyUISelect
              label="Spécialité"
              options={specialtyOptions}
              title="s"
              name="specialityId"
              onChange={(e: any) => {
                setInputData({ ...inputData, specialityId: e });
              }}
            />
          </div>
          <div className="input gap-6 flex-1 mb-6">
            <MyUISelect
              label="Type de document"
              options={[{ label: "Ordonnance", value: "Ordonnance" }]}
              title="s"
              name="type"
              onChange={(e: any) => setInputData({ ...inputData, type: e })}
            />
          </div>
          <div className="input flex-1  mb-6">
            <h4 className="mb-2 text-lg text-[#1AA5C0]">Titre document</h4>
            <Input
              label=""
              className="focus:outline-none"
              name="name"
              onChange={(e) =>
                setInputData({ ...inputData, [e.target.name]: e.target.value })
              }
            />
          </div>
          <div className="input flex-1 mb-6">
            <h4 className="mb-2 text-lg text-[#1AA5C0] ">Commentaire</h4>
            <Input
              label=""
              name="comment"
              onChange={(e) =>
                setInputData({ ...inputData, [e.target.name]: e.target.value })
              }
            />
          </div>
        </div>

        <div className="file-input mb-6">
          <h4 className="mb-2 text-lg text-[#1AA5C0]">Fichier</h4>

          <div className="border rounded-md overflow-hidden h-[3rem] border-dashed border-gray-500 relative flex items-center">
            <input
              type="file"
              multiple={false}
              className="cursor-pointer relative block opacity-0 w-full h-full  z-50"
              onChange={handleFileChange}
              name="file"
            />
            <div className="text-center  absolute top-0 right-0 left-0 m-auto">
              <div className="flex justify-center items-center ">
                {selectedFile ? (
                  <div className="flex justify-between items-center bg-[#EFEFEF] p-2 w-full h-[3rem]">
                    <p> {selectedFile}</p>
                    <DeleteSvg
                      width={20}
                      onClick={() => setSelectedFile("")}
                      className="z-[100] cursor-pointer hover:scale-110 transition-all ease-in-out duration-200"
                    />
                  </div>
                ) : (
                  <div className="flex justify-center gap-2 items-center  p-2 w-full h-[3rem]">
                    <JoinFileSvg width={20} />
                    <p>Veuillez télécharger votre fichier</p>
                  </div>
                )}
                <h4> </h4>
              </div>
            </div>
          </div>
        </div>
        <div className="buttons flex justify-between items-center ">
          <MyButton
            text="Annuler"
            color="bg-[#858585] w-[40%] flext justify-center items-center text-white"
            rounded={false}
            onClick={handleOpen}
          />
          <MyButton
            text="Ajouter"
            color="bg-secondary w-[40%] flext justify-center items-center text-white"
            rounded={false}
            onClick={handleSubmit}
          />
        </div>
      </Dialog>
    </div>
  );
};

export default Documents;
