import { Fragment, useState } from "react";
import {
  Button,
  Dialog,
  DialogHeader,
  DialogBody,
  DialogFooter,
} from "@material-tailwind/react";

import UpdatePatient from "./UpdatePatient";

export default function UpdateModal({
  visible,
  setVisible,
  setSuccess,
  patientCine,
  data,
}: any) {
  const [size, setSize] = useState(null);
  const [ischild, setIsChild] = useState(true);
  const [iswife, setIswife] = useState(false);

  const handleOpen = (value: any) => setVisible(false);

  return (
    <Fragment>
      <Dialog open={visible} size={size || "lg"} handler={handleOpen}>
        <DialogHeader
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
            gap: "1rem",
          }}
        >
          <div className="flex justify-center items-end text-[#858585] text-[26px] gap-4">
            {/* <img src="/addpatient/addAdherant.svg" alt="" /> */}
            <div>Modifier Patient</div>
            {/* {JSON.stringify(data)} */}
          </div>
          {/* <AyantDroitSelect
            ischild={ischild}
            setIsChild={setIsChild}
            iswife={iswife}
            setIswife={setIswife}
          /> */}
        </DialogHeader>
        <DialogBody>
          <UpdatePatient
            // patientCine={patientCine}
            setSuccess={setSuccess}
            setModal={setVisible}
            isModif={true}
            patientData={data}
            setIsModif={() => {}}
            ischild={false}
            iswife={false}
            setIsChild={setIsChild}
            setIswife={setIswife}
            isSearchAndAdd={false}
            setSearchAndAdd={() => {}}
          />
        </DialogBody>
      </Dialog>
    </Fragment>
  );
}
