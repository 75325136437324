import RangeSlider from "./ShibaSlider";
import { useState, useEffect } from "react";
import { Switch } from "@material-tailwind/react";
import { ReactComponent as PlusSvg } from "../../assets/plus.svg";
import { ReactComponent as MinusSvg } from "../../assets/minusSvg.svg";
import { sign } from "crypto";
import { ReactComponent as GreenArrow } from "../../assets/GreenArrow.svg";
import { vitalSignsType } from "../../interfaces/baseParams";
import { RootState } from "../../store";
import { useSelector } from "react-redux";
import { app } from "../../enums/app";
import IMC from "./IMC";

type nibpTypeProp = {
  cuff_pressure: number;
  dia_pressure: number;
  mean_pressure: number;
  mode: number;
  nibp_status: number;
  sys_pressure: number;
};
type oxyTypeProp = {
  pulse_rate: number;
  saturation: number;
  spo2_status: number;
};
interface signObject {
  sign: string;
  name: string;
  value: /*nibpTypeProp | oxyTypeProp*/ number;
  setValue: (value: /*nibpTypeProp | oxyTypeProp |*/ number) => void;
  gettingData?: boolean;
  setGettingData?: (value: boolean) => void;
  cmpntId?: string | null;
  setCmpntId?: (value: string | null) => void;
  sensorExists: boolean;
  maxValue?: number;
  minValue?: number;
  setCancelCheck?: React.Dispatch<React.SetStateAction<boolean>>;
}

interface CardProps {
  title: string;
  icon: React.ReactNode;
  sign: signObject[];
  escapeLastValue: boolean;
  id?: string;
}

const CardWithTowValues = (props: CardProps) => {
  const [isChecked, setIsChecked] = useState(false);
  const currentApp = useSelector((state: RootState) => state.auth.currentApp);
  const handleSwitchChange = (event: any) => {
    setIsChecked(event.target.checked);
  };

  const baseParamsData = useSelector(
    (state: RootState) => state.baseParamsData
  );
  const [valueIndex, setValueIndex] = useState(0);
  const [mesurements, setMesurements] = useState(0);

  useEffect(() => {
    setMesurements(props.sign[valueIndex].value);
  }, [props.sign[valueIndex]]);

  useEffect(() => {
    // if (mesurements === 0)
    //   return;
    if (valueIndex) {
      if (
        mesurements > props.sign[valueIndex].minValue! &&
        mesurements < props.sign[valueIndex].maxValue! 
      ) {
        props.sign[valueIndex].setValue(mesurements);
      }
      else
      {
        props.sign[valueIndex].setValue(0);
      }
    }
  }, [mesurements]);
  // useEffect(() => {
  //   //set the default value to the second options
  //   if (valueIndex === 1 && getInitValue()[valueIndex] <= props.sign[valueIndex].minValue!)
  //     setMesurements(props.sign[valueIndex].minValue!);
  // }, [valueIndex]);

  useEffect(() => {
    if (isChecked) {
      props.sign.map((sign) => {
        sign.setValue(0);
      });
    }
  }, [isChecked]);

  const getInitValue = () => {
    // const prevMeasures = props.sign[0].initValue;

    // console.log("initvalue", prevMeasures);
    switch (props.id) {
      case "nibp":
        return [
          baseParamsData.vitalSignsData.sys,
          baseParamsData.vitalSignsData.dia,
        ];
      case "weight_height":
        return [
          baseParamsData.vitalSignsData.weight,
          baseParamsData.vitalSignsData.height,
          baseParamsData.vitalSignsData.imc,
        ];
      case "balance_smm":
        return [
          baseParamsData.vitalSignsData.SMM,
          baseParamsData.vitalSignsData.graisse,
          baseParamsData.vitalSignsData.gresse_porcentage,
        ];
      default:
        return "--";
    }
  };
  const handleArrClick = () => {
    if (valueIndex < props.sign.length - (props.escapeLastValue ? 2 : 1)) {
      setValueIndex(valueIndex + 1);
    } else if (
      valueIndex ===
      props.sign.length - (props.escapeLastValue ? 2 : 1)
    ) {
      setValueIndex(0);
    } else {
      setValueIndex(0);
    }
  };

  // return (
  return currentApp === app.VISIO_STATION ? (
    <div className="card border p-4 border-[#D7D7D7] rounded-xl w-[859px] ">
      <div className={`top flex gap-8 mb-4  justify-between`}>
        <div className="name flex gap-1 items-center ">
          {props.icon}
          <div className="text-[#5E5E5E] text-xl font-bold ">{props.title}</div>
        </div>
        {false && (
          // {/* {props.sign[valueIndex].sensorExists && (
          <div className="flex flex-col items-end">
            <div className="switch flex gap-2 items-center text-[#5E5E5E] opacity-70 font-bold">
              M{" "}
              <Switch
                id={props.id}
                color="teal"
                checked={isChecked}
                onChange={handleSwitchChange}
              />{" "}
              A
            </div>
            <div className="switch flex gap-2 items-center text-[#5E5E5E] opacity-70 ">
              Passer en manuel
            </div>
          </div>
        )}
      </div>
      <div className="content  bg-[#F5F5F5] rounded-xl p-3 min-h-[6rem] gap-6 flex justify-center items-center">
        {props.sign.map((sign, index) => {
          return (
            <div
              className="flex    justify-end items-end relative h-[4rem]"
              key={index}
            >
              <div></div>
              <div className="value text-sm mb-0 font-semibold  text-[#858585]   flex items-start  h-full mr-1">
                {sign.name}
              </div>
              <div className="value text-5xl font-[500] mb-0  text-[#C6C6C6]">
                {getInitValue()[index] <= props.sign[index].minValue!
                  ? "--"
                  : getInitValue()[index]}
              </div>
              <div className="unit text-[#5E5E5E] font-bold">{sign.sign}</div>
            </div>
          );
        })}
        {/* { <div className="  w-64  items-start  flex flex-col gap-2"> */}
        {props.sign[2] && <IMC imc={props.sign[2].value}></IMC>}
        {/* </div>} */}
      </div>

      {
        // isChecked ? (
        <div className={"flex gap-2"}>
          <button
            className="btn bg-primary text-white w-[95%] rounded-lg p-4 text-xl font-bold mt-4 shadow-md disabled:bg-[#D7D7D7] disabled:cursor-not-allowed"
            disabled={
              (props.sign[valueIndex].gettingData &&
                props.sign[valueIndex].cmpntId === props.id!) ||
              !props.sign[valueIndex].sensorExists
            }
            onClick={() => {
              props.sign[0].setCmpntId && props.sign[0].setCmpntId(props.id!);
              props.sign[valueIndex].setGettingData &&
                props.sign[valueIndex].setGettingData!(false);
            }}
          >
            Commencer
          </button>
          {
            // props.sign[valueIndex].gettingData &&
            //   props.sign[valueIndex].cmpntId === props.id! &&
            <button
              className={`btn animate-pulse bg-red-500 text-white rounded-lg p-4 text-xl font-bold mt-4 shadow-md disabled:bg-[#D7D7D7] disabled:cursor-not-allowed
              ${
                (props.sign[valueIndex].gettingData &&
                  props.sign[valueIndex].cmpntId === props.id!) ||
                (props.sign[valueIndex].gettingData &&
                  props.sign[valueIndex].cmpntId === props.id! &&
                  !props.sign[valueIndex].sensorExists)
                  ? ""
                  : "hidden"
              }`}
              // disabled={
              //   (props.sign[valueIndex].gettingData &&
              //     props.sign[valueIndex].cmpntId === props.id!) ||
              //   !props.sign[valueIndex].sensorExists
              // }
              onClick={() => {
                props.sign[0].setCancelCheck!(true);
              }}
            >
              X
            </button>
          }
        </div>
        // ) : (
        //   <div className="flex justify-between items-center gap-4 ">
        //     <div className="w-[65%]">
        //       <RangeSlider
        //         value={props.sign[valueIndex].value}
        //         setValue={props.sign[valueIndex].setValue}
        //         maxValue={props.sign[valueIndex].maxValue!}
        //       />
        //     </div>
        //     <div className="slider flex text-[#858585] items-center">
        //       <GreenArrow onClick={handleArrClick} className="cursor-pointer" />
        //       {props.sign[valueIndex].name}
        //       <GreenArrow
        //         onClick={handleArrClick}
        //         className="cursor-pointer rotate-180"
        //       />
        //     </div>
        //     <div className="buttons p-3 w-[35%] flex gap-2 justify-end">
        //       <MinusSvg
        //         className="cursor-pointer"
        //         onClick={() => setMesurements(mesurements - 1)}
        //       />
        //       <PlusSvg
        //         className="cursor-pointer"
        //         onClick={() => setMesurements(mesurements + 1)}
        //       />
        //     </div>
        //   </div>
        // )
      }
    </div>
  ) : (
    <div className="card border p-4 border-[#D7D7D7] rounded-xl w-[859px] ">
      <div className="top flex gap-8 mb-4  justify-between">
        <div className="name flex gap-1 items-center ">
          {props.icon}
          <div className="text-[#5E5E5E] text-xl font-bold ">{props.title}</div>
        </div>
        {props.sign[valueIndex].sensorExists && (
          <div className="flex flex-col items-end">
            <div className="switch flex gap-2 items-center text-[#5E5E5E] opacity-70 font-bold">
              M{" "}
              <Switch
                id={props.id}
                color="teal"
                checked={isChecked}
                onChange={handleSwitchChange}
              />{" "}
              A
            </div>
            <div className="switch flex gap-2 items-center text-[#5E5E5E] opacity-70 ">
              Passer en manuel
            </div>
          </div>
        )}
      </div>
      <div className="content  bg-[#F5F5F5] rounded-xl p-3 min-h-[6rem] gap-6 flex justify-center items-center">
        {props.sign.map((sign, index) => {
          return (
            <div
              className="flex    justify-end items-end relative h-[4rem]"
              key={index}
            >
              <div></div>
              <div className="value text-sm mb-0 font-semibold  text-[#858585]   flex items-start  h-full mr-1">
                {sign.name}
              </div>
              <div className="value text-5xl font-[500] mb-0  text-[#C6C6C6]">
                {getInitValue()[index] <= props.sign[index].minValue!
                  ? "--"
                  : getInitValue()[index]}
              </div>
              <div className="unit text-[#5E5E5E] font-bold">{sign.sign}</div>
            </div>
          );
        })}
        {props.sign[2] && <IMC imc={props.sign[2].value}></IMC>}
      </div>

      {isChecked ? (
        <div className={"flex gap-2"}>
          <button
            className="btn bg-primary text-white w-full rounded-lg p-4 text-xl font-bold mt-4 shadow-md disabled:bg-[#D7D7D7] disabled:cursor-not-allowed"
            disabled={
              props.sign[valueIndex].gettingData &&
              props.sign[valueIndex].cmpntId === props.id!
            }
            onClick={() => {
              props.sign[0].setCmpntId && props.sign[0].setCmpntId(props.id!);
              props.sign[valueIndex].setGettingData &&
                props.sign[valueIndex].setGettingData!(false);
            }}
          >
            Commencer
          </button>
          <button
            className={`btn animate-pulse bg-red-500 text-white rounded-lg p-4 text-xl font-bold mt-4 shadow-md disabled:bg-[#D7D7D7] disabled:cursor-not-allowed
         ${
           (props.sign[valueIndex].gettingData &&
             props.sign[valueIndex].cmpntId === props.id!) ||
           (props.sign[valueIndex].gettingData &&
             props.sign[valueIndex].cmpntId === props.id! &&
             !props.sign[valueIndex].sensorExists)
             ? ""
             : "hidden"
         }`}
            // disabled={
            //   (props.sign[valueIndex].gettingData &&
            //     props.sign[valueIndex].cmpntId === props.id!) ||
            //   !props.sign[valueIndex].sensorExists
            // }
            onClick={() => {
              props.sign[0].setCancelCheck!(true);
            }}
          >
            X
          </button>
        </div>
      ) : (
        <div className="flex justify-between items-center gap-4 ">
          <div className="w-[65%]">
            <RangeSlider
              value={props.sign[valueIndex].value}
              setValue={props.sign[valueIndex].setValue}
              maxValue={props.sign[valueIndex].maxValue!}
              minValue={props.sign[valueIndex].minValue!}
            />
          </div>
          <div className="slider flex text-[#858585] items-center">
            <GreenArrow onClick={handleArrClick} className="cursor-pointer" />
            {props.sign[valueIndex].name}
            <GreenArrow
              onClick={handleArrClick}
              className="cursor-pointer rotate-180"
            />
          </div>
          <div className="buttons p-3 w-[35%] flex gap-2 justify-end">
            <MinusSvg
              className={`
               //TODO:back here
               ${
                 mesurements <= props.sign[valueIndex].minValue!
                   ? "filter grayscale cursor-not-allowed"
                   : "cursor-pointer"
               }`}
              onClick={() => {
                if (mesurements > props.sign[valueIndex].minValue!) {
                  if (props.sign[valueIndex].name === "Poids") {
                    setMesurements(parseFloat((mesurements - 0.1).toFixed(1)));
                    return;
                  } else setMesurements(mesurements - 1);
                }
              }}
            />
            <PlusSvg
              className={`
             //TODO:back here
             ${
               mesurements >= props.sign[valueIndex].maxValue!
                 ? "filter grayscale cursor-not-allowed"
                 : "cursor-pointer"
             }`}
              onClick={() => {
                const biasValue =
                  mesurements == 0 ? props.sign[valueIndex].minValue! : 0;
                if (props.sign[valueIndex].name === "Poids") {
                  setMesurements(
                    parseFloat((biasValue + mesurements + 0.1).toFixed(1))
                  );
                  return;
                } else {
                  setMesurements(biasValue + mesurements + 1);
                }
              }}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default CardWithTowValues;
