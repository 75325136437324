// compteRenduSlice.ts

import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { SLICE } from "../../enums/store";
import { IAlcoholicState } from "@interfaces/alcoholic";
const initialState: any = {
  text: "",
};

const compteRenduSlice = createSlice({
  name: SLICE.COMPTE_RENDU,
  initialState,
  reducers: {
    setCompteRendu: (state, action: PayloadAction<any>) => {
      state.text = action.payload;
    },
    clearCompteRendu: (state) => {
      state.text = "";
    },
  },
});

export const { setCompteRendu, clearCompteRendu } = compteRenduSlice.actions;
export default compteRenduSlice.reducer;
