
import { Combobox, Transition } from "@headlessui/react";
import { ChevronDownIcon, XMarkIcon } from "@heroicons/react/24/solid";
import React, {
  Fragment,
  useEffect,
  useState,
} from "react";



export interface ISearchableSelectProps {
  name: string;
  label: string;
  multiple?: boolean;
  placeholder?: string;
  index?: number;
  dataState: string;
  defaultValue?: any;
  actions?: any;
  searchHook: (...args: any) => any; // the hook that will ping on network
  hookParams: Array<string>; // specify the parameters that will be passed to the hook
  setValue: (...args: any) => any;

  style: string;
  displayConditionState?: string;
}




/**
 * 
 * @param param0 
 * @returns 
 */
export default function SearchableInput({
  name,
  label,
  placeholder,
  defaultValue,
  multiple = false,
  setValue,
  searchHook,
  hookParams,
  style,
}: ISearchableSelectProps) {

  // Initialize filters object
  const initialState = hookParams.reduce((acc: any, param: any) => {
    acc[param] = ''; // Initial value for each state variable
    return acc;
  }, {});

  /** the select options that will take the final values */
  const [options, setOptions] = useState<any>([]);



  // Create state variables dynamically
  const [filters, setFilters] = useState(initialState);


  // Example handler to update a specific state variable
  const handleInputChange = (param: any, value: any) => {
    setFilters((prevState: any) => ({
      ...prevState,
      [param]: value,
    }));
  };


  const [internalDefaultValue, setInternalDefaultValue] = useState<any>();


  /** the selected option */
  const [selectedOption, setSelectedOption] = useState<any>("");

  /** selected options */
  const [selectedOptions, setSelectedOptions] = useState<Array<any>>([]);




  /** the hook that will fetch  */
  const { data, isLoading, isError, refetch } = searchHook(filters);


  // handlers
  const handleRemoveOptionClick = (id: any) => {
    setSelectedOptions(selectedOptions.filter((option: any) => option.id != id))
  }


  const handleAddOption = (option: any) => {
    setSelectedOptions([...selectedOptions, option]);

  }




  /** watchers */
  useEffect(() => {
    setValue(selectedOptions.map((option: any) => option.id));
  }, [selectedOptions])

  useEffect(() => {
    setOptions(data);
  }, [data])


  useEffect(() => {
    if (selectedOption) handleAddOption({ label: selectedOption.label, id: selectedOption.id })
  }, [selectedOption])




  return (
    <>

      <div className={style}>
        {label && <h4 className="mb-3 text-[#5E5E5E]">{label}</h4>}
        <Combobox
          onChange={(e: any) => {
            setSelectedOption(e);
          }}
        >
          <div className="relative mt-1">
            <div className="relative w-full cursor-default overflow-hidden rounded-lg bg-white text-left shadow-md focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 focus-visible:ring-offset-teal-300 sm:text-sm">
              <Combobox.Input
                placeholder={
                  placeholder ?? ''
                }
                className="outline-none w-full border-none h-9 py-2 pl-3 pr-10 text-sm leading-5 text-gray-900 focus:ring-0  "
                displayValue={(option: any) =>
                  selectedOption == option
                    ? option?.name
                    : !defaultValue
                      ? ""
                      : option.name
                }
                onChange={(e) => {
                  handleInputChange('search', e.target.value)
                }}
              />
              {selectedOption && (
                <button
                  onClick={() => {
                    setSelectedOption(null);

                  }}
                  className="mr-5 absolute inset-y-0 right-0 flex items-center pr-2 cursor-pointer"
                >
                  <XMarkIcon
                    className="h-5 w-5 text-gray-400"
                    aria-hidden="true"
                  />
                </button>
              )}
              {options && options?.length > 0 && (
                <>
                  <Combobox.Button className="absolute inset-y-0 right-0 flex items-center pr-2">
                    <ChevronDownIcon
                      className="h-5 w-5 text-gray-400"
                      aria-hidden="true"
                    />
                  </Combobox.Button>
                </>
              )}
            </div>
            <Transition
              as={Fragment}
              leave="transition ease-in duration-100"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
              afterLeave={() => { }}
            >
              <Combobox.Options
                className={
                  "absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm"
                }
              >
                {isLoading ? (
                  <div className="relative cursor-default select-none py-2 px-4 text-gray-700">
                    Recherche en cours...
                  </div>
                ) : (
                  <>
                    {options?.length === 0 ? (
                      <div className="relative cursor-default select-none py-2 px-4 text-gray-700">
                        Aucun résulat.
                      </div>
                    ) : (
                      options?.map((option: any, index: number) => (
                        <Combobox.Option
                          key={index}
                          className={({ active }) =>
                            `cursor-pointer	 relative  select-none py-2 pl-5 pr-4 ${active
                              ? "cursor-pointer bg-[#20a8d3] text-white"
                              : "text-gray-900"
                            }`
                          }
                          value={option}
                        >
                          {({ selected, active }) => (
                            <>
                              <span
                                className={`block truncate ${selected ? "font-medium" : "font-normal"
                                  }`}
                              >
                                {option.label}
                              </span>
                            </>
                          )}
                        </Combobox.Option>
                      ))
                    )}
                  </>
                )}
              </Combobox.Options>
            </Transition>

          </div>

        </Combobox>



        {/* list of selected options */}
        <div className="mt-4 mb-4 substance-list flex flex-wrap gap-2">
          {selectedOptions.map((option: any) => (
            <>
              <p className="bg-[#D0E5E5] text-[#087E8B] p-2 rounded-md flex items-center gap-2">
                {option.label}
                <span className="mx-2 cursor-pointer  text-[#087E8B]" id={option.id} onClick={() => { handleRemoveOptionClick(option.id) }}>
                  x
                </span>
              </p>

            </>
          ))
          }
        </div>

      </div>

    </>
  );
}
