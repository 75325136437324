// slices/counterSlice.ts
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface TableState {
 
    historiqueData:any
}

const initialState: TableState = {
   
    historiqueData:[]
};

const tableSlice = createSlice({
    name: 'tabelHistorique',
    initialState,
    reducers: {
     setHistoriqueTable(state,action: PayloadAction<any>) {
            state.historiqueData =  action.payload;

        },

    },
});

export const { setHistoriqueTable } = tableSlice.actions;
export default tableSlice.reducer;
