// formSlice.ts
import { IVitalSignsType } from "@interfaces/Examen";
import { SLICE } from "../../enums/store";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
const initialState: IVitalSignsType = {
  vitalSignsData: {
    temp: 0,//
    oxy: 110,//
    weight: 0,//
    height: 0,//
    imc: 0,//
    sys: 0,//
    dia: 0,//
    glycemi: 0,//
    rc: 0,//
    rr: 0,//
    SMM: 0,
    graisse: 0,
    gresse_porcentage: 0,
  },
  isLoading: false,
  error: null,
  isRefetchRequired: false, // to refetch data after update
  isUpdated: false,
};

const baseParamsSlice = createSlice({
  name: SLICE.BASEPARAMS,
  initialState,
  reducers: {
    updateBaseParams: (
      state,
      action: PayloadAction<{ messure: string; value: number }>
    ) => {
      const { messure, value } = action.payload;
      (state.vitalSignsData as any)[messure] = value;
    },
  },
});

export const { updateBaseParams } = baseParamsSlice.actions;
export const baseParamsReducer = baseParamsSlice.reducer;
