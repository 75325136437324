import {
  BUTTON_COLORS,
  DISPLAY_CONDITION_STATE,
  FORM_ELEMENT_TYPE,
  SELECT_DATA_STATE,
  TAG_DATA_STATE,
  TEXT_INPUT_ICON_TYPE,
} from "../../enums/stepper";
import { IForm } from "@interfaces/Forms";
import { ReactComponent as PlusSvg } from "../../assets/plusSvg.svg";
import { setMotifs, setMotifsValue } from "../../features/motifs/motifsSlice";
import {
  Chirurgicaux,
  Hospitalisations,
  HabitudesToxiques,
  Allergies,
} from "../../Components/Steps/CustomComponents";
import Tag from "../../Components/Tag";
import {
  appendOtherFamiliaux,
  setOtherFamiliaux,
} from "../../features/otherFamiliaux/otherFamiliauxSlice";
import {
  appendSelectedFamiliaux,
  setSelectedFamiliaux,
} from "../../features/selectedFamiliaux/selectedFamiliauxSlice";
import Traitement from "../../Components/Steps/CustomComponents/Traitement";
import { useParams } from "react-router-dom";
import { parseQueryString } from "../../utils";
import { appendAllergy } from "../../features/allergy/allergySlice";
import FileUploader from "../../Components/FileUploader";
import PrescriptionTabs from "../../Components/Steps/CustomComponents/PrescriptionTabs";
import Prescription from "../../Components/Steps/CustomComponents/Prescription";
import Impression from "../../Components/Steps/CustomComponents/Impression";
import Examen from "../../Components/Steps/Examen";
import {
  setAllergy,
  setAntecedentsMedicals,
  setConclusion,
  setConsultationData,
  setDiagnosticPrincipale,
  setDiagnosticSecondaire,
  setFamilyOthers,
  setFamilySelected,
  setMotifsData,
  setSurgicalMotifs,
} from "../../features/consultationData/consultationDataSlice";
import { SLICE } from "../../enums/store";
import Diagnostic from "../../Components/Steps/CustomComponents/Diagnostic";
import { setIsVoirAntecedentShown } from "../../features/isVoirAntecedentShown/isVoirAntecedentShownSlice";
import Modal from "../../Components/Modal";
import MedicalHistory from "../../Components/medicalHistory";
import Medicaux from "../../../src/Components/Steps/CustomComponents/Médicaux";
import DiagnosticPrincipaleIntelligent from "../../../src/Components/Steps/CustomComponents/Diagnostic/DiagnosticPrincipaleIntelligent";
import DiagnoscticAssocieIntelligent from "../../../src/Components/Steps/CustomComponents/Diagnostic/DiagnosticAssocieIntelligent";
import Interpretation from "../../Components/Steps/CustomComponents/Diagnostic/Interpretation";

export const ConsultationForms: any = {
  motifConsultation: {
    id: 0,
    container: {
      style: "h-full p-7 p relative mt-4",
    },
    mainComponents: [
      {
        container: {
          style: "",
        },
        title: {
          text: "Motif de consultation",
          style: "text-[#1AA5C0] font-[500] text-[20px] mb-5",
        },
        elements: [
          {
            type: FORM_ELEMENT_TYPE.TEXTINPUT,
            name: "motif",
            placeholder: "Motif",
            label: "Motifs",
            className: "outline-none ",
            containerStyle: "relative flex w-full mt-6",
            addButton: {
              shown: true,
              // dispatchAction: [setMotifsData],
              dispatchAction: [setMotifsValue, setMotifsData],
            },
          },
        ],
        // submitBtn: {
        //   icon: <PlusSvg className="cursor-pointer" width={25} height={25} />,
        //   actions: [setMotifs, setMotifsData],
        //   for: "motifs",
        //   text: "Ajouter motif",
        //   style: "absolute top-6 right-7 ",
        // },
      },
    ],
    additionalComponents: [
      {
        id: 0,
        type: "tag",
        step: 1,
        containerStyle: "mt-2 substance-list flex flex-wrap gap-2",

        tagStyle:
          "bg-[#D0E5E5] text-[#087E8B] p-2 rounded-md flex items-center gap-2",
        dataState: "motifs",
      },
    ],
  },
  antecedent: {
    id: 1,
    container: {
      style: "h-full p-7  relative mt-4",
    },
    mainComponents: [
      {
        container: {
          style: "",
        },
        title: {
          text: "Antécédents personnels",
          style:
            "title bg-[#20A8D3] w-[80%] flex items-center gap-4 h-[2.5rem] px-4 rounded-tl-lg rounded-tr-lg flex-1 text-white",
        },
        elements: [
          {
            type: FORM_ELEMENT_TYPE.CUSTOM,
            component: <Medicaux />,
          },

          {
            type: FORM_ELEMENT_TYPE.CUSTOM,
            component: <Chirurgicaux />,
          },
          // {
          //   type: FORM_ELEMENT_TYPE.CUSTOM,
          //   component: <Hospitalisations />,
          // },
        ],
        submitBtn: null,
      },
      {
        container: {
          style: "",
        },
        title: {
          text: "Antécédents familiaux",
          style:
            " mt-[4rem] title bg-[#20A8D3] w-[100%] flex items-center gap-4 h-[2.5rem] px-4 rounded-tl-lg rounded-tr-lg flex-1 text-white mt-8",
        },
        elements: [
          {
            type: FORM_ELEMENT_TYPE.SELECT,
            name: SLICE.SELECTED_FAMILIAUX,
            placeholder: "Chercher",
            label: "",
            dataState: SELECT_DATA_STATE.CHIEF_COMPLAINTS,
            actions: [setFamilySelected],
            style: "my-6",
            options: [],
          },
          {
            type: FORM_ELEMENT_TYPE.CUSTOM,
            component: (
              <Tag
                properties={{
                  id: 0,
                  type: "tag",
                  step: 1,
                  containerStyle: "mt-2 substance-list flex flex-wrap gap-2",
                  tagStyle:
                    "bg-[#D0E5E5] text-[#087E8B] p-2 rounded-md flex items-center gap-2",
                  dataState: TAG_DATA_STATE.SELECTED_FAMILIAUX,
                }}
              />
            ),
          },
          {
            displayConditionState: DISPLAY_CONDITION_STATE.OTHER_FAMILIAUX,
            type: FORM_ELEMENT_TYPE.TEXTINPUT,
            name: "autres",
            placeholder: "Autres",
            label: "Autres",
            containerStyle: "relative flex w-full mt-6",
            style: "",
            addButton: {
              shown: true,
              dispatchAction: setFamilyOthers,
            },
            options: [],
          },
          {
            type: FORM_ELEMENT_TYPE.CUSTOM,
            component: (
              <Tag
                properties={{
                  id: 0,
                  type: "tag",
                  step: 1,
                  containerStyle: "mt-2 substance-list flex flex-wrap gap-2",
                  tagStyle:
                    "bg-[#D0E5E5] text-[#087E8B] p-2 rounded-md flex items-center gap-2",
                  dataState: TAG_DATA_STATE.OTHER_FAMILIAUX,
                }}
              />
            ),
          },
        ],
        submitBtn: null,
      },
      {
        container: {
          style: "mt-[5rem]",
        },
        title: {
          text: "Habitudes toxiques",
          style:
            "title bg-[#20A8D3] w-[100%] flex items-center gap-4 h-[2.5rem] px-4 rounded-tl-lg rounded-tr-lg flex-1 text-white mt-8",
        },
        elements: [
          {
            type: FORM_ELEMENT_TYPE.CUSTOM,
            component: <HabitudesToxiques />,
          },
        ],
        submitBtn: null,
      },
      {
        container: {
          style: "",
        },
        title: {
          text: "Allergies",
          style:
            "title bg-[#20A8D3] w-[100%] flex items-center gap-4 h-[2.5rem] px-4 rounded-tl-lg rounded-tr-lg flex-1 text-white mt-8",
        },
        elements: [
          {
            type: FORM_ELEMENT_TYPE.CUSTOM,
            component: <Allergies />,
          },
          {
            displayConditionState: DISPLAY_CONDITION_STATE.ALLERGY,
            type: FORM_ELEMENT_TYPE.TEXTINPUT,
            name: "substance",
            placeholder: "Substance",
            label: "Substance",
            containerStyle: "relative flex w-full mt-6",
            style: "",
            addButton: {
              shown: true,
              dispatchAction: setAllergy,
            },
            options: [],
          },
          {
            type: FORM_ELEMENT_TYPE.CUSTOM,
            component: (
              <Tag
                properties={{
                  displayCondition: DISPLAY_CONDITION_STATE.ALLERGY,
                  id: 0,
                  type: "tag",
                  step: 1,
                  containerStyle: "mt-2 substance-list flex flex-wrap gap-2",
                  tagStyle:
                    "bg-[#D0E5E5] text-[#087E8B] p-2 rounded-md flex items-center gap-2",
                  dataState: TAG_DATA_STATE.ALLERGY,
                }}
              />
            ),
          },
        ],
        submitBtn: null,
      },
    ],
    additionalComponents: [
      {
        id: 0,
        type: "button",
        color: BUTTON_COLORS.BLACK,
        actions: [setIsVoirAntecedentShown],
        customEvent: false,
        text: "Voir les antécédents",
        className: "flex justify-center self-center p-2",
        style: "absolute top-7 right-7",
      },
      {
        type: FORM_ELEMENT_TYPE.CUSTOM,
        component: <Modal Outlet={<MedicalHistory />} />,
      },
    ],
  },
  traitementEnCours: {
    id: 2,
    container: {
      style: "h-full",
    },

    mainComponents: [
      {
        container: {
          style: "w-full",
        },
        title: {
          text: "",
          style: "",
        },
        elements: [],
        submitBtn: null,
      },
    ],
    additionalComponents: [],
  },
  examenClinique: {
    id: 3,
    container: {
      style: "",
    },
    mainComponents: [
      {
        container: {
          style: "w-full",
        },
        title: {
          text: "",
          style: "",
        },
        elements: [
          {
            type: FORM_ELEMENT_TYPE.CUSTOM,
            component: <Examen />,
          },
        ],
        submitBtn: null,
      },
    ],

    additionalComponents: [],
  },
  diagnostic: {
    id: 4,
    container: {
      style: "",
    },
    mainComponents: [
      {
        container: {
          style: " w-[100%]",
        },
        title: {
          text: "",
          style: "",
        },
        formWrapper: {
          style: "",
        },
        elements: [
          {
            type: FORM_ELEMENT_TYPE.CUSTOM,
            component: <Diagnostic />,
          },
        ],
        submitBtn: null,
      },
    ],
    additionalComponents: [],
  },
  compteRendu: {
    id: 5,
    container: {
      style: "w-full",
    },
    mainComponents: [
      {
        container: {
          style: " w-[100%]",
        },
        title: {
          text: "",
          style: "",
        },
        formWrapper: {
          style: "flex flex-col gap-3 w-full",
        },
        elements: [
          {
            type: FORM_ELEMENT_TYPE.SELECT,
            name: "diagnosticPrincipale",
            // placeholder: "Chercher",
            actions: [setDiagnosticPrincipale],
            useCase: SLICE.PRINCIPALE_DIAGNOSTIC,
            dataState: SELECT_DATA_STATE.CHIEF_COMPLAINTS,
            label: "Diagnostic principal",
            style: "my-4 w-full",
          },
          {
            type: FORM_ELEMENT_TYPE.CUSTOM,
            component: <DiagnosticPrincipaleIntelligent />,
          },
          {
            type: FORM_ELEMENT_TYPE.SELECT,
            name: "diagnosticSecondaire",
            placeholder: "Chercher",
            actions: [setDiagnosticSecondaire],
            label: "Diagnostics associés",
            dataState: SELECT_DATA_STATE.DIAGNOSTIC_ASSOCIE,
            style: "my-4 mb-6 w-full",
          },
          {
            type: FORM_ELEMENT_TYPE.CUSTOM,
            component: <DiagnoscticAssocieIntelligent />,
          },
          {
            type: FORM_ELEMENT_TYPE.CUSTOM,
            component: (
              <Tag
                properties={{
                  id: 0,
                  type: "tag",
                  step: 1,
                  containerStyle: " mb-4 substance-list flex flex-wrap gap-2",
                  tagStyle:
                    "bg-[#D0E5E5] text-[#087E8B] p-2 rounded-md flex items-center gap-2",
                  dataState: TAG_DATA_STATE.DIAGNOSTIC_ASSOCIE,
                }}
              />
            ),
          },
        ],
        submitBtn: null,
      },
      {
        container: {
          style: "w-full",
        },
        title: {
          text: "",
          style: "w-full",
        },
        elements: [
          {
            type: FORM_ELEMENT_TYPE.CUSTOM,
            component: <Interpretation />,
          },
          {
            type: FORM_ELEMENT_TYPE.TEXTAREA,
            name: "compteRendu",
            placeholder: "Compte rendu",
            label: "Compte-rendu",
            useCase: SLICE.COMPTE_RENDU,
            dataState: SLICE.COMPTE_RENDU,
            actions: [setConclusion],
            classeName: "focus:ring-0",
            style: "focus:outline-none",
          },
          {
            type: FORM_ELEMENT_TYPE.CUSTOM,
            component: <FileUploader />,
          },
        ],
        submitBtn: null,
      },
    ],
    additionalComponents: [],
  },
  prescription: {
    id: 6,
    container: {
      style: "",
    },
    mainComponents: [
      {
        container: {
          style: " w-[100%]",
        },
        title: {
          text: "",
          style: "",
        },
        elements: [
          {
            type: FORM_ELEMENT_TYPE.CUSTOM,
            component: <PrescriptionTabs />,
          },
          {
            type: FORM_ELEMENT_TYPE.CUSTOM,
            component: <Prescription />,
          },
          {
            type: FORM_ELEMENT_TYPE.TEXTAREA,
            name: "Commentaire",
            placeholder: "Commentaire",
            label: "Commentaire",
            defaultValue: "",
          },
        ],
        submitBtn: null,
      },
    ],
    additionalComponents: [],
  },
  impression: {
    id: 6,
    container: {
      style: "",
    },
    mainComponents: [
      {
        container: {
          style:
            " w-[100%] flex flex-col justify-center self-center items-center align-center mt-24",
        },
        title: {
          text: "Impression des documents",
          style: "text-[#20A8D3] text-lg ",
        },
        elements: [
          {
            type: FORM_ELEMENT_TYPE.CUSTOM,
            component: <Impression />,
          },
        ],
        submitBtn: null,
      },
    ],
    additionalComponents: [],
  },
};
