import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import APP_CONSTANTS from "../../constants";

const teleconsultationApi = createApi({
  reducerPath: "teleconsultationApi",
  baseQuery: fetchBaseQuery({
    baseUrl:process.env.REACT_APP_TOKEN_GENERATOR || 'https://services.visio-station.com/public/services/generatedevtoken/',
  }),

  endpoints: (builder) => ({
    getTeleconsultation : builder.query<any, { param1: string; param2: string }>(
      {
        query: ({ param1, param2 }) => `${param1}/${param2}/${process.env.REACT_APP_APP_ID}/${process.env.REACT_APP_CERTIF_ID}`,
        // transformResponse: (response: any) => JSON.parse(response),
        // transformErrorResponse: (response: any) => JSON.parse(response),
      }
    ),
  }),
});

export const { useGetTeleconsultationQuery, useLazyGetTeleconsultationQuery } =
  teleconsultationApi;

export default teleconsultationApi;
