import { setSelectedFamiliaux } from "../../../features/selectedFamiliaux/selectedFamiliauxSlice";
import { setMotifs } from "../../../features/motifs/motifsSlice";
import { setOtherFamiliaux } from "../../../features/otherFamiliaux/otherFamiliauxSlice";
import * as React from "react";
import { useDispatch, useSelector } from "react-redux";
import { setCurretStep } from "../../../features/currentStep/currentStepSlice";
import { useNavigate, useParams } from "react-router-dom";
import { Transition } from "@headlessui/react";
import {
  useGetConsultationDataQuery,
  useUpdateConsultationDataMutation,
} from "../../../features/consultationData/consultationDataApi";
import { clearConsultationData } from "../../../features/consultationData/consultationDataSlice";
import { setListNewMedication } from "../../../features/traitement/traitementSlice";
import { Spinner } from "@material-tailwind/react";
import { updateConsultation } from "../../../axiosAPI/Network";
import { updateStatusConsultation } from "../../../features/consultationStatus/consultationStatusSlice";
import { RootState } from "../../../store";
import { CONSULTATION_STATUSES } from "../../../enums/consultation";
import useDynamicDialog from "../../../hooks/useDynamicDialog";
import Test from "../../../Components/tabs";
import { setIsLoading } from "../../../features/dialog/dialogSlice";

export interface INavigationProps {
  isFirstStep: boolean;
  isLastStep: boolean;
}

export default function Navigation({
  isFirstStep,
  isLastStep,
}: INavigationProps) {
  const { id, consultationId } = useParams();
  const dispatch = useDispatch();
  const { openDynamicDialog } = useDynamicDialog();
  const navigate = useNavigate();
  const motifsState = useSelector((state: any) => state.motifs);
  const selectedFamiliaux = useSelector(
    (state: any) => state.selectedFamiliaux
  );
  const [isPrevLoading, setIsPrevLoading] = React.useState<boolean>(false);
  const [isNextLoading, setIsNextLoading] = React.useState<boolean>(false);
  const otherFamiliaux = useSelector((state: any) => state.otherFamiliaux);
  const currentStep = useSelector(
    (state: any) => state.currentStep.currentStep
  );
  const consultationData = useSelector(
    (state: any) => state.consultationData.consultationData
  );
  const listNewMedication = useSelector(
    (state: any) => state.traitement.listNewMedication
  );
  const [setConsultationData, { isLoading }] =
    useUpdateConsultationDataMutation();

  const handleQuitConsultation = async () => {
    console.log("quitting consultation ...", consultationId, id);
    await updateConsultation({ endTime: new Date() }, consultationId);

    dispatch(updateStatusConsultation(CONSULTATION_STATUSES.ENDED));
    window.location.href = `/doctor/patient/${id}`;
  };

  const handleEndButtonClick = async () => {
    openDynamicDialog({
      message:
        "Vous êtes sur le point de clôturer la consultation, êtes-vous sûr de vouloir effectuer cette action?",
      title: "Clôturer la consultation",
      handleConfirm: handleQuitConsultation,
      handleCancel: "",
    });
  };

  const handleNext = async () => {
    setIsPrevLoading(false);
    setIsNextLoading(true);
    console.log("handling next button", consultationId);
    await setConsultationData({
      id: consultationId,
      body: {
        ...consultationData,
        indexStep: currentStep,
        lastTimeDuration: new Date(),
      },
    });
    dispatch(clearConsultationData());
    dispatch(setMotifs({ ...motifsState, isRefetchRequired: true }));
    dispatch(setOtherFamiliaux({ ...otherFamiliaux, isRefetchRequired: true }));
    dispatch(
      setListNewMedication({ ...listNewMedication, isRefetchRequired: true })
    );

    isLastStep
      ? handleEndButtonClick()
      : dispatch(setCurretStep(currentStep + 1));
  };
  const handlePrev = async () => {
    setIsNextLoading(false);
    setIsPrevLoading(true);
    await setConsultationData({
      id: consultationId,
      body: {
        ...consultationData,
        indexStep: currentStep,
        lastTimeDuration: new Date(),
      },
    });
    dispatch(clearConsultationData());
    dispatch(setMotifs({ ...motifsState, isRefetchRequired: true }));
    dispatch(setOtherFamiliaux({ ...otherFamiliaux, isRefetchRequired: true }));
    dispatch(
      setListNewMedication({ ...listNewMedication, isRefetchRequired: true })
    );
    dispatch(
      setSelectedFamiliaux({ ...selectedFamiliaux, isRefetchRequired: true })
    );
    !isFirstStep && dispatch(setCurretStep(currentStep - 1));
  };

  return (
    <></>
    // <div className={`mt-16 w-[85%] flex left-1/2 transform -translate-x-1/2  justify-between p-2 absolute  bottom-0  bg-white shadow-lg ring-2 ring-black ring-opacity-5 rounded-t-lg `}>

    //    <button
    //     onClick={handlePrev}
    //     disabled={isPrevLoading&&isLoading ||isFirstStep}
    //     className="disabled:cursor-not-allowed  bg-[#858585] flex px-5 justify-center text-white text-center py-2 w-[12rem] rounded-md z-10"
    //   >
    //     {(isPrevLoading&&isLoading)&&<Spinner  className='mr-3'/>}

    //     Retour

    //   </button>
    //   {!isLastStep && <button
    //     onClick={handleNext}
    //     disabled={isNextLoading&&isLoading}

    //     className="disabled:cursor-not-allowed  bg-[#1AA5C0] flex px-5 text-white text-center justify-center py-2 w-[12rem] rounded-md"
    //   >
    //     {(isNextLoading&&isLoading)&&<Spinner className='mr-3'/>}
    //     Étape suivante

    //   </button>

    //   }

    //   {isLastStep && <button

    //     onClick={handleNext}
    //     className="bg-[#1AA5C0] text-white text-center py-2 w-[12rem] rounded-md z-10"
    //   >

    //     Cloturer
    //   </button>}
    // </div>
  );
}
