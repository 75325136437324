import * as React from "react";
import { useEffect, useMemo, useState } from "react";

import RadioInput from "../../../../Components/FormBuilder/FormElements/Radio";
import SearchableSelect from "../../../FormBuilder/FormElements/SearchableSelect";
import { SELECT_DATA_STATE, TAG_DATA_STATE } from "../../../../enums/stepper";
import { ReactComponent as PlusSvg } from "../../../../assets/plus.svg";
import Logo from "../../../../assets/logo.png";
import { TrashIcon } from "@heroicons/react/24/solid";
import {
  addNewSurgical,
  clearSurgical,
  setSurgicalComment,
  setSurgicalDate,
  setSurgicalMotifs,
  setSurgicalYear,
  spliceSurgicalData,
} from "../../../../features/consultationData/consultationDataSlice";
import { useDispatch, useSelector } from "react-redux";
import SelectListBox from "../../../FormBuilder/FormElements/SelectListBox";
import { years } from "../../../../constants/stepper";
import Tag from "../../../../Components/Tag";
import { setIsSurgicals } from "../../../../features/surgical/surgicalSlice";
import { Input } from "@material-tailwind/react";

export interface IChirurgicauxProps { }

export default function Chirurgicaux(props: IChirurgicauxProps) {
  const storedSurgical = useSelector(
    (state: any) =>
      state.consultationData?.consultationData?.medicalHistory?.surgical
  );
  const storedIsSurgical = useSelector(
    (state: any) => state.surgical.isSurgical
  );

  const [isCherurgical, setIsCherurgical] = React.useState<boolean>(
    storedIsSurgical || false
  );
  const [chirurgicalList, setChirurgicalList] = React.useState<any>(
    useSelector((state: any) => state.consultationData?.surgical)
  );

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(setIsSurgicals(isCherurgical));
  }, [isCherurgical]);

  useEffect(() => {
    if (!isCherurgical) {
      dispatch(clearSurgical());
    }
  }, [isCherurgical]);

  useEffect(() => {
    if (!isCherurgical && storedSurgical) {
      setIsCherurgical(storedIsSurgical);
    }
  }, [storedIsSurgical]);

  useEffect(() => {
    if (storedSurgical?.length > 0) {
      console.log("stored value were", storedSurgical)
      setChirurgicalList([...storedSurgical]);
    } else {
      setChirurgicalList([{ year: "", surgical: [], date: "", comment: "" }]);
    }
  }, [storedSurgical]);
  return (
    <div className="chirurgicaux relative mb-10">
      <h1 className="text-[#5E5E5E] -mb-4">Chirurgicaux :</h1>
      <div className="center flex gap-20  items-start">
        <div className="flex gap-4 items-center mt-8">
          <RadioInput
            id="oui1"
            name="isCherurgical"
            label="Oui"
            onChange={() => {
              setIsCherurgical(true);
            }}
            checked={isCherurgical}
          />
          <div className="h-[2rem] w-[1px] bg-blue-gray-200"></div>
          <RadioInput
            id="non1"
            name="isCherurgical"
            onChange={() => {
              setIsCherurgical(false);
            }}
            checked={!isCherurgical}
            label="Non"
          />
        </div>
        <div style={{ width: "100%" }}>
          {isCherurgical &&
            chirurgicalList?.map((chirurgical: any, index: number) => (
              <>
                <div
                  className="w-100 flex gap-8 items-top mb-4 relative"
                  key={index}
                >
                  <div className="w-[65%]">
                    <h1 className="text-[#5E5E5E] text-sm ">Maladies </h1>

                    <SearchableSelect
                      name="motifs"
                      index={index}
                      label=""
                      actions={[setSurgicalMotifs]}
                      dataState={SELECT_DATA_STATE.CHIEF_COMPLAINTS}
                      placeholder="Maladies"
                      style="mt-4 "
                    />
                    <Tag
                      properties={{
                        id: 0,
                        type: "tag",
                        step: 1,
                        index: index,
                        containerStyle:
                          "mt-4 mb-4 substance-list flex flex-wrap gap-2",
                        tagStyle:
                          "bg-[#D0E5E5] text-[#087E8B] p-2 rounded-md flex items-center gap-2",
                        dataState: TAG_DATA_STATE.SURGICAL_MOTIFS,
                      }}
                    />
                  </div>

                  {/*  */}
                  <div className=" w-[35%]">
                    <h1 className="text-[#5E5E5E] text-sm ">Commentaire </h1>

                   {storedSurgical && <textarea
                      onBlur={(e) => {
                        dispatch(setSurgicalComment({ index, value: e.target.value }))
                      }}

                      defaultValue={storedSurgical?.comment ? storedSurgical?.comment?.toString() : ""}
                      className="border-none mt-4 relative w-full cursor-default overflow-hidden rounded-lg bg-white text-left shadow-md focus:outline-none focus-visible:ring-2 focus:border-none focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 focus-visible:ring-offset-teal-300 sm:text-sm"

                      placeholder=""></textarea>}
                  </div>


                  <div className=" w-[35%]">
                    <h1 className="text-[#5E5E5E] text-sm ">Date </h1>

                    <input

                      defaultValue={ storedSurgical?.date ? storedSurgical?.date : ""}
                    type="date"
                    onChange={(e) => {
                      dispatch(setSurgicalDate({ index, value: e.target.value }))
                    }}
                    className="border-none mt-4 relative w-full cursor-default overflow-hidden rounded-lg bg-white text-left shadow-md focus:outline-none focus-visible:ring-2 focus:border-none focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 focus-visible:ring-offset-teal-300 sm:text-sm"

                      placeholder="Médecin ayant éfféctué l'operation, autre..." />
                  </div>
                  {/*  */}
                  {/*  */}

                  {/* <div className="year  w-[35%] ">
                    <h1 className="text-[#5E5E5E] text-sm ">Année </h1>
                    <SelectListBox
                      name="annee"
                      label=""
                      defaultValue={chirurgical.year}
                      index={index}
                      data={years}
                      actions={[setSurgicalYear]}
                      dataState={SELECT_DATA_STATE.YEAR}
                      placeholder="Année"
                      style="mt-4 "
                    />
                  </div> */}

                  {chirurgicalList.length > 1 && (
                    <div className="">
                      <button
                        onClick={() => {
                          setChirurgicalList((prevList: any) => {
                            const newList = [...prevList];
                            newList.splice(index, 1);
                            return newList;
                          });
                          dispatch(spliceSurgicalData(index));
                        }}
                      >
                        <TrashIcon className=" absolute top-10 right-0 h-6 w-6 text-[#5E5E5E]" />
                      </button>
                    </div>
                  )}
                </div>
              </>
            ))}
        </div>
        {isCherurgical && chirurgicalList && chirurgicalList?.length > 0 && (
          <button
            onClick={() => {
              setChirurgicalList([...chirurgicalList, { year: "", comment: "", motif: [], date: "" }]);
              dispatch(addNewSurgical());
            }}
            className="absolute flex items-center right-0 -bottom-[3.3rem] rounded-lg h-9 px-2 bg-[#1AA5C0]"
          >
            <p className="text-white mr-1">Ajouter</p>
            <PlusSvg width={25} />{" "}
          </button>
        )}
      </div>
    </div>
  );
}
