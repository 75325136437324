import RangeSlider from "./ShibaSlider";
import { useEffect, useState } from "react";
import { Switch } from "@material-tailwind/react";
import { ReactComponent as PlusSvg } from "../../assets/plus.svg";
import { ReactComponent as MinusSvg } from "../../assets/minusSvg.svg";
import { vitalSignsType } from "@interfaces/baseParams";
import { useSelector } from "react-redux";
import { RootState } from "src/store";
import { app } from "../../enums/app";
import { setUncaughtExceptionCaptureCallback } from "process";

interface CardProps {
  title: string;
  icon: React.ReactNode;
  sign: string;
  id?: string;
  value: number;
  setValue: (value: number) => void;
  gettingData?: boolean;
  setGettingData?: (value: boolean) => void;
  cmpntId?: string | null;
  setCmpntId?: (value: string | null) => void;
  sensorExists?: boolean;
  maxValue: number;
  minValue: number;
  setCancelCheck: React.Dispatch<React.SetStateAction<boolean>>;
}

const CardWithSingleValue = (props: CardProps) => {
  const { setCancelCheck } = props;
  const [isChecked, setIsChecked] = useState(false);
  const [mesurements, setMesurements] = useState(props.value);
  const currentApp = useSelector((state: RootState) => state.auth.currentApp);
  const handleSwitchChange = (event: any) => {
    setIsChecked(event.target.checked);
  };

  const baseParamsData = useSelector(
    (state: RootState) => state.baseParamsData
  );

  useEffect(() => {
    setMesurements(props.value);
  }, [props.value]);

  useEffect(() => {
    // if (mesurements === 0)
    //   return;
    if (
      mesurements > props.minValue &&
      mesurements <= props.maxValue &&
      mesurements !== undefined
    ) {
      console.log("mesurements", mesurements);
      props.setValue(mesurements);
    } else {
      console.log("mesurements", mesurements);
      props.setValue(0);
    }
  }, [mesurements]);

  useEffect(() => {
    if (isChecked) {
      props.setValue(0);
    }
  }, [isChecked]);

  const getInitValue = () => {
    // if (props.initValue) {
    // console.log("id++", props.id);
    // console.log("init value: " + baseParamsData.vitalSignsData[props.id!]);
    // switch (props.id) {
    //   case "temp":
    //     return props.initValue.temp;
    //   case "oxy":
    //     return props.initValue.oxy;
    //   case "glycemi":
    //     return props.initValue.glycemi;
    //   case "rc":
    //     return props.initValue.rc;
    //   case "rr":
    //     return props.initValue.rr;
    //   default:
    //     return "--";
    // }
    return baseParamsData.vitalSignsData[props.id!];
    // } else {
    //   return "--";
    // }props
  };
  useEffect(() => {
    console.log("redux value data:", baseParamsData.vitalSignsData);
  }, []);

  return currentApp === app.VISIO_STATION ? (
    <div className=" border  p-4 border-[#D7D7D7] rounded-xl w-full ">
      <div className="top flex gap-8 mb-4   justify-between">
        <div className="name flex gap-1 items-center ">
          {props.icon}
          <div className="text-[#5E5E5E] text-xl font-bold ">{props.title}</div>
        </div>
        {/* {props.sensorExists && ( */}
        {false && (
          <div className="flex flex-col items-end">
            <div className="switch flex gap-2 items-center text-[#5E5E5E] opacity-70 font-bold">
              M{" "}
              <Switch
                id={props.id}
                color="teal"
                checked={isChecked}
                onChange={handleSwitchChange}
              />{" "}
              A
            </div>
            <div className="switch flex gap-2 items-center text-[#5E5E5E] opacity-70 ">
              Passer en manuel
            </div>
          </div>
        )}
      </div>
      <div className="content bg-[#F5F5F5] rounded-xl p-3 min-h-[6rem] flex justify-center items-center">
        <div className="flex  items-end">
          <div className="value text-5xl mb-0 	 font-[500]  text-[#C6C6C6]">
            {/* {mesurements ? mesurements : getInitValue()} */}
            {getInitValue() === 0 || mesurements === 0 ? "--" : getInitValue()}
          </div>
          <div className="unit text-[#5E5E5E] font-bold">{props.sign}</div>
        </div>
      </div>
      {
        // isChecked ? (
        <div className={"flex gap-2"}>
          <button
            className="btn bg-primary text-white w-full rounded-lg p-3 text-xl font-bold mt-4 shadow-md disabled:bg-[#D7D7D7] disabled:cursor-not-allowed "
            disabled={
              (props.gettingData && props.cmpntId === props.id!) ||
              !props.sensorExists
            }
            // disabled={true}
            onClick={() => {
              props.setCmpntId
                ? props.setCmpntId!(props.id!)
                : setInterval(() => {
                    // setMesurements((prev) => prev + 1);
                    //set interval to 40 and stop the timer
                    setMesurements((prev) => {
                      if (prev < 100) {
                        return prev + 1;
                      } else {
                        return prev;
                      }
                    });
                  }, 250);
            }}
          >
            Commencer
          </button>
          {
            <button
              className={`btn animate-pulse bg-red-500 text-white rounded-lg p-3 text-xl font-bold mt-4 shadow-md disabled:bg-[#D7D7D7] disabled:cursor-not-allowed
              ${
                (props.gettingData && props.cmpntId === props.id!) ||
                (props.gettingData &&
                  props.cmpntId === props.id! &&
                  !props.sensorExists)
                  ? ""
                  : "hidden"
              }`}
              // disabled={
              //   (props.gettingData && props.cmpntId === props.id!) ||
              //   !props.sensorExists
              // }
              // disabled={true}
              onClick={() => {
                setCancelCheck(true);
              }}
            >
              X
            </button>
          }
        </div>
        // )
        // : (
        //   <div className="flex justify-between items-center gap-4 ">
        //     <div className="w-[65%]">
        //       <RangeSlider
        //         value={props.value}
        //         setValue={props.setValue}
        //         maxValue={props.maxValue}
        //       />
        //     </div>
        //     <div className="buttons p-3 w-[35%] flex gap-2 justify-end">
        //       <MinusSvg
        //         className="cursor-pointer"
        //         onClick={() =>
        //           mesurements > 0 ? setMesurements(mesurements - 1) : null
        //         }
        //       />
        //       <PlusSvg
        //         className="cursor-pointer"
        //         onClick={() => setMesurements(mesurements + 1)}
        //       />
        //     </div>
        //   </div>
        // )
      }
    </div>
  ) : (
    <div className=" border  p-4 border-[#D7D7D7] rounded-xl w-full ">
      <div className="top flex gap-8 mb-4   justify-between">
        <div className="name flex gap-1 items-center ">
          {props.icon}
          <div className="text-[#5E5E5E] text-xl font-bold ">{props.title}</div>
        </div>
        {props.sensorExists && (
          <div className="flex flex-col items-end">
            <div className="switch flex gap-2 items-center text-[#5E5E5E] opacity-70 font-bold">
              M{" "}
              <Switch
                id={props.id}
                color="teal"
                checked={isChecked}
                onChange={handleSwitchChange}
              />{" "}
              A
            </div>
            <div className="switch flex gap-2 items-center text-[#5E5E5E] opacity-70 ">
              Passer en manuel
            </div>
          </div>
        )}
      </div>
      <div className="content bg-[#F5F5F5] rounded-xl p-3 min-h-[6rem] flex justify-center items-center">
        <div className="flex  items-end">
          <div className="value text-5xl mb-0 	 font-[500]  text-[#C6C6C6]">
            {getInitValue() === props.minValue ? "--" : getInitValue()}
          </div>
          <div className="unit text-[#5E5E5E] font-bold">{props.sign}</div>
        </div>
      </div>
      {isChecked ? (
        <div className={"flex gap-2"}>
          <button
            className="btn bg-primary text-white w-full rounded-lg p-3 text-xl font-bold mt-4 shadow-md disabled:bg-[#D7D7D7] disabled:cursor-not-allowed "
            disabled={props.gettingData && props.cmpntId === props.id!}
            // disabled={true}
            onClick={() => {
              props.setCmpntId
                ? props.setCmpntId!(props.id!)
                : setInterval(() => {
                    // setMesurements((prev) => prev + 1);
                    //set interval to 40 and stop the timer
                    setMesurements((prev) => {
                      if (prev < 100) {
                        return prev + 1;
                      } else {
                        return prev;
                      }
                    });
                  }, 250);
            }}
          >
            Commencer
          </button>
          <button
            className={`btn animate-pulse bg-red-500 text-white rounded-lg p-3 text-xl font-bold mt-4 shadow-md disabled:bg-[#D7D7D7] disabled:cursor-not-allowed
              ${
                (props.gettingData && props.cmpntId === props.id!) ||
                (props.gettingData &&
                  props.cmpntId === props.id! &&
                  !props.sensorExists)
                  ? ""
                  : "hidden"
              }`}
            // disabled={
            //   (props.gettingData && props.cmpntId === props.id!) ||
            //   !props.sensorExists
            // }
            // disabled={true}
            onClick={() => {
              setCancelCheck(true);
            }}
          >
            X
          </button>
        </div>
      ) : (
        <div className="flex justify-between items-center gap-4 ">
          <div className="w-[65%]">
            <RangeSlider
              value={props.value}
              setValue={props.setValue}
              maxValue={props.maxValue}
              minValue={props.minValue}
            />
          </div>
          <div className="buttons p-3 w-[35%] flex gap-2 justify-end">
            <MinusSvg
              className={`
              //TODO:back here
              ${
                mesurements <= props.minValue
                  ? "filter grayscale cursor-not-allowed"
                  : "cursor-pointer"
              }`}
              onClick={() =>
                // mesurements > 0 ?  setMesurements(parseFloat((mesurements - 0.1).toFixed(1))): null
                {
                  if (mesurements > props.minValue) {
                    if (props.id === "temp") {
                      setMesurements(
                        parseFloat((mesurements - 0.1).toFixed(1))
                      );
                      return;
                    }
                    if (props.id === "glycemi") {
                      setMesurements(
                        parseFloat((mesurements - 0.01).toFixed(2))
                      );
                    } else {
                      setMesurements(mesurements - 1);
                    }
                  }
                }
              }
            />
            <PlusSvg
              className={`
                         //TODO:back here
                         ${
                           mesurements >= props.maxValue
                             ? "filter grayscale cursor-not-allowed"
                             : "cursor-pointer"
                         }`}
              onClick={() =>
                // setMesurements(parseFloat((mesurements + 0.1).toFixed(1)))
                {
                  const biasValue = mesurements == 0 ? props.minValue : 0;
                  if (mesurements < props.maxValue) {
                    if (props.id === "temp") {
                      setMesurements(
                        parseFloat((biasValue + mesurements + 0.1).toFixed(1))
                      );
                      return;
                    }
                    if (props.id === "glycemi") {
                      setMesurements(
                        parseFloat((biasValue + mesurements + 0.01).toFixed(2))
                      );
                    } else {
                      setMesurements(biasValue + mesurements + 1);
                    }
                  }
                }
              }
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default CardWithSingleValue;
