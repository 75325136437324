// specialistConclusionSlice.ts

import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { SLICE } from "../../enums/store";
import { IAnalysesRadioState } from "@interfaces/prescriptionStep";
import { dataNumberByIndex } from "@interfaces/Stepper";
const initialState: any = {
    text:null
};  

const specialistConclusionSlice = createSlice({
  name: SLICE.SPECIALIST_CONSLUSION,
  initialState,
  reducers: {
    setSpecialistConclusion:(state, action: PayloadAction<any>) => {
      state.text=action.payload
    },
    
  },
});

export const {
  setSpecialistConclusion
} = specialistConclusionSlice.actions;
export default specialistConclusionSlice.reducer;
